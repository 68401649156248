<template>
  <v-dialog v-model="show" fullscreen transition="dialog-bottom-transition">
      <v-form>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Iconos y Colores</v-toolbar-title>
      </v-toolbar>
      
      <v-card-title>Colores según el estado del Turno</v-card-title> 
      <v-card-text>        
        <v-row dense>
          
          <v-col cols="12" sm="6" md="6">
            <v-chip
              text-color="white"
              class="ma-2"
              color="green"
            >Turno disponible para ser reservado</v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-chip text-color="white" class="ma-2" color="blue">Turno reservado</v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-chip text-color="white" class="ma-2" color="grey">Entre Turno reservado</v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-chip
              text-color="white"
              class="ma-2"
              color="orange"
            >Paciente en sala de espera</v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-chip text-color="white" class="ma-2" color="purple">Paciente atendido</v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-chip
              text-color="white"
              class="ma-2"
              color="red"
            >Día feriado o cancelado para el profesional</v-chip>
          </v-col>
           <v-col cols="12" sm="12" md="12">
            <v-chip
              text-color="white"
              class="ma-2"
              color="blue"
            >Turno que dura X espacios consecutivos
            <v-avatar class="ml-2" color="#541402" size="32"><span class="white--text">X</span></v-avatar>
            </v-chip>
            
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-title>Iconos en acciones de los Turnos</v-card-title>
      <v-card-text>
        <v-row dense>
          <v-col cols="12" sm="6" md="6">
            <v-chip label color="transparent" text-color="green">
            <v-icon x-large color="green" class="mr-3">mdi-calendar</v-icon>
            <h3>Nuevo Turno</h3>
            </v-chip>
          </v-col>
          
          <v-col cols="12" sm="6" md="6">
            <v-chip label color="transparent" text-color="purple">
            <v-icon x-large color="purple" class="mr-3">mdi-pencil</v-icon>
            <h3>Editar Turno</h3>
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-chip label color="transparent" text-color="red">
            <v-icon x-large color="red" class="mr-3">mdi-delete</v-icon>
            <h3>Borrar Turno</h3>
            </v-chip>
          </v-col>

          <v-col cols="12" sm="6" md="6">
            <v-chip label color="transparent" text-color="grey">  
            <v-icon x-large color="grey" class="mr-3">mdi-calendar-multiple</v-icon>
            <h3>Reservar Entre Turno</h3>
            </v-chip>
          </v-col>
          <v-col cols="12" sm="6" md="6">
              <v-chip label color="transparent" text-color="blue"> 
            <v-icon x-large color="blue" class="mr-3">mdi-heart-pulse</v-icon>
            <h3>Historia Clínica del Paciente</h3>
          </v-chip>
          </v-col>
          
          <v-col cols="12" sm="6" md="6">
            <v-chip label color="transparent" text-color="#d35400"> 
            <v-icon x-large color="#d35400" class="mr-3">mdi-currency-usd</v-icon>
            <h3>Registrar Cobro o Deuda Paciente</h3>
            </v-chip>
          </v-col>
           <v-col cols="12" sm="6" md="6">
            <v-chip label color="transparent" text-color="black">
            <v-icon x-large color="black" class="mr-3">mdi-content-duplicate</v-icon>
            <h3>Copiar o Reprogramar Turno</h3>
            </v-chip>
          </v-col>
            <v-col cols="12" sm="6" md="6">
            <v-chip label color="transparent" text-color="indigo">
            <v-icon x-large color="indigo" class="mr-3">mdi-video</v-icon>
            <h3>Inciar Videoconsulta</h3>
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="show=false">Salir</v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      }
    }
  },
  props: ["visible"]
};
</script>