<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Ver Turnos Eliminados</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Turnos Eliminados</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :items="estados"
                v-model="estadoTurno"
                item-text="estadoTurno"
                item-value="estadoTurno"
                label="Estado Turno"
                dense
                prepend-icon="mdi-calendar-multiple"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="dni"
                prepend-icon="mdi-account"
                label="D.N.I"
                dense
                @keypress="onlyNumber"
                maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="apellidos"
                prepend-icon="mdi-account"
                label="Apellidos Paciente"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="nombres"
                prepend-icon="mdi-account"
                label="Nombres Paciente"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="isLoading"
          :loading="isLoading"
          color="primary"
          @click.stop="handleBuscarTurnos"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaTurnos"
        class="elevation-1"
        locale="es-ar"
        item-key="idTurno"
        :items-per-page="10"
        disable-sort
        disable-filtering
        :server-items-length="cantRegistros"
        :loading="isLoading"
        ref="myTable"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
          itemsPerPageOptions: [5, 10, 15],
        }"
        :options.sync="options"
      >
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          :disabled="listaTurnos.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <SeleccionarTipoImpresion
        :visible="showSeleccionarTipoImpresion"
        :listaTurnos="listaTurnosImprimir"
        :fechaDesde="fechaDesde"
        :fechaHasta="fechaHasta"
        :esEliminado="true"
        @close="showSeleccionarTipoImpresion = false"
      ></SeleccionarTipoImpresion>
    </v-card>
  </div>
</template>
<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import router from "../../router/index.js";
  import SeleccionarTipoImpresion from "../Turnos/SeleccionarTipoImpresion.vue";
  import GestionTurnosSemanaVue from "./GestionTurnosSemana.vue";
  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      dni: null,
      apellidos: null,
      nombres: null,
      requiredRules: [(v) => !!v || "Dato obligatorio"],
      prof: "",
      profsBuscar: [],
      listaTurnos: [],
      listaTurnosImprimir: [],
      headers: [
        { text: "Dia", value: "diaSemana" },
        { text: "Fecha", value: "fechaTurnoString" },
        { text: "Hora", value: "horaInicioString", sortable: false },
        { text: "Dni", value: "dniPaciente" },
        { text: "Paciente", value: "nombrePaciente" },
        { text: "Telefono", value: "celular" },
        { text: "Profesional", value: "nombreProfesional" },
        { text: "Observaciones", value: "observacionesTurno" },
        { text: "Estado", value: "estadoTurnoString" },
        { text: "Usuario Elimina", value: "usuarioEliminacion" },
        { text: "Fecha Elimina", value: "fechaEliminacionString" },
      ],
      fechaDesde: null,
      fechaHasta: null,
      estados: ["TODOS", "RESERVADO", "EN ESPERA", "ATENDIDO"],
      estadoTurno: "",
      showSeleccionarTipoImpresion: false,
      isLoading: false,
      options: {},
      cantRegistros: null,
    }),
    watch: {
      options: {
        immediate: true,
        deep: true,
        handler(val) {
          if (Object.keys(val).length) {
            this.buscarTurnos();
          }
        },
      },
    },
    methods: {
      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
          // 46 is dot
          $event.preventDefault();
        }
      },
      getTelValue(item) {
        console.log(item);
        return "";
      },
      salir() {
        router.push({ path: "/Home" });
      },
      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      toISOLocal(d) {
        var z = (n) => ("0" + n).slice(-2);
        var zz = (n) => ("00" + n).slice(-3);
        var off = d.getTimezoneOffset();
        var sign = off < 0 ? "+" : "-";
        off = Math.abs(off);
        return (
          d.getFullYear() +
          "-" +
          z(d.getMonth() + 1) +
          "-" +
          z(d.getDate()) +
          "T" +
          z(d.getHours()) +
          ":" +
          z(d.getMinutes()) +
          ":" +
          z(d.getSeconds()) +
          "." +
          zz(d.getMilliseconds()) +
          "Z"
        )
          .toString()
          .substr(0, 10);
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },
      handleBuscarTurnos() {
        this.options.page = 1;
        this.buscarTurnos();
      },
      buscarTurnos() {
        this.isLoading = true;
        var estadoTur = 0;
        if (this.estadoTurno == "TODOS") estadoTur = 0;
        if (this.estadoTurno == "RESERVADO") estadoTur = 1;
        if (this.estadoTurno == "EN ESPERA") estadoTur = 2;
        if (this.estadoTurno == "ATENDIDO") estadoTur = 3;
        const filtrosData = {
          dni: this.dni,
          apellidos: this.apellidos,
          nombres: this.nombres,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          idProfesional: this.prof,
          estadoTurno: estadoTur,
          page: this.options.page,
          items: this.options.itemsPerPage,
        };

        let self = this;
        axios
          .post("/Turnos/BuscarTurnosEliminados?", filtrosData)
          .then(function(response) {
            if (response.data != null && response.data != "") {
              self.listaTurnos = response.data.listaTurnos;
              self.cantRegistros = response.data.cantRegistros;
            } else {
              self.listaTurnos = [];
              self.showAlert({
                icon: "error",
                title:
                  "No hay turnos que coincidan con los filtros de búsqueda ingresados.",
                vm: self,
              });
            }
            self.isLoading = false;
          })
          .catch(function(error) {
            self.isLoading = false;
          });
      },
      imprimir() {
        var estadoTur = 0;
        if (this.estadoTurno == "TODOS") estadoTur = 0;
        if (this.estadoTurno == "RESERVADO") estadoTur = 1;
        if (this.estadoTurno == "EN ESPERA") estadoTur = 2;
        if (this.estadoTurno == "ATENDIDO") estadoTur = 3;
        const filtrosData = {
          dni: this.dni,
          apellidos: this.apellidos,
          nombres: this.nombres,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          idProfesional: this.prof,
          estadoTurno: estadoTur,
          page: -1,
          items: -1,
        };

        let self = this;
        this.$loading(true, "hola");
        axios
          .post("/Turnos/BuscarTurnosEliminados?", filtrosData)
          .then(function(response) {
            self.listaTurnosImprimir = response.data.listaTurnos;
          });
        this.$loading(false);
        this.showSeleccionarTipoImpresion = true;
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
    },
    created() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;
          if (self.profsBuscar.length > 1)
            self.profsBuscar.unshift({
              idProfesional: 0,
              nombreProfesional: "TODOS",
            });

          self.prof = self.profsBuscar[0].idProfesional;
        });

      this.fechaDesde = this.parseDate(
        this.formatDate(this.toISOLocal(new Date()))
      );
      this.fechaHasta = this.fechaDesde;
      this.estadoTurno = "TODOS";
    },
    components: {
      SeleccionarTipoImpresion,
    },
  };
</script>
