<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Imprimir Registros de Signos Vitales</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-radio-group v-model="radios">
          <template v-slot:label>
            <div><strong>Elegir fecha de impresión</strong></div>
          </template>
          <v-radio value="allDates" class="mt-4 mb-3">
            <template v-slot:label>
              <div>
                <strong class="success--text">Todas las fechas</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="rangeDates">
            <template v-slot:label>
              <div>
                <strong class="primary--text">Entre las fechas</strong>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-col cols="12" sm="4" md="6" class="mt-0 pt-0">
          <v-text-field
            v-model="fechaDesde"
            :value="fechaDesde"
            label="Fecha Desde"
            :rules="requiredRules"
            prepend-icon="mdi-calendar"
            type="date"
            dense
            :disabled="radios == 'allDates'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="6">
          <v-text-field
            v-model="fechaHasta"
            :value="fechaHasta"
            :rules="requiredRules"
            label="Fecha Hasta"
            prepend-icon="mdi-calendar"
            type="date"
            dense
            :disabled="radios == 'allDates'"
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
export default {
  data: (vm) => ({
    base64: "",
    fechaDesde: null,
    fechaHasta: null,
    allDates: true,
    rangeDates: false,
    radios: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
  }),
  props: ["visible", "signosVitales", "paciente"],
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
          this.getLogoUrl();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
        })
        .catch((error) => {});
    },
    setearModels() {
      var d = new Date();
      var desde = new Date(d.setMonth(d.getMonth() - 1));

      this.fechaDesde = this.parseDate(this.formatDate(this.toISOLocal(desde)));
      this.fechaHasta = this.parseDate(
        this.formatDate(this.toISOLocal(new Date()))
      );
      this.radios = "allDates";
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2500,
      });
    },
    imprimir() {
      if (new Date(this.fechaDesde) > new Date(this.fechaHasta)) {
        this.showAlert({
          icon: "error",
          title: "La fecha desde no puede ser mayor a la fecha hasta",
          vm: this,
        });
        return;
      }

      var doc = new jsPDF();

      doc.setProperties({
        title: "Signos Vitales",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Paciente: " + this.paciente.apellidos + " " + this.paciente.nombres,
        13,
        25
      );
      doc.text("D.N.I.: " + this.paciente.dni, 13, 30);
      doc.text("Feche de Nac.: " + this.paciente.fechaNac, 13, 35);
      doc.text("Edad: " + this.paciente.edad, 13, 40);

      if (this.base64 != null && this.base64 != "")
        doc.addImage(this.base64, 140, 5, 60, 20);

      doc.setFontSize(16);
      doc.text("Signos Vitales", doc.internal.pageSize.getWidth() / 2, 50, {
        align: "center",
      });
      doc.line(87, 51, 123, 51);

      var signosVitalesFecha = [];
      if (this.radios == "allDates") signosVitalesFecha = this.signosVitales;
      else
        signosVitalesFecha = this.signosVitales.filter(
          (x) =>
            new Date(x.fechaHora) >= new Date(this.fechaDesde) &&
            new Date(x.fechaHora) <=
              new Date(this.fechaHasta).setDate(
                new Date(this.fechaHasta).getDate() + 1
              )
        );

      if (signosVitalesFecha.length == 0) {
        this.showAlert({
          icon: "error",
          title: "No hay signos vitales en el rango de fecha elegido",
          vm: this,
        });
        return;
      }

      let index = 0;
      let pages = 0;
      let y = 60;
      signosVitalesFecha.forEach((signoVital) => {
        doc.line(13, y, 200, y);
        doc.setFontSize(13);
        if (signoVital.idProfesional) {
          doc.text(
            "Profesional: " +
              signoVital.matricula +
              " - " +
              signoVital.nomProfesional,
            13,
            y + 7
          );
        } else {
          doc.text("Enfermero: " + signoVital.nomProfesional, 13, y + 7);
        }
        doc.text("Fecha: " + signoVital.fechaHoraString, 13, y + 13);
        doc.line(13, y + 16, 200, y + 16);
        doc.setFontSize(12);

        if (signoVital.estatura == null || signoVital.estatura == "")
          doc.text("Estatura: S/D", 16, y + 26);
        else doc.text("Estatura: " + signoVital.estatura + " m", 16, y + 26);

        if (signoVital.peso == null || signoVital.peso == "")
          doc.text("Peso: S/D", 16, y + 36);
        else doc.text("Peso: " + signoVital.peso + " kg", 16, y + 36);

        if (signoVital.masaCorporal == null || signoVital.masaCorporal == "")
          doc.text("I.M.C.:: S/D", 16, y + 46);
        else
          doc.text("I.M.C.: " + signoVital.masaCorporal + " kg/m2", 16, y + 46);

        if (signoVital.temperatura == null || signoVital.temperatura == "")
          doc.text("Temperatura: S/D", 16, y + 56);
        else
          doc.text(
            "Temperatura: " + signoVital.temperatura + " °C",
            16,
            y + 56
          );

        if (
          signoVital.porcGrasaCorporal == null ||
          signoVital.porcGrasaCorporal == ""
        )
          doc.text("% Grasa Corporal: S/D", 16, y + 66);
        else
          doc.text(
            "% Grasa Corporal: " + signoVital.porcGrasaCorporal + " %",
            16,
            y + 66
          );

        if (
          signoVital.porcMasaMuscular == null ||
          signoVital.porcMasaMuscular == ""
        )
          doc.text("% Masa Muscular: S/D", 16, y + 76);
        else
          doc.text(
            "% Masa Muscular: " + signoVital.porcMasaMuscular + " %",
            16,
            y + 76
          );

        if (
          signoVital.frecRespiratoria == null ||
          signoVital.frecRespiratoria == ""
        )
          doc.text("Frec. Respiratoria: S/D", 140, y + 26);
        else
          doc.text(
            "Frec. Respiratoria: " + signoVital.frecRespiratoria + " r/m",
            140,
            y + 26
          );

        if (signoVital.sistolica == null || signoVital.sistolica == "")
          doc.text("Pres. Sistólica: S/D", 140, y + 36);
        else
          doc.text(
            "Pres. Sistólica: " + signoVital.sistolica + " mmHg",
            140,
            y + 36
          );

        if (signoVital.diastolica == null || signoVital.diastolica == "")
          doc.text("Pres. Diastólica: S/D", 140, y + 46);
        else
          doc.text(
            "Pres. Diastólica: " + signoVital.diastolica + " mmHg",
            140,
            y + 46
          );

        if (signoVital.frecCardiaca == null || signoVital.frecCardiaca == "")
          doc.text("Frec. Cardíaca: S/D", 140, y + 56);
        else
          doc.text(
            "Frec. Cardíaca: " + signoVital.frecCardiaca + " lpm",
            140,
            y + 56
          );

        if (signoVital.perimCefalico == null || signoVital.perimCefalico == "")
          doc.text("Per. Cefálico: S/D", 140, y + 66);
        else
          doc.text(
            "Per. Cefálico: " + signoVital.perimCefalico + " cm",
            140,
            y + 66
          );

        if (signoVital.satOxigeno == null || signoVital.satOxigeno == "")
          doc.text("Sat. Oxígeno: S/D", 140, y + 76);
        else doc.text("Sat. Oxígeno: " + signoVital.satOxigeno, 140, y + 76);

        if (signoVital.glucemia == null || signoVital.glucemia == "")
          doc.text("Glucemia: S/D", 16, y + 86);
        else
          doc.text("Glucemia: " + signoVital.glucemia + " mg/dL", 16, y + 86);

        if (index == 0) y = 151;
        else y = 116;
        index++;

        if (index < signosVitalesFecha.length && index == 2 + 3 * pages) {
          y = 20;
          pages++;
          doc.addPage();
        } else if (
          index < signosVitalesFecha.length &&
          index % 2 == 0 &&
          index != 2
        ) {
          y = 206;
        }
      });
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
};
</script>
