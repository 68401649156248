<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de {{ resolveLabel }}</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de {{ resolveLabel }}</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-select
                :items="estados"
                item-text="nombreEstado"
                item-value="idEstado"
                menu-props="auto"
                label="Estado"
                hide-details
                prepend-icon="mdi-account"
                v-model="estado"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarObrasSociales(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaObrasSociales"
        class="elevation-1"
        locale="es-ar"
        item-key="idObraSocial"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.estado="{ item }">
          <v-simple-checkbox v-model="item.estado" disabled></v-simple-checkbox>
        </template>
        <template v-slot:item.disponibleWeb="{ item }">
          <v-simple-checkbox
            v-model="item.disponibleWeb"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarObraSocial(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar {{ resolveLabelSingular }}</span>
          </v-tooltip>
          <v-tooltip top v-if="!$store.state.usuario.institucion.esEmpresa">
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                class="mr-3"
                color="blue"
                v-bind="attrs"
                @click="planes(item)"
                >mdi-briefcase-plus</v-icon
              >
            </template>
            <span>Gestionar Planes</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarObraSocial(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar {{ resolveLabelSingular }}</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaObraSocial"
          >Nueva {{ resolveLabelAbreviado }}</v-btn
        >
        <v-btn
          color="warning"
          :disabled="listaObrasSociales.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevaObraSocial
        :visible="showNuevaObraSocial"
        @close="cierraNuevaObraSocial()"
        @obraSocialRegistrada="obraSocialRegistrada()"
      ></NuevaObraSocial>
      <EditarObraSocial
        :visible="showEditarObraSocial"
        :idObraSocial="editedItem.idObraSocial"
        @close="cierraEditarObraSocial()"
        @obraSocialModificada="obraSocialModificada()"
      ></EditarObraSocial>
      <Planes
        :visible="showPlanes"
        :nombreObraSocial="editedItem.nombre"
        :idObraSocial="editedItem.idObraSocial"
        @close="showPlanes = false"
      ></Planes>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevaObraSocial from "../Configuraciones/NuevaObraSocial.vue";
import EditarObraSocial from "../Configuraciones/EditarObraSocial.vue";
import Planes from "../Configuraciones/Planes.vue";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    nombre: null,
    listaObrasSociales: [],
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Estado", value: "estado" },
      { text: "Disp. Web", value: "disponibleWeb" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevaObraSocial: false,
    showEditarObraSocial: false,
    showPlanes: false,
    editedItem: {},
    editedIndex: -1,
    estado: null,
    estados: [
      { nombreEstado: "Todas", idEstado: 2 },
      { nombreEstado: "Activas", idEstado: 1 },
      { nombreEstado: "Inactivas", idEstado: 0 },
    ],
  }),
  computed: {
    resolveLabel() {
      return this.$store.state.usuario.institucion.esEmpresa
        ? "Sedes"
        : "Obras Sociales";
    },
    resolveLabelSingular() {
      return this.$store.state.usuario.institucion.esEmpresa
        ? "Sede"
        : "Obras Social";
    },
    resolveLabelAbreviado() {
      return this.$store.state.usuario.institucion.esEmpresa
        ? "Sede"
        : "O. SOCIAL";
    },
  },
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarObraSocial(item) {
      this.$swal({
        title: `Borrar ${
          this.$store.state.usuario.institucion.esEmpresa
            ? "Sede"
            : "Obra Social"
        }`,
        text: `¿Desea borrar la ${
          this.$store.state.usuario.institucion.esEmpresa
            ? "sede"
            : "obra social"
        }?`,
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idObraSocial = item.idObraSocial;
          let self = this;
          axios
            .get("/ObraSocial/BorrarObraSocial?", {
              params: {
                idObraSocial: idObraSocial,
              },
            })
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: `La ${
                    self.$store.state.usuario.institucion.esEmpresa
                      ? "sede"
                      : "obra social"
                  } tiene transacciones asociadas`,
                  vm: self,
                });
              } else {
                self.$store.dispatch("updateSession", {
                  usuario: self.$store.state.usuario.usuario,
                  idInstitucion:
                    self.$store.state.usuario.institucion.idInstitucion,
                });
                self.showAlert({
                  icon: "success",
                  title: `${
                    self.$store.state.usuario.institucion.esEmpresa
                      ? "Sede"
                      : "Obra Social"
                  } borrada exitosamente`,
                  vm: self,
                });
                setTimeout(self.buscarObrasSociales(0), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: `La ${
                  self.$store.state.usuario.institucion.esEmpresa
                    ? "sede"
                    : "obra social"
                } no pudo ser borrada`,
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevaObraSocial() {
      this.showNuevaObraSocial = false;
      //this.buscarObrasSociales();
    },
    cierraEditarObraSocial() {
      this.showEditarObraSocial = false;
      //this.buscarObrasSociales();
    },
    obraSocialRegistrada() {
      this.showNuevaObraSocial = false;
      this.buscarObrasSociales(0);
    },
    obraSocialModificada() {
      this.showEditarObraSocial = false;
      this.buscarObrasSociales(0);
    },
    nuevaObraSocial() {
      this.showNuevaObraSocial = true;
    },
    editarObraSocial(item) {
      this.editedIndex = this.listaObrasSociales.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarObraSocial = true;
    },
    planes(item) {
      this.editedIndex = this.listaObrasSociales.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showPlanes = true;
    },
    buscarObrasSociales(state) {
      const filtrosData = {
        nombre: this.nombre,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        estado: this.estado,
      };

      let self = this;
      axios
        .get("/ObraSocial/GetObraSocialNomDeInst?", {
          params: {
            nombre: filtrosData.nombre,
            idInstitucion: filtrosData.idInstitucion,
            estado: filtrosData.estado,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaObrasSociales = response.data;
          else {
            self.listaObrasSociales = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title: `No hay ${
                  self.$store.state.usuario.institucion.esEmpresa
                    ? "sedes"
                    : "obras sociales"
                } que coincidan con el criterio de búsqueda.`,
                vm: self,
              });
            }
          }
        })
        .catch(function(error) {});
    },
    imprimir() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = ["Nombre", "Observaciones"];
      let importeTotal = 0;
      source.items.forEach((element) => {
        var temp = [element.nombre, element.observaciones];
        rows.push(temp);
      });
      //let columnHeader = source.items.headers
      //let rows = source.items.rows
      var doc = new jsPDF();
      doc.setProperties({
        title: `Listado ${
          this.$store.state.usuario.institucion.esEmpresa
            ? "Sedes"
            : "Obras Sociales"
        }`,
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);

      doc.setFontSize(16);
      doc.text(
        `Listado ${
          this.$store.state.usuario.institucion.esEmpresa
            ? "Sedes"
            : "Obras Sociales"
        }`,
        doc.internal.pageSize.getWidth() / 2,
        33,
        { align: "center" }
      );
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function(table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });

      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
  created() {
    this.estado = 2;
    this.buscarObrasSociales(0);
  },
  components: {
    NuevaObraSocial,
    EditarObraSocial,
    Planes,
  },
};
</script>
