<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Odontograma</span>
        <v-spacer></v-spacer>
        <span>
          <v-switch dense v-model="infantil" label="Infantil"></v-switch>
        </span>
      </v-card-title>
      <div style="overflow: auto">
        <svg id="svg" width="830px" height="180px">
          <g transform="scale(2), translate(5,10)">
            <line
              v-if="!infantil"
              x1="0"
              y1="33"
              x2="430"
              y2="33"
              style="stroke: rgb(0, 0, 0); stroke-width: 0.5"
            />
            <line
              v-if="!infantil"
              x1="202.5"
              y1="-10"
              x2="202.5"
              y2="72"
              style="stroke: rgb(0, 0, 0); stroke-width: 0.5"
            />

            <line
              v-if="infantil"
              x1="70"
              y1="33"
              x2="333"
              y2="33"
              style="stroke: rgb(0, 0, 0); stroke-width: 0.5"
            />
            <line
              v-if="infantil"
              x1="202.5"
              y1="-10"
              x2="202.5"
              y2="72"
              style="stroke: rgb(0, 0, 0); stroke-width: 0.5"
            />

            <g v-if="infantil == false" transform="translate(0,0)">
              <polygon
                :class="ausencia.a1 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(1, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r15"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a1 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(1, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r12"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a1 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(1, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r14"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a1 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(1, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r13"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a1 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(1, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r11"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c1"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(1, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                18
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t1"
                :stroke="tratamiento.t1"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i1"
                :stroke="implante.i1"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f1"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o1"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o1"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o1"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a1"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e1"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e1"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m1"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m1 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m1 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g v-if="infantil == false" transform="translate(25,0)">
              <polygon
                :class="ausencia.a2 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(2, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r25"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a2 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(2, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r22"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a2 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(2, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r24"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a2 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(2, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r23"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a2 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(2, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r21"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c2"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(2, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                17
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t2"
                :stroke="tratamiento.t2"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i2"
                :stroke="implante.i2"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f2"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o2"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o2"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o2"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a2"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e2"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e2"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m2"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m2 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m2 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(50,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a3 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(3, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r35"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a3 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(3, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r32"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a3 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(3, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r34"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a3 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(3, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r33"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a3 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(3, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r31"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c3"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(3, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                16
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t3"
                :stroke="tratamiento.t3"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i3"
                :stroke="implante.i3"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f3"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o3"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o3"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o3"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a3"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e3"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e3"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m3"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m3 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m3 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(75,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a4 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(4, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r45"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a4 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(4, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r42"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a4 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(4, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r44"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a4 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(4, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r43"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a4 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(4, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r41"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c4"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(4, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                15
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t4"
                :stroke="tratamiento.t4"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i4"
                :stroke="implante.i4"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f4"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o4"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o4"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o4"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a4"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e4"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e4"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m4"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m4 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m4 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(100,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a5 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(5, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r55"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a5 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(5, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r52"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a5 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(5, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r54"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a5 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(5, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r53"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a5 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(5, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r51"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c5"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(5, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                14
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t5"
                :stroke="tratamiento.t5"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i5"
                :stroke="implante.i5"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f5"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o5"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o5"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o5"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a5"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e5"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e5"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m5"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m5 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m5 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(125,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a6 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(6, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r65"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a6 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(6, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r62"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a6 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(6, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r64"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a6 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(6, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r63"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a6 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(6, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r61"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c6"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(6, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                13
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t6"
                :stroke="tratamiento.t6"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i6"
                :stroke="implante.i6"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f6"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o6"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o6"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o6"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a6"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e6"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e6"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m6"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m6 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m6 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(150,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a7 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(7, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r75"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a7 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(7, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r72"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a7 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(7, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r74"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a7 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(7, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r73"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a7 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(7, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r71"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c7"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(7, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                12
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t7"
                :stroke="tratamiento.t7"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i7"
                :stroke="implante.i7"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f7"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o7"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o7"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o7"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a7"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e7"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e7"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m7"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m7 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m7 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(175,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a8 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(8, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r85"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a8 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(8, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r82"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a8 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(8, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r84"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a8 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(8, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r83"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a8 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(8, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r81"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c8"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(8, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                11
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t8"
                :stroke="tratamiento.t8"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i8"
                :stroke="implante.i8"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f8"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o8"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o8"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o8"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a8"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e8"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e8"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m8"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m8 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m8 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(210,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a9 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(9, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r95"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a9 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(9, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r92"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a9 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(9, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r93"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a9 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(9, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r94"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a9 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(9, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r91"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c9"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(9, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                21
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t9"
                :stroke="tratamiento.t9"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i9"
                :stroke="implante.i9"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f9"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o9"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o9"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o9"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a9"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e9"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e9"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m9"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m9 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m9 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(235,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a10 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(10, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r105"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a10 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(10, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r102"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a10 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(10, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r103"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a10 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(10, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r104"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a10 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(10, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r101"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c10"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(10, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                22
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t10"
                :stroke="tratamiento.t10"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i10"
                :stroke="implante.i10"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f10"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o10"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o10"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o10"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a10"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e10"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e10"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m10"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m10 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m10 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(260,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a11 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(11, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r115"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a11 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(11, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r112"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a11 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(11, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r113"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a11 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(11, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r114"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a11 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(11, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r111"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c11"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(11, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                23
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t11"
                :stroke="tratamiento.t11"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i11"
                :stroke="implante.i11"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f11"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o11"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o11"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o11"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a11"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e11"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e11"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m11"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m11 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m11 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(285,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a12 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(12, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r125"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a12 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(12, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r122"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a12 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(12, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r123"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a12 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(12, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r124"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a12 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(12, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r121"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c12"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(12, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                24
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t12"
                :stroke="tratamiento.t12"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i12"
                :stroke="implante.i12"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f12"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o12"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o12"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o12"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a12"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e12"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e12"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m12"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m12 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m12 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(310,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a13 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(13, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r135"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a13 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(13, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r132"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a13 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(13, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r133"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a13 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(13, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r134"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a13 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(13, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r131"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c13"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(13, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                25
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t13"
                :stroke="tratamiento.t13"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i13"
                :stroke="implante.i13"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f13"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o13"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o13"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o13"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a13"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e13"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e13"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m13"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m13 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m13 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(335,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a14 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(14, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r145"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a14 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(14, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r142"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a14 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(14, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r143"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a14 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(14, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r144"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a14 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(14, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r141"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c14"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(14, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                26
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t14"
                :stroke="tratamiento.t14"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i14"
                :stroke="implante.i14"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f14"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o14"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o14"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o14"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a14"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e14"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e14"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m14"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m14 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m14 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(360,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a15 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(15, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r155"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a15 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(15, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r152"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a15 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(15, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r153"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a15 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(15, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r154"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a15 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(15, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r151"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c15"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(15, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                27
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t15"
                :stroke="tratamiento.t15"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i15"
                :stroke="implante.i15"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f15"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o15"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o15"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o15"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a15"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e15"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e15"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m15"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m15 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m15 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(385,0)" v-if="infantil == false">
              <polygon
                :class="ausencia.a16 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(16, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r165"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a16 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(16, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r162"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a16 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(16, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r163"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a16 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(16, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r164"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a16 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(16, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r161"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c16"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(16, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                28
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t16"
                :stroke="tratamiento.t16"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i16"
                :stroke="implante.i16"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f16"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o16"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o16"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o16"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a16"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e16"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e16"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m16"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m16 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m16 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(0,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a17 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(17, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r172"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a17 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(17, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r175"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a17 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(17, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r174"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a17 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(17, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r173"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a17 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(17, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r171"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c17"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(17, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                48
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t17"
                :stroke="tratamiento.t17"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i17"
                :stroke="implante.i17"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f17"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o17"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o17"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o17"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a17"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e17"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e17"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m17"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m17 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m17 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(25,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a18 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(18, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r182"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a18 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(18, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r185"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a18 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(18, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r184"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a18 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(18, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r183"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a18 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(18, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r181"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c18"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(18, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                47
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t18"
                :stroke="tratamiento.t18"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i18"
                :stroke="implante.i18"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f18"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o18"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o18"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o18"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a18"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e18"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e18"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m18"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m18 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m18 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(50,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a19 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(19, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r192"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a19 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(19, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r195"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a19 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(19, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r194"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a19 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(19, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r193"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a19 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(19, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r191"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c19"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(19, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                46
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t19"
                :stroke="tratamiento.t19"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i19"
                :stroke="implante.i19"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f19"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o19"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o19"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o19"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a19"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e19"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e19"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m19"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m19 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m19 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(75,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a20 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(20, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r202"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a20 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(20, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r205"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a20 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(20, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r204"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a20 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(20, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r203"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a20 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(20, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r201"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c20"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(20, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                45
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t20"
                :stroke="tratamiento.t20"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i20"
                :stroke="implante.i20"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f20"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o20"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o20"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o20"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a20"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e20"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e20"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m20"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m20 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m20 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(100,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a21 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(21, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r212"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a21 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(21, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r215"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a21 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(21, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r214"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a21 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(21, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r213"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a21 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(21, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r211"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c21"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(21, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                44
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t21"
                :stroke="tratamiento.t21"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i21"
                :stroke="implante.i21"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f21"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o21"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o21"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o21"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a21"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e21"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e21"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m21"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m21 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m21 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(125,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a22 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(22, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r222"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a22 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(22, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r225"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a22 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(22, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r224"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a22 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(22, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r223"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a22 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(22, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r221"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c22"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(22, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                43
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t22"
                :stroke="tratamiento.t22"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i22"
                :stroke="implante.i22"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f22"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o22"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o22"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o22"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a22"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e22"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e22"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m22"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m22 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m22 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(150,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a23 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(23, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r232"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a23 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(23, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r235"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a23 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(23, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r234"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a23 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(23, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r233"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a23 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(23, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r231"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c23"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(23, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                42
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t23"
                :stroke="tratamiento.t23"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i23"
                :stroke="implante.i23"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f23"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o23"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o23"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o23"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a23"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e23"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e23"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m23"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m23 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m23 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(175,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a24 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(24, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r242"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a24 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(24, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r245"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a24 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(24, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r244"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a24 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(24, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r243"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a24 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(24, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r241"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c24"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(24, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                41
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t24"
                :stroke="tratamiento.t24"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i24"
                :stroke="implante.i24"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f24"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o24"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o24"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o24"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a24"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e24"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e24"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m24"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m24 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m24 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(210,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a25 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(25, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r252"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a25 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(25, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r255"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a25 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(25, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r253"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a25 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(25, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r254"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a25 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(25, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r251"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c25"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(25, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                31
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t25"
                :stroke="tratamiento.t25"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i25"
                :stroke="implante.i25"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f25"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o25"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o25"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o25"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a25"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e25"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e25"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m25"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m25 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m25 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(235,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a26 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(26, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r262"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a26 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(26, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r265"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a26 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(26, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r263"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a26 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(26, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r264"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a26 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(26, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r261"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c26"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(26, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                32
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t26"
                :stroke="tratamiento.t26"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i26"
                :stroke="implante.i26"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f26"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o26"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o26"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o26"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a26"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e26"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e26"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m26"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m26 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m26 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(260,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a27 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(27, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r272"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a27 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(27, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r275"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a27 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(27, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r273"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a27 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(27, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r274"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a27 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(27, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r271"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c27"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(27, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                33
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t27"
                :stroke="tratamiento.t27"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i27"
                :stroke="implante.i27"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f27"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o27"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o27"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o27"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a27"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e27"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e27"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m27"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m27 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m27 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(285,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a28 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(28, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r282"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a28 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(28, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r285"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a28 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(28, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r283"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a28 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(28, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r284"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a28 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(28, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r281"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c28"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(28, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                34
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t28"
                :stroke="tratamiento.t28"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i28"
                :stroke="implante.i28"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f28"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o28"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o28"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o28"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a28"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e28"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e28"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m28"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m28 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m28 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(310,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a29 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(29, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r292"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a29 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(29, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r295"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a29 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(29, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r293"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a29 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(29, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r294"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a29 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(29, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r291"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c29"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(29, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                35
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t29"
                :stroke="tratamiento.t29"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i29"
                :stroke="implante.i29"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f29"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o29"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o29"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o29"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a29"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e29"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e29"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m29"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m29 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m29 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(335,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a30 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(30, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r302"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a30 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(30, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r305"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a30 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(30, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r303"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a30 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(30, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r304"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a30 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(30, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r301"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c30"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(30, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                36
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t30"
                :stroke="tratamiento.t30"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i30"
                :stroke="implante.i30"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f30"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o30"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o30"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o30"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a30"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e30"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e30"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m30"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m30 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m30 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(360,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a31 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(31, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r312"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a31 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(31, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r315"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a31 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(31, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r313"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a31 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(31, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r314"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a31 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(31, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r311"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c31"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(31, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                37
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t31"
                :stroke="tratamiento.t31"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i31"
                :stroke="implante.i31"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f31"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o31"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o31"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o31"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a31"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e31"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e31"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m31"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m31 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m31 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(385,50)" v-if="infantil == false">
              <polygon
                :class="ausencia.a32 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(32, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r322"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a32 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(32, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r325"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a32 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(32, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r323"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a32 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(32, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r324"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a32 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(32, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r321"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c32"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(32, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                38
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t32"
                :stroke="tratamiento.t32"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i32"
                :stroke="implante.i32"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f32"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o32"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o32"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o32"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a32"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e32"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e32"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m32"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m32 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m32 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(75,0)" v-if="infantil">
              <polygon
                :class="ausencia.a33 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(33, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r335"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a33 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(33, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r332"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a33 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(33, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r334"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a33 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(33, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r333"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a33 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(33, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r331"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c33"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(33, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                55
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t33"
                :stroke="tratamiento.t33"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i33"
                :stroke="implante.i33"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f33"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o33"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o33"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o33"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a33"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e33"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e33"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m33"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m33 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m33 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(100,0)" v-if="infantil">
              <polygon
                :class="ausencia.a34 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(34, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r345"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a34 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(34, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r342"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a34 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(34, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r344"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a34 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(34, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r343"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a34 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(34, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r341"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c34"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(34, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                54
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t34"
                :stroke="tratamiento.t34"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i34"
                :stroke="implante.i34"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f34"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o34"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o34"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o34"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a34"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e34"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e34"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m34"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m34 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m34 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(125,0)" v-if="infantil">
              <polygon
                :class="ausencia.a35 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(35, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r355"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a35 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(35, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r352"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a35 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(35, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r354"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a35 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(35, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r353"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a35 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(35, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r351"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c35"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(35, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                53
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t35"
                :stroke="tratamiento.t35"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i35"
                :stroke="implante.i35"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f35"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o35"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o35"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o35"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a35"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e35"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e35"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m35"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m35 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m35 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(150,0)" v-if="infantil">
              <polygon
                :class="ausencia.a36 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(36, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r365"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a36 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(36, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r362"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a36 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(36, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r364"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a36 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(36, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r363"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a36 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(36, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r361"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c36"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(36, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                52
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t36"
                :stroke="tratamiento.t36"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i36"
                :stroke="implante.i36"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f36"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o36"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o36"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o36"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a36"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e36"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e36"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m36"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m36 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m36 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(175,0)" v-if="infantil">
              <polygon
                :class="ausencia.a37 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(37, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r375"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a37 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(37, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r372"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a37 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(37, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r374"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a37 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(37, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r373"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a37 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(37, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r371"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c37"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(37, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                51
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t37"
                :stroke="tratamiento.t37"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i37"
                :stroke="implante.i37"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f37"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o37"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o37"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o37"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a37"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e37"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e37"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m37"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m37 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m37 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(210,0)" v-if="infantil">
              <polygon
                :class="ausencia.a38 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(38, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r385"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a38 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(38, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r382"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a38 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(38, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r383"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a38 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(38, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r384"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a38 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(38, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r381"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c38"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(38, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                61
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t38"
                :stroke="tratamiento.t38"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i38"
                :stroke="implante.i38"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f38"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o38"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o38"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o38"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a38"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e38"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e38"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m38"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m38 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m38 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(235,0)" v-if="infantil">
              <polygon
                :class="ausencia.a39 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(39, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r395"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a39 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(39, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r392"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a39 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(39, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r393"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a39 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(39, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r394"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a39 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(39, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r391"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c39"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(39, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                62
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t39"
                :stroke="tratamiento.t39"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i39"
                :stroke="implante.i39"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f39"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o39"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o39"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o39"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a39"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e39"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e39"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m39"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m39 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m39 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(260,0)" v-if="infantil">
              <polygon
                :class="ausencia.a40 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(40, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r405"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a40 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(40, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r402"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a40 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(40, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r403"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a40 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(40, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r404"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a40 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(40, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r401"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c40"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(40, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                63
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t40"
                :stroke="tratamiento.t40"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i40"
                :stroke="implante.i40"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f40"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o40"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o40"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o40"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a40"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e40"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e40"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m40"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m40 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m40 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(285,0)" v-if="infantil">
              <polygon
                :class="ausencia.a41 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(41, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r415"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a41 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(41, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r412"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a41 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(41, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r413"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a41 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(41, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r414"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a41 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(41, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r411"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c41"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(41, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                64
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t41"
                :stroke="tratamiento.t41"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i41"
                :stroke="implante.i41"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f41"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o41"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o41"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o41"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a41"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e41"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e41"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m41"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m41 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m41 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(310,0)" v-if="infantil">
              <polygon
                :class="ausencia.a42 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(42, 5)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r425"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a42 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(42, 2)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r422"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a42 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(42, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r423"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a42 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(42, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r424"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a42 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(42, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r421"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c42"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(42, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                65
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t42"
                :stroke="tratamiento.t42"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i42"
                :stroke="implante.i42"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f42"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o42"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o42"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o42"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a42"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e42"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e42"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m42"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m42 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m42 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(75,50)" v-if="infantil">
              <polygon
                :class="ausencia.a43 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(43, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r432"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a43 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(43, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r435"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a43 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(43, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r434"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a43 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(43, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r433"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a43 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(43, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r431"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c43"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(43, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                85
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t43"
                :stroke="tratamiento.t43"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i43"
                :stroke="implante.i43"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f43"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o43"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o43"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o43"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a43"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e43"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e43"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m43"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m43 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m43 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(100,50)" v-if="infantil">
              <polygon
                :class="ausencia.a44 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(44, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r442"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a44 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(44, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r445"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a44 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(44, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r444"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a44 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(44, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r443"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a44 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(44, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r441"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c44"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(44, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                84
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t44"
                :stroke="tratamiento.t44"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i44"
                :stroke="implante.i44"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f44"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o44"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o44"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o44"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a44"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e44"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e44"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m44"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m44 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m44 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(125,50)" v-if="infantil">
              <polygon
                :class="ausencia.a45 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(45, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r452"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a45 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(45, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r455"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a45 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(45, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r454"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a45 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(45, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r453"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a45 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(45, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r451"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c45"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(45, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                83
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t45"
                :stroke="tratamiento.t45"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i45"
                :stroke="implante.i45"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f45"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o45"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o45"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o45"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a45"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e45"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e45"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m45"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m45 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m45 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(150,50)" v-if="infantil">
              <polygon
                :class="ausencia.a46 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(46, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r462"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a46 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(46, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r465"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a46 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(46, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r464"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a46 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(46, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r463"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a46 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(46, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r461"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c46"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(46, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                82
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t46"
                :stroke="tratamiento.t46"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i46"
                :stroke="implante.i46"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f46"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o46"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o46"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o46"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a46"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e46"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e46"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m46"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m46 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m46 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(175,50)" v-if="infantil">
              <polygon
                :class="ausencia.a47 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(47, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r472"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a47 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(47, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r475"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a47 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(47, 4)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r474"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a47 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(47, 3)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r473"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a47 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(47, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r471"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c47"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(47, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                81
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t47"
                :stroke="tratamiento.t47"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i47"
                :stroke="implante.i47"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f47"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o47"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o47"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o47"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a47"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e47"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e47"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m47"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m47 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m47 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(210,50)" v-if="infantil">
              <polygon
                :class="ausencia.a48 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(48, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r482"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a48 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(48, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r485"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a48 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(48, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r483"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a48 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(48, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r484"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a48 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(48, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r481"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f48"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c48"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(48, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                71
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t48"
                :stroke="tratamiento.t48"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i48"
                :stroke="implante.i48"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o48"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o48"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o48"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a48"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e48"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e48"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m48"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m48 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m48 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(235,50)" v-if="infantil">
              <polygon
                :class="ausencia.a49 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(49, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r492"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a49 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(49, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r495"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a49 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(49, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r493"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a49 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(49, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r494"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a49 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(49, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r491"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c49"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(49, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                72
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t49"
                :stroke="tratamiento.t49"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i49"
                :stroke="implante.i49"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f49"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o49"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o49"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o49"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a49"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e49"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e49"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m49"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m49 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m49 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(260,50)" v-if="infantil">
              <polygon
                :class="ausencia.a50 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(50, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r502"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a50 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(50, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r505"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a50 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(50, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r503"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a50 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(50, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r504"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a50 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(50, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r501"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c50"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(50, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                73
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t50"
                :stroke="tratamiento.t50"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i50"
                :stroke="implante.i50"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f50"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o50"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o50"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o50"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a50"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e50"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e50"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m50"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m50 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m50 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(285,50)" v-if="infantil">
              <polygon
                :class="ausencia.a51 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(51, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r512"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a51 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(51, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r515"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a51 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(51, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r513"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a51 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(51, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r514"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a51 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(51, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r511"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c51"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(51, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                74
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t51"
                :stroke="tratamiento.t51"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i51"
                :stroke="implante.i51"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f51"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o51"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o51"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o51"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a51"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e51"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e41"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m51"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m51 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m51 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>

            <g transform="translate(310,50)" v-if="infantil">
              <polygon
                :class="ausencia.a52 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(52, 2)"
                style="cursor: pointer"
                points="5,15 15,15 20,20 0,20"
                :fill="restauracion.r522"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a52 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(52, 5)"
                style="cursor: pointer"
                points="0,0 20,0 15,5 5,5"
                :fill="restauracion.r525"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a52 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(52, 3)"
                style="cursor: pointer"
                points="15,5 20,0 20,20 15,15"
                :fill="restauracion.r523"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a52 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(52, 4)"
                style="cursor: pointer"
                points="0,0 5,5 5,15 0,20"
                :fill="restauracion.r524"
                stroke="black"
                stroke-width="0.5"
                color="black"
              ></polygon>
              <polygon
                :class="ausencia.a52 == 'red' ? 'disable' : ''"
                @click="nuevoRegistro(52, 1)"
                style="cursor: pointer"
                points="5,5 15,5 15,15, 5,15"
                :fill="restauracion.r521"
                stroke="black"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <circle
                cx="10"
                cy="10"
                r="9"
                :stroke="corona.c52"
                stroke-width="1"
                fill="none"
              ></circle>
              <text
                @click="nuevoRegistro(52, 6)"
                x="6"
                y="-4"
                fill="navy"
                stroke="navy"
                stroke-width="0.1"
                style="cursor: pointer; font-size: 6pt; font-weight: normal"
              >
                75
              </text>
              <text
                x="0"
                y="30"
                :fill="tratamiento.t52"
                :stroke="tratamiento.t52"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                TC
              </text>
              <text
                x="16"
                y="30"
                :fill="implante.i52"
                :stroke="implante.i52"
                stroke-width="0.1"
                style="font-size: 5.5pt; font-weight: normal"
              >
                I
              </text>
              <polygon
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="fija.f52"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                x1="0"
                y1="7"
                x2="20"
                y2="7"
                :stroke="ortodoncia.o52"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="10"
                x2="20"
                y2="10"
                :stroke="ortodoncia.o52"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="13"
                x2="20"
                y2="13"
                :stroke="ortodoncia.o52"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="ausencia.a52"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="0"
                x2="20"
                y2="20"
                :stroke="extraccion.e52"
                stroke-width="1"
              />
              <line
                x1="0"
                y1="20"
                x2="20"
                y2="0"
                :stroke="extraccion.e52"
                stroke-width="1"
              />
              <polygon
                style="cursor: pointer"
                points="-2,-2 22,-2 22,22, -2,22"
                fill="none"
                :stroke="movil.m51"
                stroke-width="0.5"
                color="red"
              ></polygon>
              <line
                v-if="movil.m51 != 'none'"
                x1="6"
                y1="-2"
                x2="14"
                y2="-2"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
              <line
                v-if="movil.m51 != 'none'"
                x1="6"
                y1="22"
                x2="14"
                y2="22"
                style="stroke: rgb(255, 255, 255); stroke-width: 1"
              />
            </g>
          </g>
        </svg>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="verReferencias()"
            >Ver Reseña Dibujos</v-btn
          >
          <v-btn color="warning" @click.stop="imprimir()">Imprimir</v-btn>
        </v-card-actions>
      </div>
      <v-card-title>
        <span class="title font-weight-light">Detalle Odontograma</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaOdontograma"
        class="elevation-1 row-pointer"
        locale="es-ar"
        item-key="idOdontograma"
        :items-per-page="10"
        ref="myTable"
        no-data-text="Paciente sin prácticas"
        no-results-text="Paciente sin prácticas"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.estadoString="{ item }">
          <v-chip :color="getColor(item.estado)" dark>
            {{ item.estadoString }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarOdontograma(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Registro</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarOdontograma(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Registro</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
    <NuevoOdontograma
      :visible="showNuevoOdontograma"
      :idPaciente="idPaciente"
      :idDiente="idDiente"
      :idCara="idCara"
      @close="showNuevoOdontograma = false"
      @odontogramaRegistrado="odontogramaRegistrado()"
    ></NuevoOdontograma>
    <ModificarOdontograma
      :visible="showModificarOdontograma"
      :idPaciente="idPaciente"
      :idOdontograma="editedItem.idOdontograma"
      :idCara="editedItem.idCara"
      @close="showModificarOdontograma = false"
      @odontogramaModificado="odontogramaModificado()"
    ></ModificarOdontograma>
    <OdontogramaDibujos
      :visible="showOdontogramaDibujos"
      @close="showOdontogramaDibujos = false"
    >
    </OdontogramaDibujos>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
polygon:hover {
  fill: grey;
}
.disable {
  pointer-events: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevoOdontograma from "../HistClin/NuevoRegistroOdontograma.vue";
import ModificarOdontograma from "../HistClin/ModificarRegistroOdontograma.vue";
import OdontogramaDibujos from "../HistClin/OdontogramaDibujos.vue";
import canvg from "canvg";
import autotable from "jspdf-autotable";
import jsPDF from "jspdf";
export default {
  props: [
    "idPaciente",
    "lista",
    "paciente",
    "restauracion",
    "corona",
    "ausencia",
    "extraccion",
    "ortodoncia",
    "fija",
    "movil",
    "implante",
    "tratamiento",
  ],
  computed: {
    listaOdontograma: {
      set(value) {
        if (value != []) this.$emit("actualizarOdontograma");
      },
      get() {
        return this.lista;
      },
    },
  },
  data: (vm) => ({
    showOdontogramaDibujos: false,
    a1: "blue",
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Diente", value: "nroDiente" },
      { text: "Cara", value: "nombreCara" },
      { text: "Práctica", value: "descripcion" },
      { text: "Estado", value: "estadoString" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Acciones", value: "actions" },
    ],
    infantil: false,
    idDiente: null,
    idCara: null,
    showNuevoOdontograma: false,
    showModificarOdontograma: false,
    editedItem: {},
    editedIndex: -1,
    base64: null,
  }),
  created() {
    this.getLogoUrl();
    this.dibujarOdontograma();
  },

  components: {
    NuevoOdontograma,
    ModificarOdontograma,
    OdontogramaDibujos,
  },
  watch: {
    listaOdontograma: function(val) {
      this.dibujarOdontograma();
    },
  },
  methods: {
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
        })
        .catch((error) => {});
    },
    verReferencias() {
      this.showOdontogramaDibujos = true;
    },
    imprimir() {
      var doc = new jsPDF("landscape");
      doc.setProperties({
        title: "Odontograma",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Paciente: " + this.paciente.apellidos + " " + this.paciente.nombres,
        13,
        25
      );
      doc.text("D.N.I.: " + this.paciente.dni, 13, 30);
      doc.text("Feche de Nac.: " + this.paciente.fechaNac, 13, 35);
      doc.text("Edad: " + this.paciente.edad, 13, 40);

      if (this.base64 != null && this.base64 != "")
        doc.addImage(this.base64, 220, 5, 60, 20);

      doc.setFontSize(16);

      doc.text("Odontograma", doc.internal.pageSize.getWidth() / 2, 50, {
        align: "center",
      });
      doc.line(131, 51, 166, 51);
      var svg = document.getElementById("svg").innerHTML;
      var canvas = document.createElement("canvas");
      canvas.width = 830;
      canvas.height = 180;
      var context = canvas.getContext("2d");

      //context.clearRect(0, 0, canvas.width, canvas.height);
      let img = canvg.fromString(context, svg);

      img.render();
      var imgData = canvas.toDataURL("image/png");

      // Generate PDF

      var width = doc.internal.pageSize.getWidth();
      var height = doc.internal.pageSize.getHeight();
      doc.addImage(imgData, "PNG", 15, 60, 250, 60);

      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = [
        "Fecha",
        "Diente",
        "Cara",
        "Práctica",
        "Estado",
        "Observaciones",
      ];

      source.items.forEach((element) => {
        var temp = [
          element.fechaString,
          element.nroDiente,
          element.nombreCara,
          element.descripcion,
          element.estadoString,
          element.observaciones,
        ];
        rows.push(temp);
      });
      doc.setFontSize(16);
      doc.text(
        "Detalle Odontograma",
        doc.internal.pageSize.getWidth() / 2,
        130,
        { align: "center" }
      );
      doc.line(122, 131, 176, 131);
      doc.autoTable(columnHeader, rows, {
        startY: 135,
        didParseCell: function(table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      window.open(doc.output("bloburl"), "_blank");
    },
    borrarOdontograma(item) {
      this.$swal({
        title: "Borrar Registro",
        text: "¿Desea borrar el registro?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idOdontograma = item.idOdontograma;
          let self = this;
          axios
            .get("/HistoriaClinica/BorrarOdontograma?", {
              params: {
                idOdontograma: idOdontograma,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Registro borrado exitosamente",
                vm: self,
                timer: 2200,
              });
              setTimeout(self.buscarOdontograma(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El registro no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    buscarOdontograma() {
      this.listaOdontograma = 1;
    },
    getColor(estado) {
      if (estado == 1) return "red";
      else {
        if (estado == 2) return "blue";
        else return "green";
      }
    },
    nuevoRegistro(diente, cara) {
      this.idDiente = diente;
      this.idCara = cara;
      this.showNuevoOdontograma = true;
    },
    editarOdontograma(item) {
      this.editedIndex = this.listaOdontograma.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showModificarOdontograma = true;
    },

    odontogramaRegistrado() {
      this.buscarOdontograma();
      this.showNuevoOdontograma = false;
    },
    odontogramaModificado() {
      this.buscarOdontograma();
      this.showModificarOdontograma = false;
    },
    limpiarOdontograma() {
      this.corona.c1 = "none";
      this.corona.c2 = "none";
      this.corona.c3 = "none";
      this.corona.c4 = "none";
      this.corona.c5 = "none";
      this.corona.c6 = "none";
      this.corona.c7 = "none";
      this.corona.c8 = "none";
      this.corona.c9 = "none";
      this.corona.c10 = "none";
      this.corona.c11 = "none";
      this.corona.c12 = "none";
      this.corona.c13 = "none";
      this.corona.c14 = "none";
      this.corona.c15 = "none";
      this.corona.c16 = "none";
      this.corona.c17 = "none";
      this.corona.c18 = "none";
      this.corona.c19 = "none";
      this.corona.c20 = "none";
      this.corona.c21 = "none";
      this.corona.c22 = "none";
      this.corona.c23 = "none";
      this.corona.c24 = "none";
      this.corona.c25 = "none";
      this.corona.c26 = "none";
      this.corona.c27 = "none";
      this.corona.c28 = "none";
      this.corona.c29 = "none";
      this.corona.c30 = "none";
      this.corona.c31 = "none";
      this.corona.c32 = "none";
      this.corona.c33 = "none";
      this.corona.c34 = "none";
      this.corona.c35 = "none";
      this.corona.c36 = "none";
      this.corona.c37 = "none";
      this.corona.c38 = "none";
      this.corona.c39 = "none";
      this.corona.c40 = "none";
      this.corona.c41 = "none";
      this.corona.c42 = "none";
      this.corona.c43 = "none";
      this.corona.c44 = "none";
      this.corona.c45 = "none";
      this.corona.c46 = "none";
      this.corona.c47 = "none";
      this.corona.c48 = "none";
      this.corona.c49 = "none";
      this.corona.c50 = "none";
      this.corona.c51 = "none";
      this.corona.c52 = "none";

      this.movil.m1 = "none";
      this.movil.m2 = "none";
      this.movil.m3 = "none";
      this.movil.m4 = "none";
      this.movil.m5 = "none";
      this.movil.m6 = "none";
      this.movil.m7 = "none";
      this.movil.m8 = "none";
      this.movil.m9 = "none";
      this.movil.m10 = "none";
      this.movil.m11 = "none";
      this.movil.m12 = "none";
      this.movil.m13 = "none";
      this.movil.m14 = "none";
      this.movil.m15 = "none";
      this.movil.m16 = "none";
      this.movil.m17 = "none";
      this.movil.m18 = "none";
      this.movil.m19 = "none";
      this.movil.m20 = "none";
      this.movil.m21 = "none";
      this.movil.m22 = "none";
      this.movil.m23 = "none";
      this.movil.m24 = "none";
      this.movil.m25 = "none";
      this.movil.m26 = "none";
      this.movil.m27 = "none";
      this.movil.m28 = "none";
      this.movil.m29 = "none";
      this.movil.m30 = "none";
      this.movil.m31 = "none";
      this.movil.m32 = "none";
      this.movil.m33 = "none";
      this.movil.m34 = "none";
      this.movil.m35 = "none";
      this.movil.m36 = "none";
      this.movil.m37 = "none";
      this.movil.m38 = "none";
      this.movil.m39 = "none";
      this.movil.m40 = "none";
      this.movil.m41 = "none";
      this.movil.m42 = "none";
      this.movil.m43 = "none";
      this.movil.m44 = "none";
      this.movil.m45 = "none";
      this.movil.m46 = "none";
      this.movil.m47 = "none";
      this.movil.m48 = "none";
      this.movil.m49 = "none";
      this.movil.m50 = "none";
      this.movil.m51 = "none";
      this.movil.m52 = "none";

      this.ausencia.a1 = "none";
      this.ausencia.a2 = "none";
      this.ausencia.a3 = "none";
      this.ausencia.a4 = "none";
      this.ausencia.a5 = "none";
      this.ausencia.a6 = "none";
      this.ausencia.a7 = "none";
      this.ausencia.a8 = "none";
      this.ausencia.a9 = "none";
      this.ausencia.a10 = "none";
      this.ausencia.a11 = "none";
      this.ausencia.a12 = "none";
      this.ausencia.a13 = "none";
      this.ausencia.a14 = "none";
      this.ausencia.a15 = "none";
      this.ausencia.a16 = "none";
      this.ausencia.a17 = "none";
      this.ausencia.a18 = "none";
      this.ausencia.a19 = "none";
      this.ausencia.a20 = "none";
      this.ausencia.a21 = "none";
      this.ausencia.a22 = "none";
      this.ausencia.a23 = "none";
      this.ausencia.a24 = "none";
      this.ausencia.a25 = "none";
      this.ausencia.a26 = "none";
      this.ausencia.a27 = "none";
      this.ausencia.a28 = "none";
      this.ausencia.a29 = "none";
      this.ausencia.a30 = "none";
      this.ausencia.a31 = "none";
      this.ausencia.a32 = "none";
      this.ausencia.a33 = "none";
      this.ausencia.a34 = "none";
      this.ausencia.a35 = "none";
      this.ausencia.a36 = "none";
      this.ausencia.a37 = "none";
      this.ausencia.a38 = "none";
      this.ausencia.a39 = "none";
      this.ausencia.a40 = "none";
      this.ausencia.a41 = "none";
      this.ausencia.a42 = "none";
      this.ausencia.a43 = "none";
      this.ausencia.a44 = "none";
      this.ausencia.a45 = "none";
      this.ausencia.a46 = "none";
      this.ausencia.a47 = "none";
      this.ausencia.a48 = "none";
      this.ausencia.a49 = "none";
      this.ausencia.a50 = "none";
      this.ausencia.a51 = "none";
      this.ausencia.a52 = "none";

      this.extraccion.e1 = "none";
      this.extraccion.e2 = "none";
      this.extraccion.e3 = "none";
      this.extraccion.e4 = "none";
      this.extraccion.e5 = "none";
      this.extraccion.e6 = "none";
      this.extraccion.e7 = "none";
      this.extraccion.e8 = "none";
      this.extraccion.e9 = "none";
      this.extraccion.e10 = "none";
      this.extraccion.e11 = "none";
      this.extraccion.e12 = "none";
      this.extraccion.e13 = "none";
      this.extraccion.e14 = "none";
      this.extraccion.e15 = "none";
      this.extraccion.e16 = "none";
      this.extraccion.e17 = "none";
      this.extraccion.e18 = "none";
      this.extraccion.e19 = "none";
      this.extraccion.e20 = "none";
      this.extraccion.e21 = "none";
      this.extraccion.e22 = "none";
      this.extraccion.e23 = "none";
      this.extraccion.e24 = "none";
      this.extraccion.e25 = "none";
      this.extraccion.e26 = "none";
      this.extraccion.e27 = "none";
      this.extraccion.e28 = "none";
      this.extraccion.e29 = "none";
      this.extraccion.e30 = "none";
      this.extraccion.e31 = "none";
      this.extraccion.e32 = "none";
      this.extraccion.e33 = "none";
      this.extraccion.e34 = "none";
      this.extraccion.e35 = "none";
      this.extraccion.e36 = "none";
      this.extraccion.e37 = "none";
      this.extraccion.e38 = "none";
      this.extraccion.e39 = "none";
      this.extraccion.e40 = "none";
      this.extraccion.e41 = "none";
      this.extraccion.e42 = "none";
      this.extraccion.e43 = "none";
      this.extraccion.e44 = "none";
      this.extraccion.e45 = "none";
      this.extraccion.e46 = "none";
      this.extraccion.e47 = "none";
      this.extraccion.e48 = "none";
      this.extraccion.e49 = "none";
      this.extraccion.e50 = "none";
      this.extraccion.e51 = "none";
      this.extraccion.e52 = "none";

      this.fija.f1 = "none";
      this.fija.f2 = "none";
      this.fija.f3 = "none";
      this.fija.f4 = "none";
      this.fija.f5 = "none";
      this.fija.f6 = "none";
      this.fija.f7 = "none";
      this.fija.f8 = "none";
      this.fija.f9 = "none";
      this.fija.f10 = "none";
      this.fija.f11 = "none";
      this.fija.f12 = "none";
      this.fija.f13 = "none";
      this.fija.f14 = "none";
      this.fija.f15 = "none";
      this.fija.f16 = "none";
      this.fija.f17 = "none";
      this.fija.f18 = "none";
      this.fija.f19 = "none";
      this.fija.f20 = "none";
      this.fija.f21 = "none";
      this.fija.f22 = "none";
      this.fija.f23 = "none";
      this.fija.f24 = "none";
      this.fija.f25 = "none";
      this.fija.f26 = "none";
      this.fija.f27 = "none";
      this.fija.f28 = "none";
      this.fija.f29 = "none";
      this.fija.f30 = "none";
      this.fija.f31 = "none";
      this.fija.f32 = "none";
      this.fija.f33 = "none";
      this.fija.f34 = "none";
      this.fija.f35 = "none";
      this.fija.f36 = "none";
      this.fija.f37 = "none";
      this.fija.f38 = "none";
      this.fija.f39 = "none";
      this.fija.f40 = "none";
      this.fija.f41 = "none";
      this.fija.f42 = "none";
      this.fija.f43 = "none";
      this.fija.f44 = "none";
      this.fija.f45 = "none";
      this.fija.f46 = "none";
      this.fija.f47 = "none";
      this.fija.f48 = "none";
      this.fija.f49 = "none";
      this.fija.f50 = "none";
      this.fija.f51 = "none";
      this.fija.f52 = "none";

      this.ortodoncia.o1 = "none";
      this.ortodoncia.o2 = "none";
      this.ortodoncia.o3 = "none";
      this.ortodoncia.o4 = "none";
      this.ortodoncia.o5 = "none";
      this.ortodoncia.o6 = "none";
      this.ortodoncia.o7 = "none";
      this.ortodoncia.o8 = "none";
      this.ortodoncia.o9 = "none";
      this.ortodoncia.o10 = "none";
      this.ortodoncia.o11 = "none";
      this.ortodoncia.o12 = "none";
      this.ortodoncia.o13 = "none";
      this.ortodoncia.o14 = "none";
      this.ortodoncia.o15 = "none";
      this.ortodoncia.o16 = "none";
      this.ortodoncia.o17 = "none";
      this.ortodoncia.o18 = "none";
      this.ortodoncia.o19 = "none";
      this.ortodoncia.o20 = "none";
      this.ortodoncia.o21 = "none";
      this.ortodoncia.o22 = "none";
      this.ortodoncia.o23 = "none";
      this.ortodoncia.o24 = "none";
      this.ortodoncia.o25 = "none";
      this.ortodoncia.o26 = "none";
      this.ortodoncia.o27 = "none";
      this.ortodoncia.o28 = "none";
      this.ortodoncia.o29 = "none";
      this.ortodoncia.o30 = "none";
      this.ortodoncia.o31 = "none";
      this.ortodoncia.o32 = "none";
      this.ortodoncia.o33 = "none";
      this.ortodoncia.o34 = "none";
      this.ortodoncia.o35 = "none";
      this.ortodoncia.o36 = "none";
      this.ortodoncia.o37 = "none";
      this.ortodoncia.o38 = "none";
      this.ortodoncia.o39 = "none";
      this.ortodoncia.o40 = "none";
      this.ortodoncia.o41 = "none";
      this.ortodoncia.o42 = "none";
      this.ortodoncia.o43 = "none";
      this.ortodoncia.o44 = "none";
      this.ortodoncia.o45 = "none";
      this.ortodoncia.o46 = "none";
      this.ortodoncia.o47 = "none";
      this.ortodoncia.o48 = "none";
      this.ortodoncia.o49 = "none";
      this.ortodoncia.o50 = "none";
      this.ortodoncia.o51 = "none";
      this.ortodoncia.o52 = "none";

      this.implante.i1 = "none";
      this.implante.i2 = "none";
      this.implante.i3 = "none";
      this.implante.i4 = "none";
      this.implante.i5 = "none";
      this.implante.i6 = "none";
      this.implante.i7 = "none";
      this.implante.i8 = "none";
      this.implante.i9 = "none";
      this.implante.i10 = "none";
      this.implante.i11 = "none";
      this.implante.i12 = "none";
      this.implante.i13 = "none";
      this.implante.i14 = "none";
      this.implante.i15 = "none";
      this.implante.i16 = "none";
      this.implante.i17 = "none";
      this.implante.i18 = "none";
      this.implante.i19 = "none";
      this.implante.i20 = "none";
      this.implante.i21 = "none";
      this.implante.i22 = "none";
      this.implante.i23 = "none";
      this.implante.i24 = "none";
      this.implante.i25 = "none";
      this.implante.i26 = "none";
      this.implante.i27 = "none";
      this.implante.i28 = "none";
      this.implante.i29 = "none";
      this.implante.i30 = "none";
      this.implante.i31 = "none";
      this.implante.i32 = "none";
      this.implante.i33 = "none";
      this.implante.i34 = "none";
      this.implante.i35 = "none";
      this.implante.i36 = "none";
      this.implante.i37 = "none";
      this.implante.i38 = "none";
      this.implante.i39 = "none";
      this.implante.i40 = "none";
      this.implante.i41 = "none";
      this.implante.i42 = "none";
      this.implante.i43 = "none";
      this.implante.i44 = "none";
      this.implante.i45 = "none";
      this.implante.i46 = "none";
      this.implante.i47 = "none";
      this.implante.i48 = "none";
      this.implante.i49 = "none";
      this.implante.i50 = "none";
      this.implante.i51 = "none";
      this.implante.i52 = "none";

      this.tratamiento.t1 = "none";
      this.tratamiento.t2 = "none";
      this.tratamiento.t3 = "none";
      this.tratamiento.t4 = "none";
      this.tratamiento.t5 = "none";
      this.tratamiento.t6 = "none";
      this.tratamiento.t7 = "none";
      this.tratamiento.t8 = "none";
      this.tratamiento.t9 = "none";
      this.tratamiento.t10 = "none";
      this.tratamiento.t11 = "none";
      this.tratamiento.t12 = "none";
      this.tratamiento.t13 = "none";
      this.tratamiento.t14 = "none";
      this.tratamiento.t15 = "none";
      this.tratamiento.t16 = "none";
      this.tratamiento.t17 = "none";
      this.tratamiento.t18 = "none";
      this.tratamiento.t19 = "none";
      this.tratamiento.t20 = "none";
      this.tratamiento.t21 = "none";
      this.tratamiento.t22 = "none";
      this.tratamiento.t23 = "none";
      this.tratamiento.t24 = "none";
      this.tratamiento.t25 = "none";
      this.tratamiento.t26 = "none";
      this.tratamiento.t27 = "none";
      this.tratamiento.t28 = "none";
      this.tratamiento.t29 = "none";
      this.tratamiento.t30 = "none";
      this.tratamiento.t31 = "none";
      this.tratamiento.t32 = "none";
      this.tratamiento.t33 = "none";
      this.tratamiento.t34 = "none";
      this.tratamiento.t35 = "none";
      this.tratamiento.t36 = "none";
      this.tratamiento.t37 = "none";
      this.tratamiento.t38 = "none";
      this.tratamiento.t39 = "none";
      this.tratamiento.t40 = "none";
      this.tratamiento.t41 = "none";
      this.tratamiento.t42 = "none";
      this.tratamiento.t43 = "none";
      this.tratamiento.t44 = "none";
      this.tratamiento.t45 = "none";
      this.tratamiento.t46 = "none";
      this.tratamiento.t47 = "none";
      this.tratamiento.t48 = "none";
      this.tratamiento.t49 = "none";
      this.tratamiento.t50 = "none";
      this.tratamiento.t51 = "none";
      this.tratamiento.t52 = "none";

      this.restauracion.r11 = "white";
      this.restauracion.r12 = "white";
      this.restauracion.r13 = "white";
      this.restauracion.r14 = "white";
      this.restauracion.r15 = "white";
      this.restauracion.r21 = "white";
      this.restauracion.r22 = "white";
      this.restauracion.r23 = "white";
      this.restauracion.r24 = "white";
      this.restauracion.r25 = "white";
      this.restauracion.r31 = "white";
      this.restauracion.r32 = "white";
      this.restauracion.r33 = "white";
      this.restauracion.r34 = "white";
      this.restauracion.r35 = "white";
      this.restauracion.r41 = "white";
      this.restauracion.r42 = "white";
      this.restauracion.r43 = "white";
      this.restauracion.r44 = "white";
      this.restauracion.r45 = "white";
      this.restauracion.r51 = "white";
      this.restauracion.r52 = "white";
      this.restauracion.r53 = "white";
      this.restauracion.r54 = "white";
      this.restauracion.r55 = "white";
      this.restauracion.r61 = "white";
      this.restauracion.r62 = "white";
      this.restauracion.r63 = "white";
      this.restauracion.r64 = "white";
      this.restauracion.r65 = "white";
      this.restauracion.r71 = "white";
      this.restauracion.r72 = "white";
      this.restauracion.r73 = "white";
      this.restauracion.r74 = "white";
      this.restauracion.r75 = "white";
      this.restauracion.r81 = "white";
      this.restauracion.r82 = "white";
      this.restauracion.r83 = "white";
      this.restauracion.r84 = "white";
      this.restauracion.r85 = "white";
      this.restauracion.r91 = "white";
      this.restauracion.r92 = "white";
      this.restauracion.r93 = "white";
      this.restauracion.r94 = "white";
      this.restauracion.r95 = "white";
      this.restauracion.r101 = "white";
      this.restauracion.r102 = "white";
      this.restauracion.r103 = "white";
      this.restauracion.r104 = "white";
      this.restauracion.r105 = "white";
      this.restauracion.r111 = "white";
      this.restauracion.r112 = "white";
      this.restauracion.r113 = "white";
      this.restauracion.r114 = "white";
      this.restauracion.r115 = "white";
      this.restauracion.r121 = "white";
      this.restauracion.r122 = "white";
      this.restauracion.r123 = "white";
      this.restauracion.r124 = "white";
      this.restauracion.r125 = "white";
      this.restauracion.r131 = "white";
      this.restauracion.r132 = "white";
      this.restauracion.r133 = "white";
      this.restauracion.r134 = "white";
      this.restauracion.r135 = "white";
      this.restauracion.r141 = "white";
      this.restauracion.r142 = "white";
      this.restauracion.r143 = "white";
      this.restauracion.r144 = "white";
      this.restauracion.r145 = "white";
      this.restauracion.r151 = "white";
      this.restauracion.r152 = "white";
      this.restauracion.r153 = "white";
      this.restauracion.r154 = "white";
      this.restauracion.r155 = "white";
      this.restauracion.r161 = "white";
      this.restauracion.r162 = "white";
      this.restauracion.r163 = "white";
      this.restauracion.r164 = "white";
      this.restauracion.r165 = "white";
      this.restauracion.r171 = "white";
      this.restauracion.r172 = "white";
      this.restauracion.r173 = "white";
      this.restauracion.r174 = "white";
      this.restauracion.r175 = "white";
      this.restauracion.r181 = "white";
      this.restauracion.r182 = "white";
      this.restauracion.r183 = "white";
      this.restauracion.r184 = "white";
      this.restauracion.r185 = "white";
      this.restauracion.r191 = "white";
      this.restauracion.r192 = "white";
      this.restauracion.r193 = "white";
      this.restauracion.r194 = "white";
      this.restauracion.r195 = "white";
      this.restauracion.r201 = "white";
      this.restauracion.r202 = "white";
      this.restauracion.r203 = "white";
      this.restauracion.r204 = "white";
      this.restauracion.r205 = "white";
      this.restauracion.r211 = "white";
      this.restauracion.r212 = "white";
      this.restauracion.r213 = "white";
      this.restauracion.r214 = "white";
      this.restauracion.r215 = "white";
      this.restauracion.r221 = "white";
      this.restauracion.r222 = "white";
      this.restauracion.r223 = "white";
      this.restauracion.r224 = "white";
      this.restauracion.r225 = "white";
      this.restauracion.r231 = "white";
      this.restauracion.r232 = "white";
      this.restauracion.r233 = "white";
      this.restauracion.r234 = "white";
      this.restauracion.r235 = "white";
      this.restauracion.r241 = "white";
      this.restauracion.r242 = "white";
      this.restauracion.r243 = "white";
      this.restauracion.r244 = "white";
      this.restauracion.r245 = "white";
      this.restauracion.r251 = "white";
      this.restauracion.r252 = "white";
      this.restauracion.r253 = "white";
      this.restauracion.r254 = "white";
      this.restauracion.r255 = "white";
      this.restauracion.r261 = "white";
      this.restauracion.r262 = "white";
      this.restauracion.r263 = "white";
      this.restauracion.r264 = "white";
      this.restauracion.r265 = "white";
      this.restauracion.r271 = "white";
      this.restauracion.r272 = "white";
      this.restauracion.r273 = "white";
      this.restauracion.r274 = "white";
      this.restauracion.r275 = "white";
      this.restauracion.r281 = "white";
      this.restauracion.r282 = "white";
      this.restauracion.r283 = "white";
      this.restauracion.r284 = "white";
      this.restauracion.r285 = "white";
      this.restauracion.r291 = "white";
      this.restauracion.r292 = "white";
      this.restauracion.r293 = "white";
      this.restauracion.r294 = "white";
      this.restauracion.r295 = "white";
      this.restauracion.r301 = "white";
      this.restauracion.r302 = "white";
      this.restauracion.r303 = "white";
      this.restauracion.r304 = "white";
      this.restauracion.r305 = "white";
      this.restauracion.r311 = "white";
      this.restauracion.r312 = "white";
      this.restauracion.r313 = "white";
      this.restauracion.r314 = "white";
      this.restauracion.r315 = "white";
      this.restauracion.r321 = "white";
      this.restauracion.r322 = "white";
      this.restauracion.r323 = "white";
      this.restauracion.r324 = "white";
      this.restauracion.r325 = "white";
      this.restauracion.r331 = "white";
      this.restauracion.r332 = "white";
      this.restauracion.r333 = "white";
      this.restauracion.r334 = "white";
      this.restauracion.r335 = "white";
      this.restauracion.r341 = "white";
      this.restauracion.r342 = "white";
      this.restauracion.r343 = "white";
      this.restauracion.r344 = "white";
      this.restauracion.r345 = "white";
      this.restauracion.r351 = "white";
      this.restauracion.r352 = "white";
      this.restauracion.r353 = "white";
      this.restauracion.r354 = "white";
      this.restauracion.r355 = "white";
      this.restauracion.r361 = "white";
      this.restauracion.r362 = "white";
      this.restauracion.r363 = "white";
      this.restauracion.r364 = "white";
      this.restauracion.r365 = "white";
      this.restauracion.r371 = "white";
      this.restauracion.r372 = "white";
      this.restauracion.r373 = "white";
      this.restauracion.r374 = "white";
      this.restauracion.r375 = "white";
      this.restauracion.r381 = "white";
      this.restauracion.r382 = "white";
      this.restauracion.r383 = "white";
      this.restauracion.r384 = "white";
      this.restauracion.r385 = "white";
      this.restauracion.r391 = "white";
      this.restauracion.r392 = "white";
      this.restauracion.r393 = "white";
      this.restauracion.r394 = "white";
      this.restauracion.r395 = "white";
      this.restauracion.r401 = "white";
      this.restauracion.r402 = "white";
      this.restauracion.r403 = "white";
      this.restauracion.r404 = "white";
      this.restauracion.r405 = "white";
      this.restauracion.r411 = "white";
      this.restauracion.r412 = "white";
      this.restauracion.r413 = "white";
      this.restauracion.r414 = "white";
      this.restauracion.r415 = "white";
      this.restauracion.r421 = "white";
      this.restauracion.r422 = "white";
      this.restauracion.r423 = "white";
      this.restauracion.r424 = "white";
      this.restauracion.r425 = "white";
      this.restauracion.r431 = "white";
      this.restauracion.r432 = "white";
      this.restauracion.r433 = "white";
      this.restauracion.r434 = "white";
      this.restauracion.r435 = "white";
      this.restauracion.r441 = "white";
      this.restauracion.r442 = "white";
      this.restauracion.r443 = "white";
      this.restauracion.r444 = "white";
      this.restauracion.r445 = "white";
      this.restauracion.r451 = "white";
      this.restauracion.r452 = "white";
      this.restauracion.r453 = "white";
      this.restauracion.r454 = "white";
      this.restauracion.r455 = "white";
      this.restauracion.r461 = "white";
      this.restauracion.r462 = "white";
      this.restauracion.r463 = "white";
      this.restauracion.r464 = "white";
      this.restauracion.r465 = "white";
      this.restauracion.r471 = "white";
      this.restauracion.r472 = "white";
      this.restauracion.r473 = "white";
      this.restauracion.r474 = "white";
      this.restauracion.r475 = "white";
      this.restauracion.r481 = "white";
      this.restauracion.r482 = "white";
      this.restauracion.r483 = "white";
      this.restauracion.r484 = "white";
      this.restauracion.r485 = "white";
      this.restauracion.r491 = "white";
      this.restauracion.r492 = "white";
      this.restauracion.r493 = "white";
      this.restauracion.r494 = "white";
      this.restauracion.r495 = "white";
      this.restauracion.r501 = "white";
      this.restauracion.r502 = "white";
      this.restauracion.r503 = "white";
      this.restauracion.r504 = "white";
      this.restauracion.r505 = "white";
      this.restauracion.r511 = "white";
      this.restauracion.r512 = "white";
      this.restauracion.r513 = "white";
      this.restauracion.r514 = "white";
      this.restauracion.r515 = "white";
      this.restauracion.r521 = "white";
      this.restauracion.r522 = "white";
      this.restauracion.r523 = "white";
      this.restauracion.r524 = "white";
      this.restauracion.r525 = "white";
    },
    dibujarOdontograma() {
      this.limpiarOdontograma();

      this.listaOdontograma.forEach((practica) => {
        if (practica.idPracticaOdontologica == 8) {
          if (practica.idDiente == 1) this.ausencia.a1 = "red";
          if (practica.idDiente == 2) this.ausencia.a2 = "red";
          if (practica.idDiente == 3) this.ausencia.a3 = "red";
          if (practica.idDiente == 4) this.ausencia.a4 = "red";
          if (practica.idDiente == 5) this.ausencia.a5 = "red";
          if (practica.idDiente == 6) this.ausencia.a6 = "red";
          if (practica.idDiente == 7) this.ausencia.a7 = "red";
          if (practica.idDiente == 8) this.ausencia.a8 = "red";
          if (practica.idDiente == 9) this.ausencia.a9 = "red";
          if (practica.idDiente == 10) this.ausencia.a10 = "red";
          if (practica.idDiente == 11) this.ausencia.a11 = "red";
          if (practica.idDiente == 12) this.ausencia.a12 = "red";
          if (practica.idDiente == 13) this.ausencia.a13 = "red";
          if (practica.idDiente == 14) this.ausencia.a14 = "red";
          if (practica.idDiente == 15) this.ausencia.a15 = "red";
          if (practica.idDiente == 16) this.ausencia.a16 = "red";
          if (practica.idDiente == 17) this.ausencia.a17 = "red";
          if (practica.idDiente == 18) this.ausencia.a18 = "red";
          if (practica.idDiente == 19) this.ausencia.a19 = "red";
          if (practica.idDiente == 20) this.ausencia.a20 = "red";
          if (practica.idDiente == 21) this.ausencia.a21 = "red";
          if (practica.idDiente == 22) this.ausencia.a22 = "red";
          if (practica.idDiente == 23) this.ausencia.a23 = "red";
          if (practica.idDiente == 24) this.ausencia.a24 = "red";
          if (practica.idDiente == 25) this.ausencia.a25 = "red";
          if (practica.idDiente == 26) this.ausencia.a26 = "red";
          if (practica.idDiente == 27) this.ausencia.a27 = "red";
          if (practica.idDiente == 28) this.ausencia.a28 = "red";
          if (practica.idDiente == 29) this.ausencia.a29 = "red";
          if (practica.idDiente == 30) this.ausencia.a30 = "red";
          if (practica.idDiente == 31) this.ausencia.a31 = "red";
          if (practica.idDiente == 32) this.ausencia.a32 = "red";
          if (practica.idDiente == 33) this.ausencia.a33 = "red";
          if (practica.idDiente == 34) this.ausencia.a34 = "red";
          if (practica.idDiente == 35) this.ausencia.a35 = "red";
          if (practica.idDiente == 36) this.ausencia.a36 = "red";
          if (practica.idDiente == 37) this.ausencia.a37 = "red";
          if (practica.idDiente == 38) this.ausencia.a38 = "red";
          if (practica.idDiente == 39) this.ausencia.a39 = "red";
          if (practica.idDiente == 40) this.ausencia.a40 = "red";
          if (practica.idDiente == 41) this.ausencia.a41 = "red";
          if (practica.idDiente == 42) this.ausencia.a42 = "red";
          if (practica.idDiente == 43) this.ausencia.a43 = "red";
          if (practica.idDiente == 44) this.ausencia.a44 = "red";
          if (practica.idDiente == 45) this.ausencia.a45 = "red";
          if (practica.idDiente == 46) this.ausencia.a46 = "red";
          if (practica.idDiente == 47) this.ausencia.a47 = "red";
          if (practica.idDiente == 48) this.ausencia.a48 = "red";
          if (practica.idDiente == 49) this.ausencia.a49 = "red";
          if (practica.idDiente == 50) this.ausencia.a50 = "red";
          if (practica.idDiente == 51) this.ausencia.a51 = "red";
          if (practica.idDiente == 52) this.ausencia.a52 = "red";
        }

        if (practica.idPracticaOdontologica == 5) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.fija.f1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.fija.f2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.fija.f3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.fija.f4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.fija.f5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.fija.f6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.fija.f7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.fija.f8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.fija.f9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.fija.f10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.fija.f11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.fija.f12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.fija.f13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.fija.f14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.fija.f15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.fija.f16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.fija.f17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.fija.f18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.fija.f19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.fija.f20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.fija.f21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.fija.f22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.fija.f23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.fija.f24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.fija.f25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.fija.f26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.fija.f27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.fija.f28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.fija.f29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.fija.f30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.fija.f31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.fija.f32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.fija.f33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.fija.f34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.fija.f35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.fija.f36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.fija.f37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.fija.f38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.fija.f39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.fija.f40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.fija.f41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.fija.f42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.fija.f43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.fija.f44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.fija.f45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.fija.f46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.fija.f47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.fija.f48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.fija.f49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.fija.f50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.fija.f51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.fija.f52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.fija.f1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.fija.f2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.fija.f3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.fija.f4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.fija.f5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.fija.f6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.fija.f7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.fija.f8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.fija.f9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.fija.f10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.fija.f11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.fija.f12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.fija.f13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.fija.f14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.fija.f15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.fija.f16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.fija.f17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.fija.f18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.fija.f19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.fija.f20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.fija.f21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.fija.f22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.fija.f23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.fija.f24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.fija.f25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.fija.f26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.fija.f27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.fija.f28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.fija.f29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.fija.f30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.fija.f31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.fija.f32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.fija.f33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.fija.f34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.fija.f35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.fija.f36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.fija.f37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.fija.f38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.fija.f39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.fija.f40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.fija.f41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.fija.f42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.fija.f43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.fija.f44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.fija.f45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.fija.f46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.fija.f47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.fija.f48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.fija.f49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.fija.f50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.fija.f51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.fija.f52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.fija.f1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.fija.f2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.fija.f3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.fija.f4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.fija.f5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.fija.f6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.fija.f7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.fija.f8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.fija.f9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.fija.f10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.fija.f11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.fija.f12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.fija.f13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.fija.f14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.fija.f15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.fija.f16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.fija.f17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.fija.f18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.fija.f19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.fija.f20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.fija.f21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.fija.f22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.fija.f23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.fija.f24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.fija.f25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.fija.f26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.fija.f27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.fija.f28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.fija.f29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.fija.f30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.fija.f31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.fija.f32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.fija.f33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.fija.f34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.fija.f35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.fija.f36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.fija.f37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.fija.f38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.fija.f39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.fija.f40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.fija.f41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.fija.f42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.fija.f43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.fija.f44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.fija.f45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.fija.f46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.fija.f47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.fija.f48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.fija.f49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.fija.f50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.fija.f51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.fija.f52 = "green";
        }
        if (practica.idPracticaOdontologica == 7) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.implante.i1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.implante.i2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.implante.i3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.implante.i4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.implante.i5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.implante.i6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.implante.i7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.implante.i8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.implante.i9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.implante.i10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.implante.i11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.implante.i12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.implante.i13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.implante.i14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.implante.i15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.implante.i16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.implante.i17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.implante.i18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.implante.i19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.implante.i20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.implante.i21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.implante.i22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.implante.i23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.implante.i24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.implante.i25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.implante.i26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.implante.i27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.implante.i28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.implante.i29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.implante.i30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.implante.i31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.implante.i32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.implante.i33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.implante.i34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.implante.i35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.implante.i36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.implante.i37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.implante.i38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.implante.i39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.implante.i40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.implante.i41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.implante.i42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.implante.i43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.implante.i44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.implante.i45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.implante.i46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.implante.i47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.implante.i48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.implante.i49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.implante.i50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.implante.i51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.implante.i52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.implante.i1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.implante.i2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.implante.i3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.implante.i4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.implante.i5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.implante.i6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.implante.i7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.implante.i8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.implante.i9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.implante.i10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.implante.i11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.implante.i12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.implante.i13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.implante.i14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.implante.i15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.implante.i16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.implante.i17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.implante.i18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.implante.i19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.implante.i20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.implante.i21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.implante.i22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.implante.i23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.implante.i24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.implante.i25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.implante.i26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.implante.i27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.implante.i28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.implante.i29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.implante.i30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.implante.i31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.implante.i32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.implante.i33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.implante.i34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.implante.i35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.implante.i36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.implante.i37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.implante.i38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.implante.i39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.implante.i40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.implante.i41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.implante.i42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.implante.i43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.implante.i44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.implante.i45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.implante.i46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.implante.i47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.implante.i48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.implante.i49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.implante.i50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.implante.i51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.implante.i52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.implante.i1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.implante.i2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.implante.i3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.implante.i4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.implante.i5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.implante.i6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.implante.i7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.implante.i8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.implante.i9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.implante.i10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.implante.i11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.implante.i12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.implante.i13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.implante.i14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.implante.i15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.implante.i16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.implante.i17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.implante.i18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.implante.i19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.implante.i20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.implante.i21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.implante.i22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.implante.i23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.implante.i24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.implante.i25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.implante.i26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.implante.i27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.implante.i28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.implante.i29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.implante.i30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.implante.i31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.implante.i32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.implante.i33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.implante.i34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.implante.i35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.implante.i36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.implante.i37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.implante.i38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.implante.i39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.implante.i40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.implante.i41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.implante.i42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.implante.i43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.implante.i44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.implante.i45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.implante.i46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.implante.i47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.implante.i48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.implante.i49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.implante.i50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.implante.i51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.implante.i52 = "green";
        }

        if (practica.idPracticaOdontologica == 2) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.tratamiento.t1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.tratamiento.t2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.tratamiento.t3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.tratamiento.t4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.tratamiento.t5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.tratamiento.t6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.tratamiento.t7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.tratamiento.t8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.tratamiento.t9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.tratamiento.t10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.tratamiento.t11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.tratamiento.t12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.tratamiento.t13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.tratamiento.t14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.tratamiento.t15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.tratamiento.t16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.tratamiento.t17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.tratamiento.t18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.tratamiento.t19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.tratamiento.t20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.tratamiento.t21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.tratamiento.t22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.tratamiento.t23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.tratamiento.t24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.tratamiento.t25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.tratamiento.t26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.tratamiento.t27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.tratamiento.t28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.tratamiento.t29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.tratamiento.t30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.tratamiento.t31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.tratamiento.t32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.tratamiento.t33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.tratamiento.t34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.tratamiento.t35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.tratamiento.t36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.tratamiento.t37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.tratamiento.t38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.tratamiento.t39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.tratamiento.t40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.tratamiento.t41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.tratamiento.t42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.tratamiento.t43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.tratamiento.t44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.tratamiento.t45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.tratamiento.t46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.tratamiento.t47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.tratamiento.t48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.tratamiento.t49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.tratamiento.t50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.tratamiento.t51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.tratamiento.t52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.tratamiento.t1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.tratamiento.t2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.tratamiento.t3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.tratamiento.t4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.tratamiento.t5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.tratamiento.t6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.tratamiento.t7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.tratamiento.t8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.tratamiento.t9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.tratamiento.t10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.tratamiento.t11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.tratamiento.t12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.tratamiento.t13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.tratamiento.t14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.tratamiento.t15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.tratamiento.t16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.tratamiento.t17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.tratamiento.t18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.tratamiento.t19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.tratamiento.t20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.tratamiento.t21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.tratamiento.t22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.tratamiento.t23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.tratamiento.t24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.tratamiento.t25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.tratamiento.t26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.tratamiento.t27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.tratamiento.t28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.tratamiento.t29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.tratamiento.t30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.tratamiento.t31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.tratamiento.t32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.tratamiento.t33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.tratamiento.t34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.tratamiento.t35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.tratamiento.t36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.tratamiento.t37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.tratamiento.t38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.tratamiento.t39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.tratamiento.t40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.tratamiento.t41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.tratamiento.t42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.tratamiento.t43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.tratamiento.t44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.tratamiento.t45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.tratamiento.t46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.tratamiento.t47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.tratamiento.t48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.tratamiento.t49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.tratamiento.t50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.tratamiento.t51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.tratamiento.t52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.tratamiento.t1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.tratamiento.t2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.tratamiento.t3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.tratamiento.t4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.tratamiento.t5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.tratamiento.t6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.tratamiento.t7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.tratamiento.t8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.tratamiento.t9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.tratamiento.t10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.tratamiento.t11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.tratamiento.t12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.tratamiento.t13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.tratamiento.t14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.tratamiento.t15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.tratamiento.t16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.tratamiento.t17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.tratamiento.t18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.tratamiento.t19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.tratamiento.t20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.tratamiento.t21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.tratamiento.t22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.tratamiento.t23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.tratamiento.t24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.tratamiento.t25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.tratamiento.t26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.tratamiento.t27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.tratamiento.t28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.tratamiento.t29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.tratamiento.t30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.tratamiento.t31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.tratamiento.t32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.tratamiento.t33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.tratamiento.t34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.tratamiento.t35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.tratamiento.t36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.tratamiento.t37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.tratamiento.t38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.tratamiento.t39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.tratamiento.t40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.tratamiento.t41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.tratamiento.t42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.tratamiento.t43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.tratamiento.t44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.tratamiento.t45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.tratamiento.t46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.tratamiento.t47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.tratamiento.t48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.tratamiento.t49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.tratamiento.t50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.tratamiento.t51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.tratamiento.t52 = "green";
        }
        if (practica.idPracticaOdontologica == 6) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.movil.m1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.movil.m2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.movil.m3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.movil.m4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.movil.m5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.movil.m6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.movil.m7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.movil.m8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.movil.m9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.movil.m10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.movil.m11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.movil.m12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.movil.m13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.movil.m14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.movil.m15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.movil.m16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.movil.m17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.movil.m18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.movil.m19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.movil.m20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.movil.m21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.movil.m22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.movil.m23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.movil.m24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.movil.m25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.movil.m26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.movil.m27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.movil.m28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.movil.m29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.movil.m30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.movil.m31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.movil.m32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.movil.m33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.movil.m34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.movil.m35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.movil.m36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.movil.m37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.movil.m38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.movil.m39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.movil.m40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.movil.m41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.movil.m42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.movil.m43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.movil.m44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.movil.m45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.movil.m46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.movil.m47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.movil.m48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.movil.m49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.movil.m50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.movil.m51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.movil.m52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.movil.m1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.movil.m2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.movil.m3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.movil.m4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.movil.m5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.movil.m6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.movil.m7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.movil.m8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.movil.m9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.movil.m10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.movil.m11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.movil.m12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.movil.m13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.movil.m14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.movil.m15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.movil.m16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.movil.m17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.movil.m18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.movil.m19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.movil.m20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.movil.m21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.movil.m22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.movil.m23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.movil.m24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.movil.m25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.movil.m26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.movil.m27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.movil.m28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.movil.m29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.movil.m30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.movil.m31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.movil.m32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.movil.m33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.movil.m34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.movil.m35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.movil.m36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.movil.m37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.movil.m38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.movil.m39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.movil.m40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.movil.m41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.movil.m42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.movil.m43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.movil.m44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.movil.m45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.movil.m46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.movil.m47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.movil.m48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.movil.m49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.movil.m50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.movil.m51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.movil.m52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.movil.m1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.movil.m2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.movil.m3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.movil.m4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.movil.m5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.movil.m6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.movil.m7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.movil.m8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.movil.m9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.movil.m10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.movil.m11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.movil.m12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.movil.m13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.movil.m14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.movil.m15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.movil.m16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.movil.m17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.movil.m18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.movil.m19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.movil.m20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.movil.m21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.movil.m22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.movil.m23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.movil.m24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.movil.m25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.movil.m26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.movil.m27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.movil.m28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.movil.m29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.movil.m30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.movil.m31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.movil.m32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.movil.m33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.movil.m34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.movil.m35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.movil.m36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.movil.m37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.movil.m38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.movil.m39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.movil.m40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.movil.m41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.movil.m42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.movil.m43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.movil.m44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.movil.m45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.movil.m46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.movil.m47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.movil.m48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.movil.m49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.movil.m50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.movil.m51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.movil.m52 = "green";
        }
        if (practica.idPracticaOdontologica == 1) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.extraccion.e1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.extraccion.e2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.extraccion.e3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.extraccion.e4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.extraccion.e5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.extraccion.e6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.extraccion.e7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.extraccion.e8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.extraccion.e9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.extraccion.e10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.extraccion.e11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.extraccion.e12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.extraccion.e13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.extraccion.e14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.extraccion.e15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.extraccion.e16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.extraccion.e17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.extraccion.e18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.extraccion.e19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.extraccion.e20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.extraccion.e21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.extraccion.e22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.extraccion.e23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.extraccion.e24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.extraccion.e25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.extraccion.e26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.extraccion.e27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.extraccion.e28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.extraccion.e29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.extraccion.e30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.extraccion.e31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.extraccion.e32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.extraccion.e33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.extraccion.e34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.extraccion.e35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.extraccion.e36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.extraccion.e37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.extraccion.e38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.extraccion.e39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.extraccion.e40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.extraccion.e41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.extraccion.e42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.extraccion.e43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.extraccion.e44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.extraccion.e45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.extraccion.e46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.extraccion.e47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.extraccion.e48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.extraccion.e49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.extraccion.e50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.extraccion.e51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.extraccion.e52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.extraccion.e1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.extraccion.e2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.extraccion.e3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.extraccion.e4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.extraccion.e5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.extraccion.e6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.extraccion.e7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.extraccion.e8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.extraccion.e9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.extraccion.e10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.extraccion.e11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.extraccion.e12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.extraccion.e13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.extraccion.e14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.extraccion.e15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.extraccion.e16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.extraccion.e17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.extraccion.e18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.extraccion.e19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.extraccion.e20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.extraccion.e21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.extraccion.e22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.extraccion.e23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.extraccion.e24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.extraccion.e25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.extraccion.e26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.extraccion.e27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.extraccion.e28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.extraccion.e29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.extraccion.e30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.extraccion.e31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.extraccion.e32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.extraccion.e33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.extraccion.e34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.extraccion.e35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.extraccion.e36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.extraccion.e37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.extraccion.e38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.extraccion.e39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.extraccion.e40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.extraccion.e41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.extraccion.e42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.extraccion.e43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.extraccion.e44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.extraccion.e45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.extraccion.e46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.extraccion.e47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.extraccion.e48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.extraccion.e49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.extraccion.e50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.extraccion.e51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.extraccion.e52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.extraccion.e1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.extraccion.e2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.extraccion.e3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.extraccion.e4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.extraccion.e5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.extraccion.e6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.extraccion.e7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.extraccion.e8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.extraccion.e9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.extraccion.e10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.extraccion.e11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.extraccion.e12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.extraccion.e13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.extraccion.e14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.extraccion.e15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.extraccion.e16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.extraccion.e17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.extraccion.e18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.extraccion.e19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.extraccion.e20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.extraccion.e21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.extraccion.e22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.extraccion.e23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.extraccion.e24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.extraccion.e25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.extraccion.e26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.extraccion.e27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.extraccion.e28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.extraccion.e29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.extraccion.e30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.extraccion.e31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.extraccion.e32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.extraccion.e33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.extraccion.e34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.extraccion.e35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.extraccion.e36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.extraccion.e37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.extraccion.e38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.extraccion.e39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.extraccion.e40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.extraccion.e41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.extraccion.e42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.extraccion.e43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.extraccion.e44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.extraccion.e45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.extraccion.e46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.extraccion.e47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.extraccion.e48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.extraccion.e49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.extraccion.e50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.extraccion.e51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.extraccion.e52 = "green";
        }
        if (practica.idPracticaOdontologica == 9) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.ortodoncia.o1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.ortodoncia.o2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.ortodoncia.o3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.ortodoncia.o4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.ortodoncia.o5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.ortodoncia.o6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.ortodoncia.o7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.ortodoncia.o8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.ortodoncia.o9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.ortodoncia.o10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.ortodoncia.o11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.ortodoncia.o12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.ortodoncia.o13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.ortodoncia.o14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.ortodoncia.o15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.ortodoncia.o16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.ortodoncia.o17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.ortodoncia.o18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.ortodoncia.o19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.ortodoncia.o20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.ortodoncia.o21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.ortodoncia.o22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.ortodoncia.o23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.ortodoncia.o24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.ortodoncia.o25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.ortodoncia.o26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.ortodoncia.o27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.ortodoncia.o28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.ortodoncia.o29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.ortodoncia.o30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.ortodoncia.o31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.ortodoncia.o32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.ortodoncia.o33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.ortodoncia.o34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.ortodoncia.o35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.ortodoncia.o36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.ortodoncia.o37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.ortodoncia.o38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.ortodoncia.o39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.ortodoncia.o40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.ortodoncia.o41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.ortodoncia.o42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.ortodoncia.o43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.ortodoncia.o44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.ortodoncia.o45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.ortodoncia.o46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.ortodoncia.o47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.ortodoncia.o48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.ortodoncia.o49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.ortodoncia.o50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.ortodoncia.o51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.ortodoncia.o52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.ortodoncia.o1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.ortodoncia.o2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.ortodoncia.o3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.ortodoncia.o4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.ortodoncia.o5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.ortodoncia.o6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.ortodoncia.o7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.ortodoncia.o8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.ortodoncia.o9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.ortodoncia.o10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.ortodoncia.o11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.ortodoncia.o12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.ortodoncia.o13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.ortodoncia.o14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.ortodoncia.o15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.ortodoncia.o16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.ortodoncia.o17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.ortodoncia.o18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.ortodoncia.o19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.ortodoncia.o20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.ortodoncia.o21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.ortodoncia.o22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.ortodoncia.o23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.ortodoncia.o24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.ortodoncia.o25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.ortodoncia.o26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.ortodoncia.o27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.ortodoncia.o28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.ortodoncia.o29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.ortodoncia.o30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.ortodoncia.o31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.ortodoncia.o32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.ortodoncia.o33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.ortodoncia.o34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.ortodoncia.o35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.ortodoncia.o36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.ortodoncia.o37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.ortodoncia.o38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.ortodoncia.o39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.ortodoncia.o40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.ortodoncia.o41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.ortodoncia.o42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.ortodoncia.o43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.ortodoncia.o44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.ortodoncia.o45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.ortodoncia.o46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.ortodoncia.o47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.ortodoncia.o48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.ortodoncia.o49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.ortodoncia.o50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.ortodoncia.o51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.ortodoncia.o52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.ortodoncia.o1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.ortodoncia.o2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.ortodoncia.o3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.ortodoncia.o4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.ortodoncia.o5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.ortodoncia.o6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.ortodoncia.o7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.ortodoncia.o8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.ortodoncia.o9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.ortodoncia.o10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.ortodoncia.o11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.ortodoncia.o12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.ortodoncia.o13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.ortodoncia.o14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.ortodoncia.o15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.ortodoncia.o16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.ortodoncia.o17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.ortodoncia.o18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.ortodoncia.o19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.ortodoncia.o20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.ortodoncia.o21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.ortodoncia.o22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.ortodoncia.o23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.ortodoncia.o24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.ortodoncia.o25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.ortodoncia.o26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.ortodoncia.o27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.ortodoncia.o28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.ortodoncia.o29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.ortodoncia.o30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.ortodoncia.o31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.ortodoncia.o32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.ortodoncia.o33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.ortodoncia.o34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.ortodoncia.o35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.ortodoncia.o36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.ortodoncia.o37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.ortodoncia.o38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.ortodoncia.o39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.ortodoncia.o40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.ortodoncia.o41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.ortodoncia.o42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.ortodoncia.o43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.ortodoncia.o44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.ortodoncia.o45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.ortodoncia.o46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.ortodoncia.o47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.ortodoncia.o48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.ortodoncia.o49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.ortodoncia.o50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.ortodoncia.o51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.ortodoncia.o52 = "green";
        }
        if (practica.idPracticaOdontologica == 3) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.corona.c1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.corona.c2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.corona.c3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.corona.c4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.corona.c5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.corona.c6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.corona.c7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.corona.c8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.corona.c9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.corona.c10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.corona.c11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.corona.c12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.corona.c13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.corona.c14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.corona.c15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.corona.c16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.corona.c17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.corona.c18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.corona.c19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.corona.c20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.corona.c21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.corona.c22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.corona.c23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.corona.c24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.corona.c25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.corona.c26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.corona.c27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.corona.c28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.corona.c29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.corona.c30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.corona.c31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.corona.c32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.corona.c33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.corona.c34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.corona.c35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.corona.c36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.corona.c37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.corona.c38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.corona.c39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.corona.c40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.corona.c41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.corona.c42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.corona.c43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.corona.c44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.corona.c45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.corona.c46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.corona.c47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.corona.c48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.corona.c49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.corona.c50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.corona.c51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.corona.c52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.corona.c1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.corona.c2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.corona.c3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.corona.c4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.corona.c5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.corona.c6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.corona.c7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.corona.c8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.corona.c9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.corona.c10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.corona.c11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.corona.c12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.corona.c13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.corona.c14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.corona.c15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.corona.c16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.corona.c17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.corona.c18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.corona.c19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.corona.c20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.corona.c21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.corona.c22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.corona.c23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.corona.c24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.corona.c25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.corona.c26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.corona.c27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.corona.c28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.corona.c29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.corona.c30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.corona.c31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.corona.c32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.corona.c33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.corona.c34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.corona.c35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.corona.c36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.corona.c37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.corona.c38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.corona.c39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.corona.c40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.corona.c41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.corona.c42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.corona.c43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.corona.c44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.corona.c45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.corona.c46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.corona.c47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.corona.c48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.corona.c49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.corona.c50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.corona.c51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.corona.c52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.corona.c1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.corona.c2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.corona.c3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.corona.c4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.corona.c5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.corona.c6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.corona.c7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.corona.c8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.corona.c9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.corona.c10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.corona.c11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.corona.c12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.corona.c13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.corona.c14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.corona.c15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.corona.c16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.corona.c17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.corona.c18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.corona.c19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.corona.c20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.corona.c21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.corona.c22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.corona.c23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.corona.c24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.corona.c25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.corona.c26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.corona.c27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.corona.c28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.corona.c29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.corona.c30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.corona.c31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.corona.c32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.corona.c33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.corona.c34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.corona.c35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.corona.c36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.corona.c37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.corona.c38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.corona.c39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.corona.c40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.corona.c41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.corona.c42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.corona.c43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.corona.c44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.corona.c45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.corona.c46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.corona.c47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.corona.c48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.corona.c49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.corona.c50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.corona.c51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.corona.c52 = "green";
        }
        if (practica.idPracticaOdontologica == 4) {
          if (
            practica.idDiente == 1 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r11 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r21 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r31 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r41 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r51 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r61 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r71 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r81 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r91 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r101 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r111 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r121 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r131 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r141 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r151 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r161 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r171 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r181 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r191 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r201 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r211 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r221 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r231 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r241 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r251 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r261 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r271 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r281 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r291 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r301 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r311 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r321 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r331 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r341 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r351 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r361 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r371 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r381 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r391 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r401 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r411 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r421 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r431 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r441 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r451 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r461 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r471 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r481 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r491 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r501 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r511 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r521 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r12 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r22 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r32 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r42 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r52 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r62 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r72 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r82 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r92 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r102 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r112 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r122 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r132 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r142 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r152 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r162 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r172 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r182 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r192 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r202 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r212 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r222 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r232 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r242 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r252 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r262 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r272 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r282 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r292 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r302 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r312 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r322 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r332 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r342 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r352 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r362 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r372 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r382 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r392 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r402 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r412 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r422 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r432 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r442 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r452 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r462 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r472 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r482 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r492 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r502 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r512 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r522 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r13 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r23 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r33 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r43 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r53 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r63 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r73 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r83 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r93 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r103 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r113 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r123 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r133 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r143 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r153 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r163 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r173 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r183 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r193 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r203 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r213 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r223 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r233 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r243 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r253 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r263 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r273 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r283 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r293 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r303 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r313 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r323 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r333 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r343 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r353 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r363 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r373 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r383 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r393 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r403 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r413 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r423 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r433 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r443 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r453 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r463 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r473 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r483 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r493 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r503 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r513 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r523 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r14 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r24 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r34 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r44 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r54 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r64 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r74 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r84 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r94 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r104 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r114 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r124 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r134 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r144 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r154 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r164 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r174 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r184 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r194 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r204 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r214 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r224 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r234 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r244 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r254 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r264 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r274 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r284 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r294 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r304 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r314 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r324 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r334 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r344 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r354 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r364 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r374 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r384 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r394 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r404 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r414 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r424 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r434 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r444 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r454 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r464 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r474 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r484 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r494 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r504 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r514 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r524 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r15 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r25 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r35 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r45 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r55 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r65 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r75 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r85 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r95 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r105 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r115 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r125 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r135 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r145 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r155 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r165 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r175 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r185 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r195 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r205 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r215 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r225 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r235 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r245 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r255 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r265 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r275 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r285 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r295 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r305 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r315 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r325 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r335 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r345 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r355 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r365 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r375 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r385 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r395 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r405 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r415 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r425 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r435 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r445 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r455 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r465 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r475 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r485 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r495 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r505 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r515 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r525 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r11 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r21 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r31 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r41 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r51 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r61 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r71 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r81 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r91 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r101 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r111 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r121 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r131 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r141 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r151 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r161 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r171 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r181 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r191 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r201 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r211 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r221 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r231 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r241 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r251 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r261 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r271 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r281 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r291 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r301 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r311 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r321 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r331 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r341 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r351 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r361 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r371 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r381 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r391 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r401 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r411 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r421 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r431 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r441 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r451 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r461 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r471 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r481 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r491 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r501 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r511 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r521 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r12 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r22 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r32 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r42 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r52 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r62 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r72 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r82 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r92 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r102 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r112 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r122 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r132 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r142 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r152 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r162 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r172 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r182 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r192 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r202 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r212 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r222 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r232 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r242 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r252 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r262 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r272 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r282 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r292 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r302 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r312 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r322 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r332 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r342 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r352 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r362 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r372 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r382 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r392 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r402 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r412 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r422 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r432 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r442 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r452 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r462 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r472 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r482 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r492 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r502 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r512 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r522 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r13 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r23 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r33 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r43 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r53 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r63 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r73 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r83 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r93 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r103 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r113 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r123 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r133 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r143 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r153 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r163 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r173 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r183 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r193 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r203 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r213 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r223 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r233 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r243 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r253 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r263 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r273 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r283 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r293 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r303 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r313 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r323 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r333 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r343 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r353 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r363 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r373 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r383 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r393 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r403 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r413 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r423 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r433 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r443 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r453 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r463 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r473 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r483 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r493 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r503 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r513 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r523 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r14 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r24 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r34 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r44 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r54 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r64 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r74 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r84 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r94 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r104 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r114 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r124 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r134 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r144 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r154 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r164 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r174 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r184 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r194 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r204 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r214 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r224 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r234 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r244 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r254 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r264 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r274 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r284 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r294 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r304 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r314 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r324 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r334 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r344 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r354 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r364 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r374 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r384 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r394 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r404 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r414 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r424 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r434 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r444 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r454 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r464 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r474 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r484 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r494 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r504 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r514 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r524 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r15 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r25 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r35 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r45 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r55 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r65 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r75 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r85 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r95 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r105 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r115 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r125 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r135 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r145 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r155 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r165 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r175 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r185 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r195 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r205 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r215 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r225 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r235 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r245 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r255 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r265 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r275 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r285 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r295 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r305 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r315 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r325 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r335 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r345 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r355 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r365 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r375 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r385 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r395 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r405 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r415 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r425 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r435 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r445 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r455 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r465 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r475 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r485 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r495 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r505 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r515 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r525 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r11 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r21 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r31 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r41 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r51 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r61 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r71 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r81 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r91 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r101 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r111 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r121 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r131 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r141 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r151 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r161 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r171 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r181 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r191 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r201 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r211 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r221 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r231 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r241 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r251 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r261 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r271 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r281 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r291 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r301 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r311 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r321 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r331 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r341 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r351 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r361 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r371 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r381 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r391 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r401 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r411 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r421 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r431 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r441 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r451 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r461 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r471 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r481 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r491 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r501 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r511 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r521 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r12 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r22 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r32 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r42 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r52 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r62 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r72 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r82 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r92 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r102 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r112 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r122 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r132 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r142 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r152 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r162 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r172 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r182 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r192 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r202 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r212 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r222 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r232 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r242 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r252 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r262 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r272 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r282 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r292 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r302 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r312 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r322 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r332 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r342 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r352 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r362 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r372 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r382 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r392 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r402 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r412 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r422 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r432 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r442 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r452 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r462 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r472 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r482 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r492 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r502 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r512 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r522 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r13 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r23 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r33 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r43 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r53 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r63 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r73 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r83 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r93 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r103 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r113 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r123 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r133 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r143 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r153 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r163 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r173 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r183 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r193 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r203 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r213 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r223 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r233 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r243 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r253 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r263 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r273 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r283 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r293 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r303 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r313 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r323 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r333 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r343 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r353 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r363 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r373 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r383 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r393 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r403 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r413 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r423 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r433 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r443 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r453 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r463 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r473 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r483 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r493 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r503 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r513 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r523 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r14 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r24 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r34 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r44 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r54 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r64 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r74 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r84 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r94 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r104 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r114 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r124 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r134 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r144 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r154 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r164 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r174 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r184 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r194 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r204 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r214 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r224 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r234 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r244 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r254 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r264 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r274 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r284 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r294 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r304 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r314 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r324 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r334 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r344 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r354 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r364 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r374 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r384 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r394 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r404 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r414 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r424 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r434 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r444 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r454 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r464 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r474 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r484 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r494 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r504 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r514 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r524 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r15 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r25 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r35 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r45 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r55 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r65 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r75 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r85 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r95 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r105 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r115 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r125 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r135 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r145 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r155 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r165 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r175 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r185 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r195 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r205 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r215 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r225 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r235 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r245 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r255 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r265 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r275 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r285 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r295 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r305 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r315 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r325 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r335 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r345 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r355 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r365 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r375 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r385 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r395 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r405 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r415 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r425 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r435 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r445 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r455 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r465 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r475 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r485 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r495 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r505 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r515 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r525 = "green";
        }
      });
      this.$emit(
        "actualizarOdontograma",
        this.listaOdontograma,
        this.restauracion,
        this.corona,
        this.ausencia,
        this.extraccion,
        this.ortodoncia,
        this.fija,
        this.movil,
        this.implante,
        this.tratamiento
      );
    },
  },
};
</script>
