<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Consultorios</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="this.listaConsultorios"
        class="elevation-1"
        locale="es-ar"
        item-key="idConsultorio"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarConsultorio(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Consultorio</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarConsultorio(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Consultorio</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoConsultorio"
          >Nuevo Consultorio</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoConsultorio
        :visible="showNuevoConsultorio"
        @close="cierraNuevoConsultorio()"
        @consultorioRegistrado="consultorioRegistrado()"
      ></NuevoConsultorio>
      <EditarConsultorio
        :visible="showEditarConsultorio"
        :idConsultorio="editedItem.idConsultorio"
        @close="cierraEditarConsultorio()"
        @consultorioModificado="consultorioModificado()"
      ></EditarConsultorio>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import NuevoConsultorio from "../Configuraciones/NuevoConsultorio.vue";
import EditarConsultorio from "../Configuraciones/EditarConsultorio.vue";
export default {
  data: (vm) => ({
    listaConsultorios: [],
    headers: [
      { text: "Nombre", value: "nombre" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoConsultorio: false,
    showEditarConsultorio: false,
    editedItem: {},
    editedIndex: -1,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },

    borrarConsultorio(item) {
      this.$swal({
        title: "Borrar Consultorio",
        text: "¿Desea borrar el consultorio?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idConsultorio = item.idConsultorio;
          let self = this;
          axios
            .get("/Profesional/BorrarConsultorio?", {
              params: {
                idConsultorio: idConsultorio,
              },
            })
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title:
                    "Algun profesional tiene asociado este consultorio. No se puede borrar.",
                  vm: self,
                });
              } else {
                self.showAlert({
                  icon: "success",
                  title: "Consultorio borrado exitosamente",
                  vm: self,
                });
                setTimeout(self.buscarConsultorios(), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El consultorio no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoConsultorio() {
      this.showNuevoConsultorio = false;
      //this.buscarObrasSociales();
    },
    cierraEditarConsultorio() {
      this.showEditarConsultorio = false;
      //this.buscarObrasSociales();
    },
    consultorioRegistrado() {
      this.showNuevoConsultorio = false;
      this.buscarConsultorios();
    },
    consultorioModificado() {
      this.showEditarConsultorio = false;
      this.buscarConsultorios();
    },
    nuevoConsultorio() {
      this.showNuevoConsultorio = true;
    },
    editarConsultorio(item) {
      this.editedIndex = this.listaConsultorios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarConsultorio = true;
    },
    buscarConsultorios() {
      const filtrosData = {
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Profesional/GetConsultoriosDeInst?", {
          params: {
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaConsultorios = response.data;
          else self.listaConsultorios = [];
        })
        .catch(function(error) {});
    },
  },
  created() {
    this.buscarConsultorios();
  },
  components: {
    NuevoConsultorio,
    EditarConsultorio,
  },
};
</script>
