<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Indicaciones Médicas</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaIndicaciones"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="verIndicacion"
        item-key="idIndicacion"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="verIndicacion(item)"
                >mdi-magnify</v-icon
              >
            </template>
            <span>Ver Indicación</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarIndicacion(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Indicación</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarIndicacion(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Indicación</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarIndicacion(item)"
                >mdi-email</v-icon
              >
            </template>
            <span>Enviar Indicación por Mail</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaIndicacion"
          >Nueva Indicación</v-btn
        >
        <v-btn color="warning" @click.stop="showVerTodas = true"
          >Ver Todas</v-btn
        >
      </v-card-actions>
      <NuevaIndicacion
        :visible="showNuevaIndicacion"
        :idPaciente="idPaciente"
        :paciente="paciente"
        :institucion="institucion"
        @close="showNuevaIndicacion = false"
        @indicacionRegistrada="indicacionRegistrada"
      ></NuevaIndicacion>
      <EditarIndicacion
        :visible="showEditarIndicacion"
        :idPaciente="idPaciente"
        :idIndicacion="editedItem.idIndicacion"
        :paciente="paciente"
        :institucion="institucion"
        @close="showEditarIndicacion = false"
        @indicacionModificada="indicacionModificada"
      ></EditarIndicacion>
      <VerIndicacion
        :visible="showVerIndicacion"
        :indicacion="editedItem"
        :paciente="paciente"
        :institucion="institucion"
        @close="showVerIndicacion = false"
      ></VerIndicacion>
      <EnviarIndicacion
        :visible="showEnviarIndicacion"
        :indicacion="editedItem"
        :paciente="paciente"
        :institucion="institucion"
        @close="showEnviarIndicacion = false"
      ></EnviarIndicacion>
      <VerTodasIndicaciones
        :visible="showVerTodas"
        :indicaciones="listaIndicaciones"
        :paciente="paciente"
        @close="showVerTodas = false"
      ></VerTodasIndicaciones>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevaIndicacion from "../HistClin/NuevaIndicacion.vue";
import VerIndicacion from "../HistClin/VerIndicacion.vue";
import EditarIndicacion from "../HistClin/EditarIndicacion.vue";
import EnviarIndicacion from "../HistClin/EnviarIndicacion.vue";
import VerTodasIndicaciones from "../HistClin/VerTodasIndicaciones.vue";
export default {
  data: (vm) => ({
    headers: [
      { text: "Fecha", value: "fechaIndicacionString" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevaIndicacion: false,
    showVerIndicacion: false,
    showEditarIndicacion: false,
    showEnviarIndicacion: false,
    editedItem: {},
    editedIndex: -1,
    showVerTodas: false,
  }),
  props: ["idPaciente", "lista", "paciente", "profsBuscar", "institucion"],
  computed: {
    listaIndicaciones: {
      set(value) {
        if (value != []) this.$emit("actualizarIndicaciones");
      },
      get() {
        return this.lista;
      },
    },
  },
  methods: {
    borrarIndicacion(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.$swal({
          title: "Borrar Indicación",
          text: "¿Desea borrar la indicación?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idIndicacion = item.idIndicacion;
            let self = this;
            axios
              .get("/HistoriaClinica/BorrarIndicacion?", {
                params: {
                  idIndicacion: idIndicacion,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Indicación borrada exitosamente",
                  vm: self,
                  timer: 1700,
                });
                setTimeout(self.buscarIndicaciones(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La indicación no pudo ser borrada",
                  vm: this,
                  timer: 1700,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      } else {
        this.showAlert({
          icon: "error",
          title:
            "La indicación fue creada por un profesional que usted no tiene asociado. No puede eliminar la indicación",
          vm: this,
          timer: 3000,
        });
      }
    },
    editarIndicacion(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaIndicaciones.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEditarIndicacion = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "La indicación fue creada por un profesional que usted no tiene asociado. No puede editar la indicación",
          vm: this,
          timer: 3000,
        });
      }
    },
    enviarIndicacion(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaIndicaciones.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEnviarIndicacion = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "La indicación fue creada por un profesional que usted no tiene asociado. No puede envaiar la indicación",
          vm: this,
          timer: 3000,
        });
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevaIndicacion() {
      this.showNuevaIndicacion = true;
    },
    verIndicacion(item) {
      this.editedIndex = this.listaIndicaciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerIndicacion = true;
    },
    indicacionRegistrada() {
      this.buscarIndicaciones();
      this.showNuevaIndicacion = false;
    },
    indicacionModificada() {
      this.buscarIndicaciones();
      this.showEditarIndicacion = false;
    },
    buscarIndicaciones() {
      this.listaIndicaciones = 1;
    },
  },
  components: {
    NuevaIndicacion,
    VerIndicacion,
    EditarIndicacion,
    VerTodasIndicaciones,
    EnviarIndicacion,
  },
};
</script>
