<template>
  <v-dialog
    max-width="480px"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Imprimir Turnos</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-if="!esEliminado">
        <v-radio-group v-model="radios" column>
          <v-radio
            label="Sin observaciones (Formato Vertical)"
            value="sinObs"
          ></v-radio>
          <v-radio
            :label="
              `Con observaciones y ${
                $store.state.usuario.institucion.esEmpresa
                  ? 'sede'
                  : 'obra social'
              } (Formato Horizontal)`
            "
            value="conObs"
          ></v-radio>
          <v-radio
            label="Con fecha de nacimiento (Formato Vertical)"
            value="conFN"
          ></v-radio>
          <v-radio
            label="Con teléfono (Formato Vertical)"
            value="conTel"
          ></v-radio>
        </v-radio-group>
      </v-card-text>

      <v-card-text v-else>
        <v-radio-group v-model="radios" column>
          <v-radio label="Turnos Eliminados" value="eliminados"></v-radio>
        </v-radio-group>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import jsPDF from "jspdf";
  import autotable from "jspdf-autotable";
  export default {
    data: (vm) => ({
      radios: vm.esEliminado ? "eliminados" : "sinObs",
    }),
    props: [
      "visible",
      "listaTurnos",
      "fechaDesde",
      "fechaHasta",
      "esEliminado",
    ],
    computed: {
      show: {
        get() {
          if (this.visible) this.setearModels();
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.vaciarModels();
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      setearModels() {
        this.opcionesSeleccionadas = this.opciones;
      },

      vaciarModels() {
        this.opcionesSeleccionadas = [];
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
      imprimir() {
        var source = this.listaTurnos;
        let rows = [];
        let columnHeader = [];

        if (this.radios == "sinObs") {
          var doc = new jsPDF();
          columnHeader = [
            "Dia",
            "Fecha",
            "Hora",
            "Dni",
            "Paciente",
            "Profesional",
            "Estado",
          ];

          source.forEach((element) => {
            var temp = [
              element.diaSemana,
              element.fechaTurnoString,
              element.horaInicioString,
              element.dniPaciente,
              element.nombrePaciente,
              element.nombreProfesional,
              element.estadoTurnoString,
            ];
            rows.push(temp);
          });
        } else {
          if (this.radios == "conObs") {
            var doc = new jsPDF("landscape");
            columnHeader = this.$store.state.usuario.institucion.esEmpresa
              ? [
                  "Dia",
                  "Fecha",
                  "Hora",
                  "Dni",
                  "Paciente",
                  "Observaciones",
                  "Sede",
                ]
              : [
                  "Dia",
                  "Fecha",
                  "Hora",
                  "Dni",
                  "Paciente",
                  "Observaciones",
                  "Obra Social",
                ];

            source.forEach((element) => {
              var temp = [
                element.diaSemana,
                element.fechaTurnoString,
                element.horaInicioString,
                element.dniPaciente,
                element.nombrePaciente,
                element.observacionesTurno,
                element.obraSocial,
              ];
              rows.push(temp);
            });
          } else if (this.radios == "conFN") {
            var doc = new jsPDF();
            columnHeader = [
              "Dia",
              "Fecha",
              "Hora",
              "Dni",
              "Paciente",
              "Fecha Nac.",
              "Profesional",
            ];

            source.forEach((element) => {
              var temp = [
                element.diaSemana,
                element.fechaTurnoString,
                element.horaInicioString,
                element.dniPaciente,
                element.nombrePaciente,
                element.fechaNacimientoString,
                element.nombreProfesional,
              ];
              rows.push(temp);
            });
          } else if (this.radios === "conTel") {
            var doc = new jsPDF();
            columnHeader = [
              "Dia",
              "Fecha",
              "Hora",
              "Dni",
              "Paciente",
              "Teléfono",
              "Profesional",
            ];

            source.forEach((element) => {
              var temp = [
                element.diaSemana,
                element.fechaTurnoString,
                element.horaInicioString,
                element.dniPaciente,
                element.nombrePaciente,
                element.celular,
                element.nombreProfesional,
              ];
              rows.push(temp);
            });
          } else if (this.radios === "eliminados") {
            var doc = new jsPDF("landscape");
            columnHeader = [
              "Dia",
              "Fecha",
              "Hora",
              "Dni",
              "Paciente",
              "Profesional",
              "Estado",
              "Usuario Elimina",
              "Fecha Elimina",
            ];

            source.forEach((element) => {
              var temp = [
                element.diaSemana,
                element.fechaTurnoString,
                element.horaInicioString,
                element.dniPaciente,
                element.nombrePaciente,
                element.nombreProfesional,
                element.estadoTurnoString,
                element.usuarioEliminacion,
                element.fechaEliminacionString,
              ];
              rows.push(temp);
            });
          }
        }
        //doc.text('Listado de Turnos',20,10);
        var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

        doc.setFontSize(12);
        doc.text(nombreInstitucion, 13, 10);
        doc.setFontSize(10);
        doc.text(
          "Fecha Desde: " +
            this.formatDate(this.fechaDesde) +
            "  -  " +
            "Fecha Hasta: " +
            this.formatDate(this.fechaHasta),
          13,
          16
        );

        doc.setFontSize(16);
        doc.text(
          "Listado de Turnos",
          doc.internal.pageSize.getWidth() / 2,
          33,
          {
            align: "center",
          }
        );
        if (this.radios == "conObs") {
          doc.text(
            "- Profesional: " + this.listaTurnos[0].nombreProfesional,
            173,
            33
          );
        }
        doc.autoTable(columnHeader, rows, {
          startY: 39,
          didParseCell: function(table) {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#c4c5c5";
            }
          },
        });
        window.open(doc.output("bloburl"), "_blank");

        //doc.save('turnos.pdf');
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },
    },
  };
</script>
