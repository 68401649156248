<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Plan</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="5" sm="5" md="5">
              <v-text-field
                v-model="nombrePlan"
                prepend-icon="mdi-account"
                label="Nombre Plan"
                maxlength="50"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarPlan"
            >Guardar</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    nombrePlan: null,
    valid: false,
  }),

  props: ["visible", "idPlan", "idObraSocial"],

  computed: {
    show: {
      get() {
        if (this.visible) this.buscarDatosPlan();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    buscarDatosPlan() {
      const idPlan = this.idPlan;
      let self = this;

      axios
        .get("/ObraSocial/BuscarDatosPlan?", {
          params: {
            idPlan: this.idPlan,
          },
        })
        .then((response) => {
          self.nombrePlan = response.data.nombrePlan;
        })
        .catch((error) => {});
    },
    modificarPlan() {
      this.$swal({
        title: "Modificar Plan",
        text: "¿Desea modificar el nombre del plan?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosPlan = {
            nombrePlan: this.nombrePlan,
            idObraSocial: this.idObraSocial,
            idPlan: this.idPlan,
          };
          let self = this;
          axios
            .post("/ObraSocial/ModificarPlan", datosPlan)
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: "Ya existe un plan registrado con ese nombre",
                  vm: self,
                });
              } else {
                self.$refs.form.resetValidation();

                document.getElementsByClassName(
                  "v-dialog--active"
                )[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title: "Plan modificado exitosamente",
                  vm: self,
                });
                self.$emit("planModificado");
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El plan no pudo ser modificado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>
