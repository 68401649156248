<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Registros de Vacunación</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaVacunaciones"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="editarVacunacion"
        item-key="idHC"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        no-data-text="Paciente sin registros"
        no-results-text="Paciente sin registros"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }" v-if="origen === 'enfermeria'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarVacunacion(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Registro</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarVacunacion(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Registro</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn v-if="1 === 2" color="warning" @click.stop="verCarnet"
          >Carnet Vacunacion</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn
          v-if="origen === 'enfermeria'"
          color="success"
          @click.stop="nuevaVacunacion"
          >Nueva Vacunacion</v-btn
        >
      </v-card-actions>
      <NuevaVacunacion
        :visible="showNuevaVacunacion"
        :idPaciente="idPaciente"
        @close="showNuevaVacunacion = false"
        @vacunacionRegistrada="vacunacionRegistrada"
      ></NuevaVacunacion>
      <EditarVacunacion
        :visible="showEditarVacunacion"
        :idPaciente="idPaciente"
        :idVacunacion="editedItem.idVacunacion"
        @close="showEditarVacunacion = false"
        @vacunacionModificada="vacunacionModificada"
      ></EditarVacunacion>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevaVacunacion from "./NuevaVacunacion.vue";
import EditarVacunacion from "./EditarVacunacion.vue";

export default {
  data: (vm) => ({
    headers: vm.getHeaders(),
    showNuevaVacunacion: false,
    showEditarVacunacion: false,
    editedItem: {},
    editedIndex: -1,
  }),
  props: {
    idPaciente: {
      required: true,
    },
    lista: {
      required: true,
    },
    paciente: {
      required: true,
    },
    enfermerosHabilitados: {
      required: false,
    },
    institucion: {
      required: false,
    },
    origen: {
      type: String,
      required: false,
      default: "enfermeria",
    },
  },
  computed: {
    listaVacunaciones: {
      set(value) {
        if (value != []) this.$emit("actualizarVacunaciones");
      },
      get() {
        return this.lista;
      },
    },
  },
  methods: {
    getHeaders() {
      let headers = [
        { text: "Fecha", value: "fechaString" },
        { text: "Enfermero", value: "nombreEnfermero" },
        { text: "Vacuna", value: "nombreVacuna" },
        { text: "Edad", value: "nombreEdadVacuna" },
        { text: "Dosis", value: "nombreTipoDosis" },
        { text: "Marca", value: "marca" },
        { text: "Observaciones", value: "observaciones" },
      ];
      if (this.origen === "enfermeria") {
        headers.push({ text: "Acciones", value: "actions" });
      }
      return headers;
    },
    borrarVacunacion(item) {
      if (
        this.enfermerosHabilitados.some(
          (o) => o.idEnfermero == item.idEnfermero
        )
      ) {
        this.$swal({
          title: "Borrar Registro de Vacunación",
          text: "¿Desea borrar el registro?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idVacunacion = item.idVacunacion;
            let self = this;
            axios
              .get("/Enfermeria/BorrarVacunacion?", {
                params: {
                  idVacunacion: idVacunacion,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Registro borrado exitosamente",
                  vm: self,
                  timer: 1700,
                });
                setTimeout(self.buscarVacunaciones(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El registro no pudo ser borrado",
                  vm: this,
                  timer: 1700,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El registro fue creado por un enfermero que usted no tiene asociado. No puede eliminar el registro",
          vm: this,
          timer: 3000,
        });
      }
    },
    editarVacunacion(item) {
      if (
        this.enfermerosHabilitados.some(
          (o) => o.idEnfermero == item.idEnfermero
        )
      ) {
        this.editedIndex = this.listaVacunaciones.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEditarVacunacion = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El registro fue creado por un enfermero que usted no tiene asociado. No puede editar el registro",
          vm: this,
          timer: 3000,
        });
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevaVacunacion() {
      this.showNuevaVacunacion = true;
    },
    vacunacionRegistrada() {
      this.buscarVacunaciones();
      this.showNuevaVacunacion = false;
    },
    vacunacionModificada() {
      this.buscarVacunaciones();
      this.showEditarVacunacion = false;
    },
    buscarVacunaciones() {
      this.listaVacunaciones = 1;
    },
  },
  components: {
    NuevaVacunacion,
    EditarVacunacion,
  },
};
</script>
