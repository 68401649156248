<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Presupuestos</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Presupuestos</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="paciente"
                prepend-icon="mdi-account"
                label="Paciente"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" color="primary" @click.stop="buscarPresupuestos(1)">
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaPresupuestos"
        class="elevation-1"
        locale="es-ar"
        item-key="idPresupuesto"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium color="orange" class="mr-3" v-on="on" v-bind="attrs" @click.stop="imprimir(item)"
                >mdi-printer</v-icon
              >
            </template>
            <span>Imprimir Presupuesto</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium color="purple" class="mr-3" v-on="on" v-bind="attrs" @click.stop="editarPresupuesto(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Presupuesto</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium v-on="on" color="red" class="mr-2" v-bind="attrs" @click="borrarPresupuesto(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Presupuesto</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium color="green" class="mr-3" v-on="on" v-bind="attrs" @click.stop="cobrar(item)"
                >mdi-currency-usd</v-icon
              >
            </template>
            <span>Cobros Presupuesto</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoPresupuesto">Nuevo Presupuesto</v-btn>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoPresupuesto
        :visible="showNuevoPresupuesto"
        @presupuestoRegistrado="presupuestoRegistrado"
        @close="cierraNuevoPresupuesto()"
      ></NuevoPresupuesto>
      <EditarPresupuesto
        :visible="showEditarPresupuesto"
        :idPresupuesto="editedItem.idPresupuesto"
        @presupuestoModificado="presupuestoModificado"
        @close="cierraEditarPresupuesto()"
      >
      </EditarPresupuesto>
      <NuevoCobro
        :visible="showNuevoCobro"
        :idPresupuesto="editedItem.idPresupuesto"
        :idProfesional="editedItem.idProfesional"
        :idPacienteTurno="editedItem.idPaciente"
        :pacienteTurno="editedItem.nombrePaciente"
        @close="cierraNuevoCobro()"
      ></NuevoCobro>
    </v-card>
  </div>
</template>
<style>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevoPresupuesto from "../Caja/NuevoPresupuesto.vue";
import EditarPresupuesto from "../Caja/EditarPresupuesto.vue";
import NuevoCobro from "../Caja/NuevoCobro.vue";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    paciente: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    prof: "",
    profsBuscar: [],
    listaPresupuestos: [],
    headers: [
      { text: "Fecha Presupuesto", value: "fechaPresupuestoString" },
      { text: "Fecha Vigencia", value: "fechaVigenciaString" },
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Paciente", value: "nombrePaciente" },
      { text: "Importe Total", value: "totalString" },
      { text: "Pagado", value: "pagadoString" },
      { text: "Saldo", value: "saldoString" },
      { text: "Acciones", value: "actions" },
    ],
    fechaDesde: null,
    fechaHasta: null,
    showNuevoPresupuesto: false,
    showEditarPresupuesto: false,
    editedItem: {},
    editedIndex: -1,
    showNuevoCobro: false,
  }),
  methods: {
    cobrar(item) {
      this.editedItem = Object.assign({}, item);
      console.log(this.editedItem);
      this.showNuevoCobro = true;
    },
    cierraNuevoCobro() {
      this.showNuevoCobro = false;
      this.buscarPresupuestos(0);
    },
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    borrarPresupuesto(item) {
      this.$swal({
        title: "Borrar Presupuesto",
        text: "¿Desea borrar el presupuesto?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idPresupuesto = item.idPresupuesto;
          let self = this;
          axios
            .get("/Caja/BorrarPresupuesto?", {
              params: {
                idPresupuesto: idPresupuesto,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Presupuesto borrado",
                vm: self,
                timer: 1700,
              });
              setTimeout(self.buscarPresupuestos(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El presupuesto no puede ser borrado",
                vm: this,
                timer: 2800,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoPresupuesto() {
      this.showNuevoPresupuesto = false;
      this.buscarPresupuestos(0);
    },
    cierraEditarPresupuesto() {
      this.showEditarPresupuesto = false;
      this.buscarPresupuestos(0);
    },
    nuevoPresupuesto() {
      this.showNuevoPresupuesto = true;
    },
    presupuestoRegistrado() {
      this.showNuevoPresupuesto = false;
      this.buscarPresupuestos(0);
    },
    presupuestoModificado() {
      this.showEditarPresupuesto = false;
      this.buscarPresupuestos(0);
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    editarPresupuesto(item) {
      this.editedIndex = this.listaPresupuestos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarPresupuesto = true;
    },
    buscarPresupuestos(state) {
      const filtrosData = {
        paciente: this.paciente,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        idProfesional: this.prof,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Caja/BuscarPresupuestos?", {
          params: {
            fechaDesde: filtrosData.fechaDesde,
            fechaHasta: filtrosData.fechaHasta,
            idProfesional: filtrosData.idProfesional,
            idInstitucion: filtrosData.idInstitucion,
            paciente: filtrosData.paciente ?? "",
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.listaPresupuestos = response.data;
          } else {
            self.listaPresupuestos = [];

            if (state == 1) {
              self.showAlert({
                icon: "error",
                title: "No hay presupuestos que coincidan con el criterio de búsqueda.",
                vm: self,
                timer: 2000,
              });
            }
          }
        })
        .catch(function (error) {});
    },
    async imprimir(item) {
      const { data } = await axios.get("/Usuario/BuscarDatosInstitucion", {
        params: {
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        },
      });

      this.institucion = data;

      var doc = new jsPDF();
      doc.setProperties({
        title: "Presupuesto",
      });

      let rows = [];
      let columnHeader = ["Descripción", "Importe"];
      let importeTotal = 0;
      item.detalle.forEach((element) => {
        const valor =
          element.valor >= 0
            ? `$${parseFloat(element.valor).toFixed(2)}`
            : `-$${parseFloat(Math.abs(element.valor)).toFixed(2)}`;
        var temp = [element.texto, valor];
        rows.push(temp);
        importeTotal += +element.valor;
      });

      let nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      if (this.prof === 920) {
        doc.text("Dirección: Catamarca 2076 4 F  ", 13, 15);
        doc.text("Tel: 236881598", 13, 20);
        doc.text("Mail: maguilo2076@hotmail.com", 13, 25);
      } else {
        doc.text("Dirección: " + this.institucion.direccion.toString().replace(", Argentina", ""), 13, 15);

        if (
          this.institucion.telefono != null &&
          this.institucion.telefono != "" &&
          this.institucion.celular != null &&
          this.institucion.celular != ""
        )
          doc.text("Tel / Cel: " + this.institucion.telefono + " - " + this.institucion.celular, 13, 20);
        if (
          this.institucion.telefono != null &&
          this.institucion.telefono != "" &&
          (this.institucion.celular == null || this.institucion.celular == "")
        )
          doc.text("Tel: " + this.institucion.telefono, 13, 20);
        if (
          (this.institucion.telefono == null || this.institucion.telefono == "") &&
          this.institucion.celular != null &&
          this.institucion.celular != ""
        )
          doc.text("Cel: " + this.institucion.celular, 13, 20);
        if (this.institucion.mail != "" && this.institucion.mail != null)
          doc.text("Mail: " + this.institucion.mail, 13, 25);
      }
      doc.setFontSize(16);
      doc.text("Presupuesto", doc.internal.pageSize.getWidth() / 2, 45, {
        align: "center",
      });
      doc.line(88, 46, 122, 46);
      doc.setFontSize(12);
      doc.text("Profesional: " + item.nombreProfesional, 13, 55);
      doc.text("Paciente: " + item.nombrePaciente, 13, 62);
      doc.text("Fecha Presupuesto: " + item.fechaPresupuestoString, 13, 69);
      doc.text("Fecha Vigencia Hasta: " + item.fechaVigenciaString, 13, 76);

      doc.setFontSize(14);
      doc.text("Detalle del Presupuesto", doc.internal.pageSize.getWidth() / 2, 93, {
        align: "center",
      });
      doc.line(78, 94, 132, 94);

      doc.autoTable(columnHeader, rows, {
        columnStyles: { 1: { halign: "right" } },
        headStyles: { 1: { halign: "right" } },
        startY: 102,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
            if (table.column.raw === "Importe") table.cell.styles.halign = "right";
          }
        },
      });

      let finalY = doc.lastAutoTable.finalY;

      if (finalY > 250) {
        doc.addPage();
        finalY = 20;
      }
      const total =
        importeTotal >= 0
          ? "Importe Total: $" + parseFloat(importeTotal).toFixed(2).toString().replace(".", ",")
          : "Importe Total: -$" + parseFloat(Math.abs(importeTotal)).toFixed(2).toString().replace(".", ",");
      doc.text(total, 195, finalY + 16, {
        align: "right",
      });

      window.open(doc.output("bloburl"), "_blank");
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
        if (self.profsBuscar.length > 1)
          self.profsBuscar.unshift({
            idProfesional: 0,
            nombreProfesional: "TODOS",
          });

        self.prof = self.profsBuscar[0].idProfesional;
      });

    this.fechaDesde = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
    this.fechaHasta = this.fechaDesde;
  },

  components: {
    NuevoPresupuesto,
    EditarPresupuesto,
    NuevoCobro,
  },
};
</script>
