<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nuevo Recordatorio</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="6" md="7">
              <v-text-field
                v-model="titulo"
                prepend-icon="mdi-message-text"
                label="Título"
                dense
                required
                :rules="requiredRules"
                maxlength="100"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="texto"
                prepend-icon="mdi-message-text"
                label="Texto"
                :rules="requiredRules"
                clearable
                auto-grow=true
              ></v-textarea>
            </v-col>
              <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Vigencia Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                label="Fecha Vigencia Hasta"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="usuariosSeleccionados"
                :items="usuarios"
                item-text="usuario"
                item-value="usuario"
                :menu-props="{ maxHeight: '400' }"
                label="Usuarios"
                multiple
                chips
                deletable-chips=true
                hint="Usuarios que verán el recordatorio"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarRecordatorio">Guardar</v-btn>

          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    titulo: '',
    texto: '',
    fechaDesde: '',
    fechaHasta: '',
    usuariosSeleccionados: [],
    usuarios: []

  }),

  props: ["visible"],

  computed: {
    show: {
      get() {
        if(this.visible)
          this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
 
  methods: {
    guardarRecordatorio() {
      this.$swal({
        title: "Guardar Recordatorio",
        text: "¿Desea registrar el recordatorio?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosRecordatorio = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            titulo: this.titulo,
            texto: this.texto,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
            usuarios: this.usuariosSeleccionados

          };
          let self = this;
          
           if (datosRecordatorio.fechaDesde > datosRecordatorio.fechaHasta) {
            self.showAlert({
              icon: "error",
              title: "La fecha desde no puede ser mayor que la fecha hasta",
              vm: this
            });
            return;
          }
              
             axios
            .get("/Usuario/GuardarRecordatorio", {
              params: {
                idInstitucion: datosRecordatorio.idInstitucion,
                titulo: datosRecordatorio.titulo,
                texto: datosRecordatorio.texto,
                fechaDesde: datosRecordatorio.fechaDesde,
                fechaHasta: datosRecordatorio.fechaHasta,
                usuarios: datosRecordatorio.usuarios
              }
            })
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Recordatorio Registrado",
                vm: self,
              });
              self.$emit("recordatorioRegistrado");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El recordatorio no pudo ser registrado",
                vm: this,
              });
            });
              
                     

          
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    setearModels() {
     
      this.fechaDesde = this.parseDate(
      this.formatDate(this.toISOLocal(new Date()))
      );
      
      this.usuarios = []
      this.usuariosSeleccionados = []
      let self= this;
      axios.get('/Usuario/BuscarUsuarios?', {
        params: { 
           idInstitucion: self.$store.state.usuario.institucion.idInstitucion
        }
                
      }).then(function(response) {
      if (response.data!=null && response.data!='')
          self.usuarios = response.data
      else
          self.usuarios = []
      }).catch(function(error){
                       
      })
      
      this.usuariosSeleccionados = [this.$store.state.usuario.usuario]
    },
    
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    vaciarModels() {
      this.titulo = "";
      this.texto = "";
      this.fechaDesde = "";
      this.fechaHasta = "";
      this.usuariosSeleccionados = [];
      
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    
  },
  
  created() {
    this.vaciarModels();
  },
};
</script>