import jsPDF from "jspdf";

export default function imprimirIndicacion(
  institucion,
  paciente,
  indicacion,
  base64,
  output
) {
  var doc = new jsPDF();

  doc.setProperties({
    title: "Indicación Medica",
  });
  var nombreInstitucion = institucion.nombreInstitucion.toString().trim();

  doc.setFontSize(11);
  doc.text(nombreInstitucion, 13, 35);
  if (indicacion.idProfesional === 920) {
    doc.text("Dirección: Catamarca 2076 4 F  ", 13, 40);
    doc.text("Tel: 236881598", 13, 45);
    doc.text("Mail: maguilo2076@hotmail.com", 13, 50);
  } else {
    doc.text(
      "Dirección: " +
        institucion.direccion.toString().replace(", Argentina", ""),
      13,
      40
    );

    if (
      institucion.telefono != null &&
      institucion.telefono != "" &&
      institucion.celular != null &&
      institucion.celular != ""
    )
      doc.text(
        "Tel / Cel: " + institucion.telefono + " - " + institucion.celular,
        13,
        45
      );
    if (
      institucion.telefono != null &&
      institucion.telefono != "" &&
      (institucion.celular == null || institucion.celular == "")
    )
      doc.text("Tel: " + institucion.telefono, 13, 45);
    if (
      (institucion.telefono == null || institucion.telefono == "") &&
      institucion.celular != null &&
      institucion.celular != ""
    )
      doc.text("Cel: " + institucion.celular, 13, 45);
    if (institucion.mail != "" && institucion.mail != null)
      doc.text("Mail: " + institucion.mail, 13, 50);
  }
  doc.setFontSize(11);
  doc.text("Profesional: " + indicacion.nomProfesional, 13, 65);
  doc.text("Fecha: " + indicacion.fechaIndicacionString, 13, 70);
  doc.text("Paciente: " + paciente.apellidos + " " + paciente.nombres, 13, 75);
  doc.text("D.N.I.: " + paciente.dni, 13, 80);

  if (base64 != null && base64 != "") doc.addImage(base64, 13, 5, 60, 20);

  doc.setFontSize(16);
  doc.line(13, 84, 200, 84);
  doc.text("Indicación Médica", doc.internal.pageSize.getWidth() / 2, 92, {
    align: "center",
  });
  doc.line(82, 93, 128, 93);
  doc.setFontSize(12);
  var textoSplit = indicacion.texto.split(/\r\n|\r|\n/);
  let y = 105;
  for (var i = 0; i < textoSplit.length; i++) {
    if (textoSplit[i].length < 85) {
      doc.text(textoSplit[i], 13, y);
    } else {
      var lineSplit = textoSplit[i].split(" ");
      var linePrint = "";
      var lineTemp = "";
      for (var j = 0; j < lineSplit.length; j++) {
        if (linePrint.length >= 85) {
          if (j == lineSplit.length - 1) doc.text(lineSplit[j], 13, y + 5);

          lineTemp = linePrint + lineSplit[j];
          if (lineTemp.length < 85) {
            doc.text(lineTemp, 13, y);
            linePrint = "";
          } else {
            doc.text(linePrint, 13, y);
            linePrint = lineSplit[j] + " ";
          }
          y = y + 5;

          if (y > 290) {
            y = 20;
            doc.addPage();
          }
        } else {
          linePrint = linePrint + lineSplit[j] + " ";
          if (j == lineSplit.length - 1) {
            doc.text(linePrint, 13, y);
            if (y > 290) {
              y = 20;
              doc.addPage();
            }
          }
        }
      }
    }
    y = y + 5;
    if (y > 290) {
      y = 20;
      doc.addPage();
    }
  }
  if (indicacion.diagnostico != "") {
    doc.line(13, y, 200, y);
    y = y + 8;
    if (y > 290) {
      y = 20;
      doc.addPage();
    }
  }
  doc.setFontSize(11);

  if (indicacion.diagnostico != "") {
    doc.text("Diagnóstico: " + indicacion.diagnostico, 13, y);
  }
  if (output === "screen") {
    window.open(doc.output("bloburl"), "_blank");
  } else {
    return doc.output("blob");
  }
}
