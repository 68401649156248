<template>
  <v-dialog v-model="show" fullscreen transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Gestionar Planes</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12">
            <h2 style="color: indigo">Obra Social: {{ nombreObraSocial }}</h2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="this.listaPlanes"
        class="elevation-1"
        locale="es-ar"
        item-key="idPlan"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarPlan(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Plan</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarPlan(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Plan</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoPlan">Nuevo Plan</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
      <NuevoPlan
        :visible="showNuevoPlan"
        :idObraSocial="idObraSocial"
        @close="cierraNuevoPlan()"
        @planRegistrado="planRegistrado()"
      ></NuevoPlan>
      <EditarPlan
        :visible="showEditarPlan"
        :idPlan="editedItem.idPlan"
        :idObraSocial="idObraSocial"
        @close="cierraEditarPlan()"
        @planModificado="planModificado()"
      ></EditarPlan>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevoPlan from "../Configuraciones/NuevoPlan.vue";
import EditarPlan from "../Configuraciones/EditarPlan.vue";
export default {
  data: (vm) => ({
    listaPlanes: [],
    headers: [
      { text: "Nombre", value: "nombrePlan" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoPlan: false,
    showEditarPlan: false,
    editedItem: {},
    editedIndex: -1,
  }),

  props: ["visible", "nombreObraSocial", "idObraSocial"],

  computed: {
    show: {
      get() {
        this.buscarPlanes();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    borrarPlan(item) {
      this.$swal({
        title: "Borrar Plan",
        text: "¿Desea borrar el plan?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idPlan = item.idPlan;
          let self = this;
          axios
            .get("/ObraSocial/BorrarPlan?", {
              params: {
                idPlan: idPlan,
              },
            })
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: "El plan tiene transacciones asociadas",
                  vm: self,
                });
              } else {
                self.showAlert({
                  icon: "success",
                  title: "Plan borrado exitosamente",
                  vm: self,
                });
                setTimeout(self.buscarPlanes(), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La plan no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoPlan() {
      this.showNuevoPlan = false;
      //this.buscarPlanes();
    },
    cierraEditarPlan() {
      this.showEditarPlan = false;
      //this.buscarObrasSociales();
    },
    planRegistrado() {
      this.showNuevoPlan = false;
      this.buscarPlanes();
    },
    planModificado() {
      this.showEditarPlan = false;
      this.buscarPlanes();
    },
    nuevoPlan() {
      this.showNuevoPlan = true;
    },
    editarPlan(item) {
      this.editedIndex = this.listaPlanes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarPlan = true;
    },

    buscarPlanes() {
      const idObraSocial = this.idObraSocial;

      let self = this;
      axios
        .get("/ObraSocial/GetPlanesDeOS?", {
          params: {
            idObraSocial: idObraSocial,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaPlanes = response.data;
          else self.listaPlanes = [];
        })
        .catch(function(error) {});
    },
  },
  components: {
    NuevoPlan,
    EditarPlan,
  },
};
</script>
