<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Configurar Institución</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h4>Datos Generales de la Institución</h4>
            </v-col>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-home"
                label="Nombre"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="observaciones"
                prepend-icon="mdi-home"
                label="Descripcion de la Institución"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="sitio"
                prepend-icon="mdi-web"
                label="Sitio Web Institucional"
                :rules="urlRules"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="telefono"
                prepend-icon="mdi-phone"
                label="Telefono"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="mail"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="50"
              ></v-text-field>
            </v-col>
            <v-col cols="8" md="4" sm="4">
              <v-switch
                dense
                v-model="disponibleWeb"
                label="Habilitar Turnos Web"
              ></v-switch>
            </v-col>
            <v-col cols="8" md="4" sm="4">
              <v-switch
                dense
                v-model="notifica"
                label="Enviar Recordatorio Turnos"
              ></v-switch>
            </v-col>
            <v-col cols="8" md="4" sm="4">
              <v-switch
                dense
                v-model="vllamada"
                label="Utiliza Videoconsulta"
              ></v-switch>
            </v-col>
            <v-col cols="12">
              <h4>Dirección de la Institución en Google Maps</h4>
            </v-col>
            <v-col cols="12" class="mb-0 pb-0">
              <gmap-autocomplete
                :value="direccionString"
                class="form-control"
                style="width: 100%"
                @place_changed="setPlace"
              >
                <template v-slot:input="slotProps">
                  <v-text-field
                    prepend-icon="mdi-account"
                    label="Dirección"
                    :rules="requiredRules"
                    ref="input"
                    v-on:listeners="slotProps.listeners"
                    v-on:attrs="slotProps.attrs"
                    @keyup.enter="usePlace"
                    @blur="usePlace"
                  ></v-text-field>
                </template>
              </gmap-autocomplete>
            </v-col>
            <v-col cols="12">
              <GmapMap
                style="width: 305px; height: 300px;"
                :zoom="zoom"
                :center="center"
                :options="{
                  zoomControl: true,
                  mapTypeControl: false,
                  scaleControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false,
                }"
              >
                <GmapMarker
                  v-for="(marker, index) in markers"
                  :key="index"
                  :position="marker.position"
                />

                <GmapMarker
                  v-if="this.place"
                  :position="{
                    lat: this.place.geometry.location.lat(),
                    lng: this.place.geometry.location.lng(),
                  }"
                />
              </GmapMap>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="modificarDatosInstitucion"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="salir">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </div>
</template>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
    ],
    urlRules: [
      (v) =>
        !v ||
        /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
          v
        ) ||
        "Formato inválido",
    ],
    disponibleWeb: null,
    nombre: null,
    mail: null,
    telefono: null,
    celular: null,
    observaciones: null,
    sitio: "",
    zoom: 15,
    markers: [],
    notifica: null,
    vllamada: null,
    place: null,
    center: {
      lat: -31.65292,
      lng: -64.42826,
    },
    direccion: {
      lat: 1000,
      lng: 1000,
    },
    direccionString: null,
  }),

  mounted() {
    this.cargarDatosInstitucion();
  },
  methods: {
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },

    cargarDatosInstitucion() {
      const idInstitucion = this.$store.state.usuario.institucion.idInstitucion;
      let self = this;

      axios
        .get("/Usuario/BuscarDatosInstitucion?", {
          params: {
            idInstitucion: idInstitucion,
          },
        })
        .then((response) => {
          self.nombre = response.data.nombreInstitucion;
          self.observaciones = response.data.observaciones;
          self.celular = response.data.celular;
          self.telefono = response.data.telefono;
          self.mail = response.data.mail;
          self.disponibleWeb = response.data.disponibleWeb;
          self.notifica = response.data.notificaTurnos;
          self.vllamada = response.data.utilizaVideoLlamada;
          self.sitio = response.data.sitioWeb;

          if (response.data.direccion != "") {
            if (
              response.data.latitud != 1000 &&
              response.data.latitud != null
            ) {
              this.center.lat = response.data.latitud;
              this.center.lng = response.data.longitud;
              this.direccion.lat = response.data.latitud;
              this.direccion.lng = response.data.longitud;
              this.markers.push({
                position: {
                  lat: this.direccion.lat,
                  lng: this.direccion.lng,
                },
              });
            }
            //document.getElementsByClassName("pac-target-input")[0].value = response.data.direccion
            self.direccionString = response.data.direccion;
          }
        })
        .catch((error) => {});
    },
    modificarDatosInstitucion() {
      var direccion = document.getElementsByClassName("pac-target-input");

      this.$swal({
        title: "Modificar Datos Institución",
        text: "¿Desea modificar los datos de la Institución?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosInstitucion = {
            nombreInstitucion: this.nombre,
            observaciones: this.observaciones,
            direccion: direccion[0].value.toString(),
            celular: this.celular,
            telefono: this.telefono,
            mail: this.mail,
            disponibleWeb: this.disponibleWeb,
            notificaTurnos: this.notifica,
            utilizaVideoLlamada: this.vllamada,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            lat: this.direccion.lat,
            long: this.direccion.lng,
            sitioWeb: this.sitio,
          };

          let self = this;
          axios
            .get("/Usuario/ModificarInstitucion?", {
              params: {
                nombreInstitucion: datosInstitucion.nombreInstitucion,
                observaciones: datosInstitucion.observaciones,
                direccion: datosInstitucion.direccion,
                celular: datosInstitucion.celular,
                telefono: datosInstitucion.telefono,
                mail: datosInstitucion.mail,
                disponibleWeb: datosInstitucion.disponibleWeb,
                notificaTurnos: datosInstitucion.notificaTurnos,
                utilizaVideoLlamada: datosInstitucion.utilizaVideoLlamada,
                idInstitucion: datosInstitucion.idInstitucion,
                lat: datosInstitucion.lat,
                lng: datosInstitucion.long,
                sitioWeb: datosInstitucion.sitioWeb,
              },
            })
            .then((response) => {
              if (response.data != "ok") {
                self.showAlert({
                  icon: "error",
                  title: "Los datos no pudieron ser modificados",
                  vm: self,
                });
              } else {
                self.showAlert({
                  icon: "success",
                  title: "Datos modificados exitosamente",
                  vm: self,
                });
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "Datos no modificados",
                vm: this,
              });
            });
        }
      });
    },
    salir() {
      router.push({ path: "/Home" });
    },
    setPlace(place) {
      this.place = place;
      this.markers = null;
      this.center = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      this.direccion = {
        lat: this.place.geometry.location.lat(),
        lng: this.place.geometry.location.lng(),
      };
      this.direccionString = this.place.formatted_address;
    },

    usePlace(place) {
      if (this.place) {
        if (this.markers.length == 0) {
          this.markers = null;
          this.markers.push({
            position: {
              lat: this.place.geometry.location.lat(),
              lng: this.place.geometry.location.lng(),
            },
          });
        } else {
          this.markers[0].position.lat = this.place.geometry.location.lat();
          this.markers[0].position.lng = this.place.geometry.location.lng();
        }
        this.place = null;
      }
    },
  },
};
</script>
