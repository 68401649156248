<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Recordatorio</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="6" md="7">
              <v-text-field
                v-model="titulo"
                prepend-icon="mdi-message-text"
                label="Título"
                dense
                required
                :rules="requiredRules"
                maxlength="100"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="texto"
                prepend-icon="mdi-message-text"
                label="Texto"
                :rules="requiredRules"
                clearable
                auto-grow=true
              ></v-textarea>
            </v-col>
              <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Vigencia Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                label="Fecha Vigencia Hasta"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="usuariosSeleccionados"
                :items="usuarios"
                item-text="usuario"
                item-value="usuario"
                :menu-props="{ maxHeight: '400' }"
                label="Usuarios"
                multiple
                chips
                deletable-chips=true
                hint="Usuarios que verán el recordatorio"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarRecordatorio">Guardar</v-btn>

          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    titulo: '',
    texto: '',
    fechaDesde: '',
    fechaHasta: '',
    usuariosSeleccionados: [],
    usuarios: []

  }),

  props: ["visible","idRecordatorio"],

  computed: {
    show: {
      get() {
        if(this.visible)
        {
          this.buscarRecordatorio();
          this.cargarUsuarios();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
 
  methods: {
    modificarRecordatorio() {
      this.$swal({
        title: "Modificar Recordatorio",
        text: "¿Desea modificar el recordatorio?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosRecordatorio = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            titulo: this.titulo,
            texto: this.texto,
            fechaDesde: this.fechaDesde,
            fechaHasta: this.fechaHasta,
            usuarios: this.usuariosSeleccionados,
            idRecordatorio: this.idRecordatorio

          };
          let self = this;
          
           
           if (datosRecordatorio.fechaDesde > datosRecordatorio.fechaHasta) {
            self.showAlert({
              icon: "error",
              title: "La fecha desde no puede ser mayor que la fecha hasta",
              vm: this
            });
            return;
          }
              
             axios
            .get("/Usuario/ModificarRecordatorio", {
              params: {
                idInstitucion: datosRecordatorio.idInstitucion,
                titulo: datosRecordatorio.titulo,
                texto: datosRecordatorio.texto,
                fechaDesde: datosRecordatorio.fechaDesde,
                fechaHasta: datosRecordatorio.fechaHasta,
                usuarios: datosRecordatorio.usuarios,
                idRecordatorio: datosRecordatorio.idRecordatorio
              }
            })
            .then((response) => {
              self.$refs.form.resetValidation();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Recordatorio modificado",
                vm: self,
              });
              self.$emit("recordatorioModificado");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El recordatorio no pudo ser modificado",
                vm: this,
              });
            });
              
                     

          
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    buscarRecordatorio() {
      const idRecordatorio = this.idRecordatorio;
      let self = this;

      axios
        .get("/Usuario/BuscarRecordatorio?", {
          params: {
            idRecordatorio: idRecordatorio,
          },
        })
        .then((response) => {
          self.titulo = response.data.titulo
          self.texto = response.data.texto
          self.fechaDesde = response.data.fechaDesde.toString().substring(0,10)
          self.fechaHasta = response.data.fechaHasta.toString().substring(0,10)
        })
        .catch((error) => {
          
        });
     
    },
    cargarUsuarios(){
      
      this.usuarios = []
      this.usuariosSeleccionados = []
      let self= this;
      axios.get('/Usuario/BuscarUsuarios?', {
        params: { 
           idInstitucion: self.$store.state.usuario.institucion.idInstitucion
        }
                
      }).then(function(response) {
      if (response.data!=null && response.data!= '')
        self.usuarios = response.data
      else
        self.usuarios = []
      }).catch(function(error){
     
      })

      axios.get("/Usuario/GetUsuariosRecordatorio",{
          params: {
              idRecordatorio: self.idRecordatorio,
              
          }
      }).then(response=>{
          self.usuariosSeleccionados = response.data;
      })
    },
     

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    
  },
  
};
</script>