<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Agregar Logo y Foto</v-toolbar-title>
      </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h4>Foto</h4>
            </v-col>
          </v-row>
          <v-row dense class="mt-3" align="center">
            <v-col cols="12" sm="4" md="4">
              <v-file-input
                :rules="rules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                v-model="foto"
                show-size
                chips
                class="mb-0 pb-0 mr-6"
                label="Seleccionar Foto"
                dense
                @change="displayPhoto"
                @click:clear="displayPhoto"
              ></v-file-input>
            </v-col>
            <v-col cols="12" sm="4" md="5" v-if="src != '' && src != null">
              <v-icon slot="badge" dark x-small @click="borrarFoto"
                >mdi-close</v-icon
              >
              <v-avatar size="250" tile>
                <v-img :src="src" max-height="250px" max-width="250px" contain>
                </v-img>
              </v-avatar>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-btn
                v-if="src != '' && src != null"
                @click="borrarFoto"
                color="warning"
                >Quitar Foto</v-btn
              >
            </v-col>
          </v-row>

          <!-- Logo -->

          <v-row>
            <v-col cols="12">
              <h4>Logo</h4>
            </v-col>
          </v-row>

          <v-row dense class="mt-3" align="center">
            <v-col cols="12" sm="4" md="4">
              <v-file-input
                :rules="rules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-picture-in-picture-top-right"
                v-model="logo"
                show-size
                chips
                class="mb-0 pb-0 mr-6"
                label="Seleccionar Logo"
                dense
                @change="displayLogo"
                @click:clear="displayLogo"
              ></v-file-input>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="5"
              v-if="srcLogo != '' && srcLogo != null"
            >
              <v-icon slot="badge" dark x-small @click="borrarLogo"
                >mdi-close</v-icon
              >
              <v-avatar height="70px" width="250px" tile>
                <v-img
                  :src="srcLogo"
                  max-height="100px"
                  max-width="250px"
                  contain
                >
                </v-img>
              </v-avatar>
            </v-col>
            <v-col cols="12" sm="4" md="2">
              <v-btn
                v-if="srcLogo != '' && srcLogo != null"
                @click="borrarLogo"
                color="warning"
                >Quitar Logo</v-btn
              >
            </v-col>
          </v-row>
          <v-row>
            <h5>
              * El logo se utilizará en la impresión de eventos de Historia
              Clínica, por lo que recomendamos sea de diseño horizontal.
            </h5>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            @click.stop="guardar"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="salir">Salir</v-btn>
        </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
</style>
<script>
import router from "../../router/index.js";
import axios from "../../axios-auth.js"
export default {
  data: (vm) => ({
    src: null,
    srcLogo: null,
    foto: null,
    logo: null,
    rules: [
      (value) =>
        !value || value.size < 2000000 || "La foto debe pesar 2MB o menos",
    ],
    tmpFoto: null,
    tmpLogo: null,
  }),
  mounted(){
    this.buscarFotoLogo();
  },
  methods: {
    buscarFotoLogo(){
      let self = this;

      axios
        .get("/Usuario/GetFotoLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion
          },
        })
        .then((response) => {
          self.srcLogo = response.data.urlLogo
          self.tmpLogo = response.data.urlLogo
          self.tmpFoto = response.data.urlFoto;
          self.src = response.data.urlFoto;

        })
        .catch((error) => {});
    },
    salir() {
      router.push({ path: "/Home" });
    },
    borrarFoto() {
      this.src = "";
      this.foto = null;
    },
    displayPhoto() {
      this.src = "";
      if (this.foto != "" && this.foto != null) {
        const data = URL.createObjectURL(this.foto);
        this.src = data;
      }
    },
    borrarLogo() {
      this.srcLogo = "";
      this.logo = null;
    },
    displayLogo() {
      this.srcLogo = "";
      if (this.logo != "" && this.logo != null) {
        const data = URL.createObjectURL(this.logo);
        this.srcLogo = data;
      }
    },
    guardar() {
      this.$swal({
        title: "Modificar Foto y Logo",
        text: "¿Desea modificar la foto y el logo de la institución?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')
          var fotoLogo = new FormData();
          fotoLogo.append(
            "idInstitucion",
            this.$store.state.usuario.institucion.idInstitucion
          );
          fotoLogo.append("foto", this.foto);
          fotoLogo.append("logo", this.logo)

            //foto
          if (
            (this.tmpFoto == null || this.tmpFoto == "") &&
            (this.src == null || this.src == "")
          ) {
            fotoLogo.append("modiFoto", 0);
            fotoLogo.append("ruta", this.tmpFoto);
          }
          if (
            (this.tmpFoto != null || this.tmpFoto != "") &&
            (this.src != null || this.src != "") &&
            this.tmpFoto == this.src
          ) {
            fotoLogo.append("modiFoto", 0);
            fotoLogo.append("ruta", this.tmpFoto);
          }
          if (
            (this.tmpFoto == null || this.tmpFoto == "") &&
            (this.src != null || this.src != "")
          ) {
            fotoLogo.append("modiFoto", 1);
          }
          if (
            (this.tmpFoto != null || this.tmpFoto != "") &&
            (this.src == null || this.src == "")
          ) {
            fotoLogo.append("modiFoto", 1);
            fotoLogo.append("ruta", this.tmpFoto);
          }
          if (
            (this.tmpFoto != null || this.tmpFoto != "") &&
            (this.src != null || this.src != "") &&
            this.tmpFoto != this.src
          ) {
            fotoLogo.append("modiFoto", 1);
          }
            // logo
          if (
            (this.tmpLogo == null || this.tmpLogo == "") &&
            (this.srcLogo == null || this.srcLogo == "")
          ) {
            fotoLogo.append("modiLogo", 0);
            fotoLogo.append("ruta", this.tmpLogo);
          }
          if (
            (this.tmpLogo != null || this.tmpLogo != "") &&
            (this.srcLogo != null || this.srcLogo != "") &&
            this.tmpLogo == this.srcLogo
          ) {
            fotoLogo.append("modiLogo", 0);
            fotoLogo.append("ruta", this.tmpLogo);
          }
          if (
            (this.tmpLogo == null || this.tmpLogo == "") &&
            (this.srcLogo != null || this.srcLogo != "")
          ) {
            fotoLogo.append("modiLogo", 1);
          }
          if (
            (this.tmpLogo != null || this.tmpLogo != "") &&
            (this.srcLogo == null || this.srcLogo == "")
          ) {
            fotoLogo.append("modiLogo", 1);
            fotoLogo.append("ruta", this.tmpLogo);
          }
          if (
            (this.tmpLogo != null || this.tmpLogo != "") &&
            (this.srcLogo != null || this.srcLogo != "") &&
            this.tmpLogo != this.srcLogo
          ) {
            fotoLogo.append("modiLogo", 1);
          }

          let self = this;
          axios({
            method: "post",
            url: "/Usuario/ModificarFotoLogo",
            data: fotoLogo,
            header: {
              Accept: "application/json",
              "Content-Type": "multipart/form-data",
            },
          })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "La foto y el logo fueron modificados exitosamente",
                vm: self,
              });
              setTimeout(
                router.push("/Home")
              ,2100)
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La foto y el logo deben estar en formato .jpg o .png",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>
