<template>
  <div class="antecedentes">
    <v-card class="mb-2" tile>
      <v-card-text class="pb-1 mb-1">
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="antModel"
              label="Antecedentes Personales"
              clearable
              auto-grow
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-textarea
              v-model="antFamiliaresModel"
              label="Antecedentes Familiares"
              clearable
              auto-grow
              outlined
            ></v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="ml-2 actions">
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="actualizarAntecedentes"
          >Actualizar</v-btn
        >
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
export default {
  data: (vm) => ({
    antModel: null,
    antFamiliaresModel: null,
  }),
  props: ["ant", "antFamiliares", "idPaciente", "paciente"],
  watch: {
    ant() {
      this.antModel = this.ant;
    },
    antFamiliares() {
      this.antFamiliaresModel = this.antFamiliares;
    },
  },
  methods: {
    actualizarAntecedentes() {
      this.$swal({
        title: "Actualizar Antecedentes",
        text: "¿Desea actualizar los antecedentes del paciente?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')
          if (this.idPaciente == 0) {
            self.showAlert({
              icon: "error",
              title: "Debe seleccionar un paciente primero",
              vm: self,
            });
          } else {
            const datosPaciente = {
              antecedentes: this.antModel,
              antecedentesFamiliares: this.antFamiliaresModel,
              idpaciente: this.idPaciente,
            };
            let self = this;
            axios({
              method: "post",
              url: "/Paciente/ModificarAntecedentes",
              data: datosPaciente,
              header: {
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Antecedentes actualizados exitosamente",
                  vm: self,
                });
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "Los antecedentes no pudieron ser actualizados",
                  vm: this,
                });
              });
          }
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    imprimir() {
      var doc = new jsPDF();

      doc.setProperties({
        title: "Antecedentes",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion =
        this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Paciente: " + this.paciente.apellidos + " " + this.paciente.nombres,
        13,
        25
      );
      doc.text("D.N.I.: " + this.paciente.dni, 13, 30);
      doc.text("Feche de Nac.: " + this.paciente.fechaNac, 13, 35);
      doc.text("Edad: " + this.paciente.edad, 13, 40);
      doc.setFontSize(16);
      doc.text(
        "Antecedentes Personales Patológicos",
        doc.internal.pageSize.getWidth() / 2,
        55,
        {
          align: "center",
        }
      );
      doc.line(57, 56, 153, 56);
      doc.setFontSize(12);
      var textoSplit = this.antModel.split(/\r\n|\r|\n/);
      let y = 70;
      for (var i = 0; i < textoSplit.length; i++) {
        if (textoSplit[i].length < 85) {
          doc.text(textoSplit[i], 13, y);
        } else {
          var lineSplit = textoSplit[i].split(" ");
          var linePrint = "";
          var lineTemp = "";
          for (var j = 0; j < lineSplit.length; j++) {
            if (linePrint.length >= 85) {
              if (j == lineSplit.length - 1) doc.text(lineSplit[j], 13, y + 5);

              lineTemp = linePrint + lineSplit[j];
              if (lineTemp.length < 85) {
                doc.text(lineTemp, 13, y);
                linePrint = "";
              } else {
                doc.text(linePrint, 13, y);
                linePrint = lineSplit[j] + " ";
              }
              y = y + 5;

              if (y > 290) {
                y = 20;
                doc.addPage();
              }
            } else {
              linePrint = linePrint + lineSplit[j] + " ";
              if (j == lineSplit.length - 1) {
                doc.text(linePrint, 13, y);
                if (y > 290) {
                  y = 20;
                  doc.addPage();
                }
              }
            }
          }
        }
        y = y + 5;
        if (y > 290) {
          y = 20;
          doc.addPage();
        }
      }
      if (y != 20) doc.addPage();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Paciente: " + this.paciente.apellidos + " " + this.paciente.nombres,
        13,
        25
      );
      doc.text("D.N.I.: " + this.paciente.dni, 13, 30);
      doc.text("Feche de Nac.: " + this.paciente.fechaNac, 13, 35);
      doc.text("Edad: " + this.paciente.edad, 13, 40);
      doc.setFontSize(16);
      doc.text(
        "Antecedentes Familiares",
        doc.internal.pageSize.getWidth() / 2,
        55,
        {
          align: "center",
        }
      );
      doc.line(73, 56, 137, 56);
      doc.setFontSize(12);
      var textoSplit = this.antFamiliaresModel.split(/\r\n|\r|\n/);
      y = 70;
      for (var i = 0; i < textoSplit.length; i++) {
        if (textoSplit[i].length < 85) {
          doc.text(textoSplit[i], 13, y);
        } else {
          var lineSplit = textoSplit[i].split(" ");
          var linePrint = "";
          var lineTemp = "";
          for (var j = 0; j < lineSplit.length; j++) {
            if (linePrint.length >= 85) {
              if (j == lineSplit.length - 1) doc.text(lineSplit[j], 13, y + 5);

              lineTemp = linePrint + lineSplit[j];
              if (lineTemp.length < 85) {
                doc.text(lineTemp, 13, y);
                linePrint = "";
              } else {
                doc.text(linePrint, 13, y);
                linePrint = lineSplit[j] + " ";
              }
              y = y + 5;

              if (y > 290) {
                y = 20;
                doc.addPage();
              }
            } else {
              linePrint = linePrint + lineSplit[j] + " ";
              if (j == lineSplit.length - 1) {
                doc.text(linePrint, 13, y);
                if (y > 290) {
                  y = 20;
                  doc.addPage();
                }
              }
            }
          }
        }
        y = y + 5;
        if (y > 290) {
          y = 20;
          doc.addPage();
        }
      }

      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
};
</script>
<style lang="scss">
.antecedentes {
  .actions {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
