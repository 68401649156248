<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Tutoriales de Caja</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-chip label color="transparent" text-color="black">
              <h3>Lista de Videos</h3>
            </v-chip>
          </v-col>
        </v-row>
      </v-card-text>

      <v-data-table
        :headers="headers"
        :items="this.listaVideos"
        class="elevation-1"
        :hide-default-footer="true"
        locale="es-ar"
        item-key="idVideo"
        :items-per-page="10000"
        ref="myTable"
        :disable-sort="true"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="reproducirVideo(item)"
                >mdi-youtube-tv</v-icon
              >
            </template>
            <span>Ver Video</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <ReproducirVideo
        :visible="showReproducirVideo"
        :link="editedItem.link"
        @close="showReproducirVideo = false"
      ></ReproducirVideo>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import ReproducirVideo from "../Tutoriales/ReproductorVideo.vue";
export default {
  data: (vm) => ({
    listaVideos: [],
    headers: [
      { text: "Nombre Video", value: "nombreVideo" },
      { text: "Duracion", value: "duracion" },
      { text: "Acciones", value: "actions" },
    ],
    showReproducirVideo: false,
    editedItem: {},
    editedIndex: -1,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },

    buscarVideos() {
      let self = this;
      axios
        .get("/Usuario/BuscarVideos?", {
          params: {
            modulo: "CAJA",
          },
        })
        .then(function(response) {
          if (response.data != "" && response.data != null)
            self.listaVideos = response.data;
          else self.listaVideos = [];
        })
        .catch(function(error) {});
    },
    reproducirVideo(item) {
      this.editedIndex = this.listaVideos.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showReproducirVideo = true;
    },
  },
  mounted() {
    this.buscarVideos();
  },
  components: {
    ReproducirVideo,
  },
};
</script>
