<template>
  <v-dialog v-model="show" fullscreen transition="dialog-bottom-transition">
    <v-card>
     
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Buscar Práctica </v-toolbar-title>
      </v-toolbar>
       <v-alert color="purple" dark dismissible outlined
        >Si aprieta el botón "BUSCAR" sin ingresar nada en el campo de nombre,
        traerá todas las prácticas del tipo de nomenclador
        seleccionado.</v-alert
      >
      <v-card-text>
        <v-row class="mt-2">
          <v-col cols="12" sm="4" md="4">
            <v-select
              :items="tiposNom"
              v-model="tipoNom"
              item-text="nombre"
              item-value="idTipoNom"
              label="Tipo Nomenclador"
              dense
              prepend-icon="mdi-currency-usd"
            ></v-select>
          </v-col>
          <v-col cols="12" sm="4" md="8">
            <v-text-field
              v-model="nombrePractica"
              prepend-icon="mdi-currency-usd"
              label="Nombre Práctica"
              maxlength="50"
              dense
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn color="primary" @click.stop="buscarPractica()"
          >Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaPracticas"
        class="elevation-1 row-pointer"
        locale="es-ar"
        item-key="idTurnoPractica"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="seleccionarPractica(item)"
                >mdi-checkbox-marked-circle</v-icon
              >
            </template>
            <span>Seleccionar Practica</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
export default {
  data: (vm) => ({
    tipoNom: null,
    tiposNom: [],
    nombrePractica: null,
    headers: [
      {
        text: "Tipo Nom",
        align: "start",
        filterable: false,
        value: "tipoNom",
      },
      { text: "Cod. Práctica", value: "codPractica" },
      { text: "Nombre Práctica", value: "nombrePractica" },
      { text: "Seleccionar", value: "actions", sortable: false },
    ],
    listaPracticas: [],
  }),

  computed: {
    show: {
      get() {
        if(this.visible)
          this.buscarTipoNom();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.vaciarModels();
          this.$emit("close");
        }
      },
    },
  },
  props: ["visible"],
  methods: {
    buscarTipoNom() {
      let self = this;
      axios
        .get("/Turnos/GetTipoNom")
        .then((response) => {
          self.tiposNom = response.data;
          self.tiposNom.unshift({ idTipoNom: 0, nombre: "TODOS" });
          self.tipoNom = self.tiposNom[0].idTipoNom;
        })
        .catch((error) => {});
    },
    buscarPractica() {
      const practicaData = {
        idTipoNom: this.tipoNom,
        nombrePractica: this.nombrePractica,
      };
      let self = this;
      axios
        .get("/Turnos/GetPracticasNombre?", {
          params: {
            idTipoNom: practicaData.idTipoNom,
            nombrePractica: practicaData.nombrePractica,
          },
        })
        .then(function (response) {
          if (response.data!=null && response.data!='')
            self.listaPracticas = response.data;
          else
            self.listaPracticas = [];
        })
        .catch(function (error) {
          
        });
    },
    vaciarModels() {
      this.listaPracticas = [];
      this.nombrePractica = "";
    },
    seleccionarPractica(item) {
      this.vaciarModels();
      this.$emit("seleccionarPractica", item);
    },
  },
};
</script>