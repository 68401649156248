<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Enviar Indicación</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert
            color="blue"
            dark
            outlined
            v-if="paciente.mail == '' || paciente.mail == null"
            >El paciente no tiene registrado un mail en su ficha, puede
            introducir uno a continuación.</v-alert
          >
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="email"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="enviarMail"
            >Enviar Mail</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
  .multi-line {
    white-space: pre-line;
  }
  .v-dialog__content {
    align-items: left;
    justify-content: left;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import imprimirIndicacion from "../HistClin/ImprimirEventos/imprimirIndicaciones";
  import imprimirIndicacionTrinidad from "../HistClin/ImprimirEventos/Trinidad/imprimirIndicaciones";

  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      maxWidth: "100%",
      base64: null,
      email: "",
      emailRules: [
        (v) =>
          !v ||
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail inválido",
        (v) => !!v || "Dato obligatorio",
      ],
    }),
    props: ["visible", "indicacion", "paciente", "institucion"],
    computed: {
      show: {
        get() {
          if (this.visible) {
            this.getLogoUrl();
            this.setearModels();
          }
          return this.visible;
        },
        set(value) {
          if (!value) {
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      setearModels() {
        this.email = this.paciente.mail;
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";
      },
      getLogoUrl() {
        let self = this;

        axios
          .get("/HistoriaClinica/GetLogo?", {
            params: {
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
            },
          })
          .then((response) => {
            self.base64 = response.data;
          })
          .catch((error) => {});
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: alertInfo.timer,
        });
      },
      enviarMail() {
        let file = null;
        switch (this.$store.state.usuario.institucion.idInstitucion) {
          case 95: {
            file = imprimirIndicacionTrinidad(
              this.institucion,
              this.paciente,
              this.indicacion,
              this.base64,
              "file"
            );
            break;
          }
          default: {
            file = imprimirIndicacion(
              this.institucion,
              this.paciente,
              this.indicacion,
              this.base64,
              "file"
            );
            break;
          }
        }

        this.$swal({
          title: "Enviar Indicación",
          text: "¿Desea enviar la indicación por email al paciente?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            var datosIndicacion = new FormData();
            datosIndicacion.append("file", file);
            datosIndicacion.append("email", this.email);
            datosIndicacion.append(
              "idInstitucion",
              this.$store.state.usuario.institucion.idInstitucion
            );
            datosIndicacion.append("idPaciente", this.paciente.idPaciente);
            datosIndicacion.append(
              "idIndicacion",
              this.indicacion.idIndicacion
            );
            let self = this;
            axios({
              method: "post",
              url: "/HistoriaClinica/EnviarIndicacion",
              data: datosIndicacion,
              header: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title:
                    "Se envió un mail a la casilla de correo indicada con la indicación.",
                  vm: self,
                  timer: 3000,
                });
                self.show = false;
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La indicación no pudo ser enviada.",
                  vm: this,
                  timer: 3000,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      },
    },
  };
</script>
