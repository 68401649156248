<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nuevo Cierre de Caja</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Buscar Movimientos a incluir</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="4">
              <v-select
                :items="listaUsuarios"
                item-text="usuario"
                item-value="usuario"
                label="Usuario"
                prepend-icon="mdi-account"
                v-model="usuario"
                dense
                ref="vselect"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0">
          <v-spacer></v-spacer>
          <v-btn color="primary" @click.stop="buscarMovimientos(1)">
            Buscar
            <v-icon dark right>mdi-magnify</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-form ref="form" v-model="valid2" :lazy-validation="lazy2">
        <v-card-text>
          <v-data-table
            :headers="headers"
            v-model="movimientosSeleccionados"
            :items="this.listaMovimientos"
            class="elevation-1"
            :hide-default-footer="true"
            locale="es-ar"
            item-key="idCobro"
            :items-per-page="10000"
            :item-class="rowClasses"
            show-select
            ref="myTable"
            :disable-sort="true"
            @input="sumarImporte"
          ></v-data-table>
          <v-row class="mt-4 mr-8">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="totalCobros"
              label="Total Cobros Seleccionados"
              readonly
              prefix="$"
              prepend-icon="mdi-usd-currency"
              class="shrink"
              style="width: 200px"
            >
            </v-text-field>
          </v-row>
          <v-row class="mt-1 mr-8">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="totalPagos"
              label="Total Pagos Seleccionados"
              readonly
              prefix="$"
              prepend-icon="mdi-usd-currency"
              class="shrink"
              style="width: 200px"
            >
            </v-text-field>
          </v-row>
          <v-row class="mt-1 mr-8">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="totalSaldoCaja"
              label="Saldo Caja Seleccionado"
              readonly
              prefix="$"
              prepend-icon="mdi-usd-currency"
              class="shrink"
              style="width: 200px"
            >
            </v-text-field>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" :disabled="!valid2" @click.stop="guardarCierre">Guardar</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px !important;
}
.background-red {
  background-color: rgb(255, 82, 82) !important;
  color: white !important;
}
.background-red:hover {
  background-color: rgb(228, 64, 64) !important;
  color: white !important;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
export default {
  data: (vm) => ({
    valid: false,
    valid2: false,
    lazy2: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    usuario: "",
    listaUsuarios: [],
    listaMovimientos: [],
    movimientosSeleccionados: [],
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Usuario", value: "usuario" },
      { text: "Concepto", value: "paciente" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Forma Pago", value: "nombreFormaPago" },
      { text: "Importe", value: "totalString", align: "end" },
    ],
    totalRules: [(v) => !!v || "Dato obligatorio"],
    fechaDesde: null,
    fechaHasta: null,
    totalCobros: 0,
    totalPagos: 0,
    totalSaldoCaja: 0,
  }),

  props: ["visible"],
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    rowClasses(item) {
      if (parseFloat(item.total, 2) < 0) {
        return "background-red";
      }
      return "";
    },
    sumarImporte() {
      this.totalSaldoCaja = 0;
      this.totalPagos = 0;
      this.totalCobros = 0;

      this.movimientosSeleccionados.forEach((importe) => {
        this.totalSaldoCaja += importe.total;
        if (importe.total < 0) {
          this.totalPagos += importe.total;
        } else {
          this.totalCobros += importe.total;
        }
      });
      this.totalSaldoCaja = parseFloat(this.totalSaldoCaja).toFixed(2);
      this.totalCobros = parseFloat(this.totalCobros).toFixed(2);
      this.totalPagos = parseFloat(this.totalPagos).toFixed(2);
    },
    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Caja/BuscarUsuariosCajeros", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.listaUsuarios = response.data;
          if (self.$store.state.usuario.institucion.idInstitucion === 195) {
            self.listaUsuarios.unshift({
              usuario: "TODOS",
            });
          }
          self.usuario = self.listaUsuarios[0].usuario;
        });

      this.fechaDesde = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
      this.fechaHasta = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
      this.totalSaldoCaja = 0;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    guardarCierre() {
      this.$swal({
        title: "Guardar Cierre de Caja",
        text: "¿Desea registrar el cierre de caja?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          if (this.movimientosSeleccionados.length == 0) {
            this.showAlert({
              icon: "error",
              title: "Debe seleccionar al menos 1 movimiento para liquidar",
              vm: this,
            });
          } else {
            var movimientosSel = [];
            this.movimientosSeleccionados.forEach((movimiento) => movimientosSel.push(movimiento.idCobro));

            const datosCierre = {
              usuario: this.$store.state.usuario.usuario,
              totalCobros: this.totalCobros,
              totalPagos: this.totalPagos,
              totalSaldoCaja: this.totalSaldoCaja,
              movimientos: movimientosSel,
            };
            let self = this;
            axios
              .post("/Caja/GuardarCierre?", datosCierre)
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Cierre registrado exitosamente",
                  vm: self,
                });
                this.$swal({
                  title: "Imprimir Cierre de Caja",
                  text: "¿Desea imprimir el cierre de caja?",
                  icon: "success",
                  type: "success",
                  showCancelButton: true,
                  background: "#ececec",
                  confirmButtonText: "Si",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    this.imprimir();
                  } else {
                    self.$refs.form.resetValidation();
                    self.vaciarModels();
                    document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
                    self.$emit("cierreRegistrado");
                  }
                });
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El cierre de caja no pudo ser registrado",
                  vm: this,
                });
              });
          }
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    imprimir() {
      var doc = new jsPDF("landscape");

      doc.setProperties({
        title: "Cierre de Caja",
      });
      var source = this.movimientosSeleccionados;
      let rows = [];
      let columnHeader = ["Fecha", "Concepto", "Observaciones", "Forma de Pago", "Cobro   ", "Pago    "];
      let subtotales = {};

      source.forEach((element) => {
        const cobro = element.total < 0 ? "" : element.totalString;
        const pago = element.total < 0 ? element.totalString : "";
        var temp = [element.fechaString, element.paciente, element.observaciones, element.nombreFormaPago, cobro, pago];
        rows.push(temp);
        if (element.idFormaPago === 1) {
          subtotales.efectivo = (subtotales.efectivo ?? 0) + element.total;
        } else if (element.idFormaPago > 1 && element.idFormaPago < 5) {
          subtotales.transferencia = (subtotales.transferencia ?? 0) + element.total;
        } else if (element.idFormaPago === 5) {
          subtotales.mercadoPago = (subtotales.mercadoPago ?? 0) + element.total;
        }
      });
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();

      var usuario = this.$store.state.usuario.usuario;
      var fecha = this.formatDate(this.toISOLocal(new Date()));

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(16);
      doc.text("Cierre de Caja", doc.internal.pageSize.getWidth() / 2, 20, {
        align: "center",
      });
      doc.line(130, 21, 168, 21);
      doc.setFontSize(12);
      doc.text("Usuario: " + usuario, 13, 30);
      doc.text("Fecha: " + fecha, 13, 37);

      doc.autoTable(columnHeader, rows, {
        startY: 45,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;

      if (finalY > 180) {
        doc.addPage();
        finalY = 20;
      }

      if (this.$store.state.usuario.institucion.idInstitucion === 195) {
        const subtotalEfectivo = isNaN(subtotales.efectivo) ? 0 : subtotales.efectivo;
        const subtotalTransferencia = isNaN(subtotales.transferencia) ? 0 : subtotales.transferencia;
        const subtotalMercadoPago = isNaN(subtotales.mercadoPago) ? 0 : subtotales.mercadoPago;

        doc.text(
          "Total Cobrado Ef.: $" + parseFloat(subtotalEfectivo).toFixed(2).toString().replace(".", ","),
          100,
          finalY + 8,
          { align: "right" }
        );

        doc.text(
          "Total Cobrado Deb./Cred./Transf.: $" +
            parseFloat(subtotalTransferencia).toFixed(2).toString().replace(".", ","),
          100,
          finalY + 16,
          { align: "right" }
        );

        doc.text(
          "Total Cobrado Mercado Pago: $" + parseFloat(subtotalMercadoPago).toFixed(2).toString().replace(".", ","),
          100,
          finalY + 24,
          { align: "right" }
        );
      }

      doc.text(
        "Total Cobros: $" + parseFloat(this.totalCobros).toFixed(2).toString().replace(".", ","),
        280,
        finalY + 8,
        { align: "right" }
      );
      doc.text(
        "Total Pagos: -$" + parseFloat(Math.abs(this.totalPagos)).toFixed(2).toString().replace(".", ","),
        280,
        finalY + 16,
        { align: "right" }
      );
      if (this.totalSaldoCaja < 0) {
        doc.text(
          "Total Saldo Caja: -$" + parseFloat(Math.abs(this.totalSaldoCaja)).toFixed(2).toString().replace(".", ","),
          280,
          finalY + 24,
          { align: "right" }
        );
      } else {
        doc.text(
          "Total Saldo Caja: $" + parseFloat(this.totalSaldoCaja).toFixed(2).toString().replace(".", ","),
          280,
          finalY + 24,
          { align: "right" }
        );
      }

      window.open(doc.output("bloburl"), "_blank");
      this.$refs.form.resetValidation();
      this.vaciarModels();
      document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      this.$emit("cierreRegistrado");
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.listaMovimientos = [];
      this.totalCobros = 0;
      this.totalPagos = 0;
      this.totalSaldoCaja = 0;
      this.usuario = null;
      this.movimientosSeleccionados = [];
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    buscarMovimientos(state) {
      const filtrosData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        usuario: this.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .post("/Caja/BuscarMovimientosCierre?", filtrosData)
        .then(function (response) {
          if (response.data != "" && response.data != null) self.listaMovimientos = response.data;
          else {
            self.listaMovimientos = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title: "No hay movimientos que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function (error) {});
    },
  },

  created() {
    this.vaciarModels();
  },
};
</script>
