<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Cierres de Caja</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Cierres Realizados</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" sm="4">
              <v-select
                :items="listaUsuarios"
                item-text="usuario"
                item-value="usuarios"
                menu-props="auto"
                label="Usuario"
                hide-details
                prepend-icon="mdi-account"
                v-model="usuario"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" color="primary" @click.stop="buscarCierres(1)">
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaCierres"
        class="elevation-1"
        locale="es-ar"
        item-key="idCierreCajaEnc"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium color="purple" class="mr-3" v-on="on" v-bind="attrs" @click.stop="imprimir(item)"
                >mdi-printer</v-icon
              >
            </template>
            <span>Imprimir Cierre</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon medium v-on="on" color="red" v-bind="attrs" @click="borrarCierre(item)">mdi-delete</v-icon>
            </template>
            <span>Eliminar Cierre</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoCierre">Nuevo Cierre Caja</v-btn>
        <v-btn color="warning" :disabled="listaCierres.length < 1" @click.stop="imprimirTodos">Imprimir</v-btn>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoCierre
        :visible="showNuevoCierre"
        @close="cierraNuevoCierre()"
        @cierreRegistrado="cierreRegistrado"
      ></NuevoCierre>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevoCierre from "../Caja/NuevoCierre.vue";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    usuario: "",
    listaUsuarios: [],
    listaCierres: [],
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Usuario", value: "usuario" },
      { text: "Total Cobros", value: "totalCobrosString" },
      { text: "Total Pagos", value: "totalPagosString" },
      { text: "Saldo de Caja", value: "totalSaldoCajaString" },
      { text: "Acciones", value: "actions" },
    ],
    fechaDesde: null,
    fechaHasta: null,
    showNuevoCierre: false,
    editedItem: {},
    editedIndex: -1,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarCierre(item) {
      this.$swal({
        title: "Borrar Cierre",
        text: "¿Desea borrar el cierre de caja?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idCierreCajaEnc = item.idCierreCajaEnc;
          let self = this;
          axios
            .get("/Caja/BorrarCierre?", {
              params: {
                idCierreCajaEnc: idCierreCajaEnc,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Cierre de caja borrado",
                vm: self,
              });
              setTimeout(self.buscarCierres(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El cierre de caja no puedo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoCierre() {
      this.showNuevoCierre = false;
      this.buscarCierres(0);
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    nuevoCierre() {
      this.showNuevoCierre = true;
    },
    cierreRegistrado() {
      this.buscarCierres(0);
      this.showNuevoCierre = false;
    },
    buscarCierres(state) {
      const filtrosData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        usuario: this.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Caja/BuscarCierres?", {
          params: {
            fechaDesde: filtrosData.fechaDesde,
            fechaHasta: filtrosData.fechaHasta,
            usuario: filtrosData.usuario,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") self.listaCierres = response.data;
          else {
            self.listaCierres = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title: "No hay cierres de caja que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function (error) {});
    },
    imprimir(item) {
      var doc = new jsPDF("landscape");

      doc.setProperties({
        title: "Cierre de Caja",
      });
      var source = item.movimientosIncluidos;
      let rows = [];
      let columnHeader = ["Fecha", "Concepto", "Observaciones", "Forma de Pago", "Cobro   ", "Pago    "];
      let subtotales = {};

      source.forEach((element) => {
        const cobro = element.total < 0 ? "" : element.totalString;
        const pago = element.total < 0 ? element.totalString : "";
        var temp = [element.fechaString, element.paciente, element.observaciones, element.nombreFormaPago, cobro, pago];
        rows.push(temp);
        if (element.idFormaPago === 1) {
          subtotales.efectivo = (subtotales.efectivo ?? 0) + element.total;
        } else if (element.idFormaPago > 1 && element.idFormaPago < 5) {
          subtotales.transferencia = (subtotales.transferencia ?? 0) + element.total;
        } else if (element.idFormaPago === 5) {
          subtotales.mercadoPago = (subtotales.mercadoPago ?? 0) + element.total;
        }
      });
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();

      var usuario = item.usuario;

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(16);
      doc.text("Cierre de Caja", doc.internal.pageSize.getWidth() / 2, 20, {
        align: "center",
      });
      doc.line(130, 21, 168, 21);
      doc.setFontSize(12);
      doc.text("Usuario: " + usuario, 13, 30);
      doc.text("Fecha: " + item.fechaString, 13, 37);

      doc.autoTable(columnHeader, rows, {
        startY: 45,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;

      if (finalY > 180) {
        doc.addPage();
        finalY = 20;
      }

      if (this.$store.state.usuario.institucion.idInstitucion === 195) {
        const subtotalEfectivo = isNaN(subtotales.efectivo) ? 0 : subtotales.efectivo;
        const subtotalTransferencia = isNaN(subtotales.transferencia) ? 0 : subtotales.transferencia;
        const subtotalMercadoPago = isNaN(subtotales.mercadoPago) ? 0 : subtotales.mercadoPago;

        doc.text(
          "Total Cobrado Ef.: $" + parseFloat(subtotalEfectivo).toFixed(2).toString().replace(".", ","),
          100,
          finalY + 8,
          { align: "right" }
        );

        doc.text(
          "Total Cobrado Deb./Cred./Transf.: $" +
            parseFloat(subtotalTransferencia).toFixed(2).toString().replace(".", ","),
          100,
          finalY + 16,
          { align: "right" }
        );

        doc.text(
          "Total Cobrado Mercado Pago: $" + parseFloat(subtotalMercadoPago).toFixed(2).toString().replace(".", ","),
          100,
          finalY + 24,
          { align: "right" }
        );
      }

      doc.text(
        "Total Cobros: $" + parseFloat(item.totalCobros).toFixed(2).toString().replace(".", ","),
        280,
        finalY + 8,
        { align: "right" }
      );
      doc.text(
        "Total Pagos: -$" + parseFloat(Math.abs(item.totalPagos)).toFixed(2).toString().replace(".", ","),
        280,
        finalY + 16,
        { align: "right" }
      );
      if (item.totalSaldoCaja < 0) {
        doc.text(
          "Total Saldo Caja: -$" + parseFloat(Math.abs(item.totalSaldoCaja)).toFixed(2).toString().replace(".", ","),
          280,
          finalY + 24,
          { align: "right" }
        );
      } else {
        doc.text(
          "Total Saldo Caja: $" + parseFloat(item.totalSaldoCaja).toFixed(2).toString().replace(".", ","),
          280,
          finalY + 24,
          { align: "right" }
        );
      }

      window.open(doc.output("bloburl"), "_blank");
      this.$refs.form.resetValidation();
      this.vaciarModels();
      document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      this.$emit("cierreRegistrado");
    },
    imprimirTodos() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = ["Fecha", "Usuario", "Total Cobros", "Total Pagos", "Saldo de Caja"];
      let importeTotalCobrado = 0;
      let importeSaldoCaja = 0;
      let importeTotalPagado = 0;
      source.items.forEach((element) => {
        var temp = [
          element.fechaString,
          element.usuario,
          element.totalCobrosString,
          element.totalPagosString,
          element.totalSaldoCajaString,
        ];
        rows.push(temp);
        importeTotalCobrado += element.totalCobros;
        importeTotalPagado += element.totalPagos;
      });
      importeSaldoCaja = importeTotalCobrado - Math.abs(importeTotalPagado);
      //let columnHeader = source.items.headers
      //let rows = source.items.rows
      var doc = new jsPDF();
      doc.setProperties({
        title: "Listado Resúmen Cierres de Caja",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Fecha Desde: " +
          this.formatDate(this.fechaDesde) +
          "  -  " +
          "Fecha Hasta: " +
          this.formatDate(this.fechaHasta),
        13,
        16
      );

      doc.setFontSize(16);
      doc.text("Listado de Cierres", doc.internal.pageSize.getWidth() / 2, 33, {
        align: "center",
      });
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text("Totales:", 135, finalY + 13);

      doc.text(
        "Total Cobrado: $" + parseFloat(importeTotalCobrado).toFixed(2).toString().replace(".", ","),
        195,
        finalY + 13,
        { align: "right" }
      );
      doc.text(
        "Total Pagado: -$" + parseFloat(Math.abs(importeTotalPagado)).toFixed(2).toString().replace(".", ","),
        195,
        finalY + 21,
        { align: "right" }
      );
      if (importeSaldoCaja >= 0) {
        doc.text(
          "Total Saldos de caja: $" + parseFloat(importeSaldoCaja).toFixed(2).toString().replace(".", ","),
          195,
          finalY + 29,
          { align: "right" }
        );
      } else {
        doc.text(
          "Total Saldos de caja: -$" + parseFloat(Math.abs(importeSaldoCaja)).toFixed(2).toString().replace(".", ","),
          195,
          finalY + 29,
          { align: "right" }
        );
      }

      //doc.text("Total: $"+ parseFloat(importeTotal).toFixed(2).toString().replace('.',','), 165,finalY + 8)
      window.open(doc.output("bloburl"), "_blank");
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Caja/BuscarUsuariosCajeros", {
        params: {
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        },
      })
      .then((response) => {
        self.listaUsuarios = response.data;
        self.listaUsuarios.unshift({
          usuario: "Todos",
        });
        self.usuario = self.listaUsuarios[0].usuario;
      });

    this.fechaDesde = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
    this.fechaHasta = this.fechaDesde;
  },

  components: {
    NuevoCierre,
  },
};
</script>
