<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Evoluciones</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaEvoluciones"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="verEvolucion"
        item-key="idHC"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        no-data-text="Paciente sin evoluciones"
        no-results-text="Paciente sin evoluciones"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="verEvolucion(item)"
                >mdi-magnify</v-icon
              >
            </template>
            <span>Ver Evolucion</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarEvolucion(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Evolucion</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarEvolucion(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Evolucion</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaEvolucion"
          >Nueva Evolucion</v-btn
        >
        <v-btn color="warning" @click.stop="showVerTodas = true"
          >Ver Todas</v-btn
        >
      </v-card-actions>
      <NuevaEvolucion
        :visible="showNuevaEvolucion"
        :idPaciente="idPaciente"
        :paciente="paciente"
        @close="showNuevaEvolucion = false"
        @evolucionRegistrada="evolucionRegistrada"
      ></NuevaEvolucion>
      <EditarEvolucion
        :visible="showEditarEvolucion"
        :idPaciente="idPaciente"
        :idHc="editedItem.idHc"
        @close="showEditarEvolucion = false"
        @evolucionModificada="evolucionModificada"
      ></EditarEvolucion>
      <VerEvolucion
        :visible="showVerEvolucion"
        :evolucion="editedItem"
        :paciente="paciente"
        @close="showVerEvolucion = false"
      ></VerEvolucion>
      <VerTodas
        :visible="showVerTodas"
        :evoluciones="listaEvoluciones"
        :paciente="paciente"
        @close="showVerTodas = false"
      ></VerTodas>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevaEvolucion from "../HistClin/NuevaEvolucion.vue";
import VerEvolucion from "../HistClin/VerEvolucion.vue";
import VerTodas from "../HistClin/VerTodas.vue";
import EditarEvolucion from "../HistClin/EditarEvolucion.vue";

export default {
  data: (vm) => ({
    headers: [
      { text: "Fecha", value: "fechaHoraString" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevaEvolucion: false,
    showVerEvolucion: false,
    showVerTodas: false,
    showEditarEvolucion: false,
    editedItem: {},
    editedIndex: -1,
  }),
  props: ["idPaciente", "lista", "paciente", "profsBuscar", "institucion"],
  computed: {
    listaEvoluciones: {
      set(value) {
        if (value != []) this.$emit("actualizarEvoluciones");
      },
      get() {
        return this.lista;
      },
    },
  },
  methods: {
    borrarEvolucion(item) {
      if (
        this.$store.state.usuario.institucion.bloqueaHC &&
        this.outOfLimitDate(item.fechaHoraAlta)
      ) {
        this.showAlert({
          icon: "error",
          title:
            "La evolución supera el tiempo disponible para edición y elminación",
          vm: this,
          timer: 3500,
        });
      } else {
        if (
          this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)
        ) {
          this.$swal({
            title: "Borrar Evolución",
            text: "¿Desea borrar la evolución?",
            icon: "success",
            background: "#ececec",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            showCloseButton: true,
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.value) {
              //this.$swal('Turno g', 'You successfully deleted this file', 'success')

              const idHC = item.idHc;
              let self = this;
              axios
                .get("/HistoriaClinica/BorrarEvolucion?", {
                  params: {
                    idHC: idHC,
                  },
                })
                .then((response) => {
                  self.showAlert({
                    icon: "success",
                    title: "Evolución borrada exitosamente",
                    vm: self,
                    timer: 1700,
                  });
                  setTimeout(self.buscarEvoluciones(), 1700);
                })
                .catch((error) => {
                  self.showAlert({
                    icon: "error",
                    title: "La evolución no pudo ser borrada",
                    vm: this,
                    timer: 1700,
                  });
                });
            } else {
              //this.$swal('Cancelled', 'Your file is still intact', 'info')
            }
          });
        } else {
          this.showAlert({
            icon: "error",
            title:
              "La evolución fue creada por un profesional que usted no tiene asociado. No puede eliminar la evolución",
            vm: this,
            timer: 3000,
          });
        }
      }
    },
    editarEvolucion(item) {
      if (
        this.$store.state.usuario.institucion.bloqueaHC &&
        this.outOfLimitDate(item.fechaHoraAlta)
      ) {
        this.showAlert({
          icon: "error",
          title:
            "La evolución supera el tiempo disponible para edición y elminación",
          vm: this,
          timer: 3500,
        });
      } else {
        if (
          this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)
        ) {
          this.editedIndex = this.listaEvoluciones.indexOf(item);
          this.editedItem = Object.assign({}, item);
          this.showEditarEvolucion = true;
        } else {
          this.showAlert({
            icon: "error",
            title:
              "La evolución fue creada por un profesional que usted no tiene asociado. No puede editar la evolución",
            vm: this,
            timer: 3000,
          });
        }
      }
    },
    outOfLimitDate(hcCreationDate) {
      var horaHC = new Date(hcCreationDate);
      var horaActual = new Date();
      var timeDiff = parseFloat(
        (horaActual.getTime() - horaHC.getTime()) / 3600000
      ).toFixed(0);
      var timeLimit = this.$store.state.horasBloqueoHC;
      if (timeDiff >= timeLimit) return true;
      else return false;
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevaEvolucion() {
      this.showNuevaEvolucion = true;
    },
    verEvolucion(item) {
      this.editedIndex = this.listaEvoluciones.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerEvolucion = true;
    },
    evolucionRegistrada() {
      this.buscarEvoluciones();
      this.showNuevaEvolucion = false;
    },
    evolucionModificada() {
      this.buscarEvoluciones();
      this.showEditarEvolucion = false;
    },
    buscarEvoluciones() {
      this.listaEvoluciones = 1;
    },
  },
  components: {
    NuevaEvolucion,
    VerEvolucion,
    VerTodas,
    EditarEvolucion,
  },
};
</script>
