<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Configurar Usuario</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h1 style="color: indigo">Usuario: {{ usuario }}</h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="profsSeleccionados"
                :items="profs"
                item-text="apellidoNombre"
                item-value="idProfesional"
                :menu-props="{ maxHeight: '400' }"
                label="Profesionales"
                multiple
                chips
                deletable-chips="true"
                hint="Profesionales Asociados"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-if="enfermeros.length > 0"
                v-model="enfermerosSeleccionados"
                :items="enfermeros"
                item-text="apellidoNombre"
                item-value="idEnfermero"
                :menu-props="{ maxHeight: '400' }"
                label="Enfermeros"
                multiple
                chips
                deletable-chips="true"
                hint="Enfermeros Asociados"
                persistent-hint
              ></v-select>
            </v-col>
            <v-col cols="12">
              <v-select
                v-model="rolesSeleccionados"
                :items="roles"
                item-text="nombreRol"
                item-value="idRol"
                :menu-props="{ maxHeight: '400' }"
                label="Roles"
                multiple
                chips
                deletable-chips="true"
                hint="Roles Asociados"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="guardarConfiguracion"
            >Guardar</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";
import { minLength, sameAs } from "vuelidate/lib/validators";

export default {
  data: (vm) => ({
    profs: [],
    roles: [],
    enfermeros: [],
    profsSeleccionados: [],
    rolesSeleccionados: [],
    enfermerosSeleccionados: [],
    valid: false,
  }),

  props: ["visible", "usuario"],

  computed: {
    show: {
      get() {
        if (this.visible) {
          this.cargarProfesionales();
          this.cargarEnfermeros();
          this.cargarRoles();
          console.log(this.$store.state.usuario.enfermeros);
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;

          this.$emit("close");
        }
      },
    },
  },
  validations: {
    passNuevo: {
      minLen: minLength(6),
    },
    passNuevoRepetir: {
      //sameAs: sameAs('password')
      sameAs: sameAs((vm) => {
        return vm.passNuevo;
      }),
    },
  },
  methods: {
    guardarConfiguracion() {
      this.$swal({
        title: "Guardar Configuración",
        text: "¿Desea guardar la configuración?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosUsuario = {
            usuario: this.usuario,
            roles: this.rolesSeleccionados,
            profs: this.profsSeleccionados,
            enfermeros: this.enfermerosSeleccionados,
          };

          let self = this;

          axios
            .get("/Usuario/ModificarConfiguracion?", {
              params: {
                usuario: datosUsuario.usuario,
                roles: datosUsuario.roles,
                profs: datosUsuario.profs,
                enfermeros: datosUsuario.enfermeros,
              },
            })
            .then((response) => {
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Configuración modificada exitosamente",
                vm: self,
              });
              self.$store.dispatch("updateSession", {
                usuario: datosUsuario.usuario,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
              });
              self.$emit("usuarioRegistrado");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La configuración no pudo ser modificada",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    cargarProfesionales() {
      this.profs = [];
      this.profsSeleccionados = [];
      let self = this;
      axios
        .get("/Profesional/GetProfesionalesDeInstitucion", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.profs = response.data;
          else self.profs = [];
        })
        .catch((error) => {});
      axios
        .get("/Profesional/GetProfesionalesPorUsuarioInt", {
          params: {
            usuario: self.usuario,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.profsSeleccionados = response.data;
        });
    },
    cargarRoles() {
      this.roles = [];
      this.rolesSeleccionados = [];
      let self = this;
      axios
        .get("/Usuario/GetRoles")
        .then((response) => {
          if (response.data != "" && response.data != null)
            self.roles = response.data;
          else self.roles = [];
        })
        .catch((error) => {});
      axios
        .get("/Usuario/GetRolesPorUsuarioInt", {
          params: {
            usuario: self.usuario,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.rolesSeleccionados = response.data;
        });
    },
    cargarEnfermeros() {
      this.enfermeros = [];
      this.enfermerosSeleccionados = [];
      let self = this;
      axios
        .get("/Enfermeria/GetEnfermeros", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          if (response.data != "" && response.data != null)
            self.enfermeros = response.data;
          else self.enfermeros = [];
        })
        .catch((error) => {});
      axios
        .get("/Enfermeria/GetEnfermerosPorUsuarioInt", {
          params: {
            usuario: self.usuario,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.enfermerosSeleccionados = response.data;
        });
    },
  },
};
</script>
