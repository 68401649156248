<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Nueva {{ resolveLabel }} </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="9" md="9">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-account"
                :label="`Nombre ${resolveLabel}`"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>

            <v-col cols="9" sm="9" md="9">
              <v-text-field
                v-model="observaciones"
                prepend-icon="mdi-account"
                label="Observaciones"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="8" md="4" sm="6">
              <v-switch dense v-model="estado" label="Estado"></v-switch>
            </v-col>
            <v-col cols="8" md="4" sm="6">
              <v-switch
                dense
                v-model="disponibleWeb"
                label="Disponible en Web"
              ></v-switch>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="guardarObraSocial"
            >Guardar</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    nombre: null,
    observaciones: null,
    estado: true,
    disponibleWeb: null,
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
  }),

  props: ["visible"],

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
    resolveLabel() {
      return this.$store.state.usuario.institucion.esEmpresa
        ? "Sede"
        : "Obras Social";
    },
  },

  methods: {
    guardarObraSocial() {
      this.$swal({
        title: `Guardar ${
          this.$store.state.usuario.institucion.esEmpresa
            ? "Sede"
            : "Obra Social"
        }`,
        text: `¿Desea registrar la ${
          this.$store.state.usuario.institucion.esEmpresa
            ? "sede"
            : "obra social"
        }?`,
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosObraSocial = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            nombre: this.nombre,
            observaciones: this.observaciones,
            estado: this.estado,
            disponibleWeb: this.disponibleWeb,
          };
          let self = this;
          axios
            .post("/ObraSocial/GuardarObraSocial", datosObraSocial)
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: `Ya existe una ${
                    self.$store.state.usuario.institucion.esEmpresa
                      ? "sede"
                      : "obra social"
                  } registrada con ese nombre`,
                  vm: self,
                });
              } else {
                self.$store.dispatch("updateSession", {
                  usuario: self.$store.state.usuario.usuario,
                  idInstitucion:
                    self.$store.state.usuario.institucion.idInstitucion,
                });

                self.$refs.form.resetValidation();
                self.vaciarModels();

                document.getElementsByClassName(
                  "v-dialog--active"
                )[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title: `${
                    self.$store.state.usuario.institucion.esEmpresa
                      ? "Sede"
                      : "Obra Social"
                  } registrada exitosamente`,
                  vm: self,
                });
                self.$emit("obraSocialRegistrada");
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: `La ${
                  self.$store.state.usuario.institucion.esEmpresa
                    ? "Sede"
                    : "Obra Social"
                } no pudo ser registrada`,
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    upper() {
      if (this.paciente.apellidos != null)
        this.paciente.apellidos = this.paciente.apellidos
          .toString()
          .toUpperCase();
    },
    upperNombres() {
      if (this.paciente.nombres != null)
        this.paciente.nombres = this.paciente.nombres.toString().toUpperCase();
    },

    vaciarModels() {
      this.nombre = "";
      this.observaciones = "";
      this.estado = true;
      this.disponibleWeb = false;
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },

  created() {
    this.vaciarModels();
  },
};
</script>
