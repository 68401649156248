<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Diagnósticos</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaDiagnosticos"
        class="elevation-1 row-pointer"
        locale="es-ar"
        item-key="idDiagnosticoHc"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        no-data-text="Paciente sin diagnósticos"
        no-results-text="Paciente sin diagnósticos"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarDiagnostico(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Diagnóstico</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarDiagnostico(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Diagnostico</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoDiagnostico"
          >Nuevo Diagnostico</v-btn
        >
      </v-card-actions>
      <NuevoDiagnostico
        :visible="showNuevoDiagnostico"
        :idPaciente="idPaciente"
        @close="showNuevoDiagnostico = false"
        @diagnosticoRegistrado="diagnosticoRegistrado"
      ></NuevoDiagnostico>
      <EditarDiagnostico
        :visible="showEditarDiagnostico"
        :idPaciente="idPaciente"
        :idDiagnosticoHc="editedItem.idDiagnosticoHc"
        @close="showEditarDiagnostico = false"
        @diagnosticoModificado="diagnosticoModificado"
      ></EditarDiagnostico>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevoDiagnostico from "../HistClin/NuevoDiagnostico.vue";
import EditarDiagnostico from "../HistClin/EditarDiagnostico.vue";

export default {
  data: (vm) => ({
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Diagnóstico", value: "diagnostico" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoDiagnostico: false,
    showEditarDiagnostico: false,
    editedItem: {},
    editedIndex: -1,
  }),
  props: ["idPaciente", "lista", "paciente", "profsBuscar", "institucion"],
  computed: {
    listaDiagnosticos: {
      set(value) {
        if (value != []) this.$emit("actualizarDiagnosticos");
      },
      get() {
        return this.lista;
      },
    },
  },
  methods: {
    borrarDiagnostico(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.$swal({
          title: "Borrar Diagnóstico",
          text: "¿Desea borrar el diagnóstico?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idDiagnosticoHc = item.idDiagnosticoHc;
            let self = this;
            axios
              .get("/HistoriaClinica/BorrarDiagnostico?", {
                params: {
                  idDiagnosticoHc: idDiagnosticoHc,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Diagnóstico borrado exitosamente",
                  vm: self,
                  timer: 1700,
                });
                setTimeout(self.buscarDiagnosticos(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El diagnóstico no pudo ser borrada",
                  vm: this,
                  timer: 1700,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El diagnóstico fue creado por un profesional que usted no tiene asociado. No puede eliminar el diagnóstico",
          vm: this,
          timer: 3000,
        });
      }
    },
    editarDiagnostico(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaDiagnosticos.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEditarDiagnostico = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "El diagnóstico fue creado por un profesional que usted no tiene asociado. No puede editar el diagnóstico",
          vm: this,
          timer: 3000,
        });
      }
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevoDiagnostico() {
      this.showNuevoDiagnostico = true;
    },
    diagnosticoRegistrado() {
      this.buscarDiagnosticos();
      this.showNuevoDiagnostico = false;
    },
    diagnosticoModificado() {
      this.buscarDiagnosticos();
      this.showEditarDiagnostico = false;
    },
    buscarDiagnosticos() {
      this.listaDiagnosticos = 1;
    },
  },
  components: {
    NuevoDiagnostico,
    EditarDiagnostico,
  },
};
</script>
