<template>
  <v-dialog :max-width="maxWidth" v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Evoluciones</v-toolbar-title>
      </v-toolbar>
         <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show=false">Salir</v-btn>
      </v-card-actions>
      <v-card-text v-for="(evolucion,i) in evoluciones" :key="i">
        <v-row class="my-4 mx-1">
          <h3>Profesional: {{evolucion.nomProfesional}}</h3>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Fecha: {{evolucion.fechaHoraString}}</h3>
        </v-row>
        <v-row class="mt-4 mx-1">
          <h3>Evolución:</h3>
        </v-row>
        <v-row class="ml-1">
          <v-alert class="multi-line">{{evolucion.texto}}</v-alert>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show=false">Salir</v-btn>
      </v-card-actions>
    </v-card>
    <ImprimirEvolucionesFecha 
    :visible="showImprimirEvoluciones" 
    :evoluciones="evoluciones"
    :paciente="paciente"
    @close="showImprimirEvoluciones=false"></ImprimirEvolucionesFecha>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
   align-items:left;
   justify-content:left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import ImprimirEvolucionesFecha from "../HistClin/ImprimirEvolucionesFecha.vue"
export default {
  data: (vm) => ({
    maxWidth: '100%',
    base64: null,
    showImprimirEvoluciones: false
  }),
  props: ["visible", "evoluciones", "paciente"],
  computed: {
    show: {
      get() {
               if(this.$store.state.videollamada == true)
        this.maxWidth = '68%'
      else
        this.maxWidth = '100%'
      if(this.visible)
        this.getLogoUrl();
      return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
      getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
         })
        .catch((error) => {
        });
    },
    imprimir()
    {
      this.showImprimirEvoluciones = true
    }
  },
  components: {
    ImprimirEvolucionesFecha
  }
};
</script>