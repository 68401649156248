<template>
  <v-dialog
    max-width="480px"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Hora Fin de Cancelación</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row class="mt-3">
            <v-col cols="12" md="4" sm="6">
              <v-menu
                ref="menu2"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaHasta"
                    label="Hora Hasta"
                    prepend-icon="mdi-clock"
                    readonly
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="horaHasta"
                  full-width
                  scrollable
                  format="24hs"
                  @click:minute="$refs.menu2.save(horaHasta)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="actualizarHoraHasta"
            >Aceptar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    menu3: false,
    horaHasta: "",
    time: null,
  }),
  props: ["visible", "horaItem"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.horaHasta = null;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    actualizarHoraHasta() {
      this.$emit("actualizarHoraHasta", {
        horaHasta: this.horaHasta,
        item: this.horaItem,
      });
      this.horaHasta = null;
    },
  },
};
</script>
