<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Movimientos de Caja</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Movimientos de Caja</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="4">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :items="tiposMovimientosFiltro"
                item-text="tipoMovimiento"
                item-value="tipoMovimiento"
                menu-props="auto"
                label="Tipo Movimiento"
                hide-details
                prepend-icon="mdi-currency-usd"
                v-model="tipoMovimientoFiltro"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-text-field
                v-model="paciente"
                prepend-icon="mdi-account"
                label="Concepto"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-select
                :items="formasPago"
                item-text="nombreFormaPago"
                item-value="idFormaPago"
                menu-props="auto"
                label="Forma de Pago"
                hide-details
                prepend-icon="mdi-currency-usd"
                v-model="formaPago"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-select
                :items="estados"
                item-text="estado"
                item-value="estado"
                menu-props="auto"
                label="Estado"
                hide-details
                prepend-icon="mdi-currency-usd"
                v-model="estado"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-select
                :items="usuarios"
                item-text="usuario"
                item-value="usuario"
                menu-props="auto"
                label="Usuario"
                hide-details
                prepend-icon="mdi-account"
                v-model="usuario"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" color="primary" @click.stop="buscarCobros(1)">
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaCobros"
        class="elevation-1"
        locale="es-ar"
        item-key="idCrobro"
        :items-per-page="10"
        :item-class="rowClasses"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.estado="{ item }">
          <v-chip :color="getColor(item.estado)" dark>
            {{ item.estado }}
          </v-chip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="!item.idLiqCajaEnc"
                medium
                :color="item.total < 0 ? 'white' : 'purple'"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarCobro(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Movimiento</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="!item.idLiqCajaEnc"
                medium
                v-on="on"
                :color="item.total < 0 ? 'white' : 'red'"
                class="mr-3"
                v-bind="attrs"
                @click="borrarCobro(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Movimiento</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.nombreFormaPago == 'Mercado Pago' && item.estado == 'Pendiente'"
                medium
                class="mr-3"
                v-on="on"
                color="blue"
                v-bind="attrs"
                @click="generarPreferencia(item)"
                >mdi-link</v-icon
              >
            </template>
            <span>Generar Link de Pago</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.nombreFormaPago === 'Mercado Pago' && item.estado === 'Pendiente - Link Generado'"
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarLinkMail(item)"
                >mdi-email</v-icon
              >
            </template>
            <span>Enviar Link por Mail</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.nombreFormaPago === 'Mercado Pago' && item.estado === 'Pendiente - Link Generado'"
                medium
                class="mr-3"
                color="green"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarLinkWA(item)"
                >mdi-whatsapp</v-icon
              >
            </template>
            <span>Enviar Link por Whats App</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.estado !== 'Aprobado'"
                medium
                v-on="on"
                color="green"
                v-bind="attrs"
                @click="aprobarCobro(item)"
                >mdi-check-circle</v-icon
              >
            </template>
            <span>Aprobar Pago</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-btn color="success" @click.stop="cobrar">Nuevo Cobro</v-btn>
        <v-btn color="error" @click.stop="pagar">Nuevo Pago</v-btn>
        <v-spacer></v-spacer>

        <v-btn color="warning" :disabled="listaCobros.length < 1" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoCobro
        :visible="showNuevoCobro"
        :idTurno="0"
        :tipoMovimiento="tipoMovimiento"
        @close="cierraNuevoCobro()"
      ></NuevoCobro>
      <EditarCobro
        :visible="showEditarCobro"
        :idCobro="editedItem.idCobro"
        :tipoMovimiento="tipoMovimiento"
        @close="cierraEditarCobro()"
      ></EditarCobro>
      <EnviarLinkMail
        :visible="showEnviarLinkMail"
        :link="editedItem.linkMercadoPago"
        :paciente="editedItem.idPaciente"
        :pacienteNombre="editedItem.paciente"
        :idProfesional="editedItem.idProfesional"
        @close="showEnviarLinkMail = false"
      ></EnviarLinkMail>
      <EnviarLinkWA
        :visible="showEnviarLinkWA"
        :link="editedItem.linkMercadoPago"
        :paciente="editedItem.idPaciente"
        :pacienteNombre="editedItem.paciente"
        :idProfesional="editedItem.idProfesional"
        @close="showEnviarLinkWA = false"
      ></EnviarLinkWA>
    </v-card>
  </div>
</template>
<style>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.background-red {
  background-color: rgb(255, 82, 82);
  color: white;
}
.background-red:hover {
  background-color: rgb(228, 64, 64) !important;
  color: white !important;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevoCobro from "../Caja/NuevoCobro.vue";
import EditarCobro from "../Caja/EditarCobro.vue";
import EnviarLinkMail from "../Caja/EnviarLinkMail.vue";
import EnviarLinkWA from "../Caja/EnviarLinkWA.vue";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    paciente: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    prof: "",
    profsBuscar: [],
    listaCobros: [],
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Concepto", value: "paciente" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Forma Pago", value: "nombreFormaPago" },
      { text: "Importe", value: "totalString" },
      { text: "Estado", value: "estado" },
      { text: "Usuario", value: "usuario" },
      { text: "Acciones", value: "actions" },
    ],
    fechaDesde: null,
    fechaHasta: null,
    showNuevoCobro: false,
    showEditarCobro: false,
    editedItem: {},
    editedIndex: -1,
    showEnviarLinkMail: false,
    showEnviarLinkWA: false,
    estados: [
      { estado: "Todos" },
      { estado: "Pendiente" },
      { estado: "Pendiente - Link Generado" },
      { estado: "Aprobado" },
    ],
    estado: null,
    formasPago: [],
    formaPago: null,
    usuario: null,
    usuarios: [],
    tipoMovimiento: null,
    tiposMovimientosFiltro: [{ tipoMovimiento: "Todos" }, { tipoMovimiento: "Cobros" }, { tipoMovimiento: "Pagos" }],
    tipoMovimientoFiltro: null,
  }),
  methods: {
    rowClasses(item) {
      if (parseFloat(item.total, 2) < 0) {
        return "background-red";
      }
      return "";
    },
    aprobarCobro(item) {
      this.$swal({
        title: "Aprobar Pago",
        text: "¿Desea aprobar el pago?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosCobro = {
            idCobro: item.idCobro,
          };

          let self = this;
          axios
            .post("/Caja/AprobarPago", datosCobro)
            .then((response) => {
              if (response.data === "Ok") {
                self.showAlert({
                  icon: "success",
                  title: "Pago aprobado exitosamente.",
                  vm: self,
                  timer: 2500,
                });
                setTimeout(self.buscarCobros(0), 2600);
              } else {
                self.showAlert({
                  icon: "error",
                  title: "El pago no pudo ser aprobado.",
                  vm: self,
                  timer: 2500,
                });
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El pago no pudo ser aprobado.",
                vm: self,
                timer: 2500,
              });
            });
        }
      });
    },
    enviarLinkMail(item) {
      this.editedIndex = this.listaCobros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEnviarLinkMail = true;
    },
    enviarLinkWA(item) {
      this.editedIndex = this.listaCobros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEnviarLinkWA = true;
    },
    generarPreferencia(item) {
      if (item.accessToken === null || item.accessToken === "" || item.accessToken === undefined) {
        this.showAlert({
          icon: "error",
          title: "El profesional no tiene configurado su token de mercado pago",
          vm: this,
          timer: 2800,
        });
        return;
      }
      this.$swal({
        title: "Generar Link de Pago",
        text: "¿Desea generar el link de pago?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosPreferencia = {
            idCobro: item.idCobro,
            accessToken: item.accessToken,
            total: item.total,
            nombreProfesional: item.nombreProfesional,
          };

          let self = this;
          axios
            .post("/Caja/GenerarPreferencia", datosPreferencia)
            .then((response) => {
              if (response.data === "Ok") {
                self.showAlert({
                  icon: "success",
                  title: "Link de Pago generado exitosamente.",
                  vm: self,
                  timer: 2500,
                });
                setTimeout(self.buscarCobros(0), 2600);
              } else {
                self.showAlert({
                  icon: "error",
                  title: "El Link de Pago no pudo ser generado.",
                  vm: self,
                  timer: 2500,
                });
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El Link de Pago no pudo ser generado.",
                vm: self,
                timer: 2500,
              });
            });
        }
      });
    },
    getColor(estado) {
      if (estado == "Aprobado") {
        return "blue";
      }
      if (estado == "Pendiente") {
        return "red";
      }
      if (estado == "Pendiente - Link Generado") {
        return "orange";
      }
    },
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    borrarCobro(item) {
      this.$swal({
        title: "Borrar Cobro",
        text: "¿Desea borrar el cobro?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idCobro = item.idCobro;
          let self = this;
          axios
            .get("/Caja/BorrarCobro?", {
              params: {
                idCobro: idCobro,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Cobro borrado",
                vm: self,
                timer: 1700,
              });
              setTimeout(self.buscarCobros(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title:
                  "El cobro no puede ser borrado por estar incluído en una liquidación a profesional y/o un cierre de caja",
                vm: this,
                timer: 2800,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoCobro() {
      this.showNuevoCobro = false;
      this.buscarCobros(0);
    },
    cierraEditarCobro() {
      this.showEditarCobro = false;
      this.buscarCobros(0);
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    cobrar() {
      this.tipoMovimiento = "cobro";
      this.showNuevoCobro = true;
    },
    pagar() {
      this.tipoMovimiento = "pago";
      this.showNuevoCobro = true;
    },
    editarCobro(item) {
      let self = this;
      axios
        .get("/Caja/ChequearEditarCobro?", {
          params: {
            idCobro: item.idCobro,
          },
        })
        .then((response) => {
          if (response.data == "included") {
            self.showAlert({
              icon: "error",
              title: "El cobro no puede ser modificado por estar incluído en una liquidación",
              vm: this,
              timer: 2800,
            });
          } else {
            this.tipoMovimiento = item.total >= 0 ? "cobro" : "pago";
            this.editedIndex = this.listaCobros.indexOf(item);
            this.editedItem = Object.assign({}, item);
            this.showEditarCobro = true;
          }
        });
    },
    buscarCobros(state) {
      const filtrosData = {
        paciente: this.paciente,
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        idProfesional: this.prof,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        idFormaPago: this.formaPago,
        estado: this.estado,
        usuario: this.usuario,
        tipoMovimientoFiltro: this.tipoMovimientoFiltro,
      };

      let self = this;
      axios
        .get("/Caja/BuscarCobros?", {
          params: {
            paciente: filtrosData.paciente,
            fechaDesde: filtrosData.fechaDesde,
            fechaHasta: filtrosData.fechaHasta,
            idProfesional: filtrosData.idProfesional,
            idInstitucion: filtrosData.idInstitucion,
            idFormaPago: filtrosData.idFormaPago,
            estado: filtrosData.estado,
            usuario: filtrosData.usuario,
            tipoMovimientoFiltro: filtrosData.tipoMovimientoFiltro,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") self.listaCobros = response.data;
          else {
            self.listaCobros = [];

            if (state == 1) {
              self.showAlert({
                icon: "error",
                title: "No hay cobros que coincidan con el criterio de búsqueda.",
                vm: self,
                timer: 2000,
              });
            }
          }
        })
        .catch(function (error) {});
    },
    imprimir() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = ["Fecha", "Paciente", "Observaciones", "Profesional", "Forma Pago", "Cobro   ", "Pago    "];
      let importeTotal = 0;
      let ingreso = 0;
      let egreso = 0;
      source.items.forEach((element) => {
        const cobro = element.total < 0 ? "" : element.totalString;
        const pago = element.total < 0 ? element.totalString : "";
        var temp = [
          element.fechaString,
          element.paciente,
          element.observaciones,
          element.nombreProfesional,
          element.nombreFormaPago,
          cobro,
          pago,
        ];
        rows.push(temp);
        importeTotal += element.total;
        if (element.total < 0) {
          egreso += element.total;
        } else {
          ingreso += element.total;
        }
      });
      //let columnHeader = source.items.headers
      //let rows = source.items.rows
      var doc = new jsPDF();
      doc.setProperties({
        title: "Listado de Movimientos de Caja",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Fecha Desde: " +
          this.formatDate(this.fechaDesde) +
          "  -  " +
          "Fecha Hasta: " +
          this.formatDate(this.fechaHasta),
        13,
        16
      );

      doc.setFontSize(16);
      doc.text("Listado de Movimientos de Caja", doc.internal.pageSize.getWidth() / 2, 33, {
        align: "center",
      });
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text("Ingresos: $" + parseFloat(ingreso).toFixed(2).toString().replace(".", ","), 155, finalY + 8);
      doc.text("Egresos: -$" + parseFloat(Math.abs(egreso)).toFixed(2).toString().replace(".", ","), 155, finalY + 16);
      if (importeTotal >= 0) {
        doc.text("Total: $" + parseFloat(importeTotal).toFixed(2).toString().replace(".", ","), 155, finalY + 24);
      } else {
        doc.text(
          "Total: -$" + parseFloat(Math.abs(importeTotal)).toFixed(2).toString().replace(".", ","),
          155,
          finalY + 24
        );
      }
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
        if (self.profsBuscar.length > 1)
          self.profsBuscar.unshift({
            idProfesional: 0,
            nombreProfesional: "TODOS",
          });

        self.prof = self.profsBuscar[0].idProfesional;
      });

    axios.get("/Caja/BuscarFormasPago").then((response) => {
      self.formasPago = response.data;
      self.formasPago.unshift({
        idFormaPago: 0,
        nombreFormaPago: "Todas",
      });
      self.formaPago = self.formasPago[0].idFormaPago;
    });

    axios
      .get("/Caja/BuscarUsuariosCajeros", {
        params: {
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        },
      })
      .then((response) => {
        self.usuarios = response.data;
        self.usuarios.unshift({
          usuario: "Todos",
        });
        self.usuario = self.usuarios[0].usuario;
      });

    this.estado = this.estados[0].estado;
    this.tipoMovimientoFiltro = this.tiposMovimientosFiltro[0].tipoMovimiento;

    this.fechaDesde = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
    this.fechaHasta = this.fechaDesde;
  },

  components: {
    NuevoCobro,
    EditarCobro,
    EnviarLinkMail,
    EnviarLinkWA,
  },
};
</script>
