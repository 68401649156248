<template>
  <v-dialog
    max-width="100%"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card class="mx-auto" color="grey lighten-4">
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Evolución de Signos Vitales</v-toolbar-title>
      </v-toolbar>
      <v-card-title>
        <v-row align="start">
          <div>
            <h3>Ultimos 15 registros</h3>
          </div>
        </v-row>
      </v-card-title>
      <v-divider></v-divider>

      <v-card-title v-if="this.estatura.length > 0">
        <v-icon color="blue" class="mr-8" size="48">
          mdi-human-male-height
        </v-icon>
        <v-row align="start">
          <div>
            <h4>Estatura</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioEstatura }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.estatura.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="estaturaData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.peso.length > 0">
        <v-icon color="green" class="mr-8" size="48"> mdi-scale </v-icon>
        <v-row align="start">
          <div>
            <h4>Peso</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioPeso }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.peso.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="pesoData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.imc.length > 0">
        <v-icon color="orange" class="mr-8" size="48">
          mdi-human-handsup
        </v-icon>
        <v-row align="start">
          <div>
            <h4>I.M.C.</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioImc }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.imc.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="imcData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.temperatura.length > 0">
        <v-icon color="#4b0082" class="mr-8" size="48">
          mdi-thermometer
        </v-icon>
        <v-row align="start">
          <div>
            <h4>Temperatura</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioTemperatura }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.temperatura.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="temperaturaData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.frecRespiratoria.length > 0">
        <v-icon color="cyan" class="mr-8" size="48">
          mdi-heart-pulse
        </v-icon>
        <v-row align="start">
          <div>
            <h4>Frec. Resp.</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioFrecRespiratoria }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.frecRespiratoria.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="frecRespiratoriaData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.sistolica.length > 0">
        <v-icon color="red" class="mr-8" size="48"> mdi-pulse </v-icon>
        <v-row align="start">
          <div>
            <h4>Pres. Sistólica</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioSistolica }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.sistolica.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="sistolicaData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.diastolica.length > 0">
        <v-icon color="red" class="mr-8" size="48">
          mdi-pulse
        </v-icon>
        <v-row align="start">
          <div>
            <h4>Pres. Diastólica</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioDiastolica }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.diastolica.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="diastolicaData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.frecCardiaca.length > 0">
        <v-icon color="#8b0000" class="mr-8" size="48"> mdi-heart </v-icon>
        <v-row align="start">
          <div>
            <h4>Frec. Cardíaca</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioFrecCardiaca }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.frecCardiaca.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="frecCardiacaData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.porcGrasaCorporal.length > 0">
        <v-icon color="#006400" class="mr-8" size="48"> mdi-human </v-icon>
        <v-row align="start">
          <div>
            <h4>% Grasa Corporal</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioPorcGrasaCorporal }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.porcGrasaCorporal.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="porcGrasaCorporalData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.porcMasaMuscular.length > 0">
        <v-icon color="#8b4513" class="mr-8" size="48">
          mdi-weight-lifter
        </v-icon>
        <v-row align="start">
          <div>
            <h4>% Masa Muscular</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioPorcMasaMuscular }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.porcMasaMuscular.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="porcMasaMuscularData"
          :options="options"
        ></line-chart>
      </v-sheet>

      <v-card-title v-if="this.perimCefalico.length > 0">
        <v-icon color="#E75480" class="mr-8" size="48"> mdi-baby-face </v-icon>
        <v-row align="start">
          <div>
            <h4>Per. Cefálico</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioPerimCefalico }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.perimCefalico.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="perimCefalicoData"
          :options="options"
        ></line-chart>
      </v-sheet>
      <v-card-title v-if="this.satOxigeno.length > 0">
        <v-icon color="#d6b500" class="mr-8" size="48">
          mdi-lungs
        </v-icon>
        <v-row align="start">
          <div>
            <h4>Sat. Oxígeno</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioSatOxigeno }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.satOxigeno.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="satOxigenoData"
          :options="options"
        ></line-chart>
      </v-sheet>
      <v-card-title v-if="this.glucemia.length > 0">
        <v-icon color="#3364FF" class="mr-8" size="48">
          mdi-needle
        </v-icon>
        <v-row align="start">
          <div>
            <h4>Glucemia</h4>
          </div>
          <v-spacer></v-spacer>
          <div>
            <span class="text-h6 font-weight-black"
              ><h5>Promedio: {{ promedioGlucemia }}</h5></span
            >
          </div>
        </v-row>
      </v-card-title>
      <v-sheet
        class="v-sheet--offset mx-auto"
        color="transparent"
        elevation="12"
        max-width="100%"
        v-if="this.glucemia.length > 0"
      >
        <line-chart
          :style="myStyle"
          :chartData="glucemiaData"
          :options="options"
        ></line-chart>
      </v-sheet>
    </v-card>
  </v-dialog>
</template>
<script>
import LineChart from "./LineChart.js";
export default {
  components: { LineChart },
  data: (vm) => ({
    maxWidth: "100%",

    estatura: [],
    peso: [],
    imc: [],
    temperatura: [],
    frecRespiratoria: [],
    sistolica: [],
    diastolica: [],
    frecCardiaca: [],
    porcGrasaCorporal: [],
    porcMasaMuscular: [],
    perimCefalico: [],
    satOxigeno: [],
    glucemia: [],

    labelsEstatura: [],
    labelsPeso: [],
    labelsImc: [],
    labelsTemperatura: [],
    labelsFrecRespiratoria: [],
    labelsSistolica: [],
    labelsDiastolica: [],
    labelsFrecCardiaca: [],
    labelsPorcGrasaCorporal: [],
    labelsPorcMasaMuscular: [],
    labelsPerimCefalico: [],
    labelsSatOxigeno: [],
    labelsGlucemia: [],

    estaturaData: null,
    pesoData: null,
    imcData: null,
    temperaturaData: null,
    frecRespiratoriaData: null,
    sistolicaData: null,
    diastolicaData: null,
    frecCardiacaData: null,
    porcGrasaCorporalData: null,
    porcMasaMuscularData: null,
    perimCefalicoData: null,
    satOxigenoData: null,
    glucemiaData: null,

    promedioEstatura: null,
    promedioPeso: null,
    promedioImc: null,
    promedioTemperatura: null,
    promedioFrecRespiratoria: null,
    promedioSistolica: null,
    promedioDiastolica: null,
    promedioFrecCardiaca: null,
    promedioPorcGrasaCorporal: null,
    promedioPorcMasaMuscular: null,
    promedioPerimCefalico: null,
    promedioSatOxigeno: null,
    promedioGlucemia: null,

    options: null,
  }),
  props: ["visible", "signosVitales", "paciente"],
  computed: {
    myStyle() {
      return {
        height: "280px",
      };
    },
    show: {
      get() {
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  mounted() {
    this.fillData();
  },
  watch: {
    signosVitales: function(val) {
      this.preFillData();
      this.fillData();
    },
  },
  beforeMount() {
    this.preFillData();
  },
  methods: {
    vaciarModels() {
      this.estatura = [];
      this.peso = [];
      this.imc = [];
      this.temperatura = [];
      this.frecRespiratoria = [];
      this.sistolica = [];
      this.diastolica = [];
      this.frecCardiaca = [];
      this.porcGrasaCorporal = [];
      this.porcMasaMuscular = [];
      this.perimCefalico = [];
      this.satOxigeno = [];
      this.glucemia = [];

      this.labelsEstatura = [];
      this.labelsPeso = [];
      this.labelsImc = [];
      this.labelsTemperatura = [];
      this.labelsFrecRespiratoria = [];
      this.labelsSistolica = [];
      this.labelsDiastolica = [];
      this.labelsFrecCardiaca = [];
      this.labelsPorcGrasaCorporal = [];
      this.labelsPorcMasaMuscular = [];
      this.labelsPerimCefalico = [];
      this.labelsSatOxigeno = [];
      this.labelsGlucemia = [];

      this.estaturaData = null;
      this.pesoData = null;
      this.imcData = null;
      this.temperaturaData = null;
      this.frecRespiratoriaData = null;
      this.sistolicaData = null;
      this.distolicaData = null;
      this.frecCardiacaData = null;
      this.porcGrasaCorporalData = null;
      this.porcMasaMuscularData = null;
      this.perimCefalicoData = null;
      this.satOxigenoData = null;
      this.glucemiaData = null;

      this.promedioEstatura = null;
      this.promedioPeso = null;
      this.promedioImc = null;
      this.promedioTemperatura = null;
      this.promedioFrecRespiratoria = null;
      this.promedioFrecCardiaca = null;
      this.promedioSistolica = null;
      this.promedioDiastolica = null;
      this.promedioPorcGrasaCorporal = null;
      this.promedioPorcMasaMuscular = null;
      this.promedioPerimCefalico = null;
      this.promedioSatOxigeno = null;
      this.promedioGlucemia = null;
    },
    avg(array) {
      var sum = 0;
      for (var i = 0; i < array.length; i++) {
        sum = sum + array[i];
      }
      return parseFloat(sum / array.length).toFixed(2);
    },
    preFillData() {
      this.vaciarModels();

      this.signosVitales.slice(0, 15).forEach((element) => {
        if (element.estatura != null && element.estatura != "") {
          this.estatura.unshift(element.estatura);
          this.labelsEstatura.unshift(element.fechaHoraString);
        }

        if (element.peso != null && element.peso != "") {
          this.peso.unshift(element.peso);
          this.labelsPeso.unshift(element.fechaHoraString);
        }

        if (element.masaCorporal != null && element.masaCorporal != "") {
          this.imc.unshift(element.masaCorporal);
          this.labelsImc.unshift(element.fechaHoraString);
        }

        if (element.temperatura != null && element.temperatura != "") {
          this.temperatura.unshift(element.temperatura);
          this.labelsTemperatura.unshift(element.fechaHoraString);
        }

        if (
          element.frecRespiratoria != null &&
          element.frecRespiratoria != ""
        ) {
          this.frecRespiratoria.unshift(element.frecRespiratoria);
          this.labelsFrecRespiratoria.unshift(element.fechaHoraString);
        }

        if (element.sistolica != null && element.sistolica != "") {
          this.sistolica.unshift(element.sistolica);
          this.labelsSistolica.unshift(element.fechaHoraString);
        }

        if (element.diastolica != null && element.diastolica != "") {
          this.diastolica.unshift(element.diastolica);
          this.labelsDiastolica.unshift(element.fechaHoraString);
        }

        if (element.frecCardiaca != null && element.frecCardiaca != "") {
          this.frecCardiaca.unshift(element.frecCardiaca);
          this.labelsFrecCardiaca.unshift(element.fechaHoraString);
        }

        if (
          element.porcGrasaCorporal != null &&
          element.porcGrasaCorporal != ""
        ) {
          this.porcGrasaCorporal.unshift(element.porcGrasaCorporal);
          this.labelsPorcGrasaCorporal.unshift(element.fechaHoraString);
        }

        if (
          element.porcMasaMuscular != null &&
          element.porcMasaMuscular != ""
        ) {
          this.porcMasaMuscular.unshift(element.porcMasaMuscular);
          this.labelsPorcMasaMuscular.unshift(element.fechaHoraString);
        }

        if (element.perimCefalico != null && element.perimCefalico != "") {
          this.perimCefalico.unshift(element.perimCefalico);
          this.labelsPerimCefalico.unshift(element.fechaHoraString);
        }

        if (element.satOxigeno != null && element.satOxigeno != "") {
          this.satOxigeno.unshift(element.satOxigeno);
          this.labelsSatOxigeno.unshift(element.fechaHoraString);
        }

        if (element.glucemia != null && element.glucemia != "") {
          this.glucemia.unshift(element.glucemia);
          this.labelsGlucemia.unshift(element.fechaHoraString);
        }

        this.promedioEstatura = this.avg(this.estatura) + " m";
        this.promedioPeso = this.avg(this.peso) + " kg";
        this.promedioImc = this.avg(this.imc) + " kg/m2";
        this.promedioTemperatura = this.avg(this.temperatura) + " °C";
        this.promedioFrecRespiratoria =
          this.avg(this.frecRespiratoria) + " r/m";
        this.promedioSistolica = this.avg(this.sistolica) + " mmHg";
        this.promedioDiastolica = this.avg(this.diastolica) + " mmHg";
        this.promedioFrecCardiaca = this.avg(this.frecCardiaca) + " lpm";
        this.promedioPorcGrasaCorporal =
          this.avg(this.porcGrasaCorporal) + " %";
        this.promedioPorcMasaMuscular = this.avg(this.porcMasaMuscular) + " %";
        this.promedioPerimCefalico = this.avg(this.perimCefalico) + " cm";
        this.promedioSatOxigeno = this.avg(this.satOxigeno);
        this.promedioGlucemia = this.avg(this.glucemia);
      });
    },
    fillData() {
      this.estaturaData = {
        labels: this.labelsEstatura,
        datasets: [
          {
            label: "Estatura",
            data: this.estatura,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "blue",
            borderWidth: 1,
          },
        ],
      };
      this.pesoData = {
        labels: this.labelsPeso,
        datasets: [
          {
            label: "Peso",
            data: this.peso,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "green",
            borderWidth: 1,
          },
        ],
      };
      this.imcData = {
        labels: this.labelsImc,
        datasets: [
          {
            label: "I.M.C.",
            data: this.imc,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "orange",
            borderWidth: 1,
          },
        ],
      };
      this.temperaturaData = {
        labels: this.labelsTemperatura,
        datasets: [
          {
            label: "Temperatura",
            data: this.temperatura,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "indigo",
            borderWidth: 1,
          },
        ],
      };
      this.frecRespiratoriaData = {
        labels: this.labelsFrecRespiratoria,
        datasets: [
          {
            label: "Frec. Respiratoria",
            data: this.frecRespiratoria,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "cyan",
            borderWidth: 1,
          },
        ],
      };
      this.sistolicaData = {
        labels: this.labelsSistolica,
        datasets: [
          {
            label: "Pres. Sistólica",
            data: this.sistolica,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "red",
            borderWidth: 1,
          },
        ],
      };
      this.diastolicaData = {
        labels: this.labelsDiastolica,
        datasets: [
          {
            label: "Pres. Diastólica",
            data: this.diastolica,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "red",
            borderWidth: 1,
          },
        ],
      };
      this.frecCardiacaData = {
        labels: this.labelsFrecCardiaca,
        datasets: [
          {
            label: "Frec. Cardíaca",
            data: this.frecCardiaca,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "darkred",
            borderWidth: 1,
          },
        ],
      };
      this.porcGrasaCorporalData = {
        labels: this.labelsPorcGrasaCorporal,
        datasets: [
          {
            label: "% Grasa Corporal",
            data: this.porcGrasaCorporal,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "#006400",
            borderWidth: 1,
          },
        ],
      };
      this.porcMasaMuscularData = {
        labels: this.labelsPorcMasaMuscular,
        datasets: [
          {
            label: "% Masa Muscular",
            data: this.porcMasaMuscular,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "#8b4513",
            borderWidth: 1,
          },
        ],
      };
      this.perimCefalicoData = {
        labels: this.labelsPerimCefalico,
        datasets: [
          {
            label: "Per. Cefálico",
            data: this.perimCefalico,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "#E75480",
            borderWidth: 1,
          },
        ],
      };
      this.satOxigenoData = {
        labels: this.labelsSatOxigeno,
        datasets: [
          {
            label: "Sat. Oxígeno",
            data: this.satOxigeno,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "#d6b500",
            borderWidth: 1,
          },
        ],
      };
      this.glucemiaData = {
        labels: this.labelsGlucemia,
        datasets: [
          {
            label: "Glucemia",
            data: this.glucemia,
            borderWidth: 1,
            tension: 0.1,
            backgroundColor: "transparent",
            borderColor: "#3364FF",
            borderWidth: 1,
          },
        ],
      };
      this.options = {
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        responsive: true,
        maintainAspectRatio: false,
      };
    },
  },
};
</script>
