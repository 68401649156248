<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Datos Afiliatorios</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="my-4 mx-1">
          <h2>Obra Social: {{ obraSocial }} - {{ nroAfiliado }}</h2>
          <span>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="rgba(0, 0, 0, 0.6)"
                  class="ml-3"
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="copiar()"
                  >mdi-content-copy</v-icon
                >
              </template>
              <span>Copiar Dato Afiliatorio</span>
            </v-tooltip></span
          >
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../axios-auth.js";
export default {
  data: (vm) => ({
    maxWidth: "40%",
    obraSocial: "",
    nroAfiliado: "",
  }),
  props: ["visible", "idPaciente"],
  computed: {
    show: {
      get() {
        if (this.visible) this.buscarDatosAfiliatorios();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    buscarDatosAfiliatorios() {
      let self = this;

      axios
        .get("/HistoriaClinica/BuscarUltimaReceta?", {
          params: {
            idPaciente: self.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "") {
            self.nroAfiliado = response.data.nroAfiliado;
            self.obraSocial = response.data.obraSocial;
          } else {
            self.nroAfiliado = null;
            self.obraSocial = null;
          }
        })
        .catch((error) => {});
    },
    copiar() {
      navigator.clipboard.writeText(this.obraSocial + " - " + this.nroAfiliado);
    },
  },
};
</script>
