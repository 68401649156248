<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{ tipoMovimiento === "cobro" ? "Nuevo Cobro" : "Nuevo Pago" }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row v-if="idTurno > 0">
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Nuevo Cobro</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaCobro"
                :value="fechaCobro"
                label="Fecha Cobro"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                :disabled="idTurno > 0 || idPresupuesto > 0"
                dense
                ref="profsBuscar"
              ></v-select>
            </v-col>
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                v-model="paciente"
                prepend-icon="mdi-account"
                :rules="requiredRules"
                label="Concepto"
                :disabled="idTurno > 0 || idPresupuesto > 0"
                dense
                @change="idPaciente = null"
                maxlength="150"
                :hint="tipoMovimiento === 'cobro' ? 'Puede buscar un paciente existente apretando la lupa' : ''"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn v-show="!(idTurno > 0) && tipoMovimiento === 'cobro'" color="primary" @click="buscarPaciente">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="observaciones"
                prepend-icon="mdi-account"
                label="Observaciones"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" v-if="tipoMovimiento === 'cobro'">
              <v-select
                :items="formasPago"
                item-text="nombreFormaPago"
                item-value="idFormaPago"
                menu-props="auto"
                label="Forma de Pago"
                hide-details
                prepend-icon="mdi-currency-usd"
                v-model="formaPago"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="total"
                prepend-icon="mdi-currency-usd"
                :rules="tipoMovimiento === 'cobro' ? totalRulesCobros : totalRulesPagos"
                label="Importe"
                @blur="toFixed"
                @keypress="onlyNumber"
                dense
                maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarCobro">Guardar</v-btn>
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
      <v-card-text v-if="idTurno > 0 || idPresupuesto > 0">
        <v-row dense>
          <v-col cols="12">
            <v-chip class="ma-0" label color="transparent" text-color="black">
              <h3>Cobros ya realizados para este {{ idTurno > 0 ? "turno" : "prespuesto" }}</h3>
            </v-chip>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="this.listaCobros"
          class="elevation-1"
          :hide-default-footer="true"
          locale="es-ar"
          item-key="idCrobro"
          :items-per-page="10000"
          ref="myTable"
          :disable-sort="true"
        >
          <template v-slot:item.estado="{ item }">
            <v-chip :color="getColor(item.estado)" dark>
              {{ item.estado }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium color="purple" class="mr-3" v-on="on" v-bind="attrs" @click.stop="editarCobro(item)"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Editar Cobro</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium v-on="on" class="mr-3" color="red" v-bind="attrs" @click="borrarCobro(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Eliminar Cobro</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  v-on="on"
                  class="mr-3"
                  color="indigo"
                  v-bind="attrs"
                  @click="imprimirCobro(item, pacienteTurno, $refs.profsBuscar.selectedItems[0].nombreProfesional)"
                  >mdi-file-pdf-box</v-icon
                >
              </template>
              <span>Generar Comprobante</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="item.nombreFormaPago == 'Mercado Pago' && item.estado == 'Pendiente'"
                  medium
                  class="mr-3"
                  v-on="on"
                  color="blue"
                  v-bind="attrs"
                  @click="generarPreferencia(item)"
                  >mdi-link</v-icon
                >
              </template>
              <span>Generar Link de Pago</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="item.nombreFormaPago == 'Mercado Pago' && item.estado === 'Pendiente - Link Generado'"
                  medium
                  color="orange"
                  class="mr-3"
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="enviarLinkMail(item)"
                  >mdi-email</v-icon
                >
              </template>
              <span>Enviar Link por Mail</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="item.nombreFormaPago == 'Mercado Pago' && item.estado == 'Pendiente - Link Generado'"
                  medium
                  color="green"
                  class="mr-3"
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="enviarLinkWA(item)"
                  >mdi-whatsapp</v-icon
                >
              </template>
              <span>Enviar Link por Whats App</span>
            </v-tooltip>

            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-show="item.estado !== 'Aprobado'"
                  medium
                  v-on="on"
                  color="green"
                  v-bind="attrs"
                  @click="aprobarCobro(item)"
                  >mdi-check-circle</v-icon
                >
              </template>
              <span>Aprobar Pago</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <EditarCobro
        :visible="showEditarCobro"
        :idCobro="editedItem.idCobro"
        :idTurnoEditar="editedItem.idTurno"
        @close="cierraEditarCobro()"
      ></EditarCobro>
      <BuscarPacienteTurno
        :visible="showBuscarPaciente"
        @close="showBuscarPaciente = false"
        @seleccionarPaciente="seleccionarPaciente($event)"
      ></BuscarPacienteTurno>
      <EnviarLinkMail
        :visible="showEnviarLinkMail"
        :link="editedItem.linkMercadoPago"
        :paciente="editedItem.idPaciente"
        :pacienteNombre="editedItem.paciente"
        :idProfesional="editedItem.idProfesional"
        @close="showEnviarLinkMail = false"
      ></EnviarLinkMail>
      <EnviarLinkWA
        :visible="showEnviarLinkWA"
        :link="editedItem.linkMercadoPago"
        :paciente="editedItem.idPaciente"
        :pacienteNombre="editedItem.paciente"
        :idProfesional="editedItem.idProfesional"
        @close="showEnviarLinkWA = false"
      ></EnviarLinkWA>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import EditarCobro from "../Caja/EditarCobro.vue";
import BuscarPacienteTurno from "../Turnos/BuscarPacienteTurno.vue";
import EnviarLinkMail from "../Caja/EnviarLinkMail.vue";
import EnviarLinkWA from "../Caja/EnviarLinkWA.vue";
import CobrosMixin from "../Caja/CobrosMixin.js";

export default {
  props: {
    visible: {
      required: false,
    },
    idTurno: {
      required: false,
      default: 0,
    },
    idPresupuesto: {
      required: false,
      default: 0,
    },
    pacienteTurno: { required: false },
    idProfesional: { required: false },
    idPacienteTurno: { required: false },
    tipoMovimiento: { required: false, default: "cobro" },
    estadoTurno: { required: false, default: 0 },
  },
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    totalRulesCobros: [(v) => !!v || "Dato obligatorio", (v) => v >= 0 || "El total debe ser mayor o igual a 0"],
    totalRulesPagos: [(v) => !!v || "Dato obligatorio", (v) => v > 0 || "El total debe ser mayor a 0"],
    prof: "",
    profsBuscar: [],
    fechaCobro: null,
    paciente: null,
    observaciones: null,
    idPaciente: null,
    total: null,
    listaCobros: [],
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Concepto", value: "paciente" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Forma Pago", value: "nombreFormaPago" },
      { text: "Importe", value: "totalString" },
      { text: "Estado", value: "estado" },
      { text: "Acciones", value: "actions" },
    ],
    showEditarCobro: null,
    editedItem: {},
    editedIndex: -1,
    showBuscarPaciente: false,
    formasPago: [],
    formaPago: null,
    showEnviarLinkMail: false,
    showEnviarLinkWA: false,
  }),
  mixins: [CobrosMixin],
  watch: {
    estadoTurno(val) {
      console.log(val);
    },
  },
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
          if (this.idTurno > 0) this.buscarCobrosTurno();
          if (this.idPresupuesto > 0) this.buscarCobrosPresupuesto();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    aprobarCobro(item) {
      this.$swal({
        title: "Aprobar Pago",
        text: "¿Desea aprobar el pago?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosCobro = {
            idCobro: item.idCobro,
            idTurno: item.idTurno,
          };

          let self = this;
          axios
            .post("/Caja/AprobarPago", datosCobro)
            .then((response) => {
              if (response.data === "Ok") {
                self.showAlert({
                  icon: "success",
                  title: "Pago aprobado exitosamente.",
                  vm: self,
                  timer: 2500,
                });
                if (datosCobro.idTurno > 0) {
                  self.$emit("recargarLista");
                  setTimeout(self.buscarCobrosTurno(0), 2600);
                }
                if (datosCobro.idPresupuesto > 0) {
                  setTimeout(self.buscarCobrosPresupuesto(0), 2600);
                }
              } else {
                self.showAlert({
                  icon: "error",
                  title: "El pago no pudo ser aprobado.",
                  vm: self,
                  timer: 2500,
                });
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El pago no pudo ser aprobado.",
                vm: self,
                timer: 2500,
              });
            });
        }
      });
    },
    enviarLinkMail(item) {
      this.editedIndex = this.listaCobros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEnviarLinkMail = true;
    },
    enviarLinkWA(item) {
      this.editedIndex = this.listaCobros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEnviarLinkWA = true;
    },
    generarPreferencia(item) {
      if (item.accessToken === null || item.accessToken === "" || item.accessToken === undefined) {
        this.showAlert({
          icon: "error",
          title: "El profesional no tiene configurado su token de mercado pago",
          vm: this,
          timer: 2800,
        });
        return;
      }
      this.$swal({
        title: "Generar Link de Pago",
        text: "¿Desea generar el link de pago?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosPreferencia = {
            idCobro: item.idCobro,
            accessToken: item.accessToken,
            total: item.total,
            nombreProfesional: item.nombreProfesional,
          };

          let self = this;
          axios
            .post("/Caja/GenerarPreferencia", datosPreferencia)
            .then((response) => {
              if (response.data === "Ok") {
                self.showAlert({
                  icon: "success",
                  title: "Link de Pago generado exitosamente.",
                  vm: self,
                  timer: 2500,
                });
                if (self.idTurno > 0) {
                  setTimeout(self.buscarCobrosTurno(0), 2600);
                }
                if (self.idPresupuesto > 0) {
                  setTimeout(self.buscarCobrosPresupuesto(0), 2600);
                }
              } else {
                self.showAlert({
                  icon: "error",
                  title: "El Link de Pago no pudo ser generado.",
                  vm: self,
                  timer: 2500,
                });
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El Link de Pago no pudo ser generado.",
                vm: self,
                timer: 2500,
              });
            });
        }
      });
    },
    getColor(estado) {
      if (estado == "Aprobado") {
        return "blue";
      }
      if (estado == "Pendiente") {
        return "red";
      }
      if (estado == "Pendiente - Link Generado") {
        return "orange";
      }
    },

    seleccionarPaciente(item) {
      (this.idPaciente = item.idpaciente), (this.paciente = item.apellidos + ", " + item.nombres);
      this.showBuscarPaciente = false;
    },
    buscarPaciente() {
      this.showBuscarPaciente = true;
    },
    guardarCobro() {
      const title = this.tipoMovimiento === "cobro" ? "Registrar Cobro" : "Registrar Pago";
      const text = this.tipoMovimiento === "cobro" ? "¿Desea registrar el cobro?" : "¿Desea registrar el pago?";
      this.$swal({
        title: title,
        text: text,
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosCobro = {
            fecha: this.fechaCobro,
            idTurno: this.idTurno,
            estadoTurno: this.estadoTurno,
            idPaciente: this.idPaciente,
            total: this.tipoMovimiento === "cobro" ? this.total : parseFloat(0 - this.total, 2).toString(),
            observaciones: this.observaciones,
            idProfesional: this.prof,
            paciente: this.paciente,
            idFormaPago: this.tipoMovimiento === "cobro" ? this.formaPago : null,
            usuario: this.$store.state.usuario.usuario,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            idPresupuesto: this.idPresupuesto,
          };
          let self = this;
          axios
            .post("/Caja/GuardarCobro", datosCobro)
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
              const alertTitle = self.tipoMovimiento === "cobro" ? "Cobro registrado" : "Pago registrado";
              self.showAlert({
                icon: "success",
                title: alertTitle,
                vm: self,
              });
              if (self.tipoMovimiento === "cobro" && self.idTurno > 0) {
                this.$swal({
                  title: "Generar Comprobante",
                  text: "¿Desea generar el comprobante de cobro al paciente?",
                  icon: "success",
                  type: "success",
                  showCancelButton: true,
                  background: "#ececec",
                  confirmButtonText: "Si",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    this.imprimirCobro(
                      datosCobro,
                      self.pacienteTurno,
                      self.$refs.profsBuscar.selectedItems[0].nombreProfesional
                    );
                  }
                });
              }

              if (self.idTurno > 0) {
                self.buscarCobrosTurno();
                if (self.estadoTurno === 1) {
                  self.$emit("recargarLista");
                }
              }
              if (self.idPresupuesto > 0) {
                self.buscarCobrosPresupuesto();
              }
            })
            .catch((error) => {
              const alertTitleError =
                self.tipoMovimiento === "cobro" ? "El cobro no pudo ser registrado" : "El pago no pudo ser registrado";
              self.showAlert({
                icon: "error",
                title: alertTitleError,
                vm: this,
              });
            });
        }
        if (this.idTurno > 0 && this.$store.state.usuario.institucion.idInstitucion === 170) {
          const self = this;
          axios
            .get("/Turnos/Atendido?", {
              params: {
                idTurno: this.idTurno,
              },
            })
            .then((response) => {
              self.$emit("recargarLista");
            });
        }
      });
    },
    borrarCobro(item) {
      this.$swal({
        title: "Borrar Cobro",
        text: "¿Desea borrar el cobro?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idCobro = item.idCobro;
          let self = this;
          axios
            .get("/Caja/BorrarCobro?", {
              params: {
                idCobro: idCobro,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Cobro borrado",
                vm: self,
              });
              if (self.idTurno > 0) {
                setTimeout(self.buscarCobrosTurno(), 1700);
              }
              if (self.idPresupuesto > 0) {
                setTimeout(self.buscarCobrosPresupuesto(), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title:
                  "El cobro no puede ser borrado por estar incluído en una liquidación a profesional y/o un cierre de caja",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;

          if (self.tipoMovimiento === "pago") {
            self.profsBuscar.unshift({
              idProfesional: null,
              nombreProfesional: "NO ASOCIADO",
            });
          }

          if (self.idTurno == 0 && self.idPresupuesto == 0) self.prof = self.profsBuscar[0].idProfesional;
          else self.prof = self.idProfesional;
        });

      this.fechaCobro = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
      if (this.pacienteTurno != null) this.paciente = "Cobro Paciente: " + this.pacienteTurno;
      if (this.idPacienteTurno != null) this.idPaciente = this.idPacienteTurno;

      axios.get("/Caja/BuscarFormasPago").then((response) => {
        self.formasPago = response.data;
        self.formaPago = self.formasPago[0].idFormaPago;
      });
    },
    buscarCobrosTurno() {
      const filtrosData = {
        idTurno: this.idTurno,
      };

      let self = this;
      axios
        .get("/Caja/BuscarCobrosTurno?", {
          params: {
            idTurno: filtrosData.idTurno,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") self.listaCobros = response.data;
          else self.listaCobros = [];
        })
        .catch(function (error) {});
    },
    buscarCobrosPresupuesto() {
      const filtrosData = {
        idPresupuesto: this.idPresupuesto,
      };

      let self = this;
      axios
        .get("/Caja/BuscarCobrosPresupuesto?", {
          params: {
            idPresupuesto: filtrosData.idPresupuesto,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") self.listaCobros = response.data;
          else self.listaCobros = [];
        })
        .catch(function (error) {});
    },
    cierraEditarCobro() {
      this.showEditarCobro = false;
      if (this.idTurno > 0) {
        this.buscarCobrosTurno();
      }
      if (this.idPresupuesto > 0) {
        this.buscarCobrosPresupuesto();
      }
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toFixed() {
      if (isNaN(parseFloat(this.total))) this.total = parseFloat(0.0).toFixed(2);
      else this.total = parseFloat(this.total).toFixed(2);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    vaciarModels() {
      if (this.idTurno == 0) this.paciente = "";
      this.observaciones = "";
      this.total = "";
      this.formaPago = this.formasPago[0].idFormaPago;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    editarCobro(item) {
      this.editedIndex = this.listaCobros.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarCobro = true;
    },
  },
  components: {
    EditarCobro,
    BuscarPacienteTurno,
    EnviarLinkMail,
    EnviarLinkWA,
  },
};
</script>
