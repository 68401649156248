import { render, staticRenderFns } from "./Indicaciones.vue?vue&type=template&id=262cafc8&scoped=true"
import script from "./Indicaciones.vue?vue&type=script&lang=js"
export * from "./Indicaciones.vue?vue&type=script&lang=js"
import style0 from "./Indicaciones.vue?vue&type=style&index=0&id=262cafc8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "262cafc8",
  null
  
)

export default component.exports