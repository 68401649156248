<template>
  <v-dialog
    max-width="480px"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary" class="mb-2">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Características del Paciente</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col>
              <v-select
                :items="gruposSanguineos"
                item-text="tipoGrupoSanguineo"
                item-value="idGrupoSanguineo"
                menu-props="auto"
                label="Grupo Sanguíneo"
                hide-details
                v-model="grupoSanguineo"
                dense
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                :items="rhs"
                item-text="tipoRh"
                item-value="idRh"
                menu-props="auto"
                label="Rh"
                hide-details
                v-model="rh"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CaracteristicaPacienteOpcion
                v-model="esGestante"
                text="Es Gestante"
              />
            </v-col>
            <v-col>
              <CaracteristicaPacienteOpcion
                v-model="esDiabetico"
                text="Es Diabético"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CaracteristicaPacienteOpcion
                v-model="esEPOC"
                text="Tiene EPOC"
              />
            </v-col>
            <v-col>
              <CaracteristicaPacienteOpcion
                v-model="esRCV"
                text="RCV 20% o más"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CaracteristicaPacienteOpcion
                v-model="esObeso"
                text="Tiene Obesidad"
              />
            </v-col>
            <v-col>
              <CaracteristicaPacienteOpcion
                v-model="esFumador"
                text="Es Fumador"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <CaracteristicaPacienteOpcion v-model="esHTA" text="Tiene HTA" />
            </v-col>
            <v-col>
              <CaracteristicaPacienteOpcion
                v-model="esSobrePeso"
                text="Tiene Sobrepeso"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="guardar()">Guardar</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
  import axios from "../../axios-auth.js";
  import CaracteristicaPacienteOpcion from "./CaracteristicaPacienteOpcion.vue";

  export default {
    name: "CaracteristicasPaciente",
    components: { CaracteristicaPacienteOpcion },
    props: {
      visible: {
        type: Boolean,
        required: true,
      },
      idPaciente: {
        type: Number,
      },
    },
    data() {
      return {
        esGestante: false,
        esDiabetico: false,
        esHTA: false,
        esEPOC: false,
        esRCV: false,
        esObeso: false,
        esFumador: false,
        esSobrePeso: false,
        rh: null,
        grupoSanguineo: null,
        rhs: [],
        gruposSanguineos: [],
      };
    },
    computed: {
      show: {
        get() {
          if (this.visible) {
            this.cargarGrupoYFactor();
            this.buscarCaracteristicas();
          }
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.vaciarModels();
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      vaciarModels() {
        this.esDiabetico = false;
        this.esEPOC = false;
        this.esFumador = false;
        this.esGestante = false;
        this.esHTA = false;
        this.esObeso = false;
        this.esRCV = false;
        this.rh = null;
        this.grupoSanguineo = null;
        this.esSobrePeso = false;
      },
      async cargarGrupoYFactor() {
        const { data: gruposSanguineos } = await axios.get(
          "/Paciente/GetGruposSanguineos"
        );
        if (gruposSanguineos.length) {
          this.gruposSanguineos = gruposSanguineos;
          this.gruposSanguineos.unshift({
            idGrupoSanguineo: 0,
            tipoGrupoSanguineo: "Sin Especificar",
          });
        }

        const { data: rhs } = await axios.get("/Paciente/GetRhs");
        if (rhs.length) {
          this.rhs = rhs;
          this.rhs.unshift({ idRh: 0, tipoRh: "Sin Especificar" });
        }
      },
      async buscarCaracteristicas() {
        const { data: caracteristicas } = await axios.get(
          "/Paciente/BuscarCaracteristicas",
          {
            params: {
              idPaciente: this.idPaciente,
            },
          }
        );
        this.esDiabetico = caracteristicas.esDiabetico;
        this.esEPOC = caracteristicas.esEPOC;
        this.esFumador = caracteristicas.esFumador;
        this.esGestante = caracteristicas.esGestante;
        this.esHTA = caracteristicas.esHTA;
        this.esObeso = caracteristicas.esObeso;
        this.esRCV = caracteristicas.esRCV;
        this.grupoSanguineo = caracteristicas.idGrupoSanguineo;
        this.rh = caracteristicas.idRh;
        this.esSobrePeso = caracteristicas.esSobrePeso;
      },
      guardar() {
        const caracteristicas = {
          esDiabetico: this.esDiabetico,
          esEPOC: this.esEPOC,
          esFumador: this.esFumador,
          esHTA: this.esHTA,
          esObeso: this.esObeso,
          esRCV: this.esRCV,
          esGestante: this.esGestante,
          idPaciente: this.idPaciente,
          idGrupoSanguineo: this.grupoSanguineo,
          idRh: this.rh,
          esSobrePeso: this.esSobrePeso,
        };
        let self = this;
        axios
          .post("/Paciente/GuardarCaracteristicas", caracteristicas)
          .then(() => {
            self.showAlert({
              icon: "success",
              title: "Características guardadas exitosamente",
              vm: self,
              timer: 2000,
            });
          })
          .catch(() => {
            self.showAlert({
              icon: "error",
              title:
                "Las características no pudieron ser guardadas. Intente nuevamente más tarde.",
              vm: self,
              timer: 2000,
            });
          });
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: alertInfo.timer,
        });
      },
    },
  };
</script>
