<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Institución Externa</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-home"
                label="Nombre Institución Externa"
                maxlength="100"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>

            
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarInstitucionExterna">Guardar</v-btn>

          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    nombre: null,
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
  }),

  props: ["visible", "idInstitucionExterna"],

  computed: {
    show: {
      get() {
        if(this.visible)
          this.buscarDatosInstitucionExterna();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    buscarDatosInstitucionExterna() {
      const idInstitucionExterna = this.idInstitucionExterna;
      let self = this;

      axios
        .get("/HistoriaClinica/BuscarDatosInstitucionExterna?", {
          params: {
            idInstitucionExterna: this.idInstitucionExterna,
          },
        })
        .then((response) => {
          self.nombre = response.data.nombreInstitucionExterna;
          
        })
        .catch((error) => {
          
        });
    },
    modificarInstitucionExterna() {
      this.$swal({
        title: "Modificar Institución Externa",
        text: "¿Desea modificar el nombre de la institución externa?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosInstitucionExterna = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            idInstitucionExterna: this.idInstitucionExterna,
            nombreInstitucionExterna: this.nombre
          };
          let self = this;
          axios
            .post("/HistoriaClinica/ModificarInstitucionExterna", datosInstitucionExterna)
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: "Ya existe una institución externa registrada con ese nombre",
                  vm: self,
                });
              } else {
                self.$refs.form.resetValidation();

                document.getElementsByClassName(
                  "v-dialog--active"
                )[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title: "Institución externa modificada exitosamente",
                  vm: self,
                });
                self.$emit("institucionExternaModificada");
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La institución externa no pudo ser modificada",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },

};
</script>