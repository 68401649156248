import jsPDF from "jspdf";
import axios from "../../axios-auth.js";
import moment from "moment";

export default {
  data() {
    return {
      logo: null,
    };
  },
  created() {
    this.getLogoUrl();
  },
  methods: {
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.logo = response.data;
        })
        .catch((error) => {});
    },
    async imprimirCobro(cobro, paciente, prof) {
      const { data: institucionData } = (self.institucion = await axios.get(
        "/Usuario/BuscarDatosInstitucion",
        {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        }
      ));

      const base64 = this.getLogoUrl();

      console.log(base64);

      console.log(cobro);
      console.log(paciente);
      console.log(prof);

      var doc = new jsPDF();

      doc.setProperties({
        title: "Comprobante de Pago",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(11);
      doc.text(nombreInstitucion, 13, 10);
      doc.text(
        "Dirección: " +
          institucionData.direccion.toString().replace(", Argentina", ""),
        13,
        15
      );

      if (
        institucionData.telefono != null &&
        institucionData.telefono != "" &&
        institucionData.celular != null &&
        institucionData.celular != ""
      )
        doc.text(
          "Tel / Cel: " +
            institucionData.telefono +
            " - " +
            institucionData.celular,
          13,
          20
        );
      if (
        institucionData.telefono != null &&
        institucionData.telefono != "" &&
        (institucionData.celular == null || institucionData.celular == "")
      )
        doc.text("Tel: " + institucionData.telefono, 13, 20);
      if (
        (institucionData.telefono == null || institucionData.telefono == "") &&
        institucionData != null &&
        institucionData.celular != ""
      )
        doc.text("Cel: " + institucionData.celular, 13, 20);
      if (institucionData.mail != "" && institucionData.mail != null)
        doc.text("Mail: " + institucionData.mail, 13, 25);

      if (this.logo != null && this.logo != "")
        doc.addImage(this.logo, 140, 5, 60, 20);

      doc.setFontSize(13);
      doc.line(13, 35, 200, 35);
      doc.setFontSize(16);
      doc.text(
        "Comprobante de Pago",
        doc.internal.pageSize.getWidth() / 2,
        45,
        {
          align: "center",
        }
      );
      doc.setFontSize(12);

      const text = `El día ${moment().format(
        "DD/MM/YYYY"
      )}, recibí del Sr./Sra. ${paciente}, la suma de $${
        cobro.total
      } en concepto de ${cobro.observaciones} con el profesional ${prof}`;

      var splitText = doc.splitTextToSize(text, 180);

      doc.text(splitText, 13, 60, { lineHeightFactor: 1.5 });
      window.open(doc.output("bloburl"), "_blank");
    },
  },
};
