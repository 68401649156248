<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Nuevo Pedido</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="obraSocial"
                prepend-icon="mdi-account"
                label="Obra Social"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="nroAfiliado"
                prepend-icon="mdi-account"
                label="Nº de Afiliado"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea
                prepend-icon="mdi-hospital"
                @click:prepend="usarPlantilla"
                v-model="pedido"
                label="Solicito"
                :rules="requiredRules"
                hint="Apretando en el ícono + podrá usar una plantilla de pedidos de estudio preconfigurada"
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="diagnostico"
                prepend-icon="mdi-account"
                label="Diagnóstico"
                maxlength="150"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarPedido"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
      <BuscarPlantilla
        :visible="showBuscarPlantilla"
        @close="showBuscarPlantilla = false"
        @seleccionarPlantilla="seleccionarPlantilla($event)"
      ></BuscarPlantilla>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  .v-dialog__content {
    align-items: left;
    justify-content: left;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import BuscarPlantilla from "../HistClin/BuscarPlantilla.vue";
  import imprimirPedido from "./ImprimirEventos/imprimirPedido";
  import imprimirPedidoTrinidad from "./ImprimirEventos/Trinidad/imprimirPedido";

  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      maxWidth: "100%",
      requiredRules: [(v) => !!v || "Dato obligatorio"],

      prof: "",
      profsBuscar: [],
      fecha: null,
      obraSocial: null,
      nroAfiliado: null,
      pedido: "",
      diagnostico: null,
      showBuscarPlantilla: false,
      base64: null,
    }),
    props: ["visible", "idPaciente", "paciente", "institucion"],
    computed: {
      show: {
        get() {
          if (this.visible) {
            this.setearModels();
            this.getLogoUrl();
          }
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$refs.form.resetValidation();
            this.vaciarModels();
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      getLogoUrl() {
        let self = this;

        axios
          .get("/HistoriaClinica/GetLogo?", {
            params: {
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
            },
          })
          .then((response) => {
            self.base64 = response.data;
          })
          .catch((error) => {});
      },
      imprimir() {
        switch (this.$store.state.usuario.institucion.idInstitucion) {
          case 95: {
            imprimirPedidoTrinidad(
              this.institucion,
              this.paciente,
              {
                texto: this.pedido,
                obraSocial: this.obraSocial,
                nroAfiliado: this.nroAfiliado,
                fechaPedidoString: this.formatDate(this.fecha),
                diagnostico: this.diagnostico,
              },
              this.base64,
              "screen"
            );
            break;
          }
          default: {
            imprimirPedido(
              this.institucion,
              this.paciente,
              {
                texto: this.pedido,
                obraSocial: this.obraSocial,
                nroAfiliado: this.nroAfiliado,
                fechaPedidoString: this.formatDate(this.fecha),
                diagnostico: this.diagnostico,
              },
              this.base64,
              "screen"
            );
            break;
          }
        }
        this.$refs.form.resetValidation();
        this.vaciarModels();
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
        this.$emit("pedidoRegistrado");
        //doc.save('turnos.pdf');
      },

      usarPlantilla() {
        this.showBuscarPlantilla = true;
      },
      seleccionarPlantilla(item) {
        if (this.pedido == "") this.pedido = item.textoPlantillaPedido;
        else this.pedido = this.pedido + "\n" + item.textoPlantillaPedido;
        this.showBuscarPlantilla = false;
      },
      guardarPedido() {
        this.$swal({
          title: "Registrar Pedido",
          text: "¿Desea registrar el pedido?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            const datosPedido = {
              fechaPedido: this.fecha,
              idPaciente: this.idPaciente,
              idProfesional: this.prof,
              texto: this.pedido,
              diagnostico: this.diagnostico,
              obraSocial: this.obraSocial,
              nroAfiliado: this.nroAfiliado,
            };
            let self = this;
            axios
              .post("/HistoriaClinica/GuardarPedido", datosPedido)
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Pedido registrado exitosamente",
                  vm: self,
                });
                this.$swal({
                  title: "Imprimir Pedido",
                  text: "¿Desea imprimir el pedido?",
                  icon: "success",
                  type: "success",
                  showCancelButton: true,
                  background: "#ececec",
                  confirmButtonText: "Si",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    this.imprimir();
                  } else {
                    self.$refs.form.resetValidation();
                    self.vaciarModels();
                    document.getElementsByClassName(
                      "v-dialog--active"
                    )[0].scrollTop = 0;
                    self.$emit("pedidoRegistrado");
                  }
                });
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El pedido no pudo ser registrado",
                  vm: this,
                });
              });
          }
        });
      },

      setearModels() {
        const profData = {
          usuario: this.$store.state.usuario.usuario,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        let self = this;
        axios
          .get("/Turnos/BuscarProfesionalesAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.profsBuscar = response.data.profesionales;

            self.prof = self.profsBuscar[0].idProfesional;
          });

        axios
          .get("/HistoriaClinica/BuscarUltimoPedido?", {
            params: {
              idPaciente: self.idPaciente,
            },
          })
          .then((response) => {
            if (response.data != null && response.data != "") {
              self.nroAfiliado = response.data.nroAfiliado;
              self.obraSocial = response.data.obraSocial;
            } else {
              self.nroAfiliado = null;
              self.obraSocial = null;
            }
          })
          .catch((error) => {});

        this.fecha = this.parseDate(
          this.formatDate(this.toISOLocal(new Date()))
        );
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";
      },

      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      toISOLocal(d) {
        var z = (n) => ("0" + n).slice(-2);
        var zz = (n) => ("00" + n).slice(-3);
        var off = d.getTimezoneOffset();
        var sign = off < 0 ? "+" : "-";
        off = Math.abs(off);
        return (
          d.getFullYear() +
          "-" +
          z(d.getMonth() + 1) +
          "-" +
          z(d.getDate()) +
          "T" +
          z(d.getHours()) +
          ":" +
          z(d.getMinutes()) +
          ":" +
          z(d.getSeconds()) +
          "." +
          zz(d.getMilliseconds()) +
          "Z"
        )
          .toString()
          .substr(0, 10);
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },

      vaciarModels() {
        this.pedido = null;
        this.obraSocial = null;
        this.nroAfiliado = null;
        this.diagnostico = null;
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
    },

    components: {
      BuscarPlantilla,
    },
  };
</script>
