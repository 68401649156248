import jsPDF from 'jspdf'

export default function imprimirPedido(institucion, paciente, pedido, base64, output) {
var doc = new jsPDF();
      
doc.setProperties({
  title: "Pedido",
});
//doc.text('Listado de Turnos',20,10);
var nombreInstitucion = institucion.nombreInstitucion
  .toString()
  .trim();

doc.setFontSize(11);
doc.text(nombreInstitucion, 13, 10);
 doc.text("Dirección: "+ institucion.direccion.toString().replace(', Argentina',''),13, 15);

if((institucion.telefono!= null && institucion.telefono!= '')&&(institucion.celular!= null && institucion.celular!=''))
doc.text("Tel / Cel: "+ institucion.telefono + " - " + institucion.celular,13, 20);
if((institucion.telefono!= null && institucion.telefono!= '')&&(institucion.celular== null || institucion.celular==''))
doc.text("Tel: "+ institucion.telefono,13, 20);
if((institucion.telefono== null || institucion.telefono== '')&&(institucion.celular!= null && institucion.celular!=''))
doc.text("Cel: "+ institucion.celular,13, 20);
if(institucion.mail != '' && institucion.mail!=null)
doc.text("Mail: " + institucion.mail,13, 25)
doc.setFontSize(11);
doc.text("Paciente: "+ paciente.apellidos + " " + paciente.nombres, 13, 40);
doc.text("D.N.I.: "+ paciente.dni, 13, 45);
doc.text("Obra Social: "+ pedido.obraSocial, 13, 50);
doc.text("Nº de Afiliado: "+ pedido.nroAfiliado, 13, 55);
doc.setFontSize(12);

if(base64 != null && base64 != '')
doc.addImage(base64, 140, 5, 60, 20);

doc.line(13, 59, 200, 59);
doc.text("Solicito:",13,66);
   
doc.setFontSize(12);
var textoSplit = pedido.texto.split(/\r\n|\r|\n/)
let y = 74 
for(var i=0; i<textoSplit.length;i++){
doc.text(textoSplit[i], 28, y);
y = y + 5
if(y>290)
{
  y = 20
  doc.addPage()
}
}

 if(y>290)
{
  y = 20
  doc.addPage()
}
doc.line(13, y, 200, y);
y = y + 8
 if(y>290)
{
  y = 20
  doc.addPage()
}
doc.setFontSize(11);
doc.text("Fecha: " + pedido.fechaPedidoString,13,y);
y = y + 5
 if(y>290)
{
  y = 20
  doc.addPage()
}
doc.text("Diagnóstico: " + pedido.diagnostico,13,y);

if (output === 'screen') {
window.open(doc.output("bloburl"), "_blank");
}
else
{
    return doc.output("blob");
}
}