<template>
  <v-dialog
    max-width="400px"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>N° de Historia Clínica</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="my-4 mx-1">
          <v-col cols="12">
            <v-text-field
              v-model="numHC"
              prepend-icon="mdi-account"
              label="N° Hist. Clin."
              dense
              maxlength="30"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="actualizarNumHC">Actualizar</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from "../../axios-auth.js";
export default {
  data: (vm) => ({
    numHC: "",
  }),
  props: ["visible", "idPaciente"],
  computed: {
    show: {
      get() {
        if (this.visible) this.buscarNumeroHC();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    buscarNumeroHC() {
      let self = this;

      axios
        .get("/HistoriaClinica/BuscarNumeroHC?", {
          params: {
            idPaciente: self.idPaciente,
          },
        })
        .then((response) => {
          self.numHC = response.data;
        })
        .catch((error) => {});
    },
    actualizarNumHC() {
      let self = this;

      const datosActualizacion = {
        numHC: this.numHC,
        idPaciente: this.idPaciente,
      };

      axios
        .post("/HistoriaClinica/ActualizarNumHC", datosActualizacion)
        .then((response) => {
          self.showAlert({
            icon: "success",
            title: "N° de Historia Clínica actualizado correctamente",
            vm: self,
            timer: 2500,
          });
        })
        .catch((error) => {});
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
  },
};
</script>
