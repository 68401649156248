<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nueva Liquidación a Profesional</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Buscar Cobros para Liquidar</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="4">
              <v-select
                v-if="!esLiquidacionMultiple"
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                label="Profesional"
                prepend-icon="mdi-account"
                v-model="prof"
                dense
                ref="vselect"
                style="width: 500px"
              ></v-select>
              <v-select
                v-else
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                label="Profesional"
                prepend-icon="mdi-account"
                v-model="profArray"
                dense
                ref="vselect"
                multiple
              ></v-select>
            </v-col>
            <v-spacer></v-spacer>
            <v-col style="vertical-align: top">
              <v-switch
                v-model="esLiquidacionMultiple"
                inset
                label="Múltiples Prestadores"
                style="width: 230px"
              ></v-switch>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="mt-0 pt-0">
          <v-spacer></v-spacer>
          <v-btn
            :disabled="esLiquidacionMultiple && profArray.length === 0"
            color="primary"
            @click.stop="buscarCobros(1)"
          >
            Buscar
            <v-icon dark right>mdi-magnify</v-icon>
          </v-btn>
        </v-card-actions>
      </v-form>
      <v-form ref="form" v-model="valid2" :lazy-validation="lazy2">
        <v-card-text>
          <v-data-table
            :headers="headers"
            v-model="cobrosSeleccionados"
            :items="this.listaCobros"
            class="elevation-1"
            :hide-default-footer="true"
            locale="es-ar"
            item-key="idCobro"
            :items-per-page="10000"
            show-select
            ref="myTable"
            :disable-sort="true"
            @input="sumarImporte"
          ></v-data-table>
          <v-row class="mt-4">
            <v-spacer></v-spacer>
            <v-text-field
              v-model="totalCobrado"
              label="Total Seleccionado"
              readonly
              prefix="$"
              prepend-icon="mdi-usd-currency"
              class="shrink"
              style="width: 160px"
            >
            </v-text-field>
          </v-row>
          <v-row class="mt-4">
            <v-spacer></v-spacer>

            <v-text-field
              v-model="porcentaje"
              :rules="totalRules"
              label="Porcentaje a Pagar"
              @blur="toFixed"
              suffix="%"
              @keypress.enter="toFixed"
              @keypress="onlyNumber"
              maxlength="10"
              class="shrink"
              style="width: 160px"
            ></v-text-field>

            <v-text-field
              v-model="totalPagado"
              @blur="calcularPorcentaje"
              @keypress.enter="calcularPorcentaje"
              @keypress="onlyNumber"
              maxlength="15"
              label="Total a Pagar"
              prefix="$"
              prepend-icon="mdi-usd-currency"
              class="shrink"
              style="width: 160px"
            >
            </v-text-field>
          </v-row>
          <v-row
            v-if="
              $store.state.usuario.institucion.idInstitucion === 170 &&
              porcentaje
            "
            class="mt-4"
          >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="totalTransferencias"
              label="Total Transferencias"
              readonly
              prefix="$"
              prepend-icon="mdi-usd-currency"
              class="shrink"
              style="width: 160px"
            >
            </v-text-field>
          </v-row>
          <v-row
            v-if="
              $store.state.usuario.institucion.idInstitucion === 170 &&
              porcentaje
            "
            class="mt-4"
          >
            <v-spacer></v-spacer>
            <v-text-field
              v-model="totalFinal"
              label="Total Liquidación"
              readonly
              prefix="$"
              prepend-icon="mdi-usd-currency"
              class="shrink"
              style="width: 160px"
            >
            </v-text-field>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="success"
          :disabled="!valid2"
          @click.stop="guardarLiquidacion"
          >Guardar</v-btn
        >
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
::v-deep .v-input--selection-controls {
  margin-top: 0px !important;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
export default {
  data: (vm) => ({
    valid: false,
    esLiquidacionMultiple: false,
    valid2: false,
    lazy2: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    multipleRules: [(v) => v.length === 0 || "Dato obligatorio"],
    prof: "",
    profArray: [],
    profsBuscar: [],
    listaCobros: [],
    cobrosSeleccionados: [],
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Paciente", value: "paciente" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Forma Pago", value: "nombreFormaPago" },
      { text: "Importe", value: "totalString", align: "end" },
    ],
    totalRules: [
      (v) => !!v || "Dato obligatorio",
      (v) => v >= 0 || "El porcentaje debe ser igual o mayor que 0",
    ],
    fechaDesde: null,
    fechaHasta: null,
    totalCobrado: 0,
    totalPagado: 0,
    totalTransferencias: 0,
    totalFinal: 0,
    porcentaje: null,
  }),

  props: ["visible"],
  watch: {
    esLiquidacionMultiple(val) {
      this.listaCobros = [];
      this.totalCobrado = 0;
      this.totalPagado = 0;
      this.totalTransferencias = 0;
      this.totalFinal = 0;
      this.porcentaje = null;
    },
  },
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    sumarImporte() {
      this.totalCobrado = 0;
      this.totalTransferencias = 0;
      this.cobrosSeleccionados.forEach((importe) => {
        this.totalCobrado += importe.total;
        if (importe.idFormaPago === 4) {
          this.totalTransferencias += importe.total;
        }
      });
      this.totalCobrado = parseFloat(this.totalCobrado).toFixed(2);
      this.totalTransferencias = parseFloat(this.totalTransferencias).toFixed(
        2
      );

      if (this.porcentaje > 100) this.totalPagado = parseFloat(0).toFixed(2);
      else {
        if (this.porcentaje != "" && this.porcentaje != null)
          this.totalPagado = parseFloat(
            (parseFloat(this.porcentaje).toFixed(2) / 100) * this.totalCobrado
          ).toFixed(2);
      }
      if (this.$store.state.usuario.institucion.idInstitucion === 170) {
        this.totalFinal = parseFloat(
          this.totalPagado - this.totalTransferencias
        ).toFixed(2);
      }
    },
    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;

          self.prof = self.profsBuscar[0].idProfesional;
        });

      this.fechaDesde = this.parseDate(
        this.formatDate(this.toISOLocal(new Date()))
      );
      this.fechaHasta = this.parseDate(
        this.formatDate(this.toISOLocal(new Date()))
      );
      this.totalCobrado = 0;
      this.totalTransferencias = 0;
    },
    calcularPorcentaje() {
      if (isNaN(parseFloat(this.totalPagado))) {
        this.porcentaje = parseFloat(0.0).toFixed(2);
        this.totalPagado = parseFloat(0.0).toFixed(2);
      } else {
        this.totalPagado = parseFloat(this.totalPagado).toFixed(2);
        this.porcentaje = parseFloat(
          (this.totalPagado / this.totalCobrado) * 100
        ).toFixed(2);
      }
      if (this.$store.state.usuario.institucion.idInstitucion === 170) {
        this.totalFinal = parseFloat(
          this.totalPagado - this.totalTransferencias
        ).toFixed(2);
      }
    },
    toFixed() {
      if (isNaN(parseFloat(this.porcentaje))) {
        this.porcentaje = parseFloat(0.0).toFixed(2);
        this.totalPagado = parseFloat(0.0).toFixed(2);
      } else {
        this.porcentaje = parseFloat(this.porcentaje).toFixed(2);
        this.totalPagado = parseFloat(
          (parseFloat(this.porcentaje).toFixed(2) / 100) * this.totalCobrado
        ).toFixed(2);
      }
      if (this.$store.state.usuario.institucion.idInstitucion === 170) {
        this.totalFinal = parseFloat(
          this.totalPagado - this.totalTransferencias
        ).toFixed(2);
      }
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    guardarLiquidacion() {
      this.$swal({
        title: "Guardar Liquidación de Caja",
        text: "¿Desea registrar la liquidación?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          if (this.cobrosSeleccionados.length == 0) {
            this.showAlert({
              icon: "error",
              title: "Debe seleccionar al menos 1 cobro para liquidar",
              vm: this,
            });
          } else {
            var cobrosSel = [];
            this.cobrosSeleccionados.forEach((cobro) =>
              cobrosSel.push(cobro.idCobro)
            );

            const datosLiquidacion = {
              idProfesional: this.prof,
              totalCobrado: this.totalCobrado,
              totalPagado: this.totalPagado,
              porcentaje: this.porcentaje,
              cobros: cobrosSel,
              esMultiple: this.esLiquidacionMultiple,
            };
            let self = this;
            axios
              .get("/Caja/GuardarLiquidacion?", {
                params: {
                  idProfesional: datosLiquidacion.idProfesional,
                  totalCobrado: datosLiquidacion.totalCobrado,
                  totalPagado: datosLiquidacion.totalPagado,
                  porcentaje: datosLiquidacion.porcentaje,
                  cobros: datosLiquidacion.cobros,
                  esMultiple: datosLiquidacion.esMultiple,
                  usuario: this.$store.state.usuario.usuario,
                  idInstitucion:
                    this.$store.state.usuario.institucion.idInstitucion,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Liquidación registrada exitosamente",
                  vm: self,
                });
                this.$swal({
                  title: "Imprimir Liquidación",
                  text: "¿Desea imprimir la liquidación?",
                  icon: "success",
                  type: "success",
                  showCancelButton: true,
                  background: "#ececec",
                  confirmButtonText: "Si",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    this.imprimir();
                  } else {
                    self.$refs.form.resetValidation();
                    self.vaciarModels();
                    document.getElementsByClassName(
                      "v-dialog--active"
                    )[0].scrollTop = 0;
                    self.$emit("liquidacionRegistrada");
                  }
                });
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La liquidación no pudo ser registrada",
                  vm: this,
                });
              });
          }
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    imprimir() {
      var doc = new jsPDF("landscape");

      doc.setProperties({
        title: "Liquidación de Caja",
      });
      var source = this.cobrosSeleccionados;
      let rows = [];
      let columnHeader = [
        "Fecha",
        "Paciente",
        "Obra Social",
        "Observaciones",
        "Forma de Pago",
        "Importe",
      ];
      let subtotales = {};

      source.forEach((element) => {
        var temp = [
          element.fechaString,
          element.paciente,
          element.obraSocial ?? "",
          element.observaciones,
          element.nombreFormaPago,
          element.totalString,
        ];
        rows.push(temp);
        if (element.idFormaPago === 1) {
          subtotales.efectivo = (subtotales.efectivo ?? 0) + element.total;
        } else {
          subtotales.transferencia =
            (subtotales.transferencia ?? 0) + element.total;
        }
        if (element.idFormaPago === 4) {
          subtotales.soloTransferencia =
            (subtotales.soloTransferencia ?? 0) + element.total;
        }
      });
      var nombreInstitucion =
        this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

      var nomProf = this.esLiquidacionMultiple
        ? "VARIOS"
        : this.$refs.vselect.selectedItems[0].nombreProfesional;
      var fecha = this.formatDate(this.toISOLocal(new Date()));
      var retenido = parseFloat(this.totalCobrado - this.totalPagado).toFixed(
        2
      );
      let propRetenido = parseFloat(retenido / this.totalCobrado).toFixed(2);
      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(16);
      doc.text(
        "Liquidación de Caja",
        doc.internal.pageSize.getWidth() / 2,
        20,
        {
          align: "center",
        }
      );
      doc.line(123, 21, 174, 21);
      doc.setFontSize(12);
      doc.text("Profesional: " + nomProf, 13, 30);
      doc.text("Fecha: " + fecha, 13, 37);

      doc.autoTable(columnHeader, rows, {
        startY: 45,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;
      if (finalY > 180) {
        doc.addPage();
        finalY = 20;
      }

      if (this.$store.state.usuario.institucion.idInstitucion === 195) {
        const subtotalEfectivo = isNaN(subtotales.efectivo)
          ? 0
          : subtotales.efectivo;
        const subtotalTransferencia = isNaN(subtotales.transferencia)
          ? 0
          : subtotales.transferencia;

        doc.text(
          "Total Cobrado Ef.: $" +
            parseFloat(subtotalEfectivo)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          70,
          finalY + 8,
          { align: "right" }
        );
        doc.text(
          "Retenido Ef.: $" +
            parseFloat(subtotalEfectivo * propRetenido)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          70,
          finalY + 16,
          { align: "right" }
        );
        doc.text(
          "A Pagar Ef.: $" +
            parseFloat(subtotalEfectivo - subtotalEfectivo * propRetenido)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          70,
          finalY + 24,
          { align: "right" }
        );

        doc.text(
          "Total Cobrado Electr.: $" +
            parseFloat(subtotalTransferencia)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          175,
          finalY + 8,
          { align: "right" }
        );
        doc.text(
          "Retenido Electr.: $" +
            parseFloat(subtotalTransferencia * propRetenido)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          175,
          finalY + 16,
          { align: "right" }
        );
        doc.text(
          "A Pagar Electr.: $" +
            parseFloat(
              subtotalTransferencia - subtotalTransferencia * propRetenido
            )
              .toFixed(2)
              .toString()
              .replace(".", ","),
          175,
          finalY + 24,
          { align: "right" }
        );
      }

      doc.text(
        "Total Cobrado: $" + this.totalCobrado.toString().replace(".", ","),
        280,
        finalY + 8,
        {
          align: "right",
        }
      );
      doc.text(
        "Retenido: $" + retenido.toString().replace(".", ","),
        280,
        finalY + 16,
        { align: "right" }
      );
      doc.text(
        "A Pagar: $" + this.totalPagado.toString().replace(".", ","),
        280,
        finalY + 24,
        { align: "right" }
      );

      if (this.$store.state.usuario.institucion.idInstitucion === 170) {
        const subtotalTransferencia = isNaN(subtotales.soloTransferencia)
          ? 0
          : subtotales.soloTransferencia;
        const totalFinalLiquidacion = this.totalPagado - subtotalTransferencia;
        doc.text(
          "Total Cobrado Transf.: $" +
            parseFloat(subtotalTransferencia)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          280,
          finalY + 32,
          { align: "right" }
        );
        doc.text(
          "Total Final Liquidacion.: $" +
            parseFloat(totalFinalLiquidacion)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          280,
          finalY + 40,
          { align: "right" }
        );
      }

      window.open(doc.output("bloburl"), "_blank");
      this.$refs.form.resetValidation();
      this.vaciarModels();
      document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
      this.$emit("liquidacionRegistrada");
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.listaCobros = [];
      this.totalCobrado = null;
      this.totalTransferencias = null;
      this.porcentaje = null;
      this.totalPagado = null;
      this.esLiquidacionMultiple = false;
      this.profArray = [];
      this.cobrosSeleccionados = [];
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    buscarCobros(state) {
      let filtrosData = {};
      let idProfesionalArray = [];

      if (!this.esLiquidacionMultiple) {
        idProfesionalArray.push(this.prof);
        filtrosData = {
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          idProfesional: idProfesionalArray,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          esMultiple: false,
        };
      } else {
        filtrosData = {
          fechaDesde: this.fechaDesde,
          fechaHasta: this.fechaHasta,
          idProfesional: this.profArray,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          esMultiple: true,
        };
      }

      let self = this;
      axios
        .post("/Caja/BuscarCobrosLiq?", filtrosData)
        .then(function (response) {
          if (response.data != "" && response.data != null)
            self.listaCobros = response.data;
          else {
            self.listaCobros = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay cobros que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function (error) {});
    },
  },

  created() {
    this.vaciarModels();
  },
};
</script>
