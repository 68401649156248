<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Usuarios</v-toolbar-title>
      </v-toolbar>

      <v-row dense>
        <v-col cols="12" class="ml-3">
          <v-checkbox
            v-model="soloActivos"
            label="Mostrar en la grilla solo usuarios activos"
            color="primary"
            dense
            @change="buscarUsuarios"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-data-table
        :headers="headers"
        :items="this.listaUsuarios"
        class="elevation-1"
        locale="es-ar"
        item-key="usuario"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.estadoUsuario="{ item }">
          <v-simple-checkbox
            v-model="item.estadoUsuario"
            disabled
          ></v-simple-checkbox>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="relacionesUsuario(item)"
                >mdi-cog</v-icon
              >
            </template>
            <span>Configurar Usuario</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="cambiarPass(item)"
                >mdi-wrench</v-icon
              >
            </template>
            <span>Cambiar Contraseña</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.estadoUsuario == true"
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="desactivarUsuario(item)"
                >mdi-thumb-down</v-icon
              >
            </template>
            <span>Desactivar Usuario</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="!item.estadoUsuario"
                medium
                color="green"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="activarUsuario(item)"
                >mdi-thumb-up</v-icon
              >
            </template>
            <span>Activar Usuario</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarUsuario(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Usuario</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoUsuario">Nuevo Usuario</v-btn>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoUsuario
        :visible="showNuevoUsuario"
        @close="cierraNuevoUsuario()"
        @usuarioRegistrado="usuarioRegistrado()"
      ></NuevoUsuario>
      <RelacionesUsuario
        :visible="showRelacionesUsuario"
        :usuario="editedItem.usuario"
        @close="cierraRelacionesUsuario()"
      ></RelacionesUsuario>
      <CambiarPassUsuario
        :visible="showCambiarPassUsuario"
        :usuario="editedItem.usuario"
        @close="showCambiarPassUsuario = false"
      ></CambiarPassUsuario>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import NuevoUsuario from "../Cuenta/NuevoUsuario.vue";
import RelacionesUsuario from "../Cuenta/RelacionesUsuario.vue";
import CambiarPassUsuario from "../Cuenta/CambiarPassUsuario.vue";
export default {
  data: (vm) => ({
    listaUsuarios: [],
    headers: [
      { text: "Usuario", value: "usuario" },
      { text: "Estado", value: "estadoUsuario" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoUsuario: false,
    showRelacionesUsuario: false,
    showCambiarPassUsuario: false,
    editedItem: {},
    editedIndex: -1,
    soloActivos: true,
  }),
  created() {
    this.buscarUsuarios();
  },
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarUsuario(item) {
      this.$swal({
        title: "Borrar Usuario",
        text: "¿Desea borrar el usuario?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const usuario = item.usuario;
          let self = this;
          axios
            .get("/Usuario/BorrarUsuario?", {
              params: {
                usuario: usuario,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
              },
            })
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: "El usuario tiene roles y/o profesionales asociados",
                  vm: self,
                });
              } else {
                self.showAlert({
                  icon: "success",
                  title: "Usuario borrado exitosamente",
                  vm: self,
                });
                setTimeout(self.buscarUsuarios(), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El usuario no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cambiarPass(item) {
      this.editedIndex = this.listaUsuarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showCambiarPassUsuario = true;
    },
    cierraNuevoUsuario() {
      this.showNuevoUsuario = false;
      //this.buscarUsuarios();
    },
    cierraRelacionesUsuario() {
      this.showRelacionesUsuario = false;
      //this.buscarUsuarios();
    },
    usuarioRegistrado() {
      this.showNuevoUsuario = false;
      this.buscarUsuarios();
    },
    nuevoUsuario() {
      this.showNuevoUsuario = true;
    },
    relacionesUsuario(item) {
      this.editedIndex = this.listaUsuarios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showRelacionesUsuario = true;
    },
    activarUsuario(item) {
      this.$swal({
        title: "Activar Usuario",
        text: "¿Desea activar el usuario?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const usuario = item.usuario;
          let self = this;
          axios
            .get("/Usuario/DesactivarActivarUsuario?", {
              params: {
                usuario: usuario,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
                estadoUsuario: true,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Usuario activado exitosamente",
                vm: self,
              });
              setTimeout(self.buscarUsuarios(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El usuario no pudo ser activado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    desactivarUsuario(item) {
      this.$swal({
        title: "Desactivar Usuario",
        text: "¿Desea desactivar el usuario?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const usuario = item.usuario;
          let self = this;
          axios
            .get("/Usuario/DesactivarActivarUsuario?", {
              params: {
                usuario: usuario,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
                estadoUsuario: false,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Usuario desactivado exitosamente",
                vm: self,
              });
              setTimeout(self.buscarUsuarios(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El usuario no pudo ser desactivado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    buscarUsuarios() {
      const filtrosData = {
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        soloActivos: this.soloActivos,
      };

      let self = this;
      axios
        .get("/Usuario/BuscarUsuarios?", {
          params: {
            idInstitucion: filtrosData.idInstitucion,
            soloActivos: filtrosData.soloActivos,
          },
        })
        .then(function(response) {
          if (response.data != "" && response.data != null)
            self.listaUsuarios = response.data;
          else self.listaUsuarios = [];
        })
        .catch(function(error) {});
    },
  },

  components: {
    NuevoUsuario,
    RelacionesUsuario,
    CambiarPassUsuario,
  },
};
</script>
