<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Imprimir Estadísticas</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="this.opciones"
          v-model="opcionesSeleccionadas"
          class="elevation-1"
          show-select
          :hide-default-footer="true"
          locale="es-ar"
          item-key="idOpcion"
          :items-per-page="10000"
          ref="myTable"
          :disable-sort="true"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import jsPDF from "jspdf";
export default {
  data: (vm) => ({
    opciones: [
      {
        idOpcion: "1",
        estadistica: "Turnos",
      },
      {
        idOpcion: "2",
        estadistica: "Ingresos",
      },
      {
        idOpcion: "3",
        estadistica: "Ranking de ausencias por paciente",
      },
      {
        idOpcion: "4",
        estadistica: "Ranking de turnos por obra social",
      },
      {
        idOpcion: "5",
        estadistica: "Distribución de turnos atendidos por edad",
      },
      {
        idOpcion: "6",
        estadistica: "Distribución de turnos atendidos por patalogía",
      },
    ],
    headers: [{ text: "Seleccionar Estadísticas", value: "estadistica" }],
    opcionesSeleccionadas: [],
  }),
  props: [
    "visible",
    "nomProf",
    "mesFormatted",
    "indicadores",
    "turnospacientes",
    "turnosos",
    "turnosedad",
    "turnospatologia",
  ],
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    setearModels() {
      this.opcionesSeleccionadas = this.opciones;
    },

    vaciarModels() {
      this.opcionesSeleccionadas = [];
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    imprimir() {
      if (this.opcionesSeleccionadas.length == 0) {
        this.showAlert({
          icon: "error",
          title: "Seleccione al menos una estadística para imprimir.",
          vm: this,
        });
        return;
      }
      var y = 0;
      var doc = new jsPDF();
      doc.setProperties({
        title: "Estadísticas",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();

      doc.setFontSize(12);
      y = 10;
      let finalY = 0;
      doc.text(nombreInstitucion, 13, y);
      doc.setFontSize(10);
      var nomProf = this.nomProf;
      y = 16;
      doc.text("Profesional: " + nomProf, 13, y);
      y = 21;
      doc.text("Mes / Año: " + this.mesFormatted, 13, y);
      y = 33;
      doc.setFontSize(16);
      doc.text("Estadísticas", doc.internal.pageSize.getWidth() / 2, y, { align: "center" });
      doc.line(89, 35, 121, 35);
      doc.setFontSize(14);
      if (this.opcionesSeleccionadas.some((o) => o.idOpcion == 1)) {
        doc.setFont("arial", "normal", "bold");
        doc.text("Turnos:", 13, 50);
        doc.setFont("arial", "normal", "normal");
        doc.setFontSize(12);
        doc.text("Turnos Totales: " + this.indicadores.totales, 15, 60);
        doc.text("Turnos Atendidos: " + this.indicadores.totales, 80, 60);
        doc.text("Turnos Ausentes: " + this.indicadores.totales, 150, 60);
        doc.text("Turnos En Espera: " + this.indicadores.totales, 15, 70);
        doc.text("Turnos Reservados: " + this.indicadores.totales, 80, 70);
        doc.text("Turnos Web: " + this.indicadores.totales, 150, 70);
        y = 85;
      } else {
        y = 50;
      }
      if (this.opcionesSeleccionadas.some((o) => o.idOpcion == 2)) {
        doc.setFontSize(14);

        doc.setFont("arial", "normal", "bold");
        doc.text("Ingresos: ", 13, y);
        doc.setFont("arial", "normal", "normal");
        doc.setFontSize(12);
        doc.text("$" + this.indicadores.ingresos, 38, y);
        doc.setFontSize(14);
        y = y + 15;
      }
      doc.setFontSize(14);
      if (this.opcionesSeleccionadas.some((o) => o.idOpcion == 3)) {
        let rows = [];
        let columnHeader = ["Nº", "Paciente", "Ausencias"];

        this.turnospacientes.forEach((element, index) => {
          var temp = [index + 1, element.paciente, element.ausencias];
          rows.push(temp);
        });
        doc.setFont("arial", "normal", "bold");
        doc.text("Ranking de ausencia por pacientes:", 13, y);
        doc.setFont("arial", "normal", "normal");

        doc.autoTable(columnHeader, rows, {
          startY: y + 5,
          didParseCell: function (table) {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#c4c5c5";
            }
          },
        });
        y = doc.lastAutoTable.finalY + 15;
      }

      if (this.opcionesSeleccionadas.some((o) => o.idOpcion == 4)) {
        doc.setFont("arial", "normal", "bold");
        doc.text("Ranking de turnos por obra social:", 13, y);
        doc.setFont("arial", "normal", "normal");

        let rowsOs = [];
        let columnHeaderOs = ["Nº", "Obra Social", "Turnos"];

        this.turnosos.forEach((element, index) => {
          var temp = [index + 1, element.obrasocial, element.turnos];
          rowsOs.push(temp);
        });

        doc.autoTable(columnHeaderOs, rowsOs, {
          startY: y + 5,
          didParseCell: function (table) {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#c4c5c5";
            }
          },
        });
        y = doc.lastAutoTable.finalY + 15;
      }

      if (this.opcionesSeleccionadas.some((o) => o.idOpcion == 5)) {
        doc.setFont("arial", "normal", "bold");
        doc.text("Distribución de Turnos Atendidos por Edad:", 13, y);
        doc.setFont("arial", "normal", "normal");

        let rowsEdad = [];
        let columnHeaderEdad = ["Nº", "Edad", "Cantidad"];

        this.turnosedad.forEach((element, index) => {
          var temp = [index + 1, element.edad, element.cantidad];
          rowsEdad.push(temp);
        });

        doc.autoTable(columnHeaderEdad, rowsEdad, {
          startY: y + 5,
          didParseCell: function (table) {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#c4c5c5";
            }
          },
        });
        y = doc.lastAutoTable.finalY + 15;
      }

      if (this.opcionesSeleccionadas.some((o) => o.idOpcion == 6)) {
        doc.setFont("arial", "normal", "bold");
        doc.text("Distribución de Turnos Atendidos por Patología:", 13, y);
        doc.setFont("arial", "normal", "normal");
        let rowsPat = [];
        let columnHeaderPat = ["Nº", "Patología", "Cantidad"];

        this.turnospatologia.forEach((element, index) => {
          var temp = [index + 1, element.nombrePatologia, element.cantidad];
          rowsPat.push(temp);
        });

        doc.autoTable(columnHeaderPat, rowsPat, {
          startY: y + 5,
          didParseCell: function (table) {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#c4c5c5";
            }
          },
        });
        y = doc.lastAutoTable.finalY + 15;
      }
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
};
</script>
