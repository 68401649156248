<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Registros de Signos Vitales</v-toolbar-title>
      </v-toolbar>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light-blue" dark @click.stop="verGrafico">Gráfico</v-btn>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
      <v-card-text v-for="(signoVital, i) in signosVitales" :key="i">
        <v-row class="my-4 mx-1">
          <h3>Profesional: {{ signoVital.nomProfesional }}</h3>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Fecha: {{ signoVital.fechaHoraString }}</h3>
        </v-row>
        <v-row class="mt-4 mx-1">
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-human-male-height</v-icon> Estatura:
              <span
                :class="{
                  lighter:
                    signoVital.estatura != null &&
                    signoVital.estatura != '' &&
                    signoVital.estatura > 2.6,
                  bold:
                    signoVital.estatura != null &&
                    signoVital.estatura != '' &&
                    signoVital.estatura <= 2.6,
                }"
                >{{ signoVital.estatura }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.estatura != null &&
                    signoVital.estatura != '' &&
                    signoVital.estatura > 2.6,
                  bold:
                    signoVital.estatura != null &&
                    signoVital.estatura != '' &&
                    signoVital.estatura <= 2.6,
                }"
                v-if="signoVital.estatura != null && signoVital.estatura != ''"
                >m</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-scale</v-icon> Peso:
              <span
                :class="{
                  lighter:
                    signoVital.peso != null &&
                    signoVital.peso != '' &&
                    signoVital.peso > 400,
                  bold:
                    signoVital.peso != null &&
                    signoVital.peso != '' &&
                    signoVital.peso <= 400,
                }"
                >{{ signoVital.peso }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.peso != null &&
                    signoVital.peso != '' &&
                    signoVital.peso > 400,
                  bold:
                    signoVital.peso != null &&
                    signoVital.peso != '' &&
                    signoVital.peso <= 400,
                }"
                v-if="signoVital.peso != null && signoVital.peso != ''"
                >kg</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-human-handsup</v-icon> I.M.C.:
              <span class="bold">{{ signoVital.masaCorporal }}</span>
              <span
                class="bold"
                v-if="
                  signoVital.masaCorporal != null &&
                    signoVital.masaCorporal != ''
                "
                >kg/m2</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-thermometer</v-icon> Temperatura:
              <span
                :class="{
                  lighter:
                    signoVital.temperatura != null &&
                    signoVital.temperatura != '' &&
                    (signoVital.temperatura > 45 || signoVital.temperatura < 5),
                  bold:
                    signoVital.temperatura != null &&
                    signoVital.temperatura != '' &&
                    (signoVital.temperatura <= 45 ||
                      signoVital.temperatura >= 5),
                }"
                >{{ signoVital.temperatura }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.temperatura != null &&
                    signoVital.temperatura != '' &&
                    (signoVital.temperatura > 45 || signoVital.temperatura < 5),
                  bold:
                    signoVital.temperatura != null &&
                    signoVital.temperatura != '' &&
                    (signoVital.temperatura <= 45 ||
                      signoVital.temperatura >= 5),
                }"
                v-if="
                  signoVital.temperatura != null && signoVital.temperatura != ''
                "
                >°C</span
              >
            </h3>
          </v-col>
        </v-row>
        <v-row class="mt-4 mx-1">
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-heart-pulse</v-icon> Frec. Resp.:
              <span
                :class="{
                  lighter:
                    signoVital.frecRespiratoria != null &&
                    signoVital.frecRespiratoria != '' &&
                    signoVital.frecRespiratoria > 100,
                  bold:
                    signoVital.frecRespiratoria != null &&
                    signoVital.frecRespiratoria != '' &&
                    signoVital.frecRespiratoria <= 100,
                }"
                >{{ signoVital.frecRespiratoria }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.frecRespiratoria != null &&
                    signoVital.frecRespiratoria != '' &&
                    signoVital.frecRespiratoria > 100,
                  bold:
                    signoVital.frecRespiratoria != null &&
                    signoVital.frecRespiratoria != '' &&
                    signoVital.frecRespiratoria <= 100,
                }"
                v-if="
                  signoVital.frecRespiratoria != null &&
                    signoVital.frecRespiratoria != ''
                "
                >r/m</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-pulse</v-icon> Sistólica:
              <span
                :class="{
                  lighter:
                    signoVital.sistolica != null &&
                    signoVital.sistolica != '' &&
                    signoVital.sistolica > 300,
                  bold:
                    signoVital.sistolica != null &&
                    signoVital.sistolica != '' &&
                    signoVital.sistolica <= 300,
                }"
                >{{ signoVital.sistolica }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.sistolica != null &&
                    signoVital.sistolica != '' &&
                    signoVital.sistolica > 300,
                  bold:
                    signoVital.sistolica != null &&
                    signoVital.sistolica != '' &&
                    signoVital.sistolica <= 300,
                }"
                v-if="
                  signoVital.sistolica != null && signoVital.sistolica != ''
                "
                >mmHg</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-pulse</v-icon> Diastólica:
              <span
                :class="{
                  lighter:
                    signoVital.diastolica != null &&
                    signoVital.diastolica != '' &&
                    signoVital.diastolica > 300,
                  bold:
                    signoVital.diastolica != null &&
                    signoVital.diastolica != '' &&
                    signoVital.diastolica <= 300,
                }"
                >{{ signoVital.diastolica }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.diastolica != null &&
                    signoVital.diastolica != '' &&
                    signoVital.diastolica > 300,
                  bold:
                    signoVital.diastolica != null &&
                    signoVital.diastolica != '' &&
                    signoVital.diastolica <= 300,
                }"
                v-if="
                  signoVital.diastolica != null && signoVital.diastolica != ''
                "
                >mmHg</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-heart</v-icon> Frec. Cardíaca:
              <span
                :class="{
                  lighter:
                    signoVital.frecCardiaca != null &&
                    signoVital.frecCardiaca != '' &&
                    signoVital.frecCardiaca > 300,
                  bold:
                    signoVital.frecCardiaca != null &&
                    signoVital.frecCardiaca != '' &&
                    signoVital.frecCardiaca <= 300,
                }"
                >{{ signoVital.frecCardiaca }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.frecCardiaca != null &&
                    signoVital.frecCardiaca != '' &&
                    signoVital.frecCardiaca > 300,
                  bold:
                    signoVital.frecCardiaca != null &&
                    signoVital.frecCardiaca != '' &&
                    signoVital.frecCardiaca <= 300,
                }"
                v-if="
                  signoVital.frecCardiaca != null &&
                    signoVital.frecCardiaca != ''
                "
                >lpm</span
              >
            </h3>
          </v-col>
        </v-row>
        <v-row class="mt-4 mx-1">
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-human</v-icon> % Grasa Corp.:
              <span
                :class="{
                  lighter:
                    signoVital.porcGrasaCorporal != null &&
                    signoVital.porcGrasaCorporal != '' &&
                    signoVital.porcGrasaCorporal > 100,
                  bold:
                    signoVital.porcGrasaCorporal != null &&
                    signoVital.porcGrasaCorporal != '' &&
                    signoVital.porcGrasaCorporal <= 100,
                }"
                >{{ signoVital.porcGrasaCorporal }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.porcGrasaCorporal != null &&
                    signoVital.porcGrasaCorporal != '' &&
                    signoVital.porcGrasaCorporal > 100,
                  bold:
                    signoVital.porcGrasaCorporal != null &&
                    signoVital.porcGrasaCorporal != '' &&
                    signoVital.porcGrasaCorporal <= 100,
                }"
                v-if="
                  signoVital.porcGrasaCorporal != null &&
                    signoVital.porcGrasaCorporal != ''
                "
                >%</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-weight-lifter</v-icon> % Masa Musc.:
              <span
                :class="{
                  lighter:
                    signoVital.porcMasaMuscular != null &&
                    signoVital.porcMasaMuscular != '' &&
                    signoVital.porcMasaMuscular > 100,
                  bold:
                    signoVital.porcMasaMuscular != null &&
                    signoVital.porcMasaMuscular != '' &&
                    signoVital.porcMasaMuscular <= 100,
                }"
                >{{ signoVital.porcMasaMuscular }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.porcMasaMuscular != null &&
                    signoVital.porcMasaMuscular != '' &&
                    signoVital.porcMasaMuscular > 100,
                  bold:
                    signoVital.porcMasaMuscular != null &&
                    signoVital.porcMasaMuscular != '' &&
                    signoVital.porcMasaMuscular <= 100,
                }"
                v-if="
                  signoVital.porcMasaMuscular != null &&
                    signoVital.porcMasaMuscular != ''
                "
                >%</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-baby-face</v-icon> Per. Cefálico:
              <span
                :class="{
                  lighter:
                    signoVital.perimCefalico != null &&
                    signoVital.perimCefalico != '' &&
                    signoVital.perimCefalico > 100,
                  bold:
                    signoVital.perimCefalico != null &&
                    signoVital.perimCefalico != '' &&
                    signoVital.perimCefalico <= 100,
                }"
                >{{ signoVital.perimCefalico }}</span
              >
              <span
                :class="{
                  lighter:
                    signoVital.perimCefalico != null &&
                    signoVital.perimCefalico != '' &&
                    signoVital.perimCefalico > 100,
                  bold:
                    signoVital.perimCefalico != null &&
                    signoVital.perimCefalico != '' &&
                    signoVital.perimCefalico <= 100,
                }"
                v-if="
                  signoVital.perimCefalico != null &&
                    signoVital.perimCefalico != ''
                "
                >cm</span
              >
            </h3>
          </v-col>
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-lungs</v-icon> Sat. Oxígeno:
              <span
                :class="{
                  lighter:
                    signoVital.satOxigeno != null &&
                    signoVital.satOxigeno != '' &&
                    signoVital.satOxigeno > 100,
                  bold:
                    signoVital.satOxigeno != null &&
                    signoVital.satOxigeno != '' &&
                    signoVital.satOxigeno <= 100,
                }"
                >{{ signoVital.satOxigeno }}</span
              >
            </h3>
          </v-col>
        </v-row>
        <v-row class="mt-4 mx-1">
          <v-col cols="12" md="3" sm="4">
            <h3>
              <v-icon>mdi-needle</v-icon> Glucemia:
              <span
                :class="{
                  lighter:
                    signoVital.glucemia != null &&
                    signoVital.glucemia != '' &&
                    signoVital.glucemia > 999,
                  bold:
                    signoVital.glucemia != null &&
                    signoVital.glucemia != '' &&
                    signoVital.glucemia <= 999,
                }"
                >{{ signoVital.glucemia }}</span
              >
            </h3>
          </v-col>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="light-blue" dark @click.stop="verGrafico">Gráfico</v-btn>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
    <ImprimirSignosVitalesFecha
      :visible="showImprimirSignosVitalesFecha"
      :signosVitales="signosVitales"
      :paciente="paciente"
      @close="showImprimirSignosVitalesFecha = false"
    ></ImprimirSignosVitalesFecha>
    <GraficosSignosVitales
      :visible="showVerGrafico"
      :signosVitales="signosVitales"
      :paciente="paciente"
      @close="showVerGrafico = false"
    >
    </GraficosSignosVitales>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
.bold {
  color: black !important;
}
.lighter {
  color: red !important;
}
</style>
<script>
import GraficosSignosVitales from "../HistClin/GraficosSignosVitales.vue";
import ImprimirSignosVitalesFecha from "../HistClin/ImprimirSignosVitalesFecha.vue";
export default {
  data: (vm) => ({
    maxWidth: "100%",
    showImprimirSignosVitalesFecha: false,
    showVerGrafico: false,
  }),
  props: ["visible", "signosVitales", "paciente"],
  computed: {
    show: {
      get() {
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
        })
        .catch((error) => {});
    },
    imprimir() {
      this.showImprimirSignosVitalesFecha = true;
    },
    verGrafico() {
      this.showVerGrafico = true;
    },
  },
  components: {
    ImprimirSignosVitalesFecha,
    GraficosSignosVitales,
  },
};
</script>
