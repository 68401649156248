<template>
  <v-checkbox v-model="model">
    <template v-slot:label>
      <div>
        {{ text }}
      </div>
    </template>
  </v-checkbox>
</template>

<script>
export default {
  props: {
    value: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>

<style></style>
