<template>
  <v-dialog v-model="show" fullscreen transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Buscar Paciente
        </v-toolbar-title>
      </v-toolbar>
      <v-alert color="purple" dark dismissible outlined
        >Si aprieta el botón "BUSCAR" sin ingresar nada en los campos de
        apellidos y nombres, traerá todos los pacientes de la
        institución.</v-alert
      >
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="apellidos"
              prepend-icon="mdi-account"
              label="Apellidos"
              maxlength="50"
              dense
              @keypress.enter="buscarPaciente(1)"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="6">
            <v-text-field
              v-model="nombres"
              prepend-icon="mdi-account"
              label="Nombres"
              maxlength="50"
              dense
              @keypress.enter="buscarPaciente(1)"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          color="primary"
          @click.stop="buscarPaciente(1)"
          :disabled="loading"
          :loading="loading"
          >Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaPacientes"
        class="elevation-1"
        locale="es-ar"
        item-key="idPaciente"
        :disable-sort="true"
        :items-per-page="10"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="seleccionarPaciente(item)"
                >mdi-checkbox-marked-circle</v-icon
              >
            </template>
            <span>Seleccionar Paciente</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
export default {
  data: (vm) => ({
    apellidos: null,
    nombres: null,
    headers: [
      {
        text: "Apellidos",
        align: "start",
        filterable: false,
        value: "apellidos",
      },
      { text: "Nombres", value: "nombres" },
      { text: "Documento", value: "nrodocumento" },
      { text: "Seleccionar", value: "actions", sortable: false },
    ],
    listaPacientes: [],
    loading: false,
  }),

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.vaciarModels();
          this.$emit("close");
        }
      },
    },
  },
  props: ["visible"],
  methods: {
    buscarPaciente(state) {
      const pacientesData = {
        apellidos: this.apellidos,
        nombres: this.nombres,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      this.loading = true;
      let self = this;
      axios
        .get("/Paciente/GetPacientesApeNom?", {
          params: {
            apellidos: pacientesData.apellidos,
            nombres: pacientesData.nombres,
            idInstitucion: pacientesData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != "" && response.data != null)
            self.listaPacientes = response.data;
          else {
            self.listaPacientes = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay pacientes que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
          self.loading = false;
        })
        .catch(function(error) {
          self.loading = false;
        });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    vaciarModels() {
      this.listaPacientes = [];
      this.apellidos = [];
      this.nombres = [];
    },
    seleccionarPaciente(item) {
      this.vaciarModels();
      this.$emit("seleccionarPaciente", item);
    },
  },
};
</script>
