<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Signo Vital</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-select
                v-if="origen === 'HC'"
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
              <v-select
                v-else
                :items="enfermerosBuscar"
                item-text="apellidoNombre"
                item-value="idEnfermero"
                menu-props="auto"
                label="Enfermero"
                hide-details
                prepend-icon="mdi-account"
                v-model="enfermero"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-human-male-height"
                v-model="estatura"
                label="Estatura"
                suffix="m"
                @blur="toFixedEstatura"
                @keypress="onlyNumber"
                :rules="signosRules"
                maxlength="6"
              >
                <template
                  v-slot:append-outer
                  v-if="estatura != null && estatura != '' && estatura > 2.6"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-scale"
                v-model="peso"
                label="Peso"
                suffix="kg"
                maxlength="7"
                @blur="toFixedPeso"
                @keypress="onlyNumber"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="peso != null && peso != '' && peso > 400"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-human-handsup"
                v-model="masaCorporal"
                label="I.M.C."
                suffix="kg/m2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-thermometer"
                v-model="temperatura"
                label="Temperatura"
                suffix="°C"
                @blur="toFixedTemperatura"
                @keypress="onlyNumber"
                :rules="signosRules"
                maxlength="4"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    temperatura != null &&
                      temperatura != '' &&
                      (temperatura > 45 || temperatura < 5)
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-heart-pulse"
                v-model="frecRespiratoria"
                label="Frec. Resp."
                suffix="r/m"
                maxlength="4"
                @keypress="onlyNumberWithoutDot"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    frecRespiratoria != null &&
                      frecRespiratoria != '' &&
                      frecRespiratoria > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-pulse"
                v-model="sistolica"
                label="Sistólica"
                maxlength="4"
                suffix="mmHg"
                @keypress="onlyNumberWithoutDot"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="sistolica != null && sistolica != '' && sistolica > 300"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-pulse"
                v-model="diastolica"
                label="Diastólica"
                suffix="mmHg"
                maxlength="4"
                @keypress="onlyNumberWithoutDot"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    diastolica != null && diastolica != '' && diastolica > 300
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-heart"
                v-model="frecCardiaca"
                label="Frec. Cardíaca"
                suffix="lpm"
                maxlength="4"
                @keypress="onlyNumberWithoutDot"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    frecCardiaca != null &&
                      frecCardiaca != '' &&
                      frecCardiaca > 300
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-human"
                v-model="porcGrasaCorp"
                label="% Grasa Corp."
                suffix="%"
                maxlength="6"
                @blur="toFixedPorcGrasaCorp"
                @keypress="onlyNumber"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    porcGrasaCorp != null &&
                      porcGrasaCorp != '' &&
                      porcGrasaCorp > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-weight-lifter"
                v-model="porcMasaMusc"
                label="% Masa Musc."
                suffix="%"
                maxlength="6"
                @blur="toFixedPorcMasaMusc"
                @keypress="onlyNumber"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    porcMasaMusc != null &&
                      porcMasaMusc != '' &&
                      porcMasaMusc > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-baby-face"
                v-model="perimCefalico"
                label="Per. Cefálico"
                suffix="cm"
                maxlength="7"
                @blur="toFixedPerimCefalico"
                @keypress="onlyNumber"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    perimCefalico != null &&
                      perimCefalico != '' &&
                      perimCefalico > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-lungs"
                v-model="satOxigeno"
                label="Sat. Oxígeno"
                maxlength="7"
                @blur="toFixedSatOxigeno"
                @keypress="onlyNumber"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    satOxigeno != null && satOxigeno != '' && satOxigeno > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-needle"
                v-model="glucemia"
                label="Glucemia"
                suffix="mg/dL"
                maxlength="7"
                @keypress="onlyNumberWithoutDot"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="glucemia != null && glucemia != '' && glucemia > 999"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid || !atLeastOne()"
            @click.stop="modificarSignoVital"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    signosRules: [(v) => !v || v > 0 || "El total debe ser mayor a 0"],
    prof: "",
    profsBuscar: [],
    enfermero: null,
    fecha: null,
    fechaHoraAlta: null,
    maxWidth: "100%",
    estatura: null,
    peso: null,
    masaCorporal: null,
    temperatura: null,
    frecRespiratoria: null,
    sistolica: null,
    diastolica: null,
    frecCardiaca: null,
    porcGrasaCorp: null,
    porcMasaMusc: null,
    perimCefalico: null,
    satOxigeno: null,
    glucemia: null,
  }),
  props: {
    visible: {
      type: Boolean,
    },
    idPaciente: {
      type: Number,
    },
    origen: {
      type: String,
      default: "HC",
    },
    idSignoVital: {
      type: Number,
    },
    enfermerosBuscar: {
      type: Array,
    },
  },
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    toFixedEstatura() {
      if (isNaN(parseFloat(this.estatura))) this.estatura = "";
      else this.estatura = parseFloat(this.estatura).toFixed(2);

      this.calcularIMC();
    },
    toFixedPeso() {
      if (isNaN(parseFloat(this.peso))) this.peso = "";
      else this.peso = parseFloat(this.peso).toFixed(2);

      this.calcularIMC();
    },
    calcularIMC() {
      if (
        this.estatura != null &&
        this.estatura != "" &&
        this.peso != null &&
        this.peso != ""
      ) {
        this.masaCorporal = parseFloat(
          this.peso / Math.pow(this.estatura, 2)
        ).toFixed(2);
      } else this.masaCorporal = "";
    },
    toFixedTemperatura() {
      if (isNaN(parseFloat(this.temperatura))) this.temperatura = "";
      else this.temperatura = parseFloat(this.temperatura).toFixed(2);
    },
    toFixedPorcGrasaCorp() {
      if (isNaN(parseFloat(this.porcGrasaCorp))) this.porcGrasaCorp = "";
      else this.porcGrasaCorp = parseFloat(this.porcGrasaCorp).toFixed(2);
    },
    toFixedPorcMasaMusc() {
      if (isNaN(parseFloat(this.porcMasaMusc))) this.porcMasaMusc = "";
      else this.porcMasaMusc = parseFloat(this.porcMasaMusc).toFixed(2);
    },
    toFixedPerimCefalico() {
      if (isNaN(parseFloat(this.perimCefalico))) this.perimCefalico = "";
      else this.perimCefalico = parseFloat(this.perimCefalico).toFixed(2);
    },
    toFixedSatOxigeno() {
      if (isNaN(parseFloat(this.satOxigeno))) this.satOxigeno = "";
      else this.satOxigeno = parseFloat(this.satOxigeno).toFixed(2);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    onlyNumberWithoutDot($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    atLeastOne() {
      if (
        (this.estatura == "" || this.estatura == null) &&
        (this.peso == "" || this.peso == null) &&
        (this.temperatura == "" || this.temperatura == null) &&
        (this.frecRespiratoria == "" || this.frecRespiratoria == null) &&
        (this.sistolica == "" || this.sistolica == null) &&
        (this.diastolica == "" || this.diastolica == null) &&
        (this.frecCardiaca == "" || this.frecCardiaca == null) &&
        (this.porcGrasaCorp == "" || this.porcGrasaCorp == null) &&
        (this.porcMasaMusc == "" || this.porcMasaMusc == null) &&
        (this.perimCefalico == "" || this.perimCefalico == null) &&
        (this.satOxigeno == "" || this.satOxigeno == null) &&
        (this.glucemia == "" || this.glucemia == null)
      )
        return false;
      else return true;
    },
    modificarSignoVital() {
      this.$swal({
        title: "Modificar registro de Signo Vital",
        text: "¿Desea modificar el registro?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosSignoVital = {
            fechaHora: this.fecha,
            fechaHoraAlta: this.fechaHoraAlta,
            idPaciente: this.idPaciente,
            idProfesional: this.prof,
            estatura: this.estatura,
            peso: this.peso,
            masaCorporal: this.masaCorporal,
            temperatura: this.temperatura,
            frecRespiratoria: this.frecRespiratoria,
            sistolica: this.sistolica,
            diastolica: this.diastolica,
            frecCardiaca: this.frecCardiaca,
            porcGrasaCorporal: this.porcGrasaCorp,
            porcMasaMuscular: this.porcMasaMusc,
            perimCefalico: this.perimCefalico,
            satOxigeno: this.satOxigeno,
            glucemia: this.glucemia,
            idSignoVital: this.idSignoVital,
            idEnfermero: this.enfermero,
          };
          let self = this;
          axios
            .post("/HistoriaClinica/ModificarSignoVital", datosSignoVital)
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Registro editado exitosamente",
                vm: self,
              });
              self.$emit("signoVitalModificado");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El registro no pudo ser modificado",
                vm: this,
              });
            });
        }
      });
    },

    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      if (this.origen === "HC") {
        axios
          .get("/Turnos/BuscarProfesionalesAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.profsBuscar = response.data.profesionales;
          });
      }
      axios
        .get("/HistoriaClinica/GetSignoVital?", {
          params: {
            idSignoVital: self.idSignoVital,
          },
        })
        .then((response) => {
          self.fecha = response.data.fechaHora.substring(0, 10);
          self.fechaHoraAlta = response.data.fechaHoraAlta;
          self.prof = response.data.idProfesional;
          self.estatura = response.data.estatura;
          self.peso = response.data.peso;
          self.masaCorporal = response.data.masaCorporal;
          self.temperatura = response.data.temperatura;
          self.frecRespiratoria = response.data.frecRespiratoria;
          self.sistolica = response.data.sistolica;
          self.diastolica = response.data.diastolica;
          self.frecCardiaca = response.data.frecCardiaca;
          self.porcGrasaCorp = response.data.porcGrasaCorporal;
          self.porcMasaMusc = response.data.porcMasaMuscular;
          self.perimCefalico = response.data.perimCefalico;
          self.satOxigeno = response.data.satOxigeno;
          self.glucemia = response.data.glucemia;
          self.enfermero = response.data.idEnfermero;
        })
        .catch((error) => {});
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.estatura = "";
      this.peso = "";
      this.masaCorporal = "";
      this.temperatura = "";
      this.frecRespiratoria = "";
      this.sistolica = "";
      this.diastolica = "";
      this.frecCardiaca = "";
      this.porcGrasaCorp = "";
      this.porcMasaMusc = "";
      this.perimCefalico = "";
      this.satOxigeno = "";
      this.glucemia = "";
      this.enfermero = "";
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>
