<template>
  <v-dialog
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
    max-width="900px"
  >
    <v-form ref="form" v-model="valid">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Relacionar Usuarios</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <h1 style="color: indigo">
                Profesional: {{ nombreProfesional }}
              </h1>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                v-model="usuariosSeleccionados"
                :items="usuarios"
                item-text="usuario"
                item-value="usuario"
                :menu-props="{ maxHeight: '400' }"
                label="Usuarios"
                multiple
                chips
                deletable-chips="true"
                hint="Usuarios Relacionados"
                persistent-hint
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="guardarConfiguracion"
            >Guardar</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
  import axios from "../../axios-auth.js";
  import { minLength, sameAs } from "vuelidate/lib/validators";

  export default {
    name: "RelacionarUsuarios",
    data: (vm) => ({
      usuarios: [],
      usuariosSeleccionados: [],
      valid: false,
    }),

    props: ["visible", "idProfesional", "nombreProfesional"],

    computed: {
      show: {
        get() {
          if (this.visible) {
            this.cargarUsuarios();
            console.log(this.$store.state.usuario.enfermeros);
          }
          return this.visible;
        },
        set(value) {
          if (!value) {
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;

            this.$emit("close");
          }
        },
      },
    },
    methods: {
      guardarConfiguracion() {
        this.$swal({
          title: "Guardar Configuración",
          text: "¿Desea guardar la configuración?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const datosUsuarios = {
              idProfesional: this.idProfesional,
              usuarios: this.usuariosSeleccionados,
            };

            let self = this;

            axios
              .get("/Profesional/ModificarRelaciones?", {
                params: {
                  idProfesional: datosUsuarios.idProfesional,
                  usuarios: datosUsuarios.usuarios,
                },
              })
              .then((response) => {
                document.getElementsByClassName(
                  "v-dialog--active"
                )[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title: "Configuración modificada exitosamente",
                  vm: self,
                });
                self.$emit("profesionalConfigurado");
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La configuración no pudo ser modificada",
                  vm: this,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      },

      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
      cargarUsuarios() {
        this.usuarios = [];
        this.usuariosSeleccionados = [];
        let self = this;
        axios
          .get("/Usuario/BuscarUsuarios?", {
            params: {
              idInstitucion:
                self.$store.state.usuario.institucion.idInstitucion,
              soloActivos: "false",
            },
          })
          .then((response) => {
            if (response.data != null && response.data != "")
              self.usuarios = response.data;
            else self.usuarios = [];
          })
          .catch((error) => {});
        axios
          .get("/Usuario/GetUsuariosPorProfesionalInst", {
            params: {
              idProfesional: self.idProfesional,
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
            },
          })
          .then((response) => {
            self.usuariosSeleccionados = response.data;
          });
      },
    },
  };
</script>
