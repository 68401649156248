<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestion de Cronogramas</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Nuevo Cronograma</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-select
                :items="profs"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                @change="cargarCronogramas"
              ></v-select>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-select
                :items="dias"
                item-text="nombre"
                item-value="idDia"
                menu-props="auto"
                label="Dia"
                :rules="requiredRules"
                hide-details
                prepend-icon="mdi-calendar"
                v-model="dia"
              ></v-select>
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaDesde"
                    label="Hora Desde"
                    prepend-icon="mdi-clock"
                    readonly
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="horaDesde"
                  format="24hs"
                  full-width
                  scrollable
                  @click:minute="$refs.menu.save(horaDesde)"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <v-menu
                ref="menu2"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaHasta"
                    label="Hora Hasta"
                    prepend-icon="mdi-clock"
                    readonly
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="horaHasta"
                  full-width
                  scrollable
                  format="24hs"
                  @click:minute="$refs.menu2.save(horaHasta)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Vigencia Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Vigencia Hasta"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-select
                :items="tiemposTurnos"
                item-text="tiempoNombre"
                item-value="idTiempoTurno"
                :rules="requiredRules"
                menu-props="auto"
                label="Tiempo del Turno"
                hide-details
                prepend-icon="mdi-clock"
                v-model="tiempoTurno"
              ></v-select>
            </v-col>
            <v-col cols="8" md="4" sm="4">
              <v-switch
                dense
                v-model="disponibleWeb"
                label="Disponible en Web"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-select
                :items="tipoTurnos"
                item-text="text"
                item-value="value"
                menu-props="auto"
                :label="tipoTurnoLabel"
                hide-details
                prepend-icon="mdi-account"
                v-model="tipoTurno"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="guardarCronograma"
            >Guardar</v-btn
          >
        </v-card-actions>
      </v-form>
      <v-card-text>
        <v-row dense>
          <v-col cols="12">
            <v-chip label color="transparent" text-color="black" class="ma-0">
              <h3>Lista de Cronogramas del Profesional</h3>
            </v-chip>
          </v-col>

          <v-col cols="12">
            <v-checkbox
              v-model="soloVigentes"
              label="Mostrar en la grilla solo cronogramas vigentes"
              color="primary"
              dense
              @change="cargarCronogramas"
            ></v-checkbox>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="this.listaCronogramas"
          class="elevation-1"
          :hide-default-footer="true"
          locale="es-ar"
          item-key="idCronograma"
          :disable-sort="true"
          :items-per-page="500"
        >
          <template v-slot:item.disponibleWeb="{ item }">
            <v-simple-checkbox
              v-model="item.disponibleWeb"
              disabled
            ></v-simple-checkbox>
          </template>
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="purple"
                  class="mr-3"
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="editarCronograma(item)"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Editar Cronograma</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  color="orange"
                  class="mr-3"
                  v-on="on"
                  v-bind="attrs"
                  @click.stop="verUsuarios(item)"
                  >mdi-account-multiple-check-outline</v-icon
                >
              </template>
              <span>Creado por / Modificado por</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  medium
                  v-on="on"
                  color="red"
                  v-bind="attrs"
                  @click="borrarCronograma(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Eliminar Cronograma</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <EditarCronograma
        :visible="showEditarCronograma"
        :idCronograma="editedItem.idCronograma"
        :idProfesional="prof"
        @close="showEditarCronograma = false"
        @cronogramaModificado="cronogramaModificado"
      ></EditarCronograma>
      <VerUsuarios
        :visible="showVerUsuarios"
        :cronograma="editedItem"
        @close="showVerUsuarios = false"
      ></VerUsuarios>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../../axios-auth.js";
import router from "../../../router/index.js";
import EditarCronograma from "../Configuraciones/EditarCronograma.vue";
import VerUsuarios from "./VerUsuarios.vue";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    prof: "",
    profs: [],
    listaCronogramas: [],
    headers: [
      { text: "Dia", value: "nombreDia" },
      { text: "Hora Desde", value: "horaInicioString" },
      { text: "Hora Hasta", value: "horaFinString" },
      { text: "Vigencia Desde", value: "fechaInicioString" },
      { text: "Vigencia Hasta", value: "fechaFinString" },
      { text: "Tiempo Turno", value: "tiempoTurnoNombre" },
      { text: "Disponible Web", value: "disponibleWeb" },
      { text: "Tipo Turno Predeterminado", value: "nombreTipoTurno" },
      { text: "Acciones", value: "actions" },
    ],
    tipoTurnos: [
      { value: false, text: "Presencial" },
      { value: true, text: "Videoconsulta" },
    ],
    tipoTurno: false,
    soloVigentes: true,
    menu2: false,
    menu3: false,
    modal2: false,
    modal3: false,
    horaDesde: null,
    horaHasta: null,
    fechaDesde: null,
    fechaHasta: null,
    menu: false,
    dia: "",
    dias: [],
    tiempoTurno: "",
    tiemposTurnos: [],
    disponibleWeb: false,
    time: null,
    editedItem: {},
    editedIndex: -1,
    showEditarCronograma: false,
    showVerUsuarios: false,
  }),
  created() {
    this.profs = this.$store.state.usuario.profesionales;
    this.prof = this.profs[0].idProfesional;
    this.cargarDias();
    this.cargarTiemposTurnos();
    this.soloVigentes = true;
    this.cargarCronogramas();
  },
  mounted() {
    document.getElementsByClassName("container")[0].scrollTop = 0;
  },
  computed: {
    tipoTurnoLabel() {
      return this.disponibleWeb
        ? "Tipo Turno Web"
        : "Tipo Turno Predeterminado";
    },
  },
  methods: {
    scrollToTop() {
      window.scrollTo(0, 0);
    },
    verUsuarios(item) {
      this.editedIndex = this.listaCronogramas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerUsuarios = true;
    },
    cargarCronogramas() {
      const datosCronograma = {
        soloVigentes: this.soloVigentes,
        idProfesional: this.prof,
      };
      let self = this;
      axios
        .get("/Turnos/ConsultarCronogramas?", {
          params: {
            soloVigentes: datosCronograma.soloVigentes,
            idProfesional: datosCronograma.idProfesional,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaCronogramas = response.data;
          else self.listaCronogramas = [];
        })
        .catch((error) => {});
    },
    editarCronograma(item) {
      this.editedIndex = this.listaCronogramas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarCronograma = true;
    },
    cargarDias() {
      let self = this;
      axios
        .get("/Turnos/GetDias")
        .then((response) => {
          self.dias = response.data;
        })
        .catch((error) => {});
    },
    cargarTiemposTurnos() {
      let self = this;
      axios
        .get("/Turnos/GetTiemposTurnos")
        .then((response) => {
          self.tiemposTurnos = response.data;
        })
        .catch((error) => {});
    },
    salir() {
      router.push({ path: "/Home" });
    },
    validarFecha(fecha) {
      if (fecha.toString().length > 10) return false;
      else return true;
    },
    guardarCronograma() {
      this.$swal({
        title: "Guardar Cronograma",
        text: "¿Desea registrar el cronograma?",
        icon: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          let isValidDate = this.validarFecha(this.fechaDesde);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha desde no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          isValidDate = this.validarFecha(this.fechaHasta);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha hasta no tiene un formato válido.",
              vm: this,
            });
            return;
          }

          let self = this;
          const datosCronograma = {
            idProfesional: this.prof,
            idDia: this.dia,
            idTiempoTurno: this.tiempoTurno,
            idConsultorio: 0,
            disponibleWeb: this.disponibleWeb,
            fechaInicio: this.fechaDesde,
            fechaFin: this.fechaHasta,
            horaInicioCompleta: this.horaDesde
              .replace(":", "")
              .trim()
              .replace(" ", ""),
            horaFinCompleta: this.horaHasta
              .replace(":", "")
              .trim()
              .replace(" ", ""),
            horaInicio: this.horaDesde
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(0, 2)
              .replace(" ", ""),
            horaFin: this.horaHasta
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(0, 2)
              .replace(" ", ""),
            minutoInicio: this.horaDesde
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(2, 4)
              .replace(" ", ""),
            minutoFin: this.horaHasta
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(2, 4)
              .replace(" ", ""),
          };
          if (
            datosCronograma.horaInicioCompleta > datosCronograma.horaFinCompleta
          ) {
            self.showAlert({
              icon: "error",
              title: "La hora desde no puede ser mayor que la hora hasta",
              vm: this,
            });
            return;
          }
          if (datosCronograma.fechaInicio > datosCronograma.fechaFin) {
            self.showAlert({
              icon: "error",
              title: "La fecha desde no puede ser mayor que la fecha hasta",
              vm: this,
            });
            return;
          }
          axios
            .get("/Turnos/ChequearUnicidadCronograma", {
              params: {
                idProfesional: datosCronograma.idProfesional,
                idDia: datosCronograma.idDia,
                fechaInicio: datosCronograma.fechaInicio,
                fechaFin: datosCronograma.fechaFin,
                horaInicioCompleta: datosCronograma.horaInicioCompleta,
                horaFinCompleta: datosCronograma.horaFinCompleta,
              },
            })
            .then((response) => {
              if (response.data != "") {
                self.showAlert({
                  icon: "error",
                  title: "Hay un cronograma solapado para el profesional",
                  vm: this,
                });
              } else {
                self.grabarCronograma();
              }
            });
        }
      });
    },
    grabarCronograma() {
      let self = this;
      const tipoTurno = this.tipoTurno;
      const datosCronograma = {
        idProfesional: this.prof,
        idDia: this.dia,
        idTiempoTurno: this.tiempoTurno,
        idConsultorio: 0,
        disponibleWeb: this.disponibleWeb,
        fechaInicioString: this.fechaDesde,
        fechaFinString: this.fechaHasta,
        horaInicioCompleta: this.horaDesde
          .replace(":", "")
          .trim()
          .replace(" ", ""),
        horaFinCompleta: this.horaHasta
          .replace(":", "")
          .trim()
          .replace(" ", ""),
        horaInicio: this.horaDesde
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(0, 2)
          .replace(" ", ""),
        horaFin: this.horaHasta
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(0, 2)
          .replace(" ", ""),
        minutoInicio: this.horaDesde
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(2, 4)
          .replace(" ", ""),
        minutoFin: this.horaHasta
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(2, 4)
          .replace(" ", ""),
        tipoTurno: tipoTurno,
        usuarioAlta: this.$store.state.usuario.usuario,
      };

      axios
        .post("/Turnos/GuardarCronograma", datosCronograma)
        .then((response) => {
          self.$refs.form.resetValidation();

          self.showAlert({
            icon: "success",
            title: "Cronograma Registrado",
            vm: self,
          });
          self.cargarCronogramas();
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title: "El cronograma no pudo ser registrado",
            vm: this,
          });
        });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarCronograma(item) {
      this.$swal({
        title: "Borrar Cronograma",
        text: "¿Desea borrar el cronograma?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idCronograma = item.idCronograma;
          const datosCronograma = {
            idProfesional: this.prof,
            idDia: item.idDia,
            idTiempoTurno: item.idTiempoTurno,
            idConsultorio: 0,
            disponibleWeb: item.disponibleWeb,
            fechaInicioString: item.fechaInicioString,
            fechaFinString: item.fechaFinString,
            horaInicioCompleta: item.horaInicioString
              .replace(":", "")
              .trim()
              .replace(" ", ""),
            horaFinCompleta: item.horaFinString
              .replace(":", "")
              .trim()
              .replace(" ", ""),
          };
          let self = this;
          axios
            .get("/Turnos/ChequearExistenciaTurnosCronograma", {
              params: {
                idProfesional: datosCronograma.idProfesional,
                idDia: datosCronograma.idDia,
                fechaInicio: datosCronograma.fechaInicioString,
                fechaFin: datosCronograma.fechaFinString,
                horaInicioCompleta: datosCronograma.horaInicioCompleta,
                horaFinCompleta: datosCronograma.horaFinCompleta,
              },
            })
            .then((response) => {
              if (response.data != "") {
                self.showAlert({
                  icon: "error",
                  title:
                    "Hay turnos que caen dentro del cronograma que quiere borrar",
                  vm: this,
                });
              } else {
                axios
                  .get("/Turnos/BorrarCronograma?", {
                    params: {
                      idCronograma: idCronograma,
                    },
                  })
                  .then((response) => {
                    self.showAlert({
                      icon: "success",
                      title: "Cronograma borrado",
                      vm: self,
                    });
                    setTimeout(self.cargarCronogramas(), 1700);
                  })
                  .catch((error) => {
                    self.showAlert({
                      icon: "error",
                      title: "El cronograma no pudo ser borrado",
                      vm: this,
                    });
                  });
              }
            });
        }
      });
    },
    cronogramaModificado() {
      this.cargarCronogramas();
      setTimeout(this.cerrarVentanaModificar, 600);
    },
    cerrarVentanaModificar() {
      this.showEditarCronograma = false;
    },
  },
  components: {
    EditarCronograma,
    VerUsuarios,
  },
};
</script>
