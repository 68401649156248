<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Traslado</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" md="3">
              <v-select
                :items="choferesBuscar"
                item-text="apellidoNombreChofer"
                item-value="idChofer"
                menu-props="auto"
                label="Chofer"
                hide-details
                prepend-icon="mdi-account"
                v-model="chofer"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="7" md="3">
              <v-select
                :items="enfermerosBuscar"
                item-text="apellidoNombre"
                item-value="idEnfermero"
                menu-props="auto"
                label="Enfermero"
                hide-details
                prepend-icon="mdi-account"
                v-model="enfermero"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="fechaSalida"
                :value="fechaSalida"
                label="Fecha Salida"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-menu
                ref="menu2"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaSalida"
                    label="Hora Salida"
                    prepend-icon="mdi-clock"
                    readonly
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="horaSalida"
                  full-width
                  scrollable
                  format="24hs"
                  @click:minute="$refs.menu2.save(horaSalida)"
                ></v-time-picker>
              </v-menu>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="fechaLlegada"
                :value="fechaLlegada"
                label="Fecha Llegada"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-menu
                ref="menu3"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time2"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaLlegada"
                    label="Hora Llegada"
                    prepend-icon="mdi-clock"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="horaLlegada"
                  full-width
                  scrollable
                  format="24hs"
                  @click:minute="$refs.menu3.save(horaLlegada)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                v-model="paciente"
                prepend-icon="mdi-account"
                :rules="requiredRules"
                label="Paciente"
                dense
                @change="idPaciente = 0"
                maxlength="150"
                hint="Puede ingresar el paciente a texto libre o buscar un paciente existente apretando la lupa"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn color="primary" @click="buscarPaciente">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="dni"
                prepend-icon="mdi-account"
                label="D.N.I."
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="diagnostico"
                prepend-icon="mdi-account"
                label="Diagnóstico"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="lugarTraslado"
                prepend-icon="mdi-account"
                label="Lugar de Traslado"
                :rules="requiredRules"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="observaciones"
                prepend-icon="mdi-account"
                label="Observaciones"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="modificarTraslado"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
      <BuscarPacienteTurno
        :visible="showBuscarPaciente"
        @close="showBuscarPaciente = false"
        @seleccionarPaciente="seleccionarPaciente($event)"
      ></BuscarPacienteTurno>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import BuscarPacienteTurno from "../Turnos/BuscarPacienteTurno.vue";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    enfermero: null,
    enfermerosBuscar: [],
    chofer: null,
    choferesBuscar: [],
    fechaSalida: null,
    horaSalida: null,
    fechaLlegada: null,
    horaLlegada: null,
    observaciones: "",
    diagnostico: "",
    lugarTraslado: "",
    idPaciente: 0,
    showBuscarPaciente: "",
    paciente: "",
    dni: "",
    menu2: null,
    menu3: null,
    time: null,
    time2: null,
  }),
  props: ["visible", "idTraslado"],
  components: { BuscarPacienteTurno },
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    seleccionarPaciente(item) {
      (this.idPaciente = item.idpaciente),
        (this.paciente = item.apellidos + ", " + item.nombres);
      this.showBuscarPaciente = false;
    },
    buscarPaciente() {
      this.showBuscarPaciente = true;
    },
    validarFecha(fecha) {
      if (fecha === null || fecha === "") return true;
      else if (fecha.toString().length === 10) return true;
      else return false;
    },
    modificarTraslado() {
      this.$swal({
        title: "Modificar Traslado",
        text: "¿Desea modificar los datos del traslado?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          let isValidDate = this.validarFecha(this.fechaSalida);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha de salida no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          isValidDate = this.validarFecha(this.fechaLlegada);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha de llegada no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          const datosTraslado = {
            idTraslado: this.idTraslado,
            idChofer: this.chofer,
            idEnfermero: this.enfermero,
            fechaSalida: this.fechaSalida,
            fechaLlegada: this.fechaLlegada,
            idPaciente: this.idPaciente,
            paciente: this.paciente,
            dni: this.dni,
            lugarTraslado: this.lugarTraslado,
            diagnostico: this.diagnostico,
            observaciones: this.observaciones,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            horaSalida: parseInt(
              this.horaSalida
                .replace(":", "")
                .trim()
                .replace(" ", "")
            ),
            horaLlegada: this.horaLlegada
              ? parseInt(
                  this.horaLlegada
                    .replace(":", "")
                    .trim()
                    .replace(" ", "")
                )
              : -1,
          };
          let self = this;

          axios
            .post("/Traslados/ModificarTraslado", datosTraslado)
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Traslado modificado exitosamente",
                vm: self,
              });
              self.$emit("trasladoModificado");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El traslado no pudo ser modificado",
                vm: this,
              });
            });
        }
      });
    },

    async setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Enfermeria/BuscarEnfermerosAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.enfermerosBuscar = response.data;
        });
      axios
        .get("/Traslados/BuscarChoferes?", {
          params: {
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.choferesBuscar = response.data;
        });
      await this.buscarDatosTraslado();
    },
    async buscarDatosTraslado() {
      let self = this;
      const idTraslado = this.idTraslado;
      axios
        .get("/Traslados/BuscarDatosTraslado?", {
          params: {
            idTraslado: idTraslado,
          },
        })
        .then((response) => {
          self.chofer = response.data.idChofer;
          self.enfermero = response.data.idEnfermero;
          self.fechaSalida = response.data.fechaSalida.substring(0, 10);
          self.horaSalida = response.data.horaSalidaString;
          self.fechaLlegada = response.data.fechaLlegada.substring(0, 10);
          self.horaLlegada = response.data.horaLlegadaString;
          self.idPaciente = response.data.idPaciente;
          self.paciente = response.data.paciente;
          self.dni = response.data.dni;
          self.lugarTraslado = response.data.lugarTraslado;
          self.diagnostico = response.data.diagnostico;
          self.observaciones = response.data.observaciones;
        })
        .catch((error) => {});
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.chofer = null;
      this.enfermero = null;
      this.fechaSalida = null;
      this.fechaLlegada = null;
      this.horaSalida = null;
      this.horaLlegada = null;
      this.idPaciente = 0;
      this.paciente = "";
      this.dni = "";
      this.observaciones = "";
      this.diagnostico = "";
      this.lugarTraslado = "";
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>
