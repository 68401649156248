<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Enviar Archivo</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert
            color="blue"
            dark
            outlined
            v-if="paciente.mail == '' || paciente.mail == null"
            >El paciente no tiene registrado un mail en su ficha, puede
            introducir uno a continuación.</v-alert
          >
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="email"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="enviarMail"
            >Enviar Mail</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="9" md="9">
              <h3>
                El código que se enviará al paciente para que pueda visualizar
                el archivo es: {{ codInforme }}
              </h3>
            </v-col>
            <v-col cols="12" sm="9" md="9">
              <h3>
                Puede utilizar este código y enviarseló al paciente mediante
                otra vía de comunicación
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "100%",
    email: "",
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
      (v) => !!v || "Dato obligatorio",
    ],
  }),
  props: ["visible", "codInforme", "paciente"],
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    setearModels() {
      this.email = this.paciente.mail;
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },
    enviarMail() {
      this.$swal({
        title: "Enviar email con código de archivo al paciente",
        text:
          "¿Desea enviar un correo al paciente con el código para visualizar el archivo?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datos = {
            codInforme: this.codInforme,
            email: this.email,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            idPaciente: this.paciente.idPaciente,
          };

          let self = this;
          axios
            .get("/HistoriaClinica/EnviarInforme?", {
              params: {
                codInforme: datos.codInforme,
                email: datos.email,
                idInstitucion: datos.idInstitucion,
                idPaciente: datos.idPaciente,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title:
                  "Se envió un mail a la casilla de correo indicada para que el paciente pueda visualizar el archivo desde el Portal del Paciente.",
                vm: self,
                timer: 4200,
              });
              self.show = false;
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title:
                  "El correo no pudo ser enviado. Intente mas tarde por favor.",
                vm: this,
                timer: 3000,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
  },
};
</script>
