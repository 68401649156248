<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Imprimir Evoluciones</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-radio-group v-model="radios">
          <template v-slot:label>
            <div><strong>Elegir fecha de impresión</strong></div>
          </template>
          <v-radio value="allDates" class="mt-4 mb-3">
            <template v-slot:label>
              <div>
                <strong class="success--text">Todas las fechas</strong>
              </div>
            </template>
          </v-radio>
          <v-radio value="rangeDates">
            <template v-slot:label>
              <div>
                <strong class="primary--text">Entre las fechas</strong>
              </div>
            </template>
          </v-radio>
        </v-radio-group>
        <v-col cols="12" sm="4" md="6" class="mt-0 pt-0">
          <v-text-field
            v-model="fechaDesde"
            :value="fechaDesde"
            label="Fecha Desde"
            :rules="requiredRules"
            prepend-icon="mdi-calendar"
            type="date"
            dense
            :disabled="radios == 'allDates'"
          ></v-text-field>
        </v-col>
        <v-col cols="12" sm="4" md="6">
          <v-text-field
            v-model="fechaHasta"
            :value="fechaHasta"
            :rules="requiredRules"
            label="Fecha Hasta"
            prepend-icon="mdi-calendar"
            type="date"
            dense
            :disabled="radios == 'allDates'"
          ></v-text-field>
        </v-col>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
export default {
  data: (vm) => ({
    base64: "",
    fechaDesde: null,
    fechaHasta: null,
    allDates: true,
    rangeDates: false,
    radios: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
  }),
  props: ["visible", "evoluciones", "paciente"],
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
          this.getLogoUrl();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
        })
        .catch((error) => {});
    },
    setearModels() {
      var d = new Date();
      var desde = new Date(d.setMonth(d.getMonth() - 1));

      this.fechaDesde = this.parseDate(this.formatDate(this.toISOLocal(desde)));
      this.fechaHasta = this.parseDate(
        this.formatDate(this.toISOLocal(new Date()))
      );
      this.radios = "allDates";
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2500,
      });
    },
    imprimir() {
      if (new Date(this.fechaDesde) > new Date(this.fechaHasta)) {
        this.showAlert({
          icon: "error",
          title: "La fecha desde no puede ser mayor a la fecha hasta",
          vm: this,
        });
        return;
      }

      var doc = new jsPDF();

      doc.setProperties({
        title: "Evolucion",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion =
        this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Paciente: " + this.paciente.apellidos + " " + this.paciente.nombres,
        13,
        25
      );
      doc.text("D.N.I.: " + this.paciente.dni, 13, 30);
      doc.text("Feche de Nac.: " + this.paciente.fechaNac, 13, 35);
      doc.text("Edad: " + this.paciente.edad, 13, 40);

      if (this.base64 != null && this.base64 != "")
        doc.addImage(this.base64, 140, 5, 60, 20);

      doc.setFontSize(16);
      doc.text("Evoluciones", doc.internal.pageSize.getWidth() / 2, 50, {
        align: "center",
      });
      doc.line(90, 51, 120, 51);
      let y = 63;

      var evolucionesFecha = [];
      if (this.radios == "allDates") evolucionesFecha = this.evoluciones;
      else {
        evolucionesFecha = this.evoluciones.filter(
          (x) =>
            new Date(x.fechaHora) >= new Date(this.fechaDesde) &&
            new Date(x.fechaHora) <=
              new Date(this.fechaHasta).setDate(
                new Date(this.fechaHasta).getDate() + 1
              )
        );
      }
      if (evolucionesFecha.length == 0) {
        this.showAlert({
          icon: "error",
          title: "No hay evoluciones en el rango de fecha elegido",
          vm: this,
        });
        return;
      }

      evolucionesFecha.forEach((evolucion) => {
        if (y + 13 >= 290) {
          doc.addPage();
          y = 20; // Restart height position
        }

        doc.setFontSize(13);
        doc.line(13, y, 200, y);
        y = y + 5;
        if (y >= 290) {
          doc.addPage();
          y = 20; // Restart height position
        }
        doc.text(
          "Profesional: " +
            evolucion.matricula +
            " - " +
            evolucion.nomProfesional,
          13,
          y
        );

        y = y + 6;
        if (y >= 290) {
          doc.addPage();
          y = 20; // Restart height position
        }

        doc.text("Fecha: " + evolucion.fechaHoraString, 13, y);
        doc.line(13, y + 2, 200, y + 2);
        y = y + 8;
        if (y >= 290) {
          doc.addPage();
          y = 20; // Restart height position
        }

        doc.setFontSize(12);
        var textoSplit = evolucion.texto.split(/\r\n|\r|\n/);

        for (var i = 0; i < textoSplit.length; i++) {
          if (textoSplit[i].length < 85) {
            doc.text(textoSplit[i], 13, y);
          } else {
            var lineSplit = textoSplit[i].split(" ");
            var linePrint = "";
            var lineTemp = "";
            for (var j = 0; j < lineSplit.length; j++) {
              if (linePrint.length >= 85) {
                if (j == lineSplit.length - 1)
                  doc.text(lineSplit[j], 13, y + 5);

                lineTemp = linePrint + lineSplit[j];
                if (lineTemp.length < 85) {
                  doc.text(lineTemp, 13, y);
                  linePrint = "";
                } else {
                  doc.text(linePrint, 13, y);
                  linePrint = lineSplit[j] + " ";
                }
                y = y + 5;

                if (y > 290) {
                  y = 20;
                  doc.addPage();
                }
              } else {
                linePrint = linePrint + lineSplit[j] + " ";
                if (j == lineSplit.length - 1) {
                  doc.text(linePrint, 13, y);
                  if (y > 290) {
                    y = 20;
                    doc.addPage();
                  }
                }
              }
            }
          }
          y = y + 5;
          if (y > 290) {
            y = 20;
            doc.addPage();
          }
        }

        y = y + 7;
        //   if (y >= 290) {
        //   doc.addPage();
        //   y = 20; // Restart height position

        // }
      });
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
};
</script>
