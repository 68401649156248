<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="showed"
    :fullscreen="fullscreen"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="showed = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Ver Foto</v-toolbar-title>
    </v-toolbar>

    <div class="divClass">
      <div
        style="height: 100%"
        class="images"
        v-viewer.rebuild="{ inline: true }"
      >
        <img class="image" v-for="src in images" :src="src" :key="src" />
      </div>
    </div>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-dialog {
  height: 104% !important;
}
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
.divClass {
  height: 90.2%;
  width: 100%;
  background-color: white;
  position: relative;
}
</style>
<style lang="scss" scoped>
.image {
  display: none;
}
</style>
<style>
.viewer-loading > img {
  display: none; /* hide big images when it is loading */
}
</style>
<script>
export default {
  data: (vm) => ({
    maxWidth: "100%",
    fullscreen: true,
    images: [],
    height: "100%",
    loading: false,
  }),
  props: ["visible", "foto"],
  computed: {
    showed: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.vaciarModels();
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    show() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    setearModels() {
      this.loading = true;
      if (this.$store.state.videollamada == true) {
        this.fullscreen = false;
        this.maxWidth = "68%";
        this.height = "500px";
      } else {
        this.maxWidth = "100%";
        this.fullscreen = true;
        this.height = "100%";
      }
      this.images.push(this.foto);

      this.loading = false;
    },
    vaciarModels() {
      this.images = [];
    },
  },
};
</script>
