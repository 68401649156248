<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Nuevo Registro de Vacunación</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="enfermerosBuscar"
                item-text="apellidoNombre"
                item-value="idEnfermero"
                menu-props="auto"
                label="Enfermero"
                hide-details
                prepend-icon="mdi-account"
                v-model="enfermero"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="vacunas"
                item-text="nombre"
                item-value="idVacuna"
                menu-props="auto"
                label="Vacuna"
                hide-details
                prepend-icon="mdi-account"
                v-model="vacuna"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="edadesVacuna"
                item-text="nombre"
                item-value="idEdadVacuna"
                menu-props="auto"
                label="Edad"
                hide-details
                prepend-icon="mdi-account"
                v-model="edadVacuna"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="tiposDosis"
                item-text="nombre"
                item-value="idTipoDosis"
                menu-props="auto"
                label="Tipo de Dosis"
                hide-details
                prepend-icon="mdi-account"
                v-model="tipoDosis"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <v-text-field
                v-model="marca"
                prepend-icon="mdi-account"
                label="Marca"
                maxlength="100"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-text-field
                v-model="lote"
                prepend-icon="mdi-account"
                label="Lote"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-text-field
                v-model="fechaVencimiento"
                :value="fechaVencimiento"
                label="Fecha Vencimiento"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea
                prepend-icon="mdi-account"
                v-model="observaciones"
                label="Observaciones"
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="guardarVacunacion"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    enfermero: "",
    enfermerosBuscar: [],
    vacunas: [],
    vacuna: null,
    edadesVacuna: [],
    edadVacuna: null,
    tiposDosis: [],
    tipoDosis: null,
    marca: "",
    lote: "",
    fechaVencimiento: null,
    observaciones: "",
    fecha: null,
    maxWidth: "100%",
  }),
  props: ["visible", "idPaciente"],
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    validarFecha(fecha) {
      if (fecha === null || fecha === "") return true;
      else if (fecha.toString().length === 10) return true;
      else return false;
    },
    guardarVacunacion() {
      this.$swal({
        title: "Registrar Vacunación",
        text: "¿Desea registrar la vacunación?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          let isValidDate = this.validarFecha(this.fecha);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha de la vacunación no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          isValidDate = this.validarFecha(this.fechaVencimiento);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title:
                "La fecha de vencimiento de la vacuna no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          const datosVacunacion = {
            fecha: this.fecha,
            fechaHoraAlta: this.fecha,
            idPaciente: this.idPaciente,
            idEnfermero: this.enfermero,
            idEdadVacuna: this.edadVacuna,
            idTipoDosis: this.tipoDosis,
            idVacuna: this.vacuna,
            marca: this.marca,
            lote: this.lote,
            fechaVencimiento: this.fechaVencimiento,
            fechaVencimientoString: this.fechaVencimiento,
            observaciones: this.observaciones,
          };
          let self = this;

          axios
            .post("/Enfermeria/GuardarVacunacion", datosVacunacion)
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Vacunación registrada exitosamente",
                vm: self,
              });
              self.$emit("vacunacionRegistrada");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La vacunación no pudo ser registrada",
                vm: this,
              });
            });
        }
      });
    },

    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Enfermeria/BuscarEnfermerosAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.enfermerosBuscar = response.data;

          self.enfermero = self.enfermerosBuscar[0].idEnfermero;
        });

      this.fecha = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
      this.buscarVacunas();
      this.buscarEdadesVacuna();
      this.buscarTiposDosis();
    },
    buscarVacunas() {
      let self = this;
      axios.get("/Enfermeria/ObtenerVacunas").then((response) => {
        self.vacunas = response.data;
        self.vacuna = self.vacunas[0].idVacuna;
      });
    },
    buscarEdadesVacuna() {
      let self = this;
      axios.get("/Enfermeria/GetEdadesVacuna").then((response) => {
        self.edadesVacuna = response.data;
        self.edadVacuna = self.edadesVacuna[0].idEdadVacuna;
      });
    },
    buscarTiposDosis() {
      let self = this;
      axios.get("/Enfermeria/GetTiposDosis").then((response) => {
        self.tiposDosis = response.data;
        self.tipoDosis = self.tiposDosis[0].idTipoDosis;
      });
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.marca = "";
      this.lote = "";
      this.fechaVencimiento = null;
      this.observaciones = "";
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>
