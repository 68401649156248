import jsPDF from "jspdf";

export default function imprimirReceta(
  institucion,
  paciente,
  receta,
  base64,
  output
) {
  var doc = new jsPDF();

  doc.setProperties({
    title: "Receta",
  });
  //doc.text('Listado de Turnos',20,10);
  var nombreInstitucion = institucion.nombreInstitucion.toString().trim();

  doc.setFontSize(11);
  doc.text(nombreInstitucion, 13, 10);
  doc.text(
    "Dirección: " + institucion.direccion.toString().replace(", Argentina", ""),
    13,
    15
  );

  if (
    institucion.telefono != null &&
    institucion.telefono != "" &&
    institucion.celular != null &&
    institucion.celular != ""
  )
    doc.text(
      "Tel / Cel: " + institucion.telefono + " - " + institucion.celular,
      13,
      20
    );
  if (
    institucion.telefono != null &&
    institucion.telefono != "" &&
    (institucion.celular == null || institucion.celular == "")
  )
    doc.text("Tel: " + institucion.telefono, 13, 20);
  if (
    (institucion.telefono == null || institucion.telefono == "") &&
    institucion.celular != null &&
    institucion.celular != ""
  )
    doc.text("Cel: " + institucion.celular, 13, 20);
  if (institucion.mail != "" && institucion.mail != null)
    doc.text("Mail: " + institucion.mail, 13, 25);
  doc.setFontSize(11);
  doc.text("Paciente: " + paciente.apellidos + " " + paciente.nombres, 13, 40);
  doc.text("D.N.I.: " + paciente.dni, 13, 45);
  doc.text("Obra Social: " + receta.obraSocial, 13, 50);
  doc.text("Nº de Afiliado: " + receta.nroAfiliado, 13, 55);
  doc.setFontSize(13);
  doc.line(13, 59, 200, 59);
  doc.text("R.p.", 13, 66);

  doc.setFontSize(12);

  if (base64 != null && base64 != "") {
    console.log(institucion);
    if (institucion.idInstitucion === 195) {
      doc.addImage(base64, 175, 7, 25, 25);
    } else {
      doc.addImage(base64, 140, 7, 60, 20);
    }
  }

  var textoSplit = receta.texto.split(/\r\n|\r|\n/);
  let y = 77;
  for (var i = 0; i < textoSplit.length; i++) {
    doc.text(textoSplit[i], 25, y);
    y = y + 5;
    if (y > 290) {
      y = 20;
      doc.addPage();
    }
  }

  if (y > 290) {
    y = 20;
    doc.addPage();
  }
  doc.line(13, y, 200, y);
  y = y + 8;
  if (y > 290) {
    y = 20;
    doc.addPage();
  }
  doc.setFontSize(11);
  doc.text("Fecha: " + receta.fechaRecetaString, 13, y);
  y = y + 5;
  if (y > 290) {
    y = 20;
    doc.addPage();
  }
  doc.text("Diagnóstico: " + receta.diagnostico, 13, y);

  if (output === "screen") {
    window.open(doc.output("bloburl"), "_blank");
  } else {
    return doc.output("blob");
  }
}
