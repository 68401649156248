<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Diagnóstico</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-combobox
                :items="patologias"
                v-model="patologia"
                item-text="nombrePatologia"
                item-value="idPatologia"
                label="Patología"
                :rules="requiredRules"
                dense
                prepend-icon="mdi-heart-pulse"
              ></v-combobox>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea
                prepend-icon="mdi-hospital"
                v-model="observaciones"
                label="Observaciones"
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="modificarDiagnostico"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "100%",
    requiredRules: [(v) => !!v || "Dato obligatorio"],

    prof: "",
    profsBuscar: [],
    fecha: null,
    patologia: null,
    patologias: [],
    observaciones: "",
  }),
  props: ["visible", "idPaciente", "idDiagnosticoHc"],
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    buscarPatologias() {
      let self = this;
      if (this.$store.state.usuario.institucion.esEmpresa) {
        axios
          .get("/Turnos/GetPatologiasEmpresas", {
            params: {
              idInstitucion:
                self.$store.state.usuario.institucion.idInstitucion,
              idProfesional: self.prof,
            },
          })
          .then((response) => {
            self.patologias = response.data;
          })
          .catch((error) => {});
      } else {
        axios
          .get("/Turnos/GetPatologias")
          .then((response) => {
            self.patologias = response.data;
          })
          .catch((error) => {});
      }
    },
    modificarDiagnostico() {
      this.$swal({
        title: "Modificar Diagnóstico",
        text: "¿Desea modificar el diagnóstico?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          if (this.patologia.idPatologia === undefined) {
            this.showAlert({
              icon: "error",
              title: "Por favor elija un diagnóstico válido de la lista.",
              vm: this,
            });
            this.patologia = null;
            return;
          }
          const datosDiagnostico = {
            fecha: this.fecha,
            idPaciente: this.idPaciente,
            idProfesional: this.prof,
            idPatologia: this.patologia.idPatologia,
            observaciones: this.observaciones,
            idDiagnosticoHc: this.idDiagnosticoHc,
            esEmpresa: this.$store.state.usuario.institucion.esEmpresa,
          };
          let self = this;
          axios
            .post("/HistoriaClinica/ModificarDiagnostico", datosDiagnostico)
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Diagnóstico modificado exitosamente",
                vm: self,
              });
              self.$emit("diagnosticoModificado");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El diagnóstico no pudo ser modificado",
                vm: this,
              });
            });
        }
      });
    },

    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;
        });

      axios
        .get("/HistoriaClinica/GetDiagnostico?", {
          params: {
            idDiagnosticoHc: self.idDiagnosticoHc,
            esEmpresa: self.$store.state.usuario.institucion.esEmpresa,
          },
        })
        .then((response) => {
          self.fecha = response.data.fecha.substring(0, 10);
          self.prof = response.data.idProfesional;
          self.observaciones = response.data.observaciones;
          self.patologia = {
            idPatologia: response.data.idPatologia,
            nombrePatologia: response.data.diagnostico,
          };
          self.buscarPatologias();
        })
        .catch((error) => {});
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.observaciones = "";
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>
