<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nueva Plantilla</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
         
            <v-row>
              <v-col cols="12" sm="7" md="4">
                <v-select
                  :items="profsBuscar"
                  item-text="nombreProfesional"
                  item-value="idProfesional"
                  menu-props="auto"
                  label="Creada Por"
                  hide-details
                  prepend-icon="mdi-account"
                  v-model="prof"
                  dense
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
            <v-col cols="9" sm="9" md="9">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-account"
                label="Nombre Plantilla"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
            </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                prepend-icon="mdi-hospital"
                v-model="texto"
                label="Texto de la Plantilla"
                :rules="requiredRules"
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
         
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarPlantilla">Guardar</v-btn>

          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    nombre: null,
    texto: null,
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    prof: "",
    profsBuscar: [],
    
  }),

  props: ["visible"],

  computed: {
    show: {
      get() {
        if(this.visible)
          this.setearModels()
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    setearModels(){
        const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;

          self.prof = self.profsBuscar[0].idProfesional;
        });
    },
    guardarPlantilla() {
      this.$swal({
        title: "Guardar Plantilla de Pedidos de Estudios",
        text: "¿Desea registrar la plantilla?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosPlantilla = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            nombrePlantillaPedido: this.nombre,
            textoPlantillaPedido: this.texto,
            idProfesional: this.prof
          };
          let self = this;
          axios
            .post("/HistoriaClinica/GuardarPlantilla", datosPlantilla)
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: "Ya existe una plantilla registrada con ese nombre",
                  vm: self,
                });
              } else {
                self.$refs.form.resetValidation();
                self.vaciarModels();

                document.getElementsByClassName(
                  "v-dialog--active"
                )[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title: "Plantilla registrada exitosamente",
                  vm: self,
                });
                self.$emit("plantillaRegistrada");
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La plantilla no pudo ser registrada",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    vaciarModels() {
      this.nombre = "";
      this.texto = "";
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
   
  created() {
    this.vaciarModels();
    
  },
  
};
</script>