<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Evolución</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="my-4 mx-1">
          <h3>Profesional: {{ evolucion.nomProfesional }}</h3>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Fecha: {{ evolucion.fechaHoraString }}</h3>
        </v-row>
        <v-row class="mt-4 mx-1">
          <h3>Evolución:</h3>
        </v-row>
        <v-row class="ml-1">
          <v-alert class="multi-line">{{ evolucion.texto }}</v-alert>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
import canvas from "canvg";
export default {
  data: (vm) => ({
    maxWidth: "100%",
    base64: "",
  }),
  props: ["visible", "evolucion", "paciente"],
  computed: {
    show: {
      get() {
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";

        if (this.visible) this.getLogoUrl();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
        })
        .catch((error) => {});
    },
    imprimir() {
      var doc = new jsPDF();

      doc.setProperties({
        title: "Evolucion",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion =
        this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Paciente: " + this.paciente.apellidos + " " + this.paciente.nombres,
        13,
        25
      );

      doc.text("D.N.I.: " + this.paciente.dni, 13, 30);
      doc.text("Feche de Nac.: " + this.paciente.fechaNac, 13, 35);
      doc.text("Edad: " + this.paciente.edad, 13, 40);
      doc.setFontSize(16);

      if (this.base64 != null && this.base64 != "")
        doc.addImage(this.base64, 140, 5, 60, 20);

      doc.text("Evolución", doc.internal.pageSize.getWidth() / 2, 50, {
        align: "center",
      });
      doc.line(93, 51, 117, 51);
      doc.line(13, 60, 200, 60);
      doc.setFontSize(13);
      doc.text(
        "Profesional: " +
          this.evolucion.matricula +
          " - " +
          this.evolucion.nomProfesional,
        13,
        67
      );
      doc.text("Fecha: " + this.evolucion.fechaHoraString, 13, 73);
      doc.line(13, 76, 200, 76);
      doc.setFontSize(12);
      var textoSplit = this.evolucion.texto.split(/\r\n|\r|\n/);
      let y = 83;
      for (var i = 0; i < textoSplit.length; i++) {
        if (textoSplit[i].length < 85) {
          doc.text(textoSplit[i], 13, y);
        } else {
          var lineSplit = textoSplit[i].split(" ");
          var linePrint = "";
          var lineTemp = "";
          for (var j = 0; j < lineSplit.length; j++) {
            if (linePrint.length >= 85) {
              if (j == lineSplit.length - 1) doc.text(lineSplit[j], 13, y + 5);

              lineTemp = linePrint + lineSplit[j];
              if (lineTemp.length < 85) {
                doc.text(lineTemp, 13, y);
                linePrint = "";
              } else {
                doc.text(linePrint, 13, y);
                linePrint = lineSplit[j] + " ";
              }
              y = y + 5;

              if (y > 290) {
                y = 20;
                doc.addPage();
              }
            } else {
              linePrint = linePrint + lineSplit[j] + " ";
              if (j == lineSplit.length - 1) {
                doc.text(linePrint, 13, y);
                if (y > 290) {
                  y = 20;
                  doc.addPage();
                }
              }
            }
          }
        }
        y = y + 5;
        if (y > 290) {
          y = 20;
          doc.addPage();
        }
        //comentario version 3
      }
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
};
</script>
