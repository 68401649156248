<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Archivo</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="titulo"
                prepend-icon="mdi-account"
                label="Título"
                maxlength="100"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="descripcion"
                prepend-icon="mdi-account"
                label="Descripción"
                maxlength="100"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          
          <v-row dense>
            <v-col cols="12">
                <v-file-input
                :rules="rules"
                accept="application/pdf, .jpg, .jpeg, .png, .doc, .docx"
                prepend-icon="mdi-file-document"
                v-model="archivo"
                show-size
                chips
                label="Archivo"
                dense
                
              ></v-file-input>
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarInforme">Guardar</v-btn>
          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-form>
     
    </v-card>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    rules: [
      (value) =>
        !value || value.size < 5000000 || "El archivo debe pesar 5MB o menos",
      (v) => !!v || "Dato obligatorio"
    ],
    prof: "",
    profsBuscar: [],
    fecha: null,
    titulo: null,
    descripcion: null,
    archivo: null,
    
  }),
  props: ["visible", "idPaciente", "paciente","idInforme"],
  computed: {
    show: {
      get() {
        if(this.visible)
          this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
     
    modificarInforme() {
      this.$swal({
        title: "Modificar archivo",
        text: "¿Desea modificar el archivo?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')
           var datosInforme = new FormData()
          
            datosInforme.append("fechaInforme",this.fecha)
            datosInforme.append("idProfesional",this.prof)
            datosInforme.append("idPaciente",this.idPaciente)
            datosInforme.append("titulo",this.titulo)
            datosInforme.append("descripcion",this.descripcion)
            datosInforme.append("path",this.archivo)
            datosInforme.append("idInforme", this.idInforme)
        
          let self = this;
          axios({
            method: 'post',
            url: '/HistoriaClinica/ModificarInforme',
            data: datosInforme,
            header: {
            'Accept': 'application/json',
            'Content-Type': 'multipart/form-data',
          },
          })
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Archivo Modificado",
                vm: self,
              });
              self.$emit("informeModificado");
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El archivo debe estar en formato pdf, jpg, jpeg, png, doc o docx",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;
                 
        });

         axios
        .get("/HistoriaClinica/GetInforme?", {
          params: {
            idInforme: self.idInforme,
          },
        })
        .then((response) => {
          self.fecha = response.data.fechaInforme.substring(0, 10);
          self.prof = response.data.idProfesional;
          self.titulo = response.data.titulo;
          self.descripcion = response.data.descripcion;
          self.archivo = response.data.ruta;
        })
        .catch((error) => {});
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.titulo = null;
      this.descripcion = null;
      this.archivo = null;
                  
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    
  },
 
};
</script>