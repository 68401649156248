<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title> Agregar Datos Adicionales al Turno </v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row dense>
          <v-expansion-panels v-model="state" accordion class="mt-3">
            <v-expansion-panel v-if="!$store.state.usuario.institucion.esEmpresa">
              <v-expansion-panel-header> Datos Afiliatorios </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="mb-1">
                  <v-col cols="12">
                    <v-chip label color="transparent" text-color="black" class="ma-0">
                      <h4>Guardar dato afiliatorio</h4>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="4" md="4">
                    <v-select
                      :items="planes"
                      v-model="plan"
                      item-text="nombrePlan"
                      item-value="idPlan"
                      label="Plan"
                      dense
                      prepend-icon="mdi-account"
                    ></v-select>
                  </v-col>
                  <v-col cols="12" sm="4" md="4">
                    <v-text-field
                      v-model="numAfiliado"
                      prepend-icon="mdi-account"
                      label="Nº de Afiliado"
                      maxlength="50"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    v-if="datosAfiliatorios.length == 0"
                    color="success"
                    :disabled="numAfiliado.toString().length < 1"
                    @click.stop="guardarDatosAfiliatorios(1)"
                    >Guardar</v-btn
                  >
                  <v-btn
                    v-if="datosAfiliatorios.length > 0"
                    color="success"
                    :disabled="numAfiliado.toString().length < 1"
                    @click.stop="guardarDatosAfiliatorios(0)"
                    >Actualizar</v-btn
                  >
                </v-card-actions>
                <v-row dense>
                  <v-col cols="12">
                    <v-chip label color="transparent" text-color="black" class="ma-0">
                      <h4>Dato afiliatorio guardado para el turno</h4>
                    </v-chip>
                  </v-col>
                </v-row>

                <v-data-table
                  :headers="headersDatosAfilitatorios"
                  :items="this.datosAfiliatorios"
                  class="elevation-1"
                  :hide-default-footer="true"
                  locale="es-ar"
                  item-key="idTurno"
                  :disable-sort="true"
                  :items-per-page="1"
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="!$store.state.usuario.institucion.esEmpresa">
              <v-expansion-panel-header> Prácticas a Facturar </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="mb-1">
                  <v-col cols="12">
                    <v-chip label color="transparent" text-color="black" class="ma-0">
                      <h4>Guardar práctica</h4>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="5" md="3">
                    <v-select
                      :items="tiposNom"
                      v-model="tipoNom"
                      item-text="nombre"
                      item-value="idTipoNom"
                      label="Tipo Nomenclador"
                      dense
                      prepend-icon="mdi-currency-usd"
                    ></v-select>
                  </v-col>
                  <v-col cols="9" sm="5" md="2">
                    <v-text-field
                      v-model="codPractica"
                      prepend-icon="mdi-currency-usd"
                      label="Cód. Practica"
                      hint="Ingrese el código de la práctica y apriete enter o toque fuera de este cuadro de texto. Si la práctica existe, se verán sus datos. Puede también buscar la práctica por nombre haciendo click en la lupa"
                      maxlength="50"
                      @keypress.enter="buscarPracticaCod"
                      @blur="buscarPracticaCod"
                      dense
                    ></v-text-field>
                  </v-col>
                  <v-col cols="2" sm="2" md="2">
                    <v-btn color="primary" @click="buscarPractica()">
                      <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" md="10" sm="4">
                    <v-text-field
                      v-model="nombrePractica"
                      prepend-icon="mdi-currency-usd"
                      label="Nombre Práctica"
                      maxlength="250"
                      dense
                      required
                      disabled
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" md="2" sm="4">
                    <v-text-field
                      v-model="cantidad"
                      prepend-icon="mdi-currency-usd"
                      label="Cantidad"
                      @keypress="onlyNumber"
                      maxlength="4"
                      dense
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" :disabled="validarPracticas() == false" @click.stop="guardarPractica()"
                    >Guardar</v-btn
                  >
                </v-card-actions>
                <v-row dense>
                  <v-col cols="12">
                    <v-chip label color="transparent" text-color="black" class="ma-0">
                      <h4>Prácticas guardadas para el turno</h4>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="headersPracticas"
                  :items="this.listaPracticas"
                  class="elevation-1 row-pointer"
                  locale="es-ar"
                  item-key="idTurnoPractica"
                  :items-per-page="10"
                  ref="myTable"
                  :disable-sort="true"
                  :footer-props="{
                    itemsPerPageText: 'Filas por página',
                  }"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-on="on" color="red" v-bind="attrs" @click="borrarPractica(item)"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Eliminar Práctica</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="0 == 1">
              <v-expansion-panel-header> Saldo Paciente </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" :disabled="numAfiliado.toString().length < 1" @click.stop="modificarTurno"
                    >Guardar</v-btn
                  >
                </v-card-actions>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel v-if="!$store.state.usuario.institucion.esEmpresa">
              <v-expansion-panel-header> Patologías </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row dense class="mb-1">
                  <v-col cols="12">
                    <v-chip label color="transparent" text-color="black" class="ma-0">
                      <h4>Guardar patología</h4>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-row dense>
                  <v-col cols="12" sm="6" md="5">
                    <v-combobox
                      :items="patologias"
                      v-model="patologia"
                      item-text="nombrePatologia"
                      item-value="idPatologia"
                      label="Patología"
                      dense
                      prepend-icon="mdi-heart-pulse"
                    ></v-combobox>
                  </v-col>
                  <v-col cols="9" sm="6" md="7">
                    <v-text-field
                      v-model="observaciones"
                      prepend-icon="mdi-heart-pulse"
                      label="Observaciones"
                      maxlength="200"
                      dense
                    ></v-text-field>
                  </v-col>
                </v-row>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="success" :disabled="validarPatologia() == false" @click.stop="guardarPatologia()"
                    >Guardar</v-btn
                  >
                </v-card-actions>
                <v-row dense>
                  <v-col cols="12">
                    <v-chip label color="transparent" text-color="black" class="ma-0">
                      <h4>Patologías guardadas para el turno</h4>
                    </v-chip>
                  </v-col>
                </v-row>
                <v-data-table
                  :headers="headersPatologias"
                  :items="this.listaPatologias"
                  class="elevation-1 row-pointer"
                  locale="es-ar"
                  item-key="idTurnoPatologia"
                  :items-per-page="10"
                  ref="myTable"
                  :disable-sort="true"
                  :footer-props="{
                    itemsPerPageText: 'Filas por página',
                  }"
                >
                  <template v-slot:item.actions="{ item }">
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon medium v-on="on" color="red" v-bind="attrs" @click="borrarPatologia(item)"
                          >mdi-delete</v-icon
                        >
                      </template>
                      <span>Eliminar Patología</span>
                    </v-tooltip>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-expansion-panel>
              <v-expansion-panel-header> Usuarios del Turno </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-row class="mb-3">
                  <v-chip
                    ><h4>
                      Usuario Alta:
                      <strong style="color: blue">{{ datosAuditoria.usuarioAlta }}</strong>
                    </h4></v-chip
                  >
                </v-row>
                <v-row class="mb-3">
                  <v-chip
                    ><h4>
                      Fecha Alta:
                      <strong style="color: blue">{{ datosAuditoria.fechaAlta }}</strong>
                    </h4></v-chip
                  >
                </v-row>
                <v-row class="mb-3">
                  <v-chip
                    ><h4>
                      Usuario Modi:
                      <strong style="color: blue">{{ datosAuditoria.usuarioModi }}</strong>
                    </h4></v-chip
                  >
                </v-row>
                <v-row class="mb-3">
                  <v-chip
                    ><h4>
                      Fecha Modi:
                      <strong style="color: blue">{{ datosAuditoria.fechaModi }}</strong>
                    </h4></v-chip
                  >
                </v-row>
              </v-expansion-panel-content>
            </v-expansion-panel>
            <v-expansion-panel v-if="!$store.state.usuario.institucion.esEmpresa">
              <v-expansion-panel-header> Turnos Futuros del Paciente </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-data-table
                  :headers="headersTurnos"
                  :items="this.listaTurnos"
                  class="elevation-1"
                  locale="es-ar"
                  item-key="idTurno"
                  :items-per-page="10"
                  disable-sort
                  ref="myTable"
                  :footer-props="{
                    itemsPerPageText: 'Filas por página',
                    itemsPerPageOptions: [5, 10, 15],
                  }"
                >
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-row>
      </v-card-text>
      <v-card-actions class="mx-2">
        <v-btn color="success" @click.stop="showCaracteristicas = true">Caracteristicas Paciente</v-btn>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
      <BuscarPractica
        :visible="showBuscarPractica"
        @close="showBuscarPractica = false"
        @seleccionarPractica="seleccionarPractica($event)"
      ></BuscarPractica>
      <CaracteristicasPaciente
        v-if="showCaracteristicas"
        :visible="showCaracteristicas"
        :idPaciente="idPaciente"
        @close="showCaracteristicas = false"
      />
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import BuscarPractica from "../Turnos/BuscarPractica.vue";
import CaracteristicasPaciente from "../HistClin/CaracteristicasPaciente.vue";
export default {
  data: (vm) => ({
    planes: [],
    plan: "",
    numAfiliado: "",
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    state: null,
    headersDatosAfilitatorios: [
      { text: "Plan", value: "nombrePlan" },
      { text: "Nº de Afiliado", value: "numAfiliado" },
    ],
    datosAfiliatorios: [],
    listaPracticas: [],
    headersPracticas: [
      { text: "Tipo Nomenclador", value: "nombreTipoNom" },
      { text: "Cód. Práctica", value: "codPractica" },
      { text: "Nombre Práctica", value: "nombrePractica" },
      { text: "Cantidad", value: "cantidad" },
      { text: "Acciones", value: "actions" },
    ],
    idNomenclador: null,
    tiposNom: [],
    tipoNom: null,
    codPractica: null,
    nombrePractica: null,
    cantidad: null,
    showBuscarPractica: false,
    showCaracteristicas: false,
    patologia: null,
    patologias: [],
    observaciones: "",
    listaPatologias: [],
    headersPatologias: [
      { text: "Patología", value: "nombrePatologia" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Acciones", value: "actions" },
    ],
    datosAuditoria: {
      usuarioAlta: "",
      fechaAlta: "",
      usuarioModi: "",
      fechaModi: "",
    },
    listaTurnos: [],
    headersTurnos: [
      { text: "Dia", value: "diaSemana" },
      { text: "Fecha", value: "fechaTurnoString" },
      { text: "Hora", value: "horaInicioString", sortable: false },
      { text: "Dni", value: "dniPaciente" },
      { text: "Paciente", value: "nombrePaciente" },
      { text: "Telefono", value: "celular" },
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Observaciones", value: "observacionesTurno" },
      { text: "Obra Social", value: "obraSocial" },
      { text: "Estado", value: "estadoTurnoString" },
    ],
  }),

  props: ["visible", "idTurno", "idObraSocial", "idPaciente"],

  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.vaciarModels();
          this.validarPracticas();
          this.vaciarModelsPatologia();
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    borrarPatologia(item) {
      this.$swal({
        title: "Borrar Patología",
        text: "¿Desea borrar la patología?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idTurnoPatologia = item.idTurnoPatologia;
          let self = this;
          axios
            .get("/Turnos/BorrarPatologiaTurno?", {
              params: {
                idTurnoPatologia: idTurnoPatologia,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Patología borrada",
                vm: self,
                timer: 2500,
              });
              setTimeout(self.buscarPatologiasTurno(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La patología no pudo ser borrada",
                vm: this,
                timer: 2000,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    buscarPractica() {
      this.showBuscarPractica = true;
    },
    validarPatologia() {
      if (this.patologia == null || this.patologia.idPatologia == null) return false;
      else return true;
    },
    validarPracticas() {
      if (
        this.nombrePractica == "" ||
        this.nombrePractica == null ||
        this.codPractica == "" ||
        this.codPractica == null ||
        this.cantidad == "0" ||
        this.cantidad == "" ||
        this.cantidad == null
      )
        return false;
      else return true;
    },
    borrarPractica(item) {
      this.$swal({
        title: "Borrar Práctica",
        text: "¿Desea borrar la práctica?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idTurnoPractica = item.idTurnoPractica;
          let self = this;
          axios
            .get("/Turnos/BorrarPracticaTurno?", {
              params: {
                idTurnoPractica: idTurnoPractica,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Práctica borrada",
                vm: self,
                timer: 2500,
              });
              setTimeout(self.buscarPracticas(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La práctica no pudo ser borrada",
                vm: this,
                timer: 2000,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    guardarPractica() {
      this.$swal({
        title: "Guardar Práctica",
        text: "¿Desea guardar la práctica?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosPractica = {
            idTurno: this.idTurno,
            idTipoNom: this.tipoNom,
            codPractica: this.codPractica,
            cantidad: this.cantidad,
          };

          let self = this;
          axios
            .post("/Turnos/GuardarTurnoPractica", datosPractica)
            .then((response) => {
              self.vaciarModelsPractica();
              document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;

              self.showAlert({
                icon: "success",
                title: "Práctica guardada",
                vm: self,
                timer: 2500,
              });
              self.buscarPracticas();
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La práctica no pudo ser guardada",
                vm: this,
                timer: 2500,
              });
            });
        }
      });
    },
    guardarPatologia() {
      this.$swal({
        title: "Guardar Patología",
        text: "¿Desea guardar la patología?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosPatologia = {
            idTurno: this.idTurno,
            idPatologia: this.patologia.idPatologia,
            observaciones: this.observaciones,
          };
          let self = this;
          axios
            .post("/Turnos/GuardarTurnoPatologia", datosPatologia)
            .then((response) => {
              if (response.data == "Existe") {
                self.showAlert({
                  icon: "error",
                  title: "Ya existe esta patología guardada para este turno",
                  vm: self,
                  timer: 2500,
                });
                return;
              }

              self.vaciarModelsPatologia();
              document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;

              self.showAlert({
                icon: "success",
                title: "Patología guardada",
                vm: self,
                timer: 2500,
              });
              self.buscarPatologiasTurno();
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La patología no pudo ser guardada",
                vm: this,
                timer: 2500,
              });
            });
        }
      });
    },
    buscarPatologiasTurno() {
      const idTurno = this.idTurno;

      let self = this;
      axios
        .get("/Turnos/BuscarPatologiasTurno?", {
          params: {
            idTurno: idTurno,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") self.listaPatologias = response.data;
          else self.listaPatologias = [];
        })
        .catch(function (error) {});
    },
    vaciarModelsPatologia() {
      this.patologia = null;
      this.observaciones = "";
    },
    buscarPracticas() {
      const idTurno = this.idTurno;

      let self = this;
      axios
        .get("/Turnos/BuscarPracticasTurno?", {
          params: {
            idTurno: idTurno,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") self.listaPracticas = response.data;
          else self.listaPracticas = [];
        })
        .catch(function (error) {});
    },
    vaciarModelsPractica() {
      (this.codPractica = ""), (this.nombrePractica = ""), (this.cantidad = "");
    },
    seleccionarPractica(item) {
      this.showBuscarPractica = false;
      this.tipoNom = item.idTipoNom;
      this.codPractica = item.codPractica;
      this.nombrePractica = item.nombrePractica;
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    buscarPlanes() {
      const idObraSocial = this.idObraSocial;

      let self = this;
      axios
        .get("/ObraSocial/GetPlanesDeOS?", {
          params: {
            idObraSocial: idObraSocial,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.planes = response.data;
            self.plan = self.planes[0].idPlan;
          } else self.planes = [];
        })
        .catch(function (error) {});
    },
    setearModels() {
      if (!this.$store.state.usuario.institucion.esEmpresa) {
        this.buscarPlanes();
        this.buscarDatosAfiliatorios();
        this.buscarTipoNom();
        this.buscarPracticas();
        this.buscarPatologias();
        this.buscarPatologiasTurno();
        this.buscarTurnosFuturo();
      }
      this.buscarDatosAuditoria();
    },
    buscarTurnosFuturo() {
      let self = this;
      axios
        .get("/Turnos/BuscarTurnosFuturo?", {
          params: {
            idPaciente: self.idPaciente,
            idTurno: self.idTurno,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.listaTurnos = response.data.listaTurnos;
          }
        })
        .catch(function (error) {
          self.listaTurnos = [];
          console.log(error);
        });
    },
    buscarDatosAuditoria() {
      let self = this;
      axios
        .get("/Turnos/GetDatosAuditoria", {
          params: {
            idTurno: this.idTurno,
          },
        })
        .then((response) => {
          self.datosAuditoria.usuarioAlta = response.data.usuarioAlta;
          self.datosAuditoria.fechaAlta =
            self.formatDate(response.data.fechaAlta.toString().substring(0, 10)) +
            " - " +
            response.data.fechaAlta.toString().substring(11, 16) +
            " hs.";
          self.datosAuditoria.usuarioModi = response.data.usuarioModi;
          self.datosAuditoria.fechaModi =
            self.formatDate(response.data.fechaModi.toString().substring(0, 10)) +
            " - " +
            response.data.fechaModi.toString().substring(11, 16) +
            " hs.";
        })
        .catch((error) => {});
    },
    buscarTipoNom() {
      let self = this;
      axios
        .get("/Turnos/GetTipoNom")
        .then((response) => {
          self.tiposNom = response.data;
          self.tipoNom = self.tiposNom[0].idTipoNom;
        })
        .catch((error) => {});
    },
    buscarPatologias() {
      let self = this;
      axios
        .get("/Turnos/GetPatologias")
        .then((response) => {
          self.patologias = response.data;
          //self.patologia= self.patologias[0].idPatologia;
        })
        .catch((error) => {});
    },
    buscarDatosAfiliatoriosPaciente() {
      var datosAf = {
        idPaciente: this.idPaciente,
        idObraSocial: this.idObraSocial,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarDatosAfiliatoriosPaciente?", {
          params: {
            idPaciente: datosAf.idPaciente,
            idObraSocial: datosAf.idObraSocial,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.plan = response.data.idPlan;
            self.numAfiliado = response.data.numAfiliado;
          } else self.numAfiliado = "";
        })
        .catch(function (error) {});
    },
    vaciarModels() {
      this.numAfiliado = "";
      this.plan = "";
      this.state = null;
    },
    guardarDatosAfiliatorios(val) {
      this.$swal({
        title: "Guardar Datos Afiliatorios",
        text: "¿Desea guardar los datos afiliatorios?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosAfiliatorios = {
            idTurno: this.idTurno,
            idPaciente: this.idPaciente,
            idPlan: this.plan,
            numAfiliado: this.numAfiliado,
            guarda: val,
          };

          let self = this;
          axios
            .post("/Turnos/GuardarDatosAfiliatorios", datosAfiliatorios)
            .then((response) => {
              self.numAfiliado = "";
              document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;

              self.showAlert({
                icon: "success",
                title: "Datos Afiliatorios Guardados",
                vm: self,
                timer: 2500,
              });
              if (self.idTurno > 0) this.buscarDatosAfiliatorios();
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "Los datos afiliatorios no pudieron ser guardados",
                vm: this,
                timer: 2500,
              });
            });
        }
      });
    },
    buscarDatosAfiliatorios() {
      var idTurno = this.idTurno;

      let self = this;
      axios
        .get("/Turnos/BuscarDatosAfiliatorios?", {
          params: {
            idTurno: idTurno,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.datosAfiliatorios = response.data;
            self.numAfiliado = "";
          } else {
            self.datosAfiliatorios = [];
            self.buscarDatosAfiliatoriosPaciente();
          }
        })
        .catch(function (error) {});
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    buscarPracticaCod() {
      const practicasData = {
        idTipoNom: this.tipoNom,
        codPractica: this.codPractica,
      };
      let self = this;
      axios
        .get("/Turnos/GetPractica?", {
          params: {
            idTipoNom: practicasData.idTipoNom,
            codPractica: practicasData.codPractica,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") self.nombrePractica = response.data.nombrePractica;
          else self.nombrePractica = "";
        })
        .catch(function (error) {});
    },
  },

  created() {
    //this.vaciarModels();
  },
  components: {
    BuscarPractica,
    CaracteristicasPaciente,
  },
};
</script>
