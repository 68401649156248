<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Receta</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="obraSocial"
                prepend-icon="mdi-account"
                label="Obra Social"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="nroAfiliado"
                prepend-icon="mdi-account"
                label="Nº de Afiliado"
                maxlength="80"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea
                prepend-icon="mdi-hospital"
                @click:prepend="agregarMedicamento"
                v-model="receta"
                label="Texto Receta"
                :rules="requiredRules"
                hint="Apretando en el ícono + podrá agregar un medicamento buscando en nuestro manual farmacéutico"
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="diagnostico"
                prepend-icon="mdi-account"
                label="Diagnóstico"
                maxlength="150"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <BuscarMedicamento
            :visible="showBuscarMedicamento"
            @close="showBuscarMedicamento = false"
            @seleccionarMedicamento="seleccionarMedicamento($event)"
          ></BuscarMedicamento>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="modificarReceta"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  .v-dialog__content {
    align-items: left;
    justify-content: left;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import BuscarMedicamento from "../HistClin/BuscarMedicamento.vue";
  import imprimirReceta from "./ImprimirEventos/imprimirReceta";
  import imprimirRecetaTrinidad from "./ImprimirEventos/Trinidad/imprimirReceta";

  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      maxWidth: "100%",
      requiredRules: [(v) => !!v || "Dato obligatorio"],

      prof: "",
      profsBuscar: [],
      fecha: null,
      obraSocial: null,
      nroAfiliado: null,
      receta: null,
      diagnostico: null,
      showBuscarMedicamento: false,
      base64: null,
    }),
    props: ["visible", "idPaciente", "idReceta", "paciente", "institucion"],
    computed: {
      show: {
        get() {
          if (this.visible) {
            this.setearModels();
            this.getLogoUrl();
          }
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$refs.form.resetValidation();
            this.vaciarModels();
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      getLogoUrl() {
        let self = this;

        axios
          .get("/HistoriaClinica/GetLogo?", {
            params: {
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
            },
          })
          .then((response) => {
            self.base64 = response.data;
          })
          .catch((error) => {});
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },
      imprimir() {
        switch (this.$store.state.usuario.institucion.idInstitucion) {
          case 95: {
            console.log(this.receta);
            imprimirRecetaTrinidad(
              this.institucion,
              this.paciente,
              {
                obraSocial: this.obraSocial,
                nroAfiliado: this.nroAfiliado,
                texto: this.receta,
                diagnostico: this.diagnostico,
                fechaRecetaString: this.formatDate(this.fecha),
              },
              this.base64,
              "screen"
            );
            break;
          }
          default: {
            imprimirReceta(
              this.institucion,
              this.paciente,
              {
                obraSocial: this.obraSocial,
                nroAfiliado: this.nroAfiliado,
                texto: this.receta,
                diagnostico: this.diagnostico,
                fechaRecetaString: this.formatDate(this.fecha),
              },
              this.base64,
              "screen"
            );
            break;
          }
        }
        this.$refs.form.resetValidation();
        this.vaciarModels();
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
        this.$emit("recetaModificada");
        //doc.save('turnos.pdf');
      },
      agregarMedicamento() {
        this.showBuscarMedicamento = true;
      },
      seleccionarMedicamento(item) {
        if (this.receta == "")
          this.receta = this.receta + item.nombre + " " + item.presentacion;
        else
          this.receta =
            this.receta + "\n" + item.nombre + " " + item.presentacion;

        this.showBuscarMedicamento = false;
      },
      modificarReceta() {
        this.$swal({
          title: "Modificar Receta",
          text: "¿Desea modificar la receta?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            const datosReceta = {
              fechaReceta: this.fecha,
              idPaciente: this.idPaciente,
              idProfesional: this.prof,
              texto: this.receta,
              diagnostico: this.diagnostico,
              obraSocial: this.obraSocial,
              nroAfiliado: this.nroAfiliado,
              idReceta: this.idReceta,
            };
            let self = this;
            axios
              .post("/HistoriaClinica/ModificarReceta", datosReceta)
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Receta modificada exitosamente",
                  vm: self,
                });
                this.$swal({
                  title: "Imprimir Receta",
                  text: "¿Desea imprimir la receta?",
                  icon: "success",
                  type: "success",
                  showCancelButton: true,
                  background: "#ececec",
                  confirmButtonText: "Si",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    this.imprimir();
                  } else {
                    self.$refs.form.resetValidation();
                    self.vaciarModels();
                    document.getElementsByClassName(
                      "v-dialog--active"
                    )[0].scrollTop = 0;
                    self.$emit("recetaModificada");
                  }
                });
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La receta no pudo ser modificada",
                  vm: this,
                });
              });
          }
        });
      },

      setearModels() {
        const profData = {
          usuario: this.$store.state.usuario.usuario,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        let self = this;
        axios
          .get("/Turnos/BuscarProfesionalesAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.profsBuscar = response.data.profesionales;
          });

        axios
          .get("/HistoriaClinica/GetReceta?", {
            params: {
              idReceta: self.idReceta,
            },
          })
          .then((response) => {
            self.fecha = response.data.fechaReceta.substring(0, 10);
            self.prof = response.data.idProfesional;
            self.receta = response.data.texto;
            self.nroAfiliado = response.data.nroAfiliado;
            self.obraSocial = response.data.obraSocial;
            self.diagnostico = response.data.diagnostico;
          })
          .catch((error) => {});
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";
      },

      vaciarModels() {
        this.receta = "";
        this.obraSocial = "";
        this.nroAfiliado = "";
        this.diagnostico = "";
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
    },
    components: {
      BuscarMedicamento,
    },
  };
</script>
