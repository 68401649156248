<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="showed"
    :fullscreen="fullscreen"
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="showed = false">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Ver Archivo</v-toolbar-title>
    </v-toolbar>

    <div class="divClass">
      <iframe
        v-if="extension == '.pdf'"
        :src="ruta"
        width="100%"
        :height="height"
      ></iframe>

      <div
        v-if="extension == '.jpg' || extension == '.jpeg' || extension == '.png' || extension == '.PNG' || extension == '.JPEG' || extension == '.JPG'"
        style="height: 100%"
        class="images"
        v-viewer.rebuild="{ inline: true }"
      >
        <img class="image" v-for="src in images" :src="src" :key="src" />
      </div>

      <iframe
        v-if="extension == '.doc' || extension == '.docx'"
        :src="encodedUrl"
        width="100%"
        height="100%"
        frameborder="0"
        >This is an embedded
        <a href="http://office.com">Microsoft Office</a> document, powered by
        <a target="_blank" href="http://office.com/webapps"
          >Office Online</a
        ></iframe
      >
    </div>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-dialog {
  height: 104% !important;
}
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
.divClass {
  height: 90.2%;
  width: 100%;
  background-color: white;
  position: relative;
}
</style>
<style lang="scss" scoped>
.image {
  display: none;
}
</style>
<style>
.viewer-loading > img {
  display: none; /* hide big images when it is loading */
}
</style>
<script>
import pdf from "vue-pdf";
export default {
  data: (vm) => ({
    images: [],
    ruta: null,
    index: 0,
    maxWidth: "100%",
    fullscreen: true,
    height: "100%",
    extension: null,
    encodedUrl: "",
    loading: false,
  }),
  props: ["visible", "informe", "paciente"],
  computed: {
    showed: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.vaciarModels();
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    show() {
      const viewer = this.$el.querySelector(".images").$viewer;
      viewer.show();
    },
    setearModels() {
      this.loading = true;
      if (this.$store.state.videollamada == true) {
        this.fullscreen = false;
        this.maxWidth = "68%";
        this.height = "500px";
      } else {
        this.maxWidth = "100%";
        this.fullscreen = true;
        this.height = "100%";
      }
      this.ruta = this.informe.ruta;
      this.images.push(this.informe.ruta);
      this.extension = this.informe.extension;

      if (this.extension == ".doc" || this.extension == ".docx") {
        var uri = this.ruta;
        this.encodedUrl =
          "https://view.officeapps.live.com/op/embed.aspx?src=" +
          encodeURIComponent(uri);
      }
      this.loading = false;
    },
    vaciarModels() {
      (this.extension = ""), (this.ruta = "");
      this.images = [];
    },
  },
  components: {
    pdf,
  },
};
</script>