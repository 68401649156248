<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ tipoMovimiento === "cobro" ? "Editar Cobro" : "Editar Pago" }}</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaCobro"
                :value="fechaCobro"
                label="Fecha Cobro"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                :disabled="idTurno > 0 || idPresupuesto > 0"
                dense
              ></v-select>
            </v-col>
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                v-model="paciente"
                prepend-icon="mdi-account"
                :rules="requiredRules"
                label="Paciente"
                :disabled="idTurno > 0 || idPresupuesto > 0"
                @change="idPaciente = null"
                dense
                maxlength="150"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn
                v-show="!(idTurno > 0 || idPresupuesto > 0) && tipoMovimiento === 'cobro'"
                color="primary"
                @click="buscarPaciente"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="observaciones"
                prepend-icon="mdi-account"
                label="Observaciones"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6" v-if="tipoMovimiento === 'cobro'">
              <v-select
                :items="formasPago"
                item-text="nombreFormaPago"
                item-value="idFormaPago"
                menu-props="auto"
                label="Forma de Pago"
                hide-details
                prepend-icon="mdi-currency-usd"
                v-model="formaPago"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="total"
                prepend-icon="mdi-currency-usd"
                :rules="tipoMovimiento === 'cobro' ? totalRulesCobros : totalRulesPagos"
                label="Importe"
                @blur="toFixed"
                @keypress="onlyNumber"
                dense
                maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarCobro">Guardar</v-btn>
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
        <BuscarPacienteTurno
          :visible="showBuscarPaciente"
          @close="showBuscarPaciente = false"
          @seleccionarPaciente="seleccionarPaciente($event)"
        ></BuscarPacienteTurno>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import BuscarPacienteTurno from "../Turnos/BuscarPacienteTurno.vue";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    totalRulesCobros: [(v) => !!v || "Dato obligatorio", (v) => v >= 0 || "El total debe ser mayor o igual a 0"],
    totalRulesPagos: [(v) => !!v || "Dato obligatorio", (v) => v > 0 || "El total debe ser mayor a 0"],
    prof: "",
    profsBuscar: [],
    fechaCobro: null,
    paciente: null,
    observaciones: null,
    total: null,
    idTurno: null,
    idPresupuesto: null,
    idPaciente: null,
    showBuscarPaciente: false,
    formaPago: null,
    formasPago: [],
  }),
  props: {
    visible: { required: false },
    idCobro: { required: false },
    idTurnoEditar: { required: false },
    tipoMovimiento: { required: false, default: "cobro" },
  },
  computed: {
    show: {
      get() {
        if (this.visible) this.buscarDatosCobro();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    seleccionarPaciente(item) {
      (this.idPaciente = item.idpaciente), (this.paciente = item.apellidos + ", " + item.nombres);
      this.showBuscarPaciente = false;
    },
    buscarPaciente() {
      this.showBuscarPaciente = true;
    },
    modificarCobro() {
      const title = this.tipoMovimiento === "cobro" ? "Modificar Cobro" : "Modificar Pago";
      const text = this.tipoMovimiento === "cobro" ? "¿Desea modificar el cobro?" : "¿Desea modificar el pago?";
      this.$swal({
        title: title,
        text: text,
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const datosCobro = {
            fecha: this.fechaCobro,
            idTurno: this.idTurno,
            idPresupuesto: this.idPresupuesto,
            idPaciente: this.idPaciente,
            total: this.tipoMovimiento === "cobro" ? this.total : parseFloat(0 - this.total, 2).toString(),
            observaciones: this.observaciones,
            idProfesional: this.prof,
            paciente: this.paciente,
            idCobro: this.idCobro,
            idFormaPago: this.tipoMovimiento === "cobro" ? this.formaPago : null,
            usuario: this.$store.state.usuario.usuario,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          };
          let self = this;
          axios
            .post("/Caja/ModificarCobro", datosCobro)
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
              const alertTitle = self.tipoMovimiento === "cobro" ? "Cobro modificado" : "Pago modificado";
              self.showAlert({
                icon: "success",
                title: alertTitle,
                vm: self,
              });
              self.$emit("close");
            })
            .catch((error) => {
              const alertTitleError =
                self.tipoMovimiento === "cobro" ? "El cobro no pudo ser modificado" : "El pago no pudo ser modificado";
              self.showAlert({
                icon: "error",
                title: alertTitleError,
                vm: this,
              });
            });
        }
      });
    },

    buscarDatosCobro() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;
          if (self.tipoMovimiento === "pago") {
            self.profsBuscar.unshift({
              idProfesional: null,
              nombreProfesional: "NO ASOCIADO",
            });
          }
        })
        .catch((error) => {});

      axios.get("/Caja/BuscarFormasPago").then((response) => {
        self.formasPago = response.data;
      });

      const cobroData = {
        idCobro: this.idCobro,
      };

      axios
        .get("/Caja/BuscarDatosCobro?", {
          params: {
            idCobro: cobroData.idCobro,
          },
        })
        .then((response) => {
          self.fechaCobro = response.data.fecha.substring(0, 10);
          self.prof = response.data.idProfesional;
          self.paciente = response.data.paciente;
          self.observaciones = response.data.observaciones;
          self.total = parseFloat(response.data.total).toFixed(2);
          self.idTurno = response.data.idTurno;
          self.idPresupuesto = response.data.idPresupuesto;
          self.idPaciente = response.data.idPaciente;
          self.formaPago = response.data.idFormaPago;
        })
        .catch((error) => {});
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    toFixed() {
      if (isNaN(parseFloat(this.total))) this.total = parseFloat(0.0).toFixed(2);
      else this.total = parseFloat(this.total).toFixed(2);
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    vaciarModels() {
      this.paciente = "";
      this.observaciones = "";
      this.total = "";
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
  components: {
    BuscarPacienteTurno,
  },
};
</script>
