<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Instituciones Externas</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="this.listaInstitucionesExternas"
        class="elevation-1"
        locale="es-ar"
        item-key="idInstitucionExterna"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarInstitucionExterna(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Institucion Externa</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarInstitucionExterna(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Institucion Externa</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaInstitucionExterna"
          >Nueva Inst. Externa</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevaInstitucionExterna
        :visible="showNuevaInstitucionExterna"
        @close="cierraNuevaInstitucionExterna()"
        @institucionExternaRegistrada="institucionExternaRegistrada()"
      ></NuevaInstitucionExterna>
      <EditarInstitucionExterna
        :visible="showEditarInstitucionExterna"
        :idInstitucionExterna="editedItem.idInstitucionExterna"
        @close="cierraEditarInstitucionExterna()"
        @institucionExternaModificada="institucionExternaModificada()"
      ></EditarInstitucionExterna>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import NuevaInstitucionExterna from "../Configuraciones/NuevaInstitucionExterna.vue";
import EditarInstitucionExterna from "../Configuraciones/EditarInstitucionExterna.vue";
export default {
  data: (vm) => ({
    listaInstitucionesExternas: [],
    headers: [
      { text: "Nombre", value: "nombreInstitucionExterna" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevaInstitucionExterna: false,
    showEditarInstitucionExterna: false,
    editedItem: {},
    editedIndex: -1,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarInstitucionExterna(item) {
      this.$swal({
        title: "Borrar Institucion Externa",
        text: "¿Desea borrar la institución externa?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idInstitucionExterna = item.idInstitucionExterna;
          let self = this;
          axios
            .get("/HistoriaClinica/BorrarInstitucionExterna?", {
              params: {
                idInstitucionExterna: idInstitucionExterna,
              },
            })
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title:
                    "Existen archivos asociados a esta institución externa. No se puede borrar.",
                  vm: self,
                });
              } else {
                self.showAlert({
                  icon: "success",
                  title: "Institución externa borrada exitosamente",
                  vm: self,
                });
                setTimeout(self.buscarInstitucionesExternas(), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title:
                  "Existen archivos asociados a esta institución externa. No se puede borrar.",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevaInstitucionExterna() {
      this.showNuevaInstitucionExterna = false;
      //this.buscarObrasSociales();
    },
    cierraEditarInstitucionExterna() {
      this.showEditarInstitucionExterna = false;
      //this.buscarObrasSociales();
    },
    institucionExternaRegistrada() {
      this.showNuevaInstitucionExterna = false;
      this.buscarInstitucionesExternas();
    },
    institucionExternaModificada() {
      this.showEditarInstitucionExterna = false;
      this.buscarInstitucionesExternas();
    },
    nuevaInstitucionExterna() {
      this.showNuevaInstitucionExterna = true;
    },
    editarInstitucionExterna(item) {
      this.editedIndex = this.listaInstitucionesExternas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarInstitucionExterna = true;
    },
    buscarInstitucionesExternas() {
      const filtrosData = {
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetInstitucionesExternas?", {
          params: {
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaInstitucionesExternas = response.data;
          else self.listaInstitucionesExternas = [];
        })
        .catch(function(error) {});
    },
  },
  created() {
    this.buscarInstitucionesExternas();
  },

  components: {
    NuevaInstitucionExterna,
    EditarInstitucionExterna,
  },
};
</script>
