<template>

  <v-dialog :max-width="maxWidth" v-model="show" persistent transition="dialog-bottom-transition">
    
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Subir Archivo</v-toolbar-title>
      </v-toolbar>
      
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="2">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
               <v-col cols="12" sm="7" md="2">
              <v-select
                :items="cantidadArchivos"
                item-text="cantidadArchivos"
                item-value="cantidadArchivos"
                menu-props="auto"
                label="Cant. de Archivos"
                hide-details
                prepend-icon="mdi-file"
                v-model="cantidad"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="titulo"
                prepend-icon="mdi-account"
                label="Título"
                maxlength="100"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="descripcion"
                prepend-icon="mdi-account"
                label="Descripción"
                maxlength="100"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
            <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="origen"
                item-text="origen"
                item-value="origen"
                menu-props="auto"
                label="Orígen del Archivo"
                hide-details
                prepend-icon="mdi-account"
                v-model="externo"
                dense
              ></v-select>
            </v-col>
             <v-col cols="12" sm="7" md="4">
              <v-select
                :items="listaInstitucionesExternas"
                item-text="nombreInstitucionExterna"
                item-value="idInstitucionExterna"
                menu-props="auto"
                label="Institución Externa"
                prepend-icon="mdi-home"
                v-model="institucionExterna"
                persistent-hint
                v-if="externo=='Externo'"
                hint="Puede cargar las instituciones externas desde el menú Gestión"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
                <v-file-input
                :rules="rules"
                :accept="acceptedFormats()"
                prepend-icon="mdi-file-document"
                v-model="archivo"
                show-size
                chips
                label="Archivo"
                dense
                :multiple="isMultiple()"
              ></v-file-input>
            </v-col>
          </v-row>
          
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <div v-if="loading==true" class="loader mr-2"></div><h4 v-if="loading==true">Subiendo archivo</h4> 
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarInforme">Guardar</v-btn>
          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-form>
     
    </v-card>
     
  </v-dialog>
</template>
<style scoped>
.v-dialog__content {
   align-items:left;
   justify-content:left;
}
.loader {
  border: 4px solid #f3f3f3; /* Light grey */
  border-top: 4px solid #3498db; /* Blue */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    loading: false,
    maxWidth: '100%',
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    rules: [
     values =>
       !values || values.some(value => value.size < 20480000) || "El archivo debe pesar 20MB o menos",
     (v) => !!v || "Dato obligatorio"
   ],
    prof: "",
    profsBuscar: [],
    fecha: null,
    titulo: null,
    descripcion: null,
    archivo: null,
    origen: ['Interno', 'Externo'],
    cantidadArchivos: ['Único', 'Múltiples (sólo imágenes)'],
    cantidad: 'Único',
    externo: 'Interno',
    institucionExterna: null,
    listaInstitucionesExternas: []
    
  }),
  props: ["visible", "idPaciente", "paciente"],
  computed: {
    show: {
      get() {
        if(this.visible)
          this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    acceptedFormats(){
      if (this.cantidad == 'Único')
        return "application/pdf, .jpg, .jpeg, .png, .tiff, .doc, .docx"
      else
        return ".jpg, .jpeg, .png, .tiff"
    },
    isMultiple()
    {
      if (this.cantidad == 'Único')
        return false
      else
        return true
    },
    guardarInforme() {
      this.$swal({
        title: "Registrar Archivo",
        text: "¿Desea registrar el archivo?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
           if (this.cantidad == 'Único' && !this.archivo.some(archivo => (archivo.type=='image/jpeg' || archivo.type=='image/jpg' || archivo.type=='image/png' || archivo.type=='image/tiff' 
           || archivo.type=='application/pdf' || archivo.type == 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
           || archivo.type=='application/msword')))
           {
              this.showAlert({
              timer: 2500,
              icon: "error",
              title: "El formato del archivo seleccionado no es válido",
              vm: this,
            });
            return;
           }
           if (this.cantidad != 'Único' && (
                                            this.archivo.some(archivo => archivo.type!='image/jpeg' && archivo.type!='image/jpg' && archivo.type!='image/png' && 
                                            archivo.type!='image/tiff')
                                           )
              )
             {
              this.showAlert({
              timer: 2500,
              icon: "error",
              title: "Alguno de los archivos seleccionados no es un archivo de imágen válido",
              vm: this,
            });
            return;
           }

           this.loading = true;
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')
           var datosInforme = new FormData()
          
            datosInforme.append("fechaInforme",this.fecha)
            datosInforme.append("idProfesional",this.prof)
            datosInforme.append("idPaciente",this.idPaciente)
            datosInforme.append("titulo",this.titulo)
            datosInforme.append("descripcion",this.descripcion)
            if(this.externo == 'Externo')
            {
              datosInforme.append("externo",true)
              if (this.institucionExterna != null)
              datosInforme.append("idInstitucionExterna", this.institucionExterna)
            }
            else
            {
              datosInforme.append("externo",false)
            }

            if(this.cantidad=='Único')
            {
              datosInforme.append("varios", false)
            }
            else
            {
              datosInforme.append("varios", true)
            }

            this.archivo.forEach(arch => {
              datosInforme.append("path", arch)  
            });
                   
          let self = this;
          axios({
            method: 'post',
            url: '/HistoriaClinica/GuardarInforme',
            data: datosInforme,
            header: {
            'Content-Type': 'multipart/form-data',
          },
          })
            .then((response) => {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              document.getElementsByClassName(
               "v-dialog--active"
             )[0].scrollTop = 0;
             self.showAlert({
               icon: "success",
               title: "Archivo Registrado",
               vm: self,
               timer: 2000
             });
              self.$emit("informeRegistrado");
            })
            .catch((error) => {
              this.loading = false;
              self.showAlert({
                icon: "error",
                title: "El archivo no puedo ser guardado",
                vm: this,
                timer: 2000
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
      
    },

    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;

          self.prof = self.profsBuscar[0].idProfesional;
        });

       axios.get('/HistoriaClinica/GetInstitucionesExternas?', {
        params: {        
          idInstitucion: self.$store.state.usuario.institucion.idInstitucion
        }
                
      }).then(function(response) {
      if (response.data!=null && response.data!='')
      {
        self.listaInstitucionesExternas = response.data
        self.institucionExterna = self.listaInstitucionesExternas[0].idInstitucionExterna
      } 
      else
        self.listaInstitucionesExternas = []     
      }).catch(function(error){       
        
      })

      this.fecha = this.parseDate(this.formatDate(this.toISOLocal(new Date())));
           if(this.$store.state.videollamada == true)
        this.maxWidth = '68%'
      else
        this.maxWidth = '100%'
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.titulo = null;
      this.descripcion = null;
      this.archivo = null;
      this.loading = false;
      this.externo = 'Interno';
      this.cantidad = 'Único';
                  
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    
  },
 
};
</script>