<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Gestionar Alergias</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="mt-3">
          <v-col cols="12">
            <h2 style="color: indigo">Paciente: {{ nombrePaciente }}</h2>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="this.listaAlergias"
        class="elevation-1"
        locale="es-ar"
        item-key="idAlergiaPaciente"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarAlergia(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Alergia</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarAlergia(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Alergia</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaAlergia"
          >Registrar Alergia</v-btn
        >
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
      <NuevaAlergia
        :visible="showNuevaAlergia"
        :idPaciente="idPaciente"
        @close="cierraNuevaAlergia()"
        @alergiaRegistrada="alergiaRegistrada()"
      ></NuevaAlergia>
      <EditarAlergia
        :visible="showEditarAlergia"
        :idAlergiaPaciente="editedItem.idAlergiaPaciente"
        @close="cierraEditarAlergia()"
        @alergiaModificada="alergiaModificada()"
      ></EditarAlergia>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevaAlergia from "./NuevaAlergia.vue";
import EditarAlergia from "./EditarAlergia.vue";
export default {
  data: (vm) => ({
    listaAlergias: [],
    headers: [
      { text: "Alergia", value: "alergia" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevaAlergia: false,
    showEditarAlergia: false,
    editedItem: {},
    editedIndex: -1,
  }),

  props: ["visible", "nombrePaciente", "idPaciente"],

  computed: {
    show: {
      get() {
        this.buscarAlergias();
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    borrarAlergia(item) {
      this.$swal({
        title: "Borrar Alergia",
        text: "¿Desea borrar la alergia?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idAlergiaPaciente = item.idAlergiaPaciente;
          let self = this;
          axios
            .get("/Paciente/BorrarAlergia?", {
              params: {
                idAlergiaPaciente: idAlergiaPaciente,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Alergia borrada exitosamente",
                vm: self,
              });
              setTimeout(self.buscarAlergias(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La alergia no pudo ser borrada",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevaAlergia() {
      this.showNuevaAlergia = false;
      //this.buscarPlanes();
    },
    cierraEditarAlergia() {
      this.showEditarAlergia = false;
      //this.buscarObrasSociales();
    },
    alergiaRegistrada() {
      this.showNuevaAlergia = false;
      this.buscarAlergias();
    },
    alergiaModificada() {
      this.showEditarAlergia = false;
      this.buscarAlergias();
    },
    nuevaAlergia() {
      this.showNuevaAlergia = true;
    },
    editarAlergia(item) {
      this.editedIndex = this.listaAlergias.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarAlergia = true;
    },

    buscarAlergias() {
      const idPaciente = this.idPaciente;

      let self = this;
      axios
        .get("/Paciente/GetAlergiasDePaciente?", {
          params: {
            idPaciente: idPaciente,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaAlergias = response.data;
          else self.listaAlergias = [];
        })
        .catch(function(error) {});
    },
  },
  components: {
    NuevaAlergia,
    EditarAlergia,
  },
};
</script>
