<template>
  <v-card>
    <v-toolbar dark color="primary">
      <v-btn icon dark @click="salir">
        <v-icon>mdi-close</v-icon>
      </v-btn>
      <v-toolbar-title>Pestañas Habilitadas</v-toolbar-title>
    </v-toolbar>

    <v-card-text>
      <v-data-table
        :headers="headers"
        :items="this.tabs"
        v-model="selectedTabs"
        class="elevation-1"
        show-select
        :hide-default-footer="true"
        locale="es-ar"
        item-key="idHcTab"
        :items-per-page="10000"
        ref="myTable"
        :disable-sort="true"
      >
      </v-data-table>
    </v-card-text>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="success" @click.stop="guardar">Guardar</v-btn>
      <v-btn color="error" @click.stop="salir">Salir</v-btn>
    </v-card-actions>
  </v-card>
</template>
<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import router from "../../router/index.js";
  export default {
    data: (vm) => ({
      headers: [{ text: "Seleccionar Pestañas", value: "nombre" }],
      selectedTabs: [],
      tabs: [],
    }),
    methods: {
      guardar() {
        this.$swal({
          title: "Modificar configuración de pestañas",
          text: "¿Desea modificar la configuración?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          let self = this;
          let selectedTabsSave;
          selectedTabsSave = this.tabs.filter(function(array_el) {
            return (
              self.selectedTabs.filter(function(anotherOne_el) {
                return anotherOne_el.idHcTab == array_el.idHcTab;
              }).length == 0
            );
          });
          let tabList = selectedTabsSave.map((a) => a.idHcTab);
          const datosTabs = {
            selectedTabsToSave: tabList,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          };
          axios
            .get("/HistoriaClinica/SaveTabs", {
              params: {
                selectedTabsToSave: datosTabs.selectedTabsToSave,
                idInstitucion: datosTabs.idInstitucion,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title:
                  "Configuración modificada exitosamente. Los cambios se verán reflejados a partir del próximo inicio de sesión.",
                vm: self,
              });
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La configuración no pudo ser modificada.",
                vm: self,
              });
            });
        });
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 3500,
        });
      },
      getTabs() {
        let self = this;
        axios.get("/HistoriaClinica/GetTabs").then((response) => {
          self.tabs = response.data;
          self.getSelectedTabs();
        });
      },
      getSelectedTabs() {
        let self = this;
        const idInstitucion = this.$store.state.usuario.institucion
          .idInstitucion;
        axios
          .get("/HistoriaClinica/GetTabsInstitucion", {
            params: {
              idInstitucion: idInstitucion,
            },
          })
          .then((response) => {
            if (response.data == null || response.data == undefined) {
              self.selectedTabs = self.tabs;
            } else {
              self.selectedTabs = self.tabs.filter(function(array_el) {
                return (
                  response.data.filter(function(anotherOne_el) {
                    return anotherOne_el.idHcTabs == array_el.idHcTab;
                  }).length == 0
                );
              });
            }
          });
      },
      salir() {
        router.push({ path: "/Home" });
      },
    },
    mounted() {
      this.getTabs();
    },
  };
</script>
