<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Traslados</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Traslados</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-select
                :items="choferesBuscar"
                item-text="apellidoNombreChofer"
                item-value="idChofer"
                menu-props="auto"
                label="Chofer"
                hide-details
                prepend-icon="mdi-ambulance"
                v-model="chofer"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-select
                :items="enfermerosBuscar"
                item-text="apellidoNombre"
                item-value="idEnfermero"
                menu-props="auto"
                label="Enfermero"
                hide-details
                prepend-icon="mdi-needle"
                v-model="enfermero"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="fechaSalidaDesde"
                :value="fechaSalidaDesde"
                label="Fecha Salida Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="3">
              <v-text-field
                v-model="fechaSalidaHasta"
                :value="fechaSalidaHasta"
                :rules="requiredRules"
                label="Fecha Salida Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="paciente"
                prepend-icon="mdi-account"
                label="Paciente"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarTraslados(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaTraslados"
        class="elevation-1"
        locale="es-ar"
        item-key="idTraslado"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarTraslado(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Traslado</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                class="mr-3"
                v-bind="attrs"
                @click="borrarTraslado(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Traslado</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoTraslado"
          >Nuevo Traslado</v-btn
        >
        <v-btn
          color="warning"
          :disabled="listaTraslados.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoTraslado
        :visible="showNuevoTraslado"
        @close="cierraNuevoTraslado()"
        @trasladoRegistrado="trasladoRegistrado"
      ></NuevoTraslado>
      <EditarTraslado
        :visible="showEditarTraslado"
        :idTraslado="editedItem.idTraslado"
        @close="cierraEditarTraslado()"
        @trasladoModificado="trasladoModificado"
      ></EditarTraslado>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevoTraslado from "../Traslados/NuevoTraslado.vue";
import EditarTraslado from "../Traslados/EditarTraslado.vue";

export default {
  name: "Traslados",
  data: (vm) => ({
    valid: false,
    lazy: false,
    paciente: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    chofer: null,
    choferesBuscar: [],
    enfermero: null,
    enfermerosBuscar: [],
    listaTraslados: [],
    headers: [
      { text: "Fecha Salida", value: "fechaSalidaString" },
      { text: "Hora Salida", value: "horaSalidaString" },
      { text: "Fecha LLegada", value: "fechaLlegadaString" },
      { text: "Hora LLegada", value: "horaLlegadaString" },
      { text: "Chofer", value: "apellidoNombreChofer" },
      { text: "Enfermero", value: "apellidoNombreEnfermero" },
      { text: "Paciente", value: "paciente" },
      { text: "Diagnóstico", value: "diagnostico" },
      { text: "Lugar de Traslado", value: "lugarTraslado" },
      { text: "Observaciones", value: "observaciones" },
      { text: "Acciones", value: "actions" },
    ],
    fechaSalidaDesde: null,
    fechaSalidaHasta: null,
    showNuevoTraslado: false,
    showEditarTraslado: false,
    editedItem: {},
    editedIndex: -1,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    borrarTraslado(item) {
      this.$swal({
        title: "Borrar Traslado",
        text: "¿Desea borrar el traslado?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idTraslado = item.idTraslado;
          let self = this;
          axios
            .get("/Traslados/BorrarTraslado?", {
              params: {
                idTraslado: idTraslado,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Traslado borrado",
                vm: self,
                timer: 1700,
              });
              setTimeout(self.buscarTraslados(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El traslado no pudo ser borrado",
                vm: this,
                timer: 2800,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoTraslado() {
      this.showNuevoTraslado = false;
      this.buscarTraslados(0);
    },
    cierraEditarTraslado() {
      this.showEditarTraslado = false;
      this.buscarTraslados(0);
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    nuevoTraslado() {
      this.showNuevoTraslado = true;
    },
    editarTraslado(item) {
      this.editedIndex = this.listaTraslados.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarTraslado = true;
    },
    trasladoRegistrado() {
      this.buscarTraslados(0);
      this.showNuevoTraslado = false;
    },
    trasladoModificado() {
      this.buscarTraslados(0);
      this.showEditarTraslado = false;
    },
    buscarTraslados(state) {
      const filtrosData = {
        paciente: this.paciente,
        fechaSalidaDesde: this.fechaSalidaDesde,
        fechaSalidaHasta: this.fechaSalidaHasta,
        idChofer: this.chofer,
        idEnfermero: this.enfermero,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Traslados/BuscarTraslados?", {
          params: {
            paciente: filtrosData.paciente,
            fechaSalidaDesde: filtrosData.fechaSalidaDesde,
            fechaSalidaHasta: filtrosData.fechaSalidaHasta,
            idChofer: filtrosData.idChofer,
            idEnfermero: filtrosData.idEnfermero,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaTraslados = response.data;
          else {
            self.listaTraslados = [];

            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay traslados que coincidan con el criterio de búsqueda.",
                vm: self,
                timer: 2000,
              });
            }
          }
        })
        .catch(function(error) {});
    },
    imprimir() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = [
        "Fecha Salida",
        "Hora Salida",
        "Fecha Llegada",
        "Hora Llegada",
        "Chofer",
        "Enfermero",
        "Paciente",
        "Diagnóstico",
        "Lugar de Traslado",
        "Observaciones",
      ];
      source.items.forEach((element) => {
        var temp = [
          element.fechaSalidaString,
          element.horaSalidaString,
          element.fechaLlegadaString,
          element.horaLlegadaString,
          element.apellidoNombreChofer,
          element.apellidoNombreEnfermero,
          element.paciente,
          element.diagnostico,
          element.lugarTraslado,
          element.observaciones,
        ];
        rows.push(temp);
      });
      //let columnHeader = source.items.headers
      //let rows = source.items.rows
      var doc = new jsPDF("landscape");
      doc.setProperties({
        title: "Listado Traslados",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Fecha Salida Desde: " +
          this.formatDate(this.fechaSalidaDesde) +
          "  -  " +
          "Fecha Salida Hasta: " +
          this.formatDate(this.fechaSalidaHasta),
        13,
        16
      );

      doc.setFontSize(16);
      doc.text(
        "Listado de Traslados",
        doc.internal.pageSize.getWidth() / 2,
        33,
        {
          align: "center",
        }
      );
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function(table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Enfermeria/BuscarEnfermerosAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.enfermerosBuscar = response.data;
        self.enfermerosBuscar.unshift({
          idEnfermero: 0,
          apellidoNombre: "TODOS",
        });
        self.enfermero = self.enfermerosBuscar[0].idEnfermero;
      });
    axios
      .get("/Traslados/BuscarChoferes?", {
        params: {
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.choferesBuscar = response.data;
        self.choferesBuscar.unshift({
          idChofer: 0,
          apellidoNombreChofer: "TODOS",
        });
        self.chofer = self.choferesBuscar[0].idChofer;
      });

    this.fechaSalidaDesde = this.parseDate(
      this.formatDate(this.toISOLocal(new Date()))
    );
    this.fechaSalidaHasta = this.parseDate(
      this.formatDate(this.toISOLocal(new Date()))
    );
  },

  components: {
    NuevoTraslado,
    EditarTraslado,
  },
};
</script>
