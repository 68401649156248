<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Profesionales</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Profesionales</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="apellidos"
                prepend-icon="mdi-account"
                label="Apellidos"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="nombres"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-select
                :items="estados"
                item-text="nombreEstado"
                item-value="idEstado"
                menu-props="auto"
                label="Estado"
                hide-details
                prepend-icon="mdi-account"
                v-model="estado"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarProfesionales(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaProfesionales"
        class="elevation-1"
        locale="es-ar"
        item-key="idProfesional"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarProfesional(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Profesional</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-if="
                  roles.some((o) => o.idRol === 1) ||
                  roles.some((o) => o.idRol === 6)
                "
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="relacionesUsuario(item)"
                >mdi-cog</v-icon
              >
            </template>
            <span>Relacionar Usuarios</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.estado == 'Activo' && permiteCrearProfesionales"
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="desactivarProfesional(item)"
                >mdi-thumb-down</v-icon
              >
            </template>
            <span>Desactivar Profesional</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="item.estado == 'Inactivo' && permiteCrearProfesionales"
                medium
                color="green"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="activarProfesional(item)"
                >mdi-thumb-up</v-icon
              >
            </template>
            <span>Activar Profesional</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                v-show="permiteCrearProfesionales"
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarProfesional(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Profesional</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions class="ml-0 pl-0">
        <v-spacer></v-spacer>
        <v-btn
          class="ml-0 pl-0 mr-0 pr-0"
          color="success"
          v-show="permiteCrearProfesionales"
          @click.stop="nuevoProfesional"
          >Nuevo Profesional</v-btn
        >
        <v-btn
          color="warning"
          :disabled="listaProfesionales.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoProfesional
        :visible="showNuevoProfesional"
        @close="cierraNuevoProfesional()"
      ></NuevoProfesional>
      <EditarProfesional
        :visible="showEditarProfesional"
        :idProfesional="editedItem.idProfesional"
        :matriculaActual="editedItem.matricula"
        @close="cierraEditarProfesional()"
        @profesionalModificado="profesionalModificado"
      ></EditarProfesional>
      <RelacionarUsuarios
        :visible="showRelacionarUsuarios"
        :idProfesional="editedItem.idProfesional"
        :nombreProfesional="
          editedItem.apellidos + ' ' + editedItem.nombreProfesional
        "
        @close="cierraRelacionarUsuarios()"
        @profesionalConfigurado="profesionalConfigurado"
      />
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevoProfesional from "../Configuraciones/NuevoProfesional.vue";
import EditarProfesional from "../Configuraciones/EditarProfesional.vue";
import RelacionarUsuarios from "./RelacionarUsuarios.vue";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    apellidos: null,
    nombres: null,
    listaProfesionales: [],
    headers: [
      { text: "Matr.", value: "matricula" },
      { text: "Apellidos", value: "apellidos" },
      { text: "Nombres", value: "nombreProfesional" },
      { text: "Estado", value: "estado" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoProfesional: false,
    showEditarProfesional: false,
    showRelacionarUsuarios: false,
    editedItem: {},
    editedIndex: -1,
    permiteCrearProfesionales: false,
    estado: null,
    estados: [
      { nombreEstado: "Todos", idEstado: 0 },
      { nombreEstado: "Activos", idEstado: 1 },
      { nombreEstado: "Inactivos", idEstado: 2 },
    ],
    roles: [],
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    activarProfesional(item) {
      this.$swal({
        title: "Activar Profesional",
        text: "¿Desea activar el profesional?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idProfesional = item.idProfesional;
          let self = this;
          axios
            .get("/Profesional/DesactivarActivarProfesional?", {
              params: {
                idProfesional: idProfesional,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
                estado: "Activo",
              },
            })
            .then((response) => {
              self.$store.dispatch("updateSession", {
                usuario: self.$store.state.usuario.usuario,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
              });

              self.showAlert({
                icon: "success",
                title: "Profesional activado exitosamente",
                vm: self,
              });

              setTimeout(self.buscarProfesionales(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El profesional no pudo ser activado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    desactivarProfesional(item) {
      this.$swal({
        title: "Desactivar Profesional",
        text: "¿Desea desactivar el profesional?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idProfesional = item.idProfesional;
          let self = this;
          axios
            .get("/Profesional/DesactivarActivarProfesional?", {
              params: {
                idProfesional: idProfesional,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
                estado: "Inactivo",
              },
            })
            .then((response) => {
              self.$store.dispatch("updateSession", {
                usuario: self.$store.state.usuario.usuario,
                idInstitucion:
                  self.$store.state.usuario.institucion.idInstitucion,
              });
              self.showAlert({
                icon: "success",
                title: "Profesional desactivado exitosamente",
                vm: self,
              });
              setTimeout(self.buscarProfesionales(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El profesional no pudo ser desactivado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    borrarProfesional(item) {
      this.$swal({
        title: "Borrar Profesional",
        text: "¿Desea borrar el profesional?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idprofesional = item.idProfesional;
          let self = this;
          axios
            .get("/Profesional/BorrarProfesional?", {
              params: {
                idProfesional: idprofesional,
              },
            })
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: "El profesional tiene transacciones asociadas",
                  vm: self,
                });
              } else {
                self.$store.dispatch("updateSession", {
                  usuario: self.$store.state.usuario.usuario,
                  idInstitucion:
                    self.$store.state.usuario.institucion.idInstitucion,
                });
                self.showAlert({
                  icon: "success",
                  title: "Profesional borrado exitosamente",
                  vm: self,
                });
                setTimeout(self.buscarProfesionales(0), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El profesional no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoProfesional() {
      this.showNuevoProfesional = false;
      this.buscarProfesionales(0);
    },
    cierraEditarProfesional() {
      this.showEditarProfesional = false;
      this.buscarProfesionales(0);
    },
    cierraRelacionarUsuarios() {
      this.showRelacionarUsuarios = false;
    },
    profesionalModificado() {
      this.showEditarProfesional = false;
      this.buscarProfesionales(0);
    },
    profesionalConfigurado() {
      this.showRelacionarUsuarios = false;
    },
    nuevoProfesional() {
      this.showNuevoProfesional = true;
    },
    editarProfesional(item) {
      this.editedIndex = this.listaProfesionales.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarProfesional = true;
    },
    relacionesUsuario(item) {
      this.editedIndex = this.listaProfesionales.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showRelacionarUsuarios = true;
    },
    buscarProfesionales(state) {
      const filtrosData = {
        apellidos: this.apellidos,
        nombres: this.nombres,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        estado: this.estado,
      };

      let self = this;
      axios
        .get("/Profesional/GetProfesionalesApeNomDeInst?", {
          params: {
            ape: filtrosData.apellidos,
            nom: filtrosData.nombres,
            idInst: filtrosData.idInstitucion,
            estado: filtrosData.estado,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "")
            self.listaProfesionales = response.data;
          else {
            self.listaProfesionales = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay profesionales que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function (error) {});
    },
    imprimir() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = ["Matr.", "Apellidos", "Nombres", "Estado"];
      let importeTotal = 0;
      source.items.forEach((element) => {
        var temp = [
          element.matricula,
          element.apellidos,
          element.nombreProfesional,
          element.estado,
        ];
        rows.push(temp);
      });
      //let columnHeader = source.items.headers
      //let rows = source.items.rows
      var doc = new jsPDF();
      doc.setProperties({
        title: "Listado Profesionales",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion =
        this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);

      doc.setFontSize(16);
      doc.text(
        "Listado de Profesionales",
        doc.internal.pageSize.getWidth() / 2,
        33,
        { align: "center" }
      );
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });

      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
  created() {
    this.roles = this.$store.state.usuario.roles;
    this.permiteCrearProfesionales =
      this.$store.state.usuario.institucion.permiteCrearProfesionales;
    this.estado = 0;
    this.buscarProfesionales(0);
  },
  components: {
    NuevoProfesional,
    EditarProfesional,
    RelacionarUsuarios,
  },
};
</script>
