<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Recordatorios</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-row>
          <v-col cols="12">
            <v-checkbox
              v-model="soloVigentes"
              label="Mostrar en la grilla solo recordatorios vigentes"
              color="primary"
              dense
              @change="buscarRecordatorios"
            ></v-checkbox>
          </v-col>
        </v-row>
      </v-card-text>
      <v-data-table
        :headers="headers"
        :items="this.listaRecordatorios"
        class="elevation-1"
        locale="es-ar"
        item-key="usuario"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarRecordatorio(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Recordatorio</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarRecordatorio(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Recordatorio</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoRecordatorio">Nuevo</v-btn>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoRecordatorio
        :visible="showNuevoRecordatorio"
        @close="cierraNuevoRecordatorio()"
        @recordatorioRegistrado="recordatorioRegistrado()"
      ></NuevoRecordatorio>
      <EditarRecordatorio
        :visible="showEditarRecordatorio"
        :idRecordatorio="editedItem.idRecordatorio"
        @close="cierraEditarRecordatorio()"
        @recordatorioModificado="recordatorioModificado()"
      ></EditarRecordatorio>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import NuevoRecordatorio from "../Configuraciones/NuevoRecordatorio.vue";
import EditarRecordatorio from "../Configuraciones/EditarRecordatorio.vue";
export default {
  data: (vm) => ({
    listaRecordatorios: [],
    headers: [
      { text: "Titulo", value: "titulo" },
      { text: "Texto", value: "texto" },
      { text: "Vig. Desde", value: "fechaDesdeString" },
      { text: "Vig. Hasta", value: "fechaHastaString" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoRecordatorio: false,
    showEditarRecordatorio: false,
    editedItem: {},
    editedIndex: -1,
    soloVigentes: true,
  }),
  created() {
    this.soloVigentes = true;
    this.buscarRecordatorios();
  },
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarRecordatorio(item) {
      this.$swal({
        title: "Borrar Recordatorio",
        text: "¿Desea borrar el recordatorio?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idRecordatorio = item.idRecordatorio;
          let self = this;
          axios
            .get("/Usuario/BorrarRecordatorio?", {
              params: {
                idRecordatorio: idRecordatorio,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Recordatorio borrado exitosamente",
                vm: self,
              });
              setTimeout(self.buscarRecordatorios(), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El recordatorio no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoRecordatorio() {
      this.showNuevoRecordatorio = false;
      //this.buscarUsuarios();
    },
    cierraEditarRecordatorio() {
      this.showEditarRecordatorio = false;
      //this.buscarUsuarios();
    },
    recordatorioRegistrado() {
      this.showNuevoRecordatorio = false;
      this.buscarRecordatorios();
    },
    recordatorioModificado() {
      this.showEditarRecordatorio = false;
      this.buscarRecordatorios();
    },
    nuevoRecordatorio() {
      this.showNuevoRecordatorio = true;
    },
    editarRecordatorio(item) {
      this.editedIndex = this.listaRecordatorios.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarRecordatorio = true;
    },

    buscarRecordatorios() {
      const filtrosData = {
        soloVigentes: this.soloVigentes,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Usuario/BuscarRecordatorios?", {
          params: {
            soloVigentes: filtrosData.soloVigentes,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaRecordatorios = response.data;
          else self.listaRecordatorios = [];
        })
        .catch(function(error) {});
    },
  },

  components: {
    NuevoRecordatorio,
    EditarRecordatorio,
  },
};
</script>
