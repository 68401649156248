<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Recetas</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaRecetas"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="verReceta"
        item-key="idHC"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="verReceta(item)"
                >mdi-magnify</v-icon
              >
            </template>
            <span>Ver Receta</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarReceta(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Receta</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarReceta(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Receta</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="orange"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="enviarReceta(item)"
                >mdi-email</v-icon
              >
            </template>
            <span>Enviar Receta por Mail</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaReceta">Nueva Receta</v-btn>
        <v-btn color="warning" @click.stop="showVerTodas = true"
          >Ver Todas</v-btn
        >
      </v-card-actions>
      <NuevaReceta
        :visible="showNuevaReceta"
        :idPaciente="idPaciente"
        :paciente="paciente"
        :institucion="institucion"
        @close="showNuevaReceta = false"
        @recetaRegistrada="recetaRegistrada"
      ></NuevaReceta>
      <EditarReceta
        :visible="showEditarReceta"
        :idPaciente="idPaciente"
        :idReceta="editedItem.idReceta"
        :paciente="paciente"
        :institucion="institucion"
        @close="showEditarReceta = false"
        @recetaModificada="recetaModificada"
      ></EditarReceta>
      <VerReceta
        :visible="showVerReceta"
        :receta="editedItem"
        :paciente="paciente"
        :institucion="institucion"
        @close="showVerReceta = false"
      ></VerReceta>
      <EnviarReceta
        :visible="showEnviarReceta"
        :receta="editedItem"
        :paciente="paciente"
        :institucion="institucion"
        @close="showEnviarReceta = false"
      ></EnviarReceta>
      <VerTodasRecetas
        :visible="showVerTodas"
        :recetas="listaRecetas"
        :paciente="paciente"
        @close="showVerTodas = false"
      ></VerTodasRecetas>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevaReceta from "../HistClin/NuevaReceta.vue";
import VerReceta from "../HistClin/VerReceta.vue";
import EditarReceta from "../HistClin/EditarReceta.vue";
import VerTodasRecetas from "../HistClin/VerTodasRecetas.vue";
import EnviarReceta from "../HistClin/EnviarReceta.vue";
export default {
  data: (vm) => ({
    headers: [
      { text: "Fecha", value: "fechaRecetaString" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevaReceta: false,
    showVerReceta: false,
    showEditarReceta: false,
    showEnviarReceta: false,
    editedItem: {},
    editedIndex: -1,
    ultimaReceta: {},
    showVerTodas: false,
  }),
  props: ["idPaciente", "lista", "paciente", "profsBuscar", "institucion"],
  computed: {
    listaRecetas: {
      set(value) {
        if (value != []) this.$emit("actualizarRecetas");
      },
      get() {
        return this.lista;
      },
    },
  },
  methods: {
    borrarReceta(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.$swal({
          title: "Borrar Receta",
          text: "¿Desea borrar la receta?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idReceta = item.idReceta;
            let self = this;
            axios
              .get("/HistoriaClinica/BorrarReceta?", {
                params: {
                  idReceta: idReceta,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Receta borrada exitosamente",
                  vm: self,
                  timer: 1700,
                });
                setTimeout(self.buscarRecetas(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La receta no pudo ser borrada",
                  vm: this,
                  timer: 1700,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      } else {
        this.showAlert({
          icon: "error",
          title:
            "La receta fue creada por un profesional que usted no tiene asociado. No puede eliminar la receta",
          vm: this,
          timer: 3000,
        });
      }
    },
    editarReceta(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaRecetas.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEditarReceta = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "La receta fue creada por un profesional que usted no tiene asociado. No puede editar la receta",
          vm: this,
          timer: 3000,
        });
      }
    },
    enviarReceta(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.editedIndex = this.listaRecetas.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEnviarReceta = true;
      } else {
        this.showAlert({
          icon: "error",
          title:
            "La receta fue creada por un profesional que usted no tiene asociado. No puede editar la receta",
          vm: this,
          timer: 3000,
        });
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevaReceta() {
      this.showNuevaReceta = true;
    },
    verReceta(item) {
      this.editedIndex = this.listaRecetas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerReceta = true;
    },
    recetaRegistrada() {
      this.buscarRecetas();
      this.showNuevaReceta = false;
    },
    recetaModificada() {
      this.buscarRecetas();
      this.showEditarReceta = false;
    },
    buscarRecetas() {
      this.listaRecetas = 1;
    },
  },
  components: {
    NuevaReceta,
    VerReceta,
    EditarReceta,
    VerTodasRecetas,
    EnviarReceta,
  },
};
</script>
