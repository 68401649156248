<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Notas de Enfermería</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaNotasEnfermeria"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="editarNotaEnfermeria"
        item-key="idHC"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        no-data-text="Paciente sin registros"
        no-results-text="Paciente sin registros"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.nota="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <span v-on="on" v-bind="attrs">
                {{ item.textoNotaTruncado }}
              </span>
            </template>
            <span>{{ item.textoNota }}</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }" v-if="origen === 'enfermeria'">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarNotaEnfermeria(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Nota</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarNotaEnfermeria(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Nota</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          v-if="origen === 'enfermeria'"
          color="success"
          @click.stop="nuevaNotaEnfermeria"
          >Nueva Nota</v-btn
        >
      </v-card-actions>
      <NuevaNotaEnfermeria
        :visible="showNuevaNotaEnfermeria"
        :idPaciente="idPaciente"
        @close="showNuevaNotaEnfermeria = false"
        @notaEnfermeriaRegistrada="notaEnfermeriaRegistrada"
      ></NuevaNotaEnfermeria>
      <EditarNotaEnfermeria
        :visible="showEditarNotaEnfermeria"
        :idPaciente="idPaciente"
        :idNotaEnfermeria="editedItem.idNotaEnfermeria"
        :origen="origen"
        @close="showEditarNotaEnfermeria = false"
        @notaEnfermeriaModificada="notaEnfermeriaModificada"
      ></EditarNotaEnfermeria>
    </v-card>
  </div>
</template>
<style scoped>
  .row-pointer >>> tbody tr :hover {
    cursor: pointer;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import NuevaNotaEnfermeria from "./NuevaNotaEnfermeria.vue";
  import EditarNotaEnfermeria from "./EditarNotaEnfermeria.vue";

  export default {
    data: (vm) => ({
      headers: vm.getHeaders(),
      showNuevaNotaEnfermeria: false,
      showEditarNotaEnfermeria: false,
      editedItem: {},
      editedIndex: -1,
    }),
    props: {
      idPaciente: {
        required: true,
      },
      lista: {
        required: true,
      },
      paciente: {
        required: true,
      },
      enfermerosHabilitados: {
        required: false,
      },
      institucion: {
        required: false,
      },
      origen: {
        type: String,
        required: false,
        default: "enfermeria",
      },
    },
    computed: {
      listaNotasEnfermeria: {
        set(value) {
          if (value != []) this.$emit("actualizarNotasEnfermeria");
        },
        get() {
          return this.lista;
        },
      },
    },
    methods: {
      getHeaders() {
        let headers = [
          { text: "Fecha", value: "fechaNotaString" },
          { text: "Enfermero", value: "nombreEnfermero" },
          { text: "Texto Nota", value: "nota" },
        ];
        if (this.origen === "enfermeria") {
          headers.push({ text: "Acciones", value: "actions" });
        }
        return headers;
      },
      borrarNotaEnfermeria(item) {
        if (
          this.enfermerosHabilitados.some(
            (o) => o.idEnfermero == item.idEnfermero
          )
        ) {
          this.$swal({
            title: "Borrar Nota de Enfermería",
            text: "¿Desea borrar la nota?",
            icon: "success",
            background: "#ececec",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            showCloseButton: true,
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.value) {
              //this.$swal('Turno g', 'You successfully deleted this file', 'success')

              const idNotaEnfermeria = item.idNotaEnfermeria;
              let self = this;
              axios
                .get("/Enfermeria/BorrarNotaEnfermeria?", {
                  params: {
                    idNotaEnfermeria: idNotaEnfermeria,
                  },
                })
                .then((response) => {
                  self.showAlert({
                    icon: "success",
                    title: "Nota borrada exitosamente",
                    vm: self,
                    timer: 1700,
                  });
                  setTimeout(self.buscarNotasEnfermeria(), 1700);
                })
                .catch((error) => {
                  self.showAlert({
                    icon: "error",
                    title: "La nota no pudo ser borrada",
                    vm: this,
                    timer: 1700,
                  });
                });
            } else {
              //this.$swal('Cancelled', 'Your file is still intact', 'info')
            }
          });
        } else {
          this.showAlert({
            icon: "error",
            title:
              "La nota fue creada por un enfermero que usted no tiene asociado. No puede eliminar la nota",
            vm: this,
            timer: 3000,
          });
        }
      },
      editarNotaEnfermeria(item) {
        if (this.origen === "hc") {
          this.editedIndex = this.listaNotasEnfermeria.indexOf(item);
          this.editedItem = Object.assign({}, item);
          this.showEditarNotaEnfermeria = true;
          return;
        }
        if (
          this.enfermerosHabilitados.some(
            (o) => o.idEnfermero == item.idEnfermero
          )
        ) {
          this.editedIndex = this.listaNotasEnfermeria.indexOf(item);
          this.editedItem = Object.assign({}, item);
          this.showEditarNotaEnfermeria = true;
        } else {
          this.showAlert({
            icon: "error",
            title:
              "La nota fue creada por un enfermero que usted no tiene asociado. No puede editar la nota",
            vm: this,
            timer: 3000,
          });
        }
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: alertInfo.timer,
        });
      },
      nuevaNotaEnfermeria() {
        this.showNuevaNotaEnfermeria = true;
      },
      notaEnfermeriaRegistrada() {
        this.buscarNotasEnfermeria();
        this.showNuevaNotaEnfermeria = false;
      },
      notaEnfermeriaModificada() {
        this.buscarNotasEnfermeria();
        this.showEditarNotaEnfermeria = false;
      },
      buscarNotasEnfermeria() {
        this.listaNotasEnfermeria = 1;
      },
    },
    components: {
      NuevaNotaEnfermeria,
      EditarNotaEnfermeria,
    },
  };
</script>
