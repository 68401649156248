<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Listado de Archivos</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Archivos</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="6">
              <v-select
                :items="origen"
                item-text="origen"
                item-value="origen"
                menu-props="auto"
                label="Orígen del Archivo"
                hide-details
                prepend-icon="mdi-account"
                v-model="externo"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="7" md="6">
              <v-select
                :items="listaInstitucionesExternas"
                item-text="nombreInstitucionExterna"
                item-value="idInstitucionExterna"
                menu-props="auto"
                label="Institución Externa"
                prepend-icon="mdi-home"
                v-model="institucionExterna"
                persistent-hint
                v-if="externo != 'Interno'"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarArchivos(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaInformes"
        class="elevation-1 row-pointer"
        locale="es-ar"
        item-key="idInforme"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.varios="{ item }">
          <v-simple-checkbox v-model="item.varios" disabled></v-simple-checkbox>
        </template>
      </v-data-table>
      <v-row class="mt-4">
        <v-spacer></v-spacer>
        <v-text-field
          v-model="cantArchivos"
          label="Cant. Archivos"
          readonly
          class="shrink mr-6"
          style="width: 80px"
        >
        </v-text-field>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          :disabled="listaInformes.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    paciente: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    prof: "",
    profsBuscar: [],
    listaInstitucionesExternas: [],
    institucionExterna: null,
    origen: ["TODOS", "Interno", "Externo"],
    externo: "TODOS",
    listaInformes: [],
    headers: [
      { text: "Fecha", value: "fechaInformeString" },
      { text: "Paciente", value: "nombrePaciente" },
      { text: "Título", value: "titulo" },
      { text: "Descripción", value: "descripcion" },
      { text: "Tipo Archivo", value: "extension" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Origen", value: "origenString" },
      { text: "Mult. Archivos", value: "varios" },
      { text: "Inst. Externa", value: "nombreInstitucionExterna" },
    ],
    fechaDesde: null,
    fechaHasta: null,
    maxWidth: null,
    cantArchivos: 0,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    buscarArchivos(state) {
      var idInstitucionExterna;

      if (this.externo == "Interno") idInstitucionExterna = 0;
      else idInstitucionExterna = this.institucionExterna;

      const filtrosData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        idProfesional: this.prof,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        idInstitucionExterna: idInstitucionExterna,
        externo: this.externo,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/BuscarInformes?", {
          params: {
            fechaDesde: filtrosData.fechaDesde,
            fechaHasta: filtrosData.fechaHasta,
            idProfesional: filtrosData.idProfesional,
            idInstitucion: filtrosData.idInstitucion,
            idInstitucionExterna: filtrosData.idInstitucionExterna,
            externo: filtrosData.externo,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "") {
            self.listaInformes = response.data;
            self.cantArchivos = self.listaInformes.length;
          } else {
            self.listaInformes = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay archivos que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function(error) {});
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    imprimir() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = [
        "Fecha",
        "Paciente",
        "Título",
        "Descripción",
        "Tipo Archivo",
        "Profesional",
        "Orígen",
        "Inst. Externa",
      ];
      let cantArchivos = this.listaInformes.length;
      source.items.forEach((element) => {
        var temp = [
          element.fechaString,
          element.nombrePaciente,
          element.titulo,
          element.descripcion,
          element.extension,
          element.nomProfesional,
          element.origenString,
          element.nombreInstitucionExterna,
        ];
        rows.push(temp);
      });
      //let columnHeader = source.items.headers
      //let rows = source.items.rows
      var doc = new jsPDF("landscape");
      doc.setProperties({
        title: "Listado de Archivos",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Fecha Desde: " +
          this.formatDate(this.fechaDesde) +
          "  -  " +
          "Fecha Hasta: " +
          this.formatDate(this.fechaHasta),
        13,
        16
      );

      doc.setFontSize(16);
      doc.text(
        "Listado de Archivos",
        doc.internal.pageSize.getWidth() / 2,
        33,
        {
          align: "center",
        }
      );
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function(table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text("Cant. Archivos: " + cantArchivos, 245, finalY + 8);
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
  created() {
    if (this.$store.state.videollamada == true) this.maxWidth = "68%";
    else this.maxWidth = "100%";
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
        if (self.profsBuscar.length > 1)
          self.profsBuscar.unshift({
            idProfesional: 0,
            nombreProfesional: "TODOS",
          });

        self.prof = self.profsBuscar[0].idProfesional;
      });

    axios
      .get("/HistoriaClinica/GetInstitucionesExternas?", {
        params: {
          idInstitucion: self.$store.state.usuario.institucion.idInstitucion,
        },
      })
      .then(function(response) {
        if (response.data != null && response.data != "") {
          self.listaInstitucionesExternas = response.data;
          self.listaInstitucionesExternas.unshift({
            idInstitucionExterna: 0,
            nombreInstitucionExterna: "TODAS",
          });
          self.institucionExterna =
            self.listaInstitucionesExternas[0].idInstitucionExterna;
        } else self.listaInstitucionesExternas = [];
      })

      .catch(function(error) {});

    this.fechaDesde = this.parseDate(
      this.formatDate(this.toISOLocal(new Date()))
    );
    this.fechaHasta = this.fechaDesde;
  },
};
</script>
