<template>
  <v-dialog :max-width="maxWidth" v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Detalle Presupuesto</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="10">
              <v-text-field
                v-model="texto"
                prepend-icon="mdi-account"
                label="Descripción"
                :rules="requiredRules"
                dense
                maxlength="150"
              ></v-text-field>
            </v-col>
            <v-col cols="9" sm="4" md="2">
              <v-text-field
                v-model="valor"
                prepend-icon="mdi-currency-usd"
                label="Importe"
                dense
                :rules="requiredRules"
                @keypress="onlyNumber"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarDetalle">Modificar</v-btn>
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
}
</style>
<script>
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "80%",
    texto: "",
    valor: "",
    requiredRules: [(v) => !!v || "Dato obligatorio"],
  }),
  props: ["visible", "detalle"],
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    vaciarModels() {
      this.texto = "";
      this.valor = "";
    },
    setearModels() {
      this.texto = this.detalle.texto;
      this.valor = this.detalle.valor;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46 && keyCode != 45) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    modificarDetalle() {
      if (isNaN(this.valor)) {
        this.showAlert({
          icon: "error",
          title: "El importe no tiene un formato válido.",
          vm: this,
        });
        return;
      }
      this.$swal({
        title: "Modificar Detalle",
        text: "¿Desea modificar el detalle?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const item = {
            idPresupuestoDetalle: this.detalle.idPresupuestoDetalle,
            texto: this.texto,
            valor: parseFloat(this.valor).toFixed(2),
          };
          this.$emit("confirmaModificacionDetalle", item);
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
  },
};
</script>
