<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>{{
          origen === "hc" ? "Ver Nota Enfermería" : "Modificar Nota Enfermería"
        }}</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="enfermerosBuscar"
                item-text="apellidoNombre"
                item-value="idEnfermero"
                menu-props="auto"
                label="Enfermero"
                hide-details
                prepend-icon="mdi-account"
                v-model="enfermero"
                :readonly="origen === 'hc'"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
                :readonly="origen === 'hc'"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-textarea
                prepend-icon="mdi-hospital"
                v-model="textoNota"
                label="Texto Nota"
                :rules="requiredRules"
                clearable
                auto-grow
                :readonly="origen === 'hc'"
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            v-if="origen !== 'hc'"
            color="success"
            :disabled="!valid"
            @click.stop="modificarNotaEnfermeria"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  .v-dialog__content {
    align-items: left;
    justify-content: left;
  }
</style>
<script>
  import axios from "../../axios-auth.js";

  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      requiredRules: [(v) => !!v || "Dato obligatorio"],
      enfermero: "",
      enfermerosBuscar: [],
      textoNota: "",
      fecha: null,
      maxWidth: "100%",
    }),
    props: ["visible", "idPaciente", "idNotaEnfermeria", "origen"],
    computed: {
      show: {
        get() {
          if (this.visible) this.setearModels();
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$refs.form.resetValidation();
            this.vaciarModels();
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      validarFecha(fecha) {
        if (fecha === null || fecha === "") return true;
        else if (fecha.toString().length === 10) return true;
        else return false;
      },
      modificarNotaEnfermeria() {
        this.$swal({
          title: "Modificar Nota de Enfermería",
          text: "¿Desea modificar la nota de enfermería?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            let isValidDate = this.validarFecha(this.fecha);
            if (!isValidDate) {
              this.showAlert({
                icon: "error",
                title: "La fecha de la nota no tiene un formato válido.",
                vm: this,
              });
              return;
            }
            const datosNotaEnfemeria = {
              fechaNota: this.fecha,
              idPaciente: this.idPaciente,
              idEnfermero: this.enfermero,
              textoNota: this.textoNota,
              idNotaEnfermeria: this.idNotaEnfermeria,
            };
            let self = this;

            axios
              .post("/Enfermeria/ModificarNotaEnfermeria", datosNotaEnfemeria)
              .then((response) => {
                self.$refs.form.resetValidation();
                self.vaciarModels();
                document.getElementsByClassName(
                  "v-dialog--active"
                )[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title: "Nota modificada exitosamente",
                  vm: self,
                });
                self.$emit("notaEnfermeriaModificada");
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La nota no pudo ser modificada",
                  vm: this,
                });
              });
          }
        });
      },

      setearModels() {
        const profData = {
          usuario: this.$store.state.usuario.usuario,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        let self = this;
        axios
          .get("/Enfermeria/BuscarEnfermerosAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.enfermerosBuscar = response.data;

            self.enfermero = self.enfermerosBuscar[0].idEnfermero;
          });

        this.fecha = this.parseDate(
          this.formatDate(this.toISOLocal(new Date()))
        );

        axios
          .get("/Enfermeria/GetNotaEnfermeria?", {
            params: {
              idNotaEnfermeria: self.idNotaEnfermeria,
            },
          })
          .then((response) => {
            self.fecha = response.data.fechaNota.substring(0, 10);
            self.enfermero = response.data.idEnfermero;
            self.textoNota = response.data.textoNota;
          })
          .catch((error) => {});
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";
      },
      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      toISOLocal(d) {
        var z = (n) => ("0" + n).slice(-2);
        var zz = (n) => ("00" + n).slice(-3);
        var off = d.getTimezoneOffset();
        var sign = off < 0 ? "+" : "-";
        off = Math.abs(off);
        return (
          d.getFullYear() +
          "-" +
          z(d.getMonth() + 1) +
          "-" +
          z(d.getDate()) +
          "T" +
          z(d.getHours()) +
          ":" +
          z(d.getMinutes()) +
          ":" +
          z(d.getSeconds()) +
          "." +
          zz(d.getMilliseconds()) +
          "Z"
        )
          .toString()
          .substr(0, 10);
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },

      vaciarModels() {
        this.textoNota = "";
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
    },
  };
</script>
