<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Enviar Link de Pago</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-alert
            color="blue"
            dark
            outlined
            v-if="mailPaciente == '' || mailPaciente == null"
            >El paciente no tiene registrado un mail en su ficha o bien no
            asoció el cobro a un paciente, puede introducir uno a
            continuación.</v-alert
          >
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="email"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="enviarMail"
            >Enviar Mail</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="9" md="9">
              <h3>
                El link que se enviará al paciente es:
                <strong>{{ link }}</strong>
              </h3>
            </v-col>
            <v-col cols="12" sm="9" md="9">
              <h3>
                Puede utilizar este link y enviarlo al paciente mediante otra
                vía de comunicación
              </h3>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    maxWidth: "100%",
    email: "",
    emailRules: [
      (v) =>
        !v ||
        /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
        "E-mail inválido",
      (v) => !!v || "Dato obligatorio",
    ],
    mailPaciente: null,
  }),
  props: ["visible", "link", "paciente", "pacienteNombre", "idProfesional"],
  computed: {
    show: {
      get() {
        if (this.visible) this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },

  methods: {
    vaciarModels() {
      this.mailPaciente = null;
      this.email = null;
    },
    setearModels() {
      this.recuperarMailPaciente();
    },
    recuperarMailPaciente() {
      if (this.paciente > 0) {
        let self = this;
        const idpaciente = this.paciente;
        axios
          .get("/Paciente/BuscarDatosPaciente?", {
            params: {
              idpaciente: idpaciente,
            },
          })
          .then((response) => {
            self.mailPaciente = response.data.email;
            self.email = response.data.email;
          })
          .catch((error) => {});
      }
    },
    enviarMail() {
      this.$swal({
        title: "Enviar email con link de pago al paciente",
        text: "¿Desea enviar un correo al paciente con el link de pago?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datos = {
            link: this.link,
            email: this.email,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            pacienteNombre: this.pacienteNombre,
            idProfesional: this.idProfesional,
          };
          let self = this;
          axios
            .get("/Caja/EnviarLink?", {
              params: {
                link: datos.link,
                email: datos.email,
                idInstitucion: datos.idInstitucion,
                pacienteNombre: datos.pacienteNombre,
                idProfesional: datos.idProfesional,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title:
                  "Se envió un mail a la casilla de correo indicada con el link de pago.",
                vm: self,
                timer: 4200,
              });
              this.show = false;
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title:
                  "El correo no pudo ser enviado. Intente mas tarde por favor.",
                vm: this,
                timer: 3000,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
  },
};
</script>
