<template>
  <v-dialog
    max-width="600px"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-form ref="form">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Creado por / Modificado por</v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense class="mt-0 pt-0 mb-4">
            <v-col cols="12">
              <h2>
                Cronograma credor por:
                <span class="auditoria">{{ cronograma.usuarioAlta }}</span>
              </h2>
            </v-col>
          </v-row>
          <v-row dense class="mt-0 pt-0 mb-4">
            <v-col cols="12">
              <h2>
                Fecha creación:
                <span class="auditoria">{{ cronograma.fechaAltaString }}</span>
              </h2>
            </v-col>
          </v-row>
          <v-row dense class="mt-0 pt-0 mb-4">
            <v-col cols="12">
              <h2>
                Cronograma modificado por:
                <span class="auditoria">{{ cronograma.usuarioModi }}</span>
              </h2>
            </v-col>
          </v-row>
          <v-row dense class="mt-0 pt-0 mb-4">
            <v-col cols="12">
              <h2>
                Fecha Ultima Modificación:
                <span class="auditoria">{{ cronograma.fechaModiString }}</span>
              </h2>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  props: ["visible", "cronograma"],
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$emit("close");
        }
      },
    },
  },
};
</script>
<style scoped>
.auditoria {
  color: red;
}
</style>
