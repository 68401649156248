<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Nueva Indicación</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                ref="vselect"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-textarea
                prepend-icon="mdi-hospital"
                v-model="texto"
                label="Texto Indicación"
                :rules="requiredRules"
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12">
              <v-text-field
                v-model="diagnostico"
                prepend-icon="mdi-account"
                label="Diagnóstico"
                maxlength="150"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="guardarEvolucion"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  .v-dialog__content {
    align-items: left;
    justify-content: left;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import imprimirIndicacion from "./ImprimirEventos/imprimirIndicaciones";
  import imprimirIndicacionTrinidad from "./ImprimirEventos/Trinidad/imprimirIndicaciones";

  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      maxWidth: "100%",
      requiredRules: [(v) => !!v || "Dato obligatorio"],

      prof: "",
      profsBuscar: [],
      fecha: null,
      texto: null,
      diagnostico: null,
      base64: null,
    }),
    props: ["visible", "idPaciente", "paciente", "institucion"],
    computed: {
      show: {
        get() {
          if (this.visible) {
            this.setearModels();
            this.getLogoUrl();
          }
          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$refs.form.resetValidation();
            this.vaciarModels();
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      getLogoUrl() {
        let self = this;

        axios
          .get("/HistoriaClinica/GetLogo?", {
            params: {
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
            },
          })
          .then((response) => {
            self.base64 = response.data;
          })
          .catch((error) => {});
      },
      imprimir() {
        switch (this.$store.state.usuario.institucion.idInstitucion) {
          case 95: {
            imprimirIndicacionTrinidad(
              this.institucion,
              this.paciente,
              {
                texto: this.texto,
                fechaIndicacionString: this.formatDate(this.fecha),
                diagnostico: this.diagnostico,
                nomProfesional: this.$refs.vselect.selectedItems[0]
                  .nombreProfesional,
              },
              this.base64,
              "screen"
            );
            break;
          }
          default: {
            imprimirIndicacion(
              this.institucion,
              this.paciente,
              {
                texto: this.texto,
                fechaIndicacionString: this.formatDate(this.fecha),
                diagnostico: this.diagnostico,
                nomProfesional: this.$refs.vselect.selectedItems[0]
                  .nombreProfesional,
              },
              this.base64,
              "screen"
            );
            break;
          }
        }

        this.$refs.form.resetValidation();
        this.vaciarModels();
        document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
        this.$emit("indicacionRegistrada");
        //doc.save('turnos.pdf');
      },
      guardarEvolucion() {
        this.$swal({
          title: "Registrar Indicación",
          text: "¿Desea registrar la indicación?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            const datosIndicacion = {
              diagnostico: this.diagnostico,
              fechaIndicacion: this.fecha,
              idPaciente: this.idPaciente,
              idProfesional: this.prof,
              texto: this.texto,
            };
            let self = this;
            axios
              .post("/HistoriaClinica/GuardarIndicacion", datosIndicacion)
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Indicación registrada exitosamente",
                  vm: self,
                });
                this.$swal({
                  title: "Imprimir Indicación",
                  text: "¿Desea imprimir la indicación?",
                  icon: "success",
                  type: "success",
                  showCancelButton: true,
                  background: "#ececec",
                  confirmButtonText: "Si",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                }).then((result) => {
                  if (result.value) {
                    this.imprimir();
                  } else {
                    self.$refs.form.resetValidation();
                    self.vaciarModels();
                    document.getElementsByClassName(
                      "v-dialog--active"
                    )[0].scrollTop = 0;
                    self.$emit("indicacionRegistrada");
                  }
                });
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La indicación no pudo ser registrada",
                  vm: this,
                });
              });
          }
        });
      },

      setearModels() {
        const profData = {
          usuario: this.$store.state.usuario.usuario,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        let self = this;
        axios
          .get("/Turnos/BuscarProfesionalesAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.profsBuscar = response.data.profesionales;

            self.prof = self.profsBuscar[0].idProfesional;
          });

        this.fecha = this.parseDate(
          this.formatDate(this.toISOLocal(new Date()))
        );
        if (this.$store.state.videollamada == true) this.maxWidth = "68%";
        else this.maxWidth = "100%";
      },

      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      toISOLocal(d) {
        var z = (n) => ("0" + n).slice(-2);
        var zz = (n) => ("00" + n).slice(-3);
        var off = d.getTimezoneOffset();
        var sign = off < 0 ? "+" : "-";
        off = Math.abs(off);
        return (
          d.getFullYear() +
          "-" +
          z(d.getMonth() + 1) +
          "-" +
          z(d.getDate()) +
          "T" +
          z(d.getHours()) +
          ":" +
          z(d.getMinutes()) +
          ":" +
          z(d.getSeconds()) +
          "." +
          zz(d.getMilliseconds()) +
          "Z"
        )
          .toString()
          .substr(0, 10);
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },

      vaciarModels() {
        this.texto = "";
        this.diagnostico = "";
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
    },
  };
</script>
