<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Feriados y Cancelaciones</v-toolbar-title>
      </v-toolbar>
      <v-tabs background-color="indigo" dark>
        <v-tab>Feriados</v-tab>
        <v-tab>Cancelaciones</v-tab>
        <!-- Feriados -->
        <v-tab-item>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-chip label color="transparent" text-color="black">
                    <h3>Nuevo Feriado</h3>
                  </v-chip>
                </v-col>
              </v-row>
              <v-row dense>
                <v-col cols="12">
                  <v-menu
                    v-model="menu2"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateFormatted"
                        label="Fecha"
                        prepend-icon="mdi-calendar"
                        @blur="date = date"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="date"
                      @input="menu2 = false"
                      locale="es-ar"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="nombreFeriado"
                    prepend-icon="mdi-calendar"
                    label="Descripcion"
                    maxlength="50"
                    :rules="requiredRules"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :disabled="!valid"
                @click.stop="guardarFeriado"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-chip label color="transparent" text-color="black">
                  <h3>Lista de Feriados</h3>
                </v-chip>
              </v-col>
            </v-row>
            <v-col cols="12">
              <v-checkbox
                v-model="soloVigentesFeriados"
                label="Mostrar en la grilla solo feriados del año en curso"
                color="primary"
                dense
                @change="cargarFeriados"
              ></v-checkbox>
            </v-col>
          </v-card-text>
          <v-data-table
            :headers="headers"
            :items="this.listaFeriados"
            class="elevation-1"
            locale="es-ar"
            item-key="idFeriado"
            :disable-sort="true"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageText: 'Filas por página',
            }"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    v-on="on"
                    color="red"
                    v-bind="attrs"
                    @click="borrarFeriado(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar Feriado</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-tab-item>
        <!-- Cancelaciones -->
        <v-tab-item>
          <v-form ref="form" v-model="valid" :lazy-validation="lazy">
            <v-card-text>
              <v-row>
                <v-col cols="12">
                  <v-chip label color="transparent" text-color="black">
                    <h3>Nueva Cancelación</h3>
                  </v-chip>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12">
                  <v-select
                    :items="profs"
                    item-text="nombreProfesional"
                    item-value="idProfesional"
                    menu-props="auto"
                    label="Profesional"
                    hide-details
                    prepend-icon="mdi-account"
                    v-model="prof"
                    @change="cargarExcepciones"
                    class="mt-6"
                    dense
                  ></v-select>
                </v-col>

                <v-col cols="12" md="3" sm="4">
                  <v-menu
                    v-model="menu3"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateDesdeFormatted"
                        label="Fecha Desde"
                        prepend-icon="mdi-calendar"
                        @blur="dateDesde = dateDesde"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateDesde"
                      @input="menu3 = false"
                      locale="es-ar"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" sm="4">
                  <v-menu
                    v-model="menu4"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on }">
                      <v-text-field
                        v-model="dateHastaFormatted"
                        label="Fecha Hasta"
                        prepend-icon="mdi-calendar"
                        @blur="dateHasta = dateHasta"
                        readonly
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-date-picker
                      v-model="dateHasta"
                      @input="menu4 = false"
                      locale="es-ar"
                      :first-day-of-week="1"
                    ></v-date-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12" md="3" sm="4">
                  <v-menu
                    ref="menu4"
                    v-model="menu5"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="horaDesde"
                        label="Hora Desde"
                        prepend-icon="mdi-clock"
                        readonly
                        :rules="requiredRules"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu5"
                      v-model="horaDesde"
                      format="24hs"
                      full-width
                      scrollable
                      @click:minute="$refs.menu4.save(horaDesde)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>

                <v-col cols="12" md="3" sm="4">
                  <v-menu
                    ref="menu6"
                    v-model="menu7"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    :return-value.sync="time"
                    transition="scale-transition"
                    offset-y
                    max-width="290px"
                    min-width="290px"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-model="horaHasta"
                        label="Hora Hasta"
                        prepend-icon="mdi-clock"
                        readonly
                        :rules="requiredRules"
                        v-bind="attrs"
                        v-on="on"
                      ></v-text-field>
                    </template>
                    <v-time-picker
                      v-if="menu7"
                      v-model="horaHasta"
                      full-width
                      scrollable
                      format="24hs"
                      @click:minute="$refs.menu6.save(horaHasta)"
                    ></v-time-picker>
                  </v-menu>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="nombreExcepcion"
                    prepend-icon="mdi-calendar"
                    label="Descripcion"
                    maxlength="100"
                    :rules="requiredRules"
                    dense
                    required
                  ></v-text-field>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                color="success"
                :disabled="!valid"
                @click.stop="guardarExcepcion"
                >Guardar</v-btn
              >
            </v-card-actions>
          </v-form>
          <v-card-text>
            <v-row dense>
              <v-col cols="12">
                <v-chip label color="transparent" text-color="black">
                  <h3>Lista de Cancelaciones del Profesional</h3>
                </v-chip>
              </v-col>
              <v-col cols="12">
                <v-checkbox
                  v-model="soloVigentesExcepciones"
                  label="Mostrar en la grilla solo cancelaciones del año en curso"
                  color="primary"
                  dense
                  @change="cargarExcepciones"
                ></v-checkbox>
              </v-col>
            </v-row>
          </v-card-text>
          <v-data-table
            :headers="headersExcepciones"
            :items="this.listaExcepciones"
            class="elevation-1"
            locale="es-ar"
            item-key="idExcepcion"
            :disable-sort="true"
            :items-per-page="10"
            :footer-props="{
              itemsPerPageText: 'Filas por página',
            }"
          >
            <template v-slot:item.actions="{ item }">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    medium
                    v-on="on"
                    color="red"
                    v-bind="attrs"
                    @click="borrarExcepcion(item)"
                    >mdi-delete</v-icon
                  >
                </template>
                <span>Eliminar Excepcion</span>
              </v-tooltip>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
</style>
<script>
  import axios from "../../../axios-auth.js";
  import router from "../../../router/index.js";
  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      requiredRules: [(v) => !!v || "Dato obligatorio"],
      date: vm.toISOLocal(new Date()),
      dateFormatted: vm.formatDate(vm.toISOLocal(new Date())),
      dateDesde: vm.toISOLocal(new Date()),
      dateDesdeFormatted: vm.formatDate(vm.toISOLocal(new Date())),
      dateHasta: vm.toISOLocal(new Date()),
      dateHastaFormatted: vm.formatDate(vm.toISOLocal(new Date())),
      menu2: false,
      menu3: false,
      menu4: false,
      menu5: false,
      menu6: false,
      menu7: false,
      modal: false,
      menu: false,
      soloVigentesExcepciones: true,
      soloVigentesFeriados: true,
      nombreFeriado: "",
      nombreExcepcion: "",
      prof: "",
      profs: [],
      listaFeriados: [],
      listaExcepciones: [],
      headers: [
        { text: "Fecha", value: "fechaString" },
        { text: "Descripcion", value: "nombre" },
        { text: "Acciones", value: "actions" },
      ],
      headersExcepciones: [
        { text: "Profesional", value: "nombreProfesional" },
        { text: "Fecha", value: "fechaString" },
        { text: "Descripcion", value: "observaciones" },
        { text: "Hora Desde", value: "horaDesde" },
        { text: "Hora Hasta", value: "horaHasta" },
        { text: "Acciones", value: "actions" },
      ],
      time: null,
      horaDesde: null,
      horaHasta: null,
    }),

    watch: {
      date(val) {
        this.dateFormatted = this.formatDate(val);
      },
      dateDesde(val) {
        this.dateDesdeFormatted = this.formatDate(val);
      },
      dateHasta(val) {
        this.dateHastaFormatted = this.formatDate(val);
      },
    },
    methods: {
      scrollToTop() {
        window.scrollTo(0, 0);
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },
      parseDate(date) {
        if (!date) return null;
        const [month, day, year] = date.split("/");
        return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
      },
      toISOLocal(d) {
        var z = (n) => ("0" + n).slice(-2);
        var zz = (n) => ("00" + n).slice(-3);
        var off = d.getTimezoneOffset();
        var sign = off < 0 ? "+" : "-";
        off = Math.abs(off);
        return (
          d.getFullYear() +
          "-" +
          z(d.getMonth() + 1) +
          "-" +
          z(d.getDate()) +
          "T" +
          z(d.getHours()) +
          ":" +
          z(d.getMinutes()) +
          ":" +
          z(d.getSeconds()) +
          "." +
          zz(d.getMilliseconds()) +
          "Z"
        )
          .toString()
          .substr(0, 10);
      },
      cargarFeriados() {
        let self = this;
        axios
          .get("/Turnos/GetFeriados?", {
            params: {
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
              soloVigentesFeriados: this.soloVigentesFeriados,
            },
          })
          .then((response) => {
            if (response.data != null && response.data != "")
              self.listaFeriados = response.data;
            else self.listaFeriados = [];
          })
          .catch((error) => {});
      },
      cargarExcepciones() {
        let self = this;
        axios
          .get("/Turnos/GetExcepciones?", {
            params: {
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
              idProfesional: this.prof,
              soloVigentesExcepciones: this.soloVigentesExcepciones,
            },
          })
          .then((response) => {
            if (response.data != null && response.data != "")
              self.listaExcepciones = response.data;
            else self.listaExcepciones = [];
          })
          .catch((error) => {});
      },
      salir() {
        router.push({ path: "/Home" });
      },
      vaciarModels() {
        this.nombreFeriado = "";
        this.nombreExcepcion = "";
      },
      guardarFeriado() {
        this.$swal({
          title: "Guardar Feriado",
          text: "¿Desea registrar el feriado?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const datosFeriado = {
              idFeriado: 0,
              fecha: this.date,
              fechaString: this.dateFormatted,
              nombre: this.nombreFeriado,
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
            };
            let self = this;
            axios
              .get("/Turnos/ChequearUnicidadFeriado?", {
                params: {
                  idInstitucion: datosFeriado.idInstitucion,
                  fecha: datosFeriado.fechaString,
                },
              })
              .then((response) => {
                if (response.data != "") {
                  self.showAlert({
                    icon: "error",
                    title: "Ya existe un feriado registrado en esa fecha",
                    vm: this,
                  });
                } else {
                  const datosFeriado = {
                    idFeriado: 0,
                    fecha: this.date,
                    fechaString: this.dateFormatted,
                    nombre: this.nombreFeriado,
                    idInstitucion: this.$store.state.usuario.institucion
                      .idInstitucion,
                  };
                  axios
                    .get("/Turnos/ChequearExistenciaTurnosFeriado?", {
                      params: {
                        idInstitucion: datosFeriado.idInstitucion,
                        fecha: datosFeriado.fechaString,
                      },
                    })
                    .then((response) => {
                      if (response.data != "") {
                        self.showAlert({
                          icon: "error",
                          title:
                            "Existen turnos reservados para el día que quiere registrar el feriado.",
                          vm: this,
                        });
                      } else {
                        self.grabarFeriado(0);
                      }
                    })
                    .catch((error) => {});
                }
              })
              .catch((error) => {});
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      },
      grabarFeriado(val) {
        let self = this;
        const datosFeriado = {
          idFeriado: 0,
          fecha: this.date,
          fechaString: this.dateFormatted,
          nombre: this.nombreFeriado,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        axios
          .post("/Turnos/GuardarFeriado", datosFeriado)
          .then((response) => {
            self.$refs.form.resetValidation();
            self.vaciarModels();

            self.showAlert({
              icon: "success",
              title: "Feriado Registrado",
              vm: self,
            });
            self.cargarFeriados();
          })
          .catch((error) => {
            self.showAlert({
              icon: "error",
              title: "El feriado no pudo ser registrado",
              vm: this,
            });
          });
      },
      guardarExcepcion() {
        this.$swal({
          title: "Guardar Cancelacion",
          text: "¿Desea registrar la cancelación del turnero?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const datosExcepcion = {
              idExcepcion: 0,
              fechaDesde: this.dateDesde,
              fechaDesdeString: this.dateDesdeFormatted,
              fechaHasta: this.dateHasta,
              fechaHastaString: this.dateHastaFormatted,
              observaciones: this.nombreExcepcion,
              idProfesional: this.prof,
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
              horaDesde: this.horaDesde,
              horaHasta: this.horaHasta,
              horaInicioCompleta: this.horaDesde
                .replace(":", "")
                .trim()
                .replace(" ", ""),
              horaFinCompleta: this.horaHasta
                .replace(":", "")
                .trim()
                .replace(" ", ""),
            };

            if (datosExcepcion.fechaDesde > datosExcepcion.fechaHasta) {
              this.showAlert({
                icon: "error",
                title: "La fecha desde no puede ser mayor que la fecha hasta",
                vm: this,
              });
              return;
            }

            if (
              datosExcepcion.horaInicioCompleta > datosExcepcion.horaFinCompleta
            ) {
              this.showAlert({
                icon: "error",
                title: "La hora desde no puede ser mayor que la hora hasta",
                vm: this,
              });
              return;
            }
            let self = this;
            axios
              .get("/Turnos/ChequearUnicidadExcepcion?", {
                params: {
                  idInstitucion: datosExcepcion.idInstitucion,
                  fechaDesde: datosExcepcion.fechaDesdeString,
                  fechaHasta: datosExcepcion.fechaHastaString,
                  idProfesional: datosExcepcion.idProfesional,
                },
              })
              .then((response) => {
                if (response.data != "") {
                  self.showAlert({
                    icon: "error",
                    title:
                      "Ya existe una cancelación de turnero registrada en esa fecha para el profesional",
                    vm: this,
                  });
                } else {
                  axios
                    .get("/Turnos/ChequearExistenciaTurnosExcepcion?", {
                      params: {
                        idInstitucion: datosExcepcion.idInstitucion,
                        fechaDesde: datosExcepcion.fechaDesdeString,
                        fechaHasta: datosExcepcion.fechaHastaString,
                        idProfesional: datosExcepcion.idProfesional,
                        horaDesde: datosExcepcion.horaDesde,
                        horaHasta: datosExcepcion.horaHasta,
                      },
                    })
                    .then((response) => {
                      if (response.data != "") {
                        self.showAlert({
                          icon: "error",
                          title:
                            "Existen turnos reservados para el día que quiere registrar la cancelación.",
                          vm: this,
                        });
                      } else {
                        self.grabarExcepcion(0);
                      }
                    })
                    .catch((error) => {});
                }
              })
              .catch((error) => {});
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      },
      grabarExcepcion(val) {
        let self = this;
        const datosExcepcion = {
          idFeriado: 0,
          fecha: this.dateDesde,
          fechaString: this.dateDesdeFormatted,
          fechaHasta: this.dateHasta,
          fechaHastaString: this.dateHastaFormatted,
          observaciones: this.nombreExcepcion,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          idProfesional: this.prof,
          horaDesde: this.horaDesde,
          horaHasta: this.horaHasta,
        };
        axios
          .post("/Turnos/GuardarExcepcion", datosExcepcion)
          .then((response) => {
            self.$refs.form.resetValidation();
            self.vaciarModels();

            self.showAlert({
              icon: "success",
              title: "Cancelación Registrada",
              vm: self,
            });
            self.cargarExcepciones();
          })
          .catch((error) => {
            self.showAlert({
              icon: "error",
              title: "La cancelación no pudo ser registrada",
              vm: this,
            });
          });
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2500,
        });
      },
      borrarFeriado(item) {
        this.$swal({
          title: "Borrar Feriado",
          text: "¿Desea borrar el feriado?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idFeriado = item.idFeriado;
            let self = this;
            axios
              .get("/Turnos/BorrarFeriado?", {
                params: {
                  idFeriado: idFeriado,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Feriado borrado",
                  vm: self,
                });
                setTimeout(self.cargarFeriados(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El feriado no pudo ser borrado",
                  vm: this,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      },
      borrarExcepcion(item) {
        this.$swal({
          title: "Borrar Cancelación",
          text: "¿Desea borrar la cancelación?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idExcepcion = item.idExcepcion;
            let self = this;
            axios
              .get("/Turnos/BorrarExcepcion?", {
                params: {
                  idExcepcion: idExcepcion,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Cancelación borrada",
                  vm: self,
                });
                setTimeout(self.cargarExcepciones(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La cancelación no pudo ser borrada",
                  vm: this,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      },
    },

    created() {
      this.profs = this.$store.state.usuario.profesionales;
      this.prof = this.profs[0].idProfesional;
      this.horaDesde = "00:00";
      this.horaHasta = "23:59";
      this.cargarExcepciones();
      this.cargarFeriados();
      document.getElementsByClassName("container")[0].scrollTop = 0;
    },
  };
</script>
