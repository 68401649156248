import { render, staticRenderFns } from "./NuevoPresupuesto.vue?vue&type=template&id=12fd7cba&scoped=true"
import script from "./NuevoPresupuesto.vue?vue&type=script&lang=js"
export * from "./NuevoPresupuesto.vue?vue&type=script&lang=js"
import style0 from "./NuevoPresupuesto.vue?vue&type=style&index=0&id=12fd7cba&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12fd7cba",
  null
  
)

export default component.exports