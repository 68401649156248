<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Nuevo Tope</v-toolbar-title>
      </v-toolbar>
      <v-form
        class="dialog-topes"
        ref="form"
        v-model="valid"
        :lazy-validation="lazy"
      >
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
            <v-col class="os-col" cols="12" md="6" sm="6">
              <v-select
                :items="osBuscar"
                item-text="nombre"
                item-value="idObraSocial"
                :label="`${resolveLabel}`"
                prepend-icon="mdi-account"
                v-model="os"
                dense
                ref="vselect"
                multiple
                chips
              ></v-select>
            </v-col>

            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Vigencia Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                label="Fecha Vigencia Hasta"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="cantMaxTurnos"
                prepend-icon="mdi-calendar"
                :rules="totalRules"
                label="Tope Diario de Turnos"
                @keypress="onlyNumber"
                maxlength="10"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid || !os.length"
            @click.stop="guardarTope"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
</style>
<script>
  import axios from "../../../axios-auth.js";

  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      requiredRules: [(v) => !!v || "Dato obligatorio"],
      totalRules: [
        (v) => !!v || "Dato obligatorio",
        (v) => v >= 0 || "La cantidad debe ser mayor o igual a 0",
      ],
      prof: "",
      profsBuscar: [],
      os: [],
      osBuscar: [],
      cantMaxTurnos: null,
      fechaDesde: null,
      fechaHasta: null,
    }),
    props: ["visible"],
    computed: {
      show: {
        get() {
          if (this.visible) this.setearModels();

          return this.visible;
        },
        set(value) {
          if (!value) {
            this.$refs.form.resetValidation();
            this.vaciarModels();
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
      resolveLabel() {
        return this.$store.state.usuario.institucion.esEmpresa
          ? "Sede"
          : "Obra Social";
      },
    },
    methods: {
      validarFecha(fecha) {
        if (fecha.toString().length > 10) return false;
        else return true;
      },
      guardarTope() {
        this.$swal({
          title: "Registrar Tope",
          text: "¿Desea registrar el tope?",
          icon: "success",
          type: "success",
          showCancelButton: true,
          background: "#ececec",
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            let isValidDate = this.validarFecha(this.fechaDesde);
            if (!isValidDate) {
              this.showAlert({
                icon: "error",
                title: "La fecha desde no tiene un formato válido.",
                vm: this,
                timer: 2000,
              });
              return;
            }
            isValidDate = this.validarFecha(this.fechaHasta);
            if (!isValidDate) {
              this.showAlert({
                icon: "error",
                title: "La fecha hasta no tiene un formato válido.",
                vm: this,
                timer: 2000,
              });
              return;
            }
            const datosTope = {
              idProfesional: this.prof,
              listaOs: this.os,
              fechaDesde: this.fechaDesde,
              fechaHasta: this.fechaHasta,
              cantMax: this.cantMaxTurnos,
            };
            let self = this;

            if (datosTope.fechaDesde > datosTope.fechaHasta) {
              self.showAlert({
                icon: "error",
                title: "La fecha desde no puede ser mayor que la fecha hasta",
                vm: this,
                timer: 2000,
              });
              return;
            }
            axios
              .post("/ObraSocial/GuardarTope", datosTope)
              .then((response) => {
                self.$refs.form.resetValidation();
                self.vaciarModels();
                document.getElementsByClassName(
                  "v-dialog--active"
                )[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title:
                    response.data === "error"
                      ? "Tope registrado excepto para aquellas obras sociales que ya poseían un tope previamente configurado para el profesional"
                      : "Tope registrado",
                  vm: self,
                  timer: response.data === "error" ? 4000 : 2000,
                });
                self.$emit("topeRegistrado");
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El tope no pudo ser registrado",
                  vm: this,
                  timer: 2000,
                });
              });
          }
        });
      },

      setearModels() {
        const profData = {
          usuario: this.$store.state.usuario.usuario,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        let self = this;
        axios
          .get("/Turnos/BuscarProfesionalesAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.profsBuscar = response.data.profesionales;
            self.prof = self.profsBuscar[0].idProfesional;
          });
        const osData = {
          nombre: null,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
        axios
          .get("/ObraSocial/GetObraSocialNomDeInst?", {
            params: {
              nombre: null,
              idInstitucion: osData.idInstitucion,
              estado: 1,
            },
          })
          .then((response) => {
            self.osBuscar = response.data;
          })
          .catch((error) => {});

        this.fechaDesde = this.parseDate(
          this.formatDate(this.toISOLocal(new Date()))
        );
      },

      parseDate(date) {
        if (!date) return null;
        const [day, month, year] = date.split("/");
        return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
      },
      toISOLocal(d) {
        var z = (n) => ("0" + n).slice(-2);
        var zz = (n) => ("00" + n).slice(-3);
        var off = d.getTimezoneOffset();
        var sign = off < 0 ? "+" : "-";
        off = Math.abs(off);
        return (
          d.getFullYear() +
          "-" +
          z(d.getMonth() + 1) +
          "-" +
          z(d.getDate()) +
          "T" +
          z(d.getHours()) +
          ":" +
          z(d.getMinutes()) +
          ":" +
          z(d.getSeconds()) +
          "." +
          zz(d.getMilliseconds()) +
          "Z"
        )
          .toString()
          .substr(0, 10);
      },
      formatDate(date) {
        if (!date) return null;
        const [year, month, day] = date.split("-");
        return `${day}/${month}/${year}`;
      },

      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
          // 46 is dot
          $event.preventDefault();
        }
      },
      vaciarModels() {
        this.cantMaxTurnos = "";
        this.fechaHasta = "";
        this.os = [];
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: alertInfo.timer,
        });
      },
    },
  };
</script>
<style>
  .dialog-topes {
    margin-top: 1rem;
  }
  .dialog-topes .os-col .v-input__slot {
    transform: translateY(-16px);
  }
</style>
