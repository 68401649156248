<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Pacientes</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Pacientes</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="9" sm="6" md="7">
              <v-text-field
                v-model="dni"
                prepend-icon="mdi-account"
                label="D.N.I"
                dense
                required
                @keypress="onlyNumber"
                maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="apellidos"
                prepend-icon="mdi-account"
                label="Apellidos"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" sm="6">
              <v-text-field
                v-model="nombres"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <template
            v-if="
              this.$store.state.usuario.institucion.idInstitucion === 69 ||
                this.$store.state.usuario.institucion.idInstitucion === 177
            "
          >
            <v-row>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esGestanteOptions"
                  v-model="esGestante"
                  item-text="text"
                  item-value="value"
                  label="Gestante"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esDiabeticoOptions"
                  v-model="esDiabetico"
                  item-text="text"
                  item-value="value"
                  label="Diabético"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esEpocOptions"
                  v-model="esEpoc"
                  item-text="text"
                  item-value="value"
                  label="EPOC"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esFumadorOptions"
                  v-model="esFumador"
                  item-text="text"
                  item-value="value"
                  label="Fumador"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esHtaOptions"
                  v-model="esHta"
                  item-text="text"
                  item-value="value"
                  label="HTA"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esObesoOptions"
                  v-model="esObeso"
                  item-text="text"
                  item-value="value"
                  label="Obesidad"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esRcvOptions"
                  v-model="esRcv"
                  item-text="text"
                  item-value="value"
                  label="RCV > 20%"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
              <v-col cols="12" md="3" sm="4" class="mt-2">
                <v-select
                  :items="esSobrePesoOptions"
                  v-model="esSobrePeso"
                  item-text="text"
                  item-value="value"
                  label="Sobrepreso"
                  dense
                  prepend-icon="mdi-account"
                ></v-select>
              </v-col>
            </v-row>
          </template>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid || loading"
          :loading="loading"
          color="primary"
          @click.stop="handleBuscarPacientes"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaPacientes"
        class="elevation-1"
        locale="es-ar"
        :items-per-page="10"
        item-key="idPaciente"
        ref="myTable"
        disable-sort
        disable-filtering
        :server-items-length="cantRegistros"
        :footer-props="{
          itemsPerPageText: 'Pacientes por página',
          itemsPerPageOptions: [5, 10, 15],
        }"
        :options.sync="options"
        :loading="isLoading"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarPaciente(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Paciente</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="green"
                class="mr-3"
                v-bind="attrs"
                @click="caracteristicas(item)"
                >mdi-list-box</v-icon
              >
            </template>
            <span>Características Paciente</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarPaciente(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Paciente</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoPaciente"
          >Nuevo Paciente</v-btn
        >
        <v-btn
          color="warning"
          :disabled="listaPacientes.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoPaciente
        :visible="showNuevoPaciente"
        @close="cierraNuevoPaciente()"
      ></NuevoPaciente>
      <EditarPaciente
        :visible="showEditarPaciente"
        :idPaciente="editedItem.idpaciente"
        :dniActual="editedItem.nrodocumento"
        @close="cierraEditarPaciente()"
        @pacienteModificado="pacienteModificado"
      ></EditarPaciente>
      <CaracteristicasPaciente
        :visible="showCaracteristicas"
        :idPaciente="editedItem.idpaciente"
        @close="cierraCaracteristicas()"
      ></CaracteristicasPaciente>
    </v-card>
  </div>
</template>
<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import router from "../../router/index.js";
  import jsPDF from "jspdf";
  import autotable from "jspdf-autotable";
  import NuevoPaciente from "../HistClin/NuevoPaciente.vue";
  import EditarPaciente from "../HistClin/EditarPaciente.vue";
  import CaracteristicasPaciente from "./CaracteristicasPaciente.vue";
  export default {
    data: (vm) => ({
      valid: false,
      lazy: false,
      esGestanteOptions: [
        { value: "todos", text: "TODOS" },
        { value: "gestante", text: "GESTANTE" },
        { value: "noGestante", text: "NO GESTANTE" },
      ],
      esGestante: "todos",
      esDiabeticoOptions: [
        { value: "todos", text: "TODOS" },
        { value: "diabetico", text: "DIABÉTICO" },
        { value: "noDiabetico", text: "NO DIABÉTICO" },
      ],
      esDiabetico: "todos",
      esEpocOptions: [
        { value: "todos", text: "TODOS" },
        { value: "epoc", text: "TIENE EPOC" },
        { value: "noEpoc", text: "NO TIENE EPOC" },
      ],
      esEpoc: "todos",
      esFumadorOptions: [
        { value: "todos", text: "TODOS" },
        { value: "fumador", text: "FUMADOR" },
        { value: "noFumador", text: "NO FUMADOR" },
      ],
      esFumador: "todos",
      esHtaOptions: [
        { value: "todos", text: "TODOS" },
        { value: "hta", text: "TIENE HTA" },
        { value: "noHta", text: "NO TIENE HTA" },
      ],
      esHta: "todos",
      esObesoOptions: [
        { value: "todos", text: "TODOS" },
        { value: "obeso", text: "TIENE OBESIDAD" },
        { value: "noObeso", text: "NO TIENE OBESIDAD" },
      ],
      esObeso: "todos",
      esRcvOptions: [
        { value: "todos", text: "TODOS" },
        { value: "rcv", text: "TIENE RCV > 20%" },
        { value: "noRcv", text: "NO TIENE RCV > 20%" },
      ],
      esRcv: "todos",
      esSobrePesoOptions: [
        { value: "todos", text: "TODOS" },
        { value: "sobrePeso", text: "TIENE SOBREPESO" },
        { value: "noSobrePeso", text: "NO TIENE SOBREPESO" },
      ],
      esSobrePeso: "todos",
      apellidos: null,
      nombres: null,
      dni: null,
      listaPacientes: [],
      headers: [
        { text: "D.N.I.", value: "nrodocumento" },
        { text: "Apellidos", value: "apellidos" },
        { text: "Nombres", value: "nombres" },
        { text: "Cel.", value: "celular" },
        { text: "Mail.", value: "email" },
        { text: "Acciones", value: "actions" },
      ],
      showNuevoPaciente: false,
      showEditarPaciente: false,
      showCaracteristicas: false,
      editedItem: {},
      editedIndex: -1,
      loading: false,
      isLoading: false,
      options: {},
      cantRegistros: null,
    }),
    watch: {
      options: {
        immediate: true,
        deep: true,
        handler(val) {
          if (Object.keys(val).length) {
            this.buscarPacientes(1);
          }
        },
      },
    },
    methods: {
      salir() {
        router.push({ path: "/Home" });
      },
      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
          // 46 is dot
          $event.preventDefault();
        }
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 1500,
        });
      },
      borrarPaciente(item) {
        this.$swal({
          title: "Borrar Paciente",
          text: "¿Desea borrar el paciente?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idpaciente = item.idpaciente;
            let self = this;
            axios
              .get("/Paciente/BorrarPaciente?", {
                params: {
                  idPaciente: idpaciente,
                },
              })
              .then((response) => {
                if (response.data == "error") {
                  self.showAlert({
                    icon: "error",
                    title: "El paciente tiene transacciones asociadas",
                    vm: self,
                  });
                } else {
                  self.showAlert({
                    icon: "success",
                    title: "Paciente borrado exitosamente",
                    vm: self,
                  });
                  setTimeout(self.buscarPacientes(0), 1700);
                }
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "El paciente no pudo ser borrado",
                  vm: this,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      },
      cierraNuevoPaciente() {
        this.showNuevoPaciente = false;
        //this.buscarPacientes();
      },
      cierraEditarPaciente() {
        this.showEditarPaciente = false;
        //this.buscarPacientes();
      },
      cierraCaracteristicas() {
        this.showCaracteristicas = false;
      },
      pacienteModificado() {
        this.showEditarPaciente = false;
        this.buscarPacientes(0);
      },
      caracteristicas(item) {
        this.editedIndex = this.listaPacientes.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showCaracteristicas = true;
      },
      nuevoPaciente() {
        this.showNuevoPaciente = true;
      },
      editarPaciente(item) {
        this.editedIndex = this.listaPacientes.indexOf(item);
        this.editedItem = Object.assign({}, item);
        this.showEditarPaciente = true;
      },
      handleBuscarPacientes() {
        this.options.page = 1;
        this.buscarPacientes(1);
      },
      buscarPacientes(state) {
        const filtros = {
          dni: this.dni,
          apellidos: this.apellidos,
          nombres: this.nombres,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          esGestante:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esGestante
              : "todos",
          esDiabetico:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esDiabetico
              : "todos",
          esEpoc:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esEpoc
              : "todos",
          esFumador:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esFumador
              : "todos",
          esHta:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esHta
              : "todos",
          esObeso:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esObeso
              : "todos",
          esRcv:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esRcv
              : "todos",
          esSobrePeso:
            this.$store.state.usuario.institucion.idInstitucion === 69 ||
            this.$store.state.usuario.institucion.idInstitucion === 177
              ? this.esSobrePeso
              : "todos",
        };
        this.isLoading = true;
        let self = this;

        const filtrosData = {
          dni: filtros.dni,
          apellidos: filtros.apellidos,
          nombres: filtros.nombres,
          idInstitucion: filtros.idInstitucion,
          esGestante: filtros.esGestante,
          esDiabetico: filtros.esDiabetico,
          esEpoc: filtros.esEpoc,
          esFumador: filtros.esFumador,
          esHta: filtros.esHta,
          esObeso: filtros.esObeso,
          esRcv: filtros.esRcv,
          esSobrePeso: filtros.esSobrePeso,
          page: this.options.page,
          items: this.options.itemsPerPage,
        };

        axios
          .post("/Paciente/BuscarPacientes?", filtrosData)
          .then(function(response) {
            if (response.data != null && response.data != "") {
              self.listaPacientes = response.data.listaPacientes;
              self.cantRegistros = response.data.cantRegistros;
              self.isLoading = false;
            } else {
              self.listaPacientes = [];
              self.isLoading = false;
              if (state == 1) {
                self.showAlert({
                  icon: "error",
                  title:
                    "No hay pacientes que coincidan con el criterio de búsqueda.",
                  vm: self,
                });
              }
            }
          })
          .catch(function(error) {
            self.isLoading = false;
          });
      },
      imprimir() {
        var source = this.$refs["myTable"];
        let rows = [];
        let columnHeader = ["D.N.I.", "Apellidos", "Nombres", "Cel.", "Mail"];
        let importeTotal = 0;
        source.items.forEach((element) => {
          var temp = [
            element.nrodocumento,
            element.apellidos,
            element.nombres,
            element.celular,
            element.email,
          ];
          rows.push(temp);
          importeTotal += element.total;
        });
        //let columnHeader = source.items.headers
        //let rows = source.items.rows
        var doc = new jsPDF();
        doc.setProperties({
          title: "Listado Pacientes",
        });
        //doc.text('Listado de Turnos',20,10);
        var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

        doc.setFontSize(12);
        doc.text(nombreInstitucion, 13, 10);
        doc.setFontSize(10);

        doc.setFontSize(16);
        doc.text(
          "Listado de Pacientes",
          doc.internal.pageSize.getWidth() / 2,
          33,
          { align: "center" }
        );
        doc.autoTable(columnHeader, rows, {
          startY: 39,
          didParseCell: function(table) {
            if (table.section === "head") {
              table.cell.styles.fillColor = "#c4c5c5";
            }
          },
        });

        window.open(doc.output("bloburl"), "_blank");
      },
    },

    components: {
      NuevoPaciente,
      EditarPaciente,
      CaracteristicasPaciente,
    },
  };
</script>
