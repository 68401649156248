<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Receta</v-toolbar-title>
      </v-toolbar>
      <v-card-text>
        <v-row class="my-4 mx-1">
          <h3>Profesional: {{ receta.nomProfesional }}</h3>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Fecha: {{ receta.fechaRecetaString }}</h3>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Obra Social: {{ receta.obraSocial }}</h3>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Nº de Afiliado: {{ receta.nroAfiliado }}</h3>
        </v-row>
        <v-row class="mt-4 mx-1">
          <h3>Texto de la Receta:</h3>
        </v-row>
        <v-row class="ml-1">
          <v-alert class="multi-line">{{ receta.texto }}</v-alert>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Diagnóstico: {{ receta.diagnostico }}</h3>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
        <v-btn color="error" @click.stop="show = false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
  .multi-line {
    white-space: pre-line;
  }
  .v-dialog__content {
    align-items: left;
    justify-content: left;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import imprimirReceta from "../HistClin/ImprimirEventos/imprimirReceta";
  import imprimirRecetaTrinidad from "../HistClin/ImprimirEventos/Trinidad/imprimirReceta";

  export default {
    data: (vm) => ({
      maxWidth: "100%",
      base64: null,
    }),
    props: ["visible", "receta", "paciente", "institucion"],
    computed: {
      show: {
        get() {
          if (this.$store.state.videollamada == true) this.maxWidth = "68%";
          else this.maxWidth = "100%";
          if (this.visible) this.getLogoUrl();
          return this.visible;
        },
        set(value) {
          if (!value) {
            document.getElementsByClassName(
              "v-dialog--active"
            )[0].scrollTop = 0;
            this.$emit("close");
          }
        },
      },
    },
    methods: {
      getLogoUrl() {
        let self = this;

        axios
          .get("/HistoriaClinica/GetLogo?", {
            params: {
              idInstitucion: this.$store.state.usuario.institucion
                .idInstitucion,
            },
          })
          .then((response) => {
            self.base64 = response.data;
          })
          .catch((error) => {});
      },
      imprimir() {
        switch (this.$store.state.usuario.institucion.idInstitucion) {
          case 95: {
            imprimirRecetaTrinidad(
              this.institucion,
              this.paciente,
              this.receta,
              this.base64,
              "screen"
            );
            break;
          }
          default: {
            imprimirReceta(
              this.institucion,
              this.paciente,
              this.receta,
              this.base64,
              "screen"
            );
            break;
          }
        }
      },
    },
  };
</script>
