<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    :fullscreen="fullscreen"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Plantilla de Pedidos de Estudios</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="this.listaPlantillas"
        class="elevation-1"
        locale="es-ar"
        item-key="idPlantillaPedido"
        :disable-sort="true"
        :items-per-page="10"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.action="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="verPlantilla(item)"
                >mdi-eye</v-icon
              >
            </template>
            <span>Ver Plantilla</span>
          </v-tooltip>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="seleccionarPlantilla(item)"
                >mdi-checkbox-marked-circle</v-icon
              >
            </template>
            <span>Seleccionar Plantilla</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <VerPlantilla
        :visible="showVerPlantilla"
        :plantilla="editedItem"
        @close="showVerPlantilla = false"
      ></VerPlantilla>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import VerPlantilla from "../HistClin/VerPlantilla.vue";
export default {
  data: (vm) => ({
    headers: [
      {
        text: "Nombre Plantilla",
        align: "start",
        filterable: false,
        value: "nombrePlantillaPedido",
      },
      { text: "Creada Por", value: "nomProfesional" },
      { text: "Ver", value: "action", sortable: false },
      { text: "Seleccionar", value: "actions", sortable: false },
    ],
    listaPlantillas: [],
    showVerPlantilla: false,
    fullscreen: true,
    maxWidth: "100%",
  }),

  computed: {
    show: {
      get() {
        if (this.visible) {
          if (this.$store.state.videollamada == true) {
            this.fullscreen = false;
            this.maxWidth = "68%";
          } else {
            this.fullscreen = true;
            this.maxWidth = "100%";
          }
          this.buscarPlantilla();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.vaciarModels();
          this.$emit("close");
        }
      },
    },
  },
  props: ["visible"],
  methods: {
    verPlantilla(item) {
      this.editedIndex = this.listaPlantillas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerPlantilla = true;
    },
    buscarPlantilla() {
      const filtrosData = {
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetPlantillasDeInst?", {
          params: {
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != "" && response.data != null)
            self.listaPlantillas = response.data;
          else self.listaPlantillas = [];
        })
        .catch(function(error) {});
    },
    vaciarModels() {
      this.listaPlantillas = [];
    },
    seleccionarPlantilla(item) {
      this.vaciarModels();

      this.$emit("seleccionarPlantilla", item);
    },
  },
  components: {
    VerPlantilla,
  },
};
</script>
