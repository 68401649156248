<template>
  <v-dialog :max-width="maxWidth" v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Recetas</v-toolbar-title>
      </v-toolbar>
      <v-card-text v-for="(receta,i) in recetas" :key="i">
        <v-row class="my-4 mx-1">
          <h3>Prescribió: {{receta.nomProfesional}}</h3>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Fecha Receta: {{receta.fechaRecetaString}}</h3>
        </v-row>
        <v-row class="mt-4 mx-1">
          <h3>Texto Receta:</h3>
        </v-row>
        <v-row class="ml-1">
          <v-alert class="multi-line">{{receta.texto}}</v-alert>
        </v-row>
        <v-row class="my-2 mx-1">
          <h3>Diagnóstico: {{receta.diagnostico}}</h3>
        </v-row>
        <v-divider></v-divider>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
       
        <v-btn color="error" @click.stop="show=false">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
   align-items:left;
   justify-content:left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
export default {
  data: (vm) => ({
    maxWidth: '100%'
  }),
  props: ["visible", "recetas", "paciente"],
  computed: {
    show: {
      get() {
      if(this.$store.state.videollamada == true)
        this.maxWidth = '68%'
      else
        this.maxWidth = '100%'
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
 
  
};
</script>