<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Choferes</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Choferes</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="4">
              <v-text-field
                v-model="apellido"
                prepend-icon="mdi-account"
                label="Apellidos"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6" sm="4">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarChoferes(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaChoferes"
        class="elevation-1"
        locale="es-ar"
        item-key="idChofer"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarChofer(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Chofer</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarChofer(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Chofer</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoChofer">Nuevo Chofer</v-btn>
        <v-btn
          color="warning"
          :disabled="listaChoferes.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoChofer
        :visible="showNuevoChofer"
        @close="cierraNuevoChofer()"
        @choferRegistrado="choferRegistrado()"
      ></NuevoChofer>
      <EditarChofer
        :visible="showEditarChofer"
        :idChofer="editedItem.idChofer"
        @close="cierraEditarChofer()"
        @choferModificado="choferModificado()"
      ></EditarChofer>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevoChofer from "./NuevoChofer.vue";
import EditarChofer from "./EditarChofer.vue";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    nombre: null,
    apellido: null,
    listaChoferes: [],
    headers: [
      { text: "Apellidos", value: "apellidoChofer" },
      { text: "Nombres", value: "nombreChofer" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoChofer: false,
    showEditarChofer: false,
    editedItem: {},
    editedIndex: -1,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarChofer(item) {
      this.$swal({
        title: "Borrar Chofer",
        text: "¿Desea borrar el chofer?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          const idChofer = item.idChofer;
          let self = this;
          axios
            .get("/Traslados/BorrarChofer?", {
              params: {
                idChofer: idChofer,
              },
            })
            .then((response) => {
              if (response.data == "error") {
                self.showAlert({
                  icon: "error",
                  title: "El chofer tiene transacciones asociadas",
                  vm: self,
                });
              } else {
                self.showAlert({
                  icon: "success",
                  title: "Chofer borrado exitosamente",
                  vm: self,
                });
                setTimeout(self.buscarChoferes(0), 1700);
              }
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El chofer no pudo ser borrado",
                vm: this,
              });
            });
        } else {
        }
      });
    },
    cierraNuevoChofer() {
      this.showNuevoChofer = false;
    },
    cierraEditarChofer() {
      this.showEditarChofer = false;
    },
    choferRegistrado() {
      this.showNuevoChofer = false;
      this.buscarChoferes(0);
    },
    choferModificado() {
      this.showEditarChofer = false;
      this.buscarChoferes(0);
    },
    nuevoChofer() {
      this.showNuevoChofer = true;
    },
    editarChofer(item) {
      this.editedIndex = this.listaChoferes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarChofer = true;
    },
    buscarChoferes(state) {
      const filtrosData = {
        apellido: this.apellido,
        nombre: this.nombre,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Traslados/GetChoferesNomDeInst?", {
          params: {
            apellido: filtrosData.apellido,
            nombre: filtrosData.nombre,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaChoferes = response.data;
          else {
            self.listaChoferes = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay choferes que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function(error) {});
    },
    imprimir() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = ["Apellidos", "Nombre"];
      source.items.forEach((element) => {
        var temp = [element.apellido, element.nombre];
        rows.push(temp);
      });
      var doc = new jsPDF();
      doc.setProperties({
        title: "Listado Choferes",
      });
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);

      doc.setFontSize(16);
      doc.text(
        "Listado de Choferes",
        doc.internal.pageSize.getWidth() / 2,
        33,
        { align: "center" }
      );
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function(table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });

      window.open(doc.output("bloburl"), "_blank");
    },
  },
  created() {
    this.buscarChoferes(0);
  },
  components: {
    NuevoChofer,
    EditarChofer,
  },
};
</script>
