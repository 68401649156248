<template>
  <div>
    <v-card class="mx-2 px-2">
      <v-toolbar dark color="primary">
        <v-toolbar-title>Dashboard</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-alert
          color="purple"
          dark
          dismissible
          outlined
        >Los indicadores de Turnos están tomadas en base al estado de los mismos. Para más información puede consultar el video tutorial donde se explica el manejo de los estados de los turnos.</v-alert>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-select
              :items="profsBuscar"
              item-text="nombreProfesional"
              item-value="idProfesional"
              menu-props="auto"
              label="Profesional"
              hide-details
              prepend-icon="mdi-account"
              v-model="prof"
              dense
              @change="recargarIndicadores"
              ref="vselect"
            ></v-select>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" sm="6">
            <v-menu
              v-model="menu2"
              :close-on-content-click="false"
              :nudge-right="40"
              transition="scale-transition"
              offset-y
              min-width="290px"
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="mesFormatted"
                  label="Mes / Año"
                  readonly
                  v-on="on"
                  prepend-icon="mdi-calendar"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="mes"
                @input="menu2 = false"
                locale="es-ar"
                type="month"
                :max="maxMes"
                @change="recargarIndicadores"
              ></v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-row>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                color="cyan"
                v-bind="attrs"
                v-on="on"
                dark
                class="mt-4 mx-auto"
                height="120"
                width="260"
              >
                <v-list-item four-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      <v-row class="ml-0 mb-2">
                        <v-icon>mdi-calendar</v-icon>
                        <h4 class="ml-2">Turnos Totales</h4>
                      </v-row>
                      <v-row class="ml-0"></v-row>
                      <v-row class="mr-0 pr-0">
                        <v-spacer></v-spacer>
                        <h1>{{indicadores.totales}}</h1>
                      </v-row>
                      <v-row>
                        <v-icon class="ml-3">mdi-chart-bar</v-icon>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
            <span>Total de turnos del mes, incluye hasta el último día del mes y todos los estados</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                v-bind="attrs"
                v-on="on"
                color="cyan"
                dark
                class="mt-4 mx-auto"
                height="120"
                width="260"
              >
                <v-list-item four-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      <v-row class="ml-0 mb-2">
                        <v-icon>mdi-calendar</v-icon>
                        <h4 class="ml-2">Turnos Atendidos</h4>
                      </v-row>
                      <v-row class="ml-0"></v-row>
                      <v-row class="mr-0 pr-0">
                        <v-spacer></v-spacer>
                        <h1>{{indicadores.atendidos}}</h1>
                      </v-row>
                      <v-row>
                        <v-icon class="ml-3">mdi-chart-bar</v-icon>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
            <span>Turnos que ya fueron atendidos por el profesional</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                color="cyan"
                v-bind="attrs"
                v-on="on"
                dark
                class="mt-4 mx-auto"
                height="120"
                width="260"
              >
                <v-list-item four-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      <v-row class="ml-0 mb-2">
                        <v-icon>mdi-calendar</v-icon>
                        <h4 class="ml-2">Turnos Ausentes</h4>
                      </v-row>
                      <v-row class="ml-0"></v-row>
                      <v-row class="mr-0 pr-0">
                        <v-spacer></v-spacer>
                        <h1>{{indicadores.ausentes}}</h1>
                      </v-row>
                      <v-row>
                        <v-icon class="ml-3">mdi-chart-bar</v-icon>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
            <span>Turnos que quedaron en estado reservado y que son anteriores a la fecha actual</span>
          </v-tooltip>
        </v-row>

        <v-row>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                color="cyan"
                v-bind="attrs"
                v-on="on"
                dark
                class="mt-4 mx-auto"
                height="120"
                width="260"
              >
                <v-list-item four-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      <v-row class="ml-0 mb-2">
                        <v-icon>mdi-calendar</v-icon>
                        <h4 class="ml-2">Turnos En Espera</h4>
                      </v-row>
                      <v-row class="ml-0"></v-row>
                      <v-row class="mr-0 pr-0">
                        <v-spacer></v-spacer>
                        <h1>{{indicadores.espera}}</h1>
                      </v-row>
                      <v-row>
                        <v-icon class="ml-3">mdi-chart-bar</v-icon>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
            <span>Turnos en estado "Paciente en Sala de Espera"</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                color="cyan"
                v-bind="attrs"
                v-on="on"
                dark
                class="mt-4 mx-auto"
                height="120"
                width="260"
              >
                <v-list-item four-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      <v-row class="ml-0 mb-2">
                        <v-icon>mdi-calendar</v-icon>
                        <h4 class="ml-2">Turnos Reservados</h4>
                      </v-row>
                      <v-row class="ml-0"></v-row>
                      <v-row class="mr-0 pr-0">
                        <v-spacer></v-spacer>
                        <h1>{{indicadores.reservados}}</h1>
                      </v-row>
                      <v-row>
                        <v-icon class="ml-3">mdi-chart-bar</v-icon>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
            <span>Turnos que están en estado reservado y cuya fecha es igual o mayor a la fecha actual</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-card
                color="cyan"
                v-bind="attrs"
                v-on="on"
                dark
                class="mt-4 mx-auto"
                height="120"
                width="260"
              >
                <v-list-item four-line>
                  <v-list-item-content>
                    <div class="overline mb-4">
                      <v-row class="ml-0 mb-2">
                        <v-icon>mdi-calendar</v-icon>
                        <h4 class="ml-2">Turnos Web</h4>
                      </v-row>
                      <v-row class="ml-0"></v-row>
                      <v-row class="mr-0 pr-0">
                        <v-spacer></v-spacer>
                        <h1>{{indicadores.web}}</h1>
                      </v-row>
                      <v-row>
                        <v-icon class="ml-3">mdi-chart-bar</v-icon>
                      </v-row>
                    </div>
                  </v-list-item-content>
                </v-list-item>
              </v-card>
            </template>
            <span>Turnos que fueron reservados a través del sitio web, sin tener en cuenta su estado</span>
          </v-tooltip>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card color="orange" dark class="mt-2 mx-5">
              <v-list-item four-line>
                <v-list-item-content>
                  <div class="overline mb-4">
                    <v-row class="ml-0 mb-2">
                      <v-icon>mdi-currency-usd</v-icon>
                      <h4 class="ml-2">Ingresos</h4>
                    </v-row>
                    <v-row class="ml-0"></v-row>
                    <v-row class="mr-0 pr-0">
                      <v-spacer></v-spacer>
                      <h1>${{indicadores.ingresos}}</h1>
                    </v-row>
                    <v-row>
                      <v-icon class="ml-3">mdi-chart-bar</v-icon>
                    </v-row>
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="mt-2 mx-5" color="#4278f6" dark>
              <v-card-title>
                <v-icon large left>mdi-chart-bar</v-icon>
                <span class="title font-weight-light">Ranking de ausencias por paciente</span>
              </v-card-title>
              <v-card-text>
                <v-card flat>
                  <v-simple-table ref="tableAusencias">
                    <template v-slot:default>
                      <thead style="background: #4278f6">
                        <tr>
                          <th class="text-left">Nº</th>
                          <th class="text-left">Paciente</th>
                          <th class="text-left">Ausencias</th>
                        </tr>
                      </thead>
                      <tbody style="background: #4278f6">
                        <tr v-for="(item,i) in turnospacientes" :key="item.paciente">
                          <td>{{ i+1 }}</td>
                          <td>{{ item.paciente }}</td>
                          <td>{{ item.ausencias }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="mt-2 mx-5" color="#b829de" dark>
              <v-card-title>
                <v-icon large left>mdi-chart-bar</v-icon>
                <span class="title font-weight-light">Ranking de turnos por obra social</span>
              </v-card-title>
              <v-card-text>
                <v-card flat>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead style="background: #b829de">
                        <tr>
                          <th class="text-left">Nº</th>
                          <th class="text-left">Obra Social</th>
                          <th class="text-left">Turnos</th>
                        </tr>
                      </thead>
                      <tbody style="background: #b829de">
                        <tr v-for="(item,i) in turnosos" :key="item.obrasocial">
                          <td>{{ i+1 }}</td>
                          <td>{{ item.obrasocial }}</td>
                          <td>{{ item.turnos }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12">
            <v-card class="mt-2 mx-5" color="#147536" dark>
              <v-card-title>
                <v-icon large left>mdi-chart-bar</v-icon>
                <span class="title font-weight-light">Distribución de Turnos Atendidos por Edad</span>
              </v-card-title>
              <v-card-text>
                <v-card flat>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead style="background: #147536">
                        <tr>
                          <th class="text-left">Nº</th>
                          <th class="text-left">Edad</th>
                          <th class="text-left">Cantidad</th> 
                        </tr>
                      </thead>
                      <tbody style="background: #147536">
                        <tr v-for="(item,i) in turnosedad" :key="item.edad">
                          <td>{{ i+1 }}</td>
                          <td>{{ item.edad }}</td>
                          <td>{{ item.cantidad }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

         <v-row>
          <v-col cols="12">
            <v-card class="mt-2 mx-5" color="#3F79D7" dark>
              <v-card-title>
                <v-icon large left>mdi-chart-bar</v-icon>
                <span class="title font-weight-light">Distribución de Turnos Atendidos por Patología</span>
              </v-card-title>
              <v-card-text>
                <v-card flat>
                  <v-simple-table>
                    <template v-slot:default>
                      <thead style="background: #3F79D7">
                        <tr>
                          <th class="text-left">Nº</th>
                          <th class="text-left">Patología</th>
                          <th class="text-left">Cantidad</th> 
                        </tr>
                      </thead>
                      <tbody style="background: #3F79D7">
                        <tr v-for="(item,i) in turnospatologia" :key="item.nombrePatologia">
                          <td>{{ i+1 }}</td>
                          <td>{{ item.nombrePatologia }}</td>
                          <td>{{ item.cantidad }}</td>
                        </tr>
                      </tbody>
                    </template>
                  </v-simple-table>
                </v-card>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>

      </v-card-text>

      <v-card-actions>
        <v-btn
          color="primary"
          @click.stop="recargarIndicadores"
          :loading="loading"
          :disabled="loading"
        >
          <v-icon left class="mx-1">mdi-refresh</v-icon>Actualizar
        </v-btn>
        <v-spacer></v-spacer>
         <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
         <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <ImprimirDashboard
      :visible="showImprimirDashboard"
      :nomProf="nomProf"
      :mesFormatted="mesFormatted"
      :indicadores="indicadores"
      :turnospacientes="turnospacientes"
      :turnosos="turnosos"
      :turnosedad="turnosedad"
      :turnospatologia="turnospatologia"
      @close="showImprimirDashboard=false"
      ></ImprimirDashboard>
    </v-card>
  </div>
</template>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import ImprimirDashboard from "../Estadisticas/ImprimirDashboard.vue"
import autotable from "jspdf-autotable";

export default {
  data: (vm) => ({
    showImprimirDashboard: false,
    profsBuscar: [],
    nomProf: '',
    prof: "",
    menu: false,
    modal: false,
    menu2: false,
    mes: "",
    mesFormatted: "",
    turnospacientes: [],
    turnosos: [],
    turnosedad: [],
    turnospatologia: [],
    loading: false,
    indicadores: {
      totales: "",
      atendidos: "",
      ausentes: "",
      espera: "",
      web: "",
      ingresos: "",
      reservados: "",
    },
    maxMes: "",
  }),
  methods: {
    recargarIndicadores() {
      this.loading = true;
      this.recargarDashboard();
      this.recargarRankingPacientes();
      this.recargarRankingOS();
      this.recarcarRankingEdad();
      this.recargarRankingPatologias();
      this.loading = false;
    },
    recargarDashboard() {
      const filtrosData = {
        idProfesional: this.prof,
        mes: this.mes,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Dashboard/RecargarDashboard?", {
          params: {
            idProfesional: filtrosData.idProfesional,
            mes: filtrosData.mes,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data!=null && response.data!='')
            self.indicadores = response.data;
          else
            self.indicadores = {};
        })
        .catch(function (error) {
                   
        });
    },
    recargarRankingPacientes() {
      this.turnospacientes = [];
      const filtrosData = {
        idProfesional: this.prof,
        mes: this.mes,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Dashboard/RecargarRankingPacientes?", {
          params: {
            idProfesional: filtrosData.idProfesional,
            mes: filtrosData.mes,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data!=null && response.data!='')
            self.turnospacientes = response.data;
          else
            self.turnospacientes = [];
        })
        .catch(function (error) {
                  
        });
    },
    recargarRankingOS() {
      this.turnosos;
      const filtrosData = {
        idProfesional: this.prof,
        mes: this.mes,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Dashboard/RecargarRankingOs?", {
          params: {
            idProfesional: filtrosData.idProfesional,
            mes: filtrosData.mes,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data!=null && response.data!='')
            self.turnosos = response.data;
          else
            self.turnosos = [];
        })
        .catch(function (error) {
                   
        });
    },
    recarcarRankingEdad(){
      this.turnosedad = [];
      const filtrosData = {
        idProfesional: this.prof,
        mes: this.mes,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Dashboard/RecargarRankingEdad?", {
          params: {
            idProfesional: filtrosData.idProfesional,
            mes: filtrosData.mes,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data!=null && response.data!='')
            self.turnosedad = response.data;
          else
            self.turnosedad = [];
        })
        .catch(function (error) {
                  
        });
    },
    recargarRankingPatologias(){
      this.turnospatologia = [];
      const filtrosData = {
        idProfesional: this.prof,
        mes: this.mes,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Dashboard/RecargarRankingPatologias?", {
          params: {
            idProfesional: filtrosData.idProfesional,
            mes: filtrosData.mes,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data!=null && response.data!='')
            self.turnospatologia = response.data;
          else
            self.turnospatologia = [];
        })
        .catch(function (error) {
                    
        });
    },
    salir() {
      router.push({ path: "/Home" });
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    parseDate(date) {
      if (!date) return null;
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}-${month.padStart(2, "0")}-${year}-`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    imprimir() {
          this.nomProf = this.$refs.vselect.selectedItems[0].nombreProfesional;            
          this.showImprimirDashboard = true;    
        
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
        if (self.profsBuscar.length > 1)
          self.profsBuscar.unshift({
            idProfesional: 0,
            nombreProfesional: "TODOS",
          });

        self.prof = self.profsBuscar[0].idProfesional;
      });

    let month = this.formatDate(this.toISOLocal(new Date())).substring(3, 5);
    if (month == 0) month = 12;
    let year = this.formatDate(this.toISOLocal(new Date())).substring(6, 10);
    //if(month==12) year = year - 1;
    if (month.toString().length == 1) month = "0" + month;
    this.mesFormatted = month + "/" + year;
    this.maxMes = year + "-" + month;
    this.mes = year + "-" + month;

    this.recargarIndicadores();
  },

  watch: {
    mes(val) {
      this.mesFormatted =
        this.mes.substring(5, 7) + "/" + this.mes.substring(0, 4);
    },
  },
  components: {
    ImprimirDashboard
  }
};
</script>