<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title> Nuevo Paciente </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.dni"
                prepend-icon="mdi-account"
                label="D.N.I"
                dense
                required
                @keypress="onlyNumber"
                @keypress.enter="buscarPacienteDni"
                @blur="buscarPacienteDni"
                :rules="requiredRules"
                maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.numHC"
                prepend-icon="mdi-account"
                label="N° Hist. Clin."
                dense
                maxlength="30"
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.apellidos"
                prepend-icon="mdi-account"
                label="Apellidos"
                maxlength="50"
                :rules="requiredRules"
                @blur="upper"
                dense
                required
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.nombres"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="50"
                :rules="requiredRules"
                @blur="upperNombres"
                dense
                required
              ></v-text-field>
            </v-col>

            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.fechaNac"
                :value="paciente.fechaNac"
                label="Fecha de Nacimiento"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-select
                :items="sexo"
                v-model="paciente.sexo"
                item-text="paciente.sexo"
                item-value="paciente.sexo"
                label="Sexo"
                dense
                prepend-icon="mdi-account"
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.direccion"
                prepend-icon="mdi-map-marker"
                label="Direccion"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="paciente.localidad"
                prepend-icon="mdi-map-marker"
                label="Localidad"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="3"
              v-if="
                !this.$store.state.usuario.institucion.notificaWA ||
                this.$store.state.usuario.institucion.idInstitucion === 114 ||
                this.$store.state.usuario.institucion.idInstitucion === 270
              "
            >
              <v-text-field
                v-model="paciente.celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="4"
              md="3"
              v-if="
                this.$store.state.usuario.institucion.notificaWA &&
                this.$store.state.usuario.institucion.idInstitucion !== 114 &&
                this.$store.state.usuario.institucion.idInstitucion !== 270
              "
            >
              <v-text-field
                v-model="paciente.celular"
                prepend-icon="mdi-cellphone"
                label="Celular"
                maxlength="50"
                hint="Ingrese el celular con la característica, sin 0, sin 15 y sin guiones. Ej: 1135036549"
                dense
                :rules="celularRules"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-text-field
                v-model="paciente.telefono"
                prepend-icon="mdi-phone"
                label="Telefono"
                maxlength="50"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-text-field
                v-model="paciente.mail"
                prepend-icon="mdi-email"
                label="E-mail"
                :rules="emailRules"
                dense
                maxlength="80"
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="3">
              <v-text-field
                v-model="paciente.telefonoEmergencia"
                prepend-icon="mdi-phone"
                label="Telefono / Contacto Emergencia"
                maxlength="120"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="12" md="12">
              <v-text-field
                v-model="paciente.observaciones"
                prepend-icon="mdi-account"
                label="Observaciones del Paciente"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="4" md="3">
              <v-file-input
                :rules="rules"
                accept="image/png, image/jpeg"
                prepend-icon="mdi-camera"
                v-model="paciente.foto"
                show-size
                chips
                class="mb-0 pb-0"
                label="Foto"
                hint="La imágen debe ser .jpg o .png y pesar 500KB o menos"
                persistent-hint
                dense
                @change="displayPhoto"
                @click:clear="displayPhoto"
              ></v-file-input>
            </v-col>
            <v-col class="pr-0 mr-0 pt-0 mt-0 ml-0" cols="12" sm="4" md="2">
              <v-badge color="error" overlap offset-y="40" offset-x="40" v-if="src != '' && src != null">
                <v-icon slot="badge" dark x-small @click="borrarFoto">mdi-close</v-icon>
                <v-avatar size="160" tile>
                  <v-img class="pr-0 mr-0 ml-0" max-height="100px" max-width="100px" :aspect-ratio="1" :src="src">
                  </v-img>
                </v-avatar>
              </v-badge>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarPaciente">Guardar</v-btn>

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    horaTurno: null,
    paciente: {
      dni: null,
      apellidos: null,
      nombres: null,
      fechaNac: null,
      sexo: null,
      direccion: null,
      localidad: null,
      mail: null,
      telefono: null,
      telefonoEmergencia: null,
      celular: null,
      observaciones: null,
      antecedentes: "",
      antecedentesFamiliares: "",
      foto: null,
      numHC: "",
    },
    src: "",
    sexo: ["FEMENINO", "MASCULINO", "OTRO"],
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    rules: [(value) => !value || value.size < 500000 || "La foto debe pesar 500KB o menos"],
    emailRules: [(v) => !v || /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || "E-mail inválido"],
    celularRules: [(v) => !v || v.length == 10 || "Debe tener 10 caracteres"],
  }),

  props: ["visible"],

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          this.paciente.dni = "";
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          if (this.$store.state.rutaSalidaNuevoPaciente == 0) this.$emit("close");
          else {
            this.$store.dispatch("setRutaSalidaNuevoPaciente", 0);
            this.$emit("cierraNuevoPacienteSinGuardar");
          }
        }
      },
    },
  },

  methods: {
    borrarFoto() {
      this.src = "";
      this.profesional.foto = null;
    },
    displayPhoto() {
      this.src = "";
      if (this.paciente.foto != "" && this.paciente.foto != null) {
        const data = URL.createObjectURL(this.paciente.foto);
        this.src = data;
      }
    },
    validarFechaNacimiento() {
      if (this.paciente.fechaNac == null) return true;
      else {
        if (this.paciente.fechaNac.toString().length > 10) return false;
        else return true;
      }
    },
    guardarPaciente() {
      this.$swal({
        title: "Guardar Paciente",
        text: "¿Desea registrar el paciente?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')
          let isValidDate = this.validarFechaNacimiento();
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha de nacimiento no tiene un formato válido.",
              vm: this,
              timer: 2000,
            });
          } else {
            var datosPaciente = new FormData();
            datosPaciente.append("idInstitucion", this.$store.state.usuario.institucion.idInstitucion);
            datosPaciente.append("nrodocumento", this.paciente.dni);
            datosPaciente.append("apellidos", this.paciente.apellidos);
            datosPaciente.append("nombres", this.paciente.nombres);
            if (this.paciente.fechaNac == null) datosPaciente.append("fechaNacimiento", "");
            else datosPaciente.append("fechaNacimiento", this.paciente.fechaNac);
            datosPaciente.append("sexo", this.paciente.sexo);
            datosPaciente.append("direccion", this.paciente.direccion);
            datosPaciente.append("localidad", this.paciente.localidad);
            datosPaciente.append("celular", this.paciente.celular.trim());
            datosPaciente.append("telefono", this.paciente.telefono.trim());
            datosPaciente.append("telefonoEmergencia", this.paciente.telefonoEmergencia);
            datosPaciente.append("numHC", this.paciente.numHC);
            datosPaciente.append("email", this.paciente.mail.trim());
            datosPaciente.append("observaciones", this.paciente.observaciones);
            datosPaciente.append("antecedentes", this.paciente.antecedentes);
            datosPaciente.append("antecedentesFamiliares", this.paciente.antecedentesFamiliares);
            datosPaciente.append("foto", this.paciente.foto);
            let self = this;
            axios({
              method: "post",
              url: "/Paciente/GuardarPaciente",
              data: datosPaciente,
              header: {
                Accept: "application/json",
                "Content-Type": "multipart/form-data",
              },
            })
              .then((response) => {
                if (response.data === "Paciente existente") {
                  self.showAlert({
                    icon: "error",
                    title: "El número de dni ya está registrado para otro paciente.",
                    vm: this,
                    timer: 2000,
                  });
                  return;
                }
                self.$refs.form.resetValidation();
                self.vaciarModels();
                self.paciente.dni = "";
                document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
                self.showAlert({
                  icon: "success",
                  title: "Paciente Registrado",
                  vm: self,
                  timer: 2000,
                });
                if (self.$store.state.rutaSalidaNuevoPaciente == 0) self.$emit("close");
                else {
                  this.$store.dispatch("setRutaSalidaNuevoPaciente", 0);
                  self.$emit("close", response.data);
                }
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La foto debe estar en formato .jpg o .png",
                  vm: this,
                  timer: 2000,
                });
              });
          }
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    upper() {
      if (this.paciente.apellidos != null) this.paciente.apellidos = this.paciente.apellidos.toString().toUpperCase();
    },
    upperNombres() {
      if (this.paciente.nombres != null) this.paciente.nombres = this.paciente.nombres.toString().toUpperCase();
    },

    vaciarModels() {
      this.paciente.apellidos = "";
      this.paciente.nombres = "";
      this.paciente.direccion = "";
      this.paciente.localidad = "";
      this.paciente.celular = "";
      this.paciente.telefono = "";
      this.paciente.mail = "";
      this.paciente.observaciones = "";
      this.paciente.antecedentes = "";
      this.paciente.antecedentesFamiliares = "";
      this.paciente.telefonoEmergencia = "";
      this.paciente.numHC = "";
      this.paciente.fechaNac = null;
      this.paciente.sexo = null;
      this.paciente.foto = null;
      this.src = "";
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    buscarPacienteDni() {
      if (isNaN(this.paciente.dni)) {
        this.showAlert({
          timer: 3200,
          icon: "error",
          title: "El D.N.I. debe estar compuesto sólo por números.",
          vm: this,
        });
        this.paciente.dni = "";
        this.paciente.idPaciente = 0;
        return;
      }
      if (this.paciente.dni != "" && this.paciente.dni != null) this.paciente.dni = parseInt(this.paciente.dni);

      const pacientesData = {
        dni: this.paciente.dni,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Paciente/GetPacienteDni?", {
          params: {
            dni: pacientesData.dni,
            idInstitucion: pacientesData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.showAlert({
              icon: "error",
              title: "DNI registrado para el paciente: " + response.data.apellidos + ", " + response.data.nombres,
              vm: self,
              timer: 3500,
            });
            self.vaciarModels();
            self.paciente.dni = "";
          }
        })
        .catch(function (error) {});
    },
  },
  watch: {
    date(val) {
      this.dateFormatted = this.formatDate(this.date);
    },
  },
  created() {
    this.vaciarModels();
    if (
      this.$store.state.usuario.institucion.idInstitucion === 114 ||
      this.$store.state.usuario.institucion.idInstitucion === 270
    ) {
      this.paciente.celular = "549";
    }
  },
};
</script>
