<template>
  <div class="pa-4" style="background: #a1a1a1">
    <h2 class="mx-auto my-4 py-0 color-white">Bienvenido {{ usuario }}!</h2>
    <h3
      class="color-white mb-2"
      v-if="$store.state.usuario.institucion.esEmpresa"
    >
      ICAS / {{ $store.state.usuario.institucion.nombreInstitucion }}
    </h3>

    <v-card
      class="mb-2"
      color="blue"
      dark
      tile
      v-if="roles.some((o) => o.idRol !== 9)"
    >
      <v-card-title>
        <v-icon large left>mdi-star</v-icon>
        <span class="title font-weight-light">Acceso Rápido</span>
      </v-card-title>
      <v-card-text>
        <v-row
          v-if="
            roles.some((o) => o.idRol === 1) || roles.some((o) => o.idRol === 4)
          "
        >
          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/GestionTurnos"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-calendar-multiple</v-icon>
                    </h3>
                  </v-col>
                  <h5>Agenda Diaria</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/GestionTurnosSemanaWide"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-tablet</v-icon>
                    </h3>
                  </v-col>

                  <h5>Agenda Semanal</h5>
                  <br />
                  <h5>Grilla</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/GestionTurnosSemana"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-cellphone</v-icon>
                    </h3>
                  </v-col>
                  <h5>Agenda Semanal</h5>
                  <br />
                  <h5>Lista</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>

          <v-col cols="12" md="3" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/HistoriasClinicas"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-hospital</v-icon>
                    </h3>
                  </v-col>

                  <h5>Historias Clínicas</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/GestionTurnos"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-calendar-multiple</v-icon>
                    </h3>
                  </v-col>
                  <h5>Agenda Diaria</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/GestionTurnosSemanaWide"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-tablet</v-icon>
                    </h3>
                  </v-col>

                  <h5>Agenda Semanal</h5>
                  <br />
                  <h5>Grilla</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card
                dark
                color="#1574db"
                height="160"
                width="220"
                to="/GestionTurnosSemana"
              >
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-cellphone</v-icon>
                    </h3>
                  </v-col>
                  <h5>Agenda Semanal</h5>
                  <br />
                  <h5>Lista</h5>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#26c6da" dark tile>
      <v-card-title>
        <v-icon large left>mdi-message-text-clock</v-icon>
        <span class="title font-weight-light">Recordatorios</span>
        <v-spacer></v-spacer>
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              v-bind="attrs"
              v-on="on"
              small
              class="mr-3"
              @click="recordatorios()"
            >
              <v-icon>mdi-cogs</v-icon>
            </v-btn>
          </template>
          <span>Gestión de Recordatorios</span>
        </v-tooltip>
      </v-card-title>
      <v-card-text>
        <h3 class="mt-2" v-if="listaRecordatorios.length == 0">
          No hay recordatorios
        </h3>
        <v-expansion-panels popout>
          <v-expansion-panel v-for="(item, i) in listaRecordatorios" :key="i">
            <v-expansion-panel-header class="ml-0" color="#10abb6">{{
              item.titulo
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="multi-line" color="#10abb6">{{
              item.texto
            }}</v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-card class="mb-2" color="#b829de" dark tile>
      <v-card-title>
        <v-icon large left>mdi-calendar-today</v-icon>
        <span class="title font-weight-light">Cumpleaños del día</span>
      </v-card-title>
      <v-card-text>
        <h3 class="mt-2" v-if="birthdays.length == 0">No hay cumpleaños</h3>
        <v-card v-if="birthdays.length > 0" flat>
          <v-simple-table>
            <template v-slot:default>
              <thead style="background: #b829de">
                <tr>
                  <th class="text-left">Paciente</th>
                  <th class="text-left">Mail</th>
                  <th class="text-left">Celular</th>
                </tr>
              </thead>
              <tbody style="background: #b829de">
                <tr v-for="item in birthdays" :key="item.paciente">
                  <td>{{ item.paciente }}</td>
                  <td>{{ item.mail }}</td>
                  <td>{{ item.celular }}</td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
        </v-card>
      </v-card-text>
    </v-card>

    <v-card
      v-if="listaNovedades.length > 0"
      class="mb-2"
      color="#69e193"
      dark
      tile
    >
      <v-card-title>
        <v-icon large left>mdi-bullhorn</v-icon>
        <span class="title font-weight-light">Novedades en SiGeMed</span>
      </v-card-title>
      <v-card-text>
        <v-expansion-panels popout>
          <v-expansion-panel v-for="(item, i) in listaNovedades" :key="i">
            <v-expansion-panel-header class="ml-0" color="#27ba48">{{
              item.titulo
            }}</v-expansion-panel-header>
            <v-expansion-panel-content class="multi-line" color="#27ba48">
              {{ item.texto }}
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="item.boton"
                  dark
                  color="#249118"
                  :href="item.link"
                  target="_blank"
                  >{{ item.textoBoton }}
                </v-btn>
              </v-card-actions>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>
    </v-card>

    <v-card class="mx-auto" color="orange" dark tile>
      <v-card-title>
        <v-icon large left>mdi-youtube-tv</v-icon>
        <span class="title font-weight-light">Videos Tutoriales</span>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesTurnos">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-calendar-multiple</v-icon>
                    </h3>
                  </v-col>
                  <h3>Turnos</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesConfig">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-cogs</v-icon>
                    </h3>
                  </v-col>
                  <h3>Configuraciones</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesGestion">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-account-multiple</v-icon>
                    </h3>
                  </v-col>
                  <h3>Gestión</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesHC">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-hospital</v-icon>
                    </h3>
                  </v-col>
                  <h3>Historia Clínica</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesCaja">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-currency-usd</v-icon>
                    </h3>
                  </v-col>
                  <h3>Caja</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
          <v-col cols="12" md="4" sm="12">
            <v-row align="center" justify="center">
              <v-card dark color="#db8315" width="300" to="/TutorialesCuenta">
                <v-card-title class="headline layout justify-center">
                  <v-col cols="12" class="headline layout justify-center">
                    <h3>
                      <v-icon x-large>mdi-key-variant</v-icon>
                    </h3>
                  </v-col>
                  <h3>Cuenta</h3>
                </v-card-title>
              </v-card>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>
<style scoped>
  .multi-line {
    white-space: pre-line;
  }
</style>
<script>
  import router from "../router/index.js";
  import axios from "../axios-auth.js";

  export default {
    data: (vm) => ({
      birthdays: [],
      usuario: null,
      listaRecordatorios: [],
      listaNovedades: [],
      titulo: "",
      modulo: "",
      roles: [],
    }),
    created() {
      this.usuario = this.$store.state.usuario.usuario.toString().trim();
      this.roles = this.$store.state.usuario.roles;
    },
    mounted() {
      if (
        this.$store.state.usuario.usuario == "" ||
        this.$store.state.usuario.usuario == null
      ) {
        this.$store.dispatch("logout");
      } else {
        this.cargarRecordatorios();
        this.cargarCumpleaños();
        this.cargarNovedades();
      }
    },
    methods: {
      recordatorios() {
        router.push("/Recordatorios");
      },
      cargarRecordatorios() {
        const recordatoriosData = {
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          usuario: this.$store.state.usuario.usuario.toString().trim(),
        };

        let self = this;
        axios
          .get("/Usuario/CargarRecordatorios?", {
            params: {
              idInstitucion: recordatoriosData.idInstitucion,
              usuario: recordatoriosData.usuario,
            },
          })
          .then(function(response) {
            if (response.data != null && response.data != "")
              self.listaRecordatorios = response.data;
            else self.listaRecordatorios = [];
          })
          .catch(function(error) {});
      },
      cargarCumpleaños() {
        const cumpleanosData = {
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };

        let self = this;
        axios
          .get("/Usuario/CargarCumpleanos?", {
            params: {
              idInstitucion: cumpleanosData.idInstitucion,
            },
          })
          .then(function(response) {
            if (response.data != "" && response.data != null)
              self.birthdays = response.data;
            else self.birthdays = [];
          })
          .catch(function(error) {
            self.birthdays = [];
          });
      },
      cargarNovedades() {
        let self = this;
        axios
          .get("/Usuario/CargarNovedades")
          .then(function(response) {
            if (response.data != "" && response.data != null)
              self.listaNovedades = response.data;
            else self.listaNovedades = [];
          })
          .catch(function(error) {
            self.listaNovedades = [];
          });
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2500,
        });
      },
    },
  };
</script>
<style>
  .color-white {
    color: white;
  }
</style>
