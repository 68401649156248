<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>
            Nuevo Consultorio
            
          </v-toolbar-title>
        </v-toolbar>
        <v-card-text>
          <v-row dense>
            
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-account"
                label="Nombre Consultorio"
                maxlength="50"
                :rules="requiredRules"
                dense
                required
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
            
        <v-card-actions>
          
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="guardarConsultorio">Guardar</v-btn>

          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
        
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";

export default {
  data: vm => ({
    nombre: null,  
    valid: false,
    lazy: false,
    requiredRules: [v => !!v || "Dato obligatorio"],    
  }),

  props: [
    "visible",   
  ],

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      }
    }
  },

  methods: {
    guardarConsultorio() {
      this.$swal({
        title: "Guardar Consultorio",
        text: "¿Desea registrar el consultorio?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true
      }).then(result => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosConsultorio = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            nombre: this.nombre,
                        
          };
          let self = this;
          axios
            .post("/Profesional/GuardarConsultorio", datosConsultorio)
            .then(response => {
             if(response.data == "error")
             {
               self.showAlert({
                icon: "error",
                title: "Ya existe un consultorio registrado con ese nombre",
                vm: self
              });
             }
             else
             {
              self.$refs.form.resetValidation();
              self.vaciarModels();
              
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Consultorio registrado exitosamente",
                vm: self
              });
              self.$emit("consultorioRegistrado");
             }
            })
            .catch(error => {
              self.showAlert({
                icon: "error",
                title: "El Consultorio no pudo ser registrado",
                vm: this
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
           
    vaciarModels() {
      this.nombre = "";
                  
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000
      });
    },
     
  },
  
  created() {
    this.vaciarModels();
    
  }
};
</script>