<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title
          >Gestión de Liquidaciones a Profesionales</v-toolbar-title
        >
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Liquidaciones</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarLiquidaciones(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaLiquidaciones"
        class="elevation-1"
        locale="es-ar"
        item-key="idLiqCajaEnc"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="imprimir(item)"
                >mdi-printer</v-icon
              >
            </template>
            <span>Imprimir Liquidación</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarLiquidacion(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Liquidación</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaLiquidacion"
          >Nueva Liq. Prof.</v-btn
        >
        <v-btn
          color="warning"
          :disabled="listaLiquidaciones.length < 1"
          @click.stop="imprimirTodas"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevaLiquidacion
        :visible="showNuevaLiquidacion"
        @close="cierraNuevaLiquidacion()"
        @liquidacionRegistrada="liquidacionRegistrada"
      ></NuevaLiquidacion>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevaLiquidacion from "../Caja/NuevaLiquidacion.vue";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    prof: "",
    profsBuscar: [],
    listaLiquidaciones: [],
    headers: [
      { text: "Fecha", value: "fechaString" },
      { text: "Profesional", value: "nomProfesional" },
      { text: "Importe Cobrado", value: "totalCobradoString", align: "end" },
      { text: "Importe Pagado", value: "totalPagadoString", align: "end" },
      { text: "Acciones", value: "actions" },
    ],
    fechaDesde: null,
    fechaHasta: null,
    showNuevaLiquidacion: false,
    editedItem: {},
    editedIndex: -1,
    cobros: [],
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarLiquidacion(item) {
      this.$swal({
        title: "Borrar Liquidación",
        text: "¿Desea borrar la liquidación?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idLiqCajaEnc = item.idLiqCajaEnc;
          let self = this;
          axios
            .get("/Caja/BorrarLiquidacion?", {
              params: {
                idLiqCajaEnc: idLiqCajaEnc,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Liquidación borrada",
                vm: self,
              });
              setTimeout(self.buscarLiquidaciones(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "La liquidación no puedo ser borrada",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevaLiquidacion() {
      this.showNuevaLiquidacion = false;
      this.buscarLiquidaciones(0);
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    nuevaLiquidacion() {
      this.showNuevaLiquidacion = true;
    },
    liquidacionRegistrada() {
      this.buscarLiquidaciones(0);
      this.showNuevaLiquidacion = false;
    },
    buscarLiquidaciones(state) {
      const filtrosData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        idProfesional: this.prof,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/Caja/BuscarLiquidaciones?", {
          params: {
            fechaDesde: filtrosData.fechaDesde,
            fechaHasta: filtrosData.fechaHasta,
            idProfesional: filtrosData.idProfesional,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "")
            self.listaLiquidaciones = response.data;
          else {
            self.listaLiquidaciones = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay liquidaciones que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function (error) {});
    },
    imprimir(item) {
      var doc = new jsPDF("landscape");
      doc.setProperties({
        title: "Liquidación de Caja",
      });

      let rows = [];
      let columnHeader = [
        "Fecha",
        "Paciente",
        "Obra Social",
        "Observaciones",
        "Forma de Pago",
        "Importe",
      ];
      let subtotales = {};
      item.cobrosIncluidos.forEach((element) => {
        var temp = [
          element.fechaString,
          element.paciente,
          element.obraSocial,
          element.observaciones,
          element.nombreFormaPago,
          element.totalString,
        ];
        rows.push(temp);
        if (element.idFormaPago === 1) {
          subtotales.efectivo = (subtotales.efectivo ?? 0) + element.total;
        } else {
          subtotales.transferencia =
            (subtotales.transferencia ?? 0) + element.total;
        }
        if (element.idFormaPago === 4) {
          subtotales.soloTransferencia =
            (subtotales.soloTransferencia ?? 0) + element.total;
        }
      });

      let nombreInstitucion =
        this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

      let retenido = parseFloat(item.totalCobrado - item.totalPagado).toFixed(
        2
      );

      let propRetenido = parseFloat(retenido / item.totalCobrado).toFixed(2);

      var nombreProf = item.esMultiple ? "VARIOS" : item.nomProfesional;
      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(16);
      doc.text(
        "Liquidación de Caja",
        doc.internal.pageSize.getWidth() / 2,
        20,
        {
          align: "center",
        }
      );
      doc.line(123, 21, 174, 21);
      doc.setFontSize(12);
      doc.text("Profesional: " + nombreProf, 13, 30);
      doc.text("Fecha: " + item.fechaString, 13, 37);

      doc.autoTable(columnHeader, rows, {
        startY: 45,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });

      let finalY = doc.lastAutoTable.finalY;

      if (finalY > 180) {
        doc.addPage();
        finalY = 20;
      }

      if (this.$store.state.usuario.institucion.idInstitucion === 195) {
        const subtotalEfectivo = isNaN(subtotales.efectivo)
          ? 0
          : subtotales.efectivo;
        const subtotalTransferencia = isNaN(subtotales.transferencia)
          ? 0
          : subtotales.transferencia;

        doc.text(
          "Total Cobrado Ef.: $" +
            parseFloat(subtotalEfectivo)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          70,
          finalY + 8,
          { align: "right" }
        );
        doc.text(
          "Retenido Ef.: $" +
            parseFloat(subtotalEfectivo * propRetenido)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          70,
          finalY + 16,
          { align: "right" }
        );
        doc.text(
          "A Pagar Ef.: $" +
            parseFloat(subtotalEfectivo - subtotalEfectivo * propRetenido)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          70,
          finalY + 24,
          { align: "right" }
        );

        doc.text(
          "Total Cobrado Electr.: $" +
            parseFloat(subtotalTransferencia)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          175,
          finalY + 8,
          { align: "right" }
        );
        doc.text(
          "Retenido Electr.: $" +
            parseFloat(subtotalTransferencia * propRetenido)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          175,
          finalY + 16,
          { align: "right" }
        );
        doc.text(
          "A Pagar Electr.: $" +
            parseFloat(
              subtotalTransferencia - subtotalTransferencia * propRetenido
            )
              .toFixed(2)
              .toString()
              .replace(".", ","),
          175,
          finalY + 24,
          { align: "right" }
        );
      }

      doc.text(
        "Total Cobrado: $" +
          parseFloat(item.totalCobrado).toFixed(2).toString().replace(".", ","),
        280,
        finalY + 8,
        { align: "right" }
      );
      doc.text(
        "Retenido: $" +
          parseFloat(retenido).toFixed(2).toString().replace(".", ","),
        280,
        finalY + 16,
        {
          align: "right",
        }
      );
      doc.text(
        "A Pagar: $" +
          parseFloat(item.totalPagado).toFixed(2).toString().replace(".", ","),
        280,
        finalY + 24,
        {
          align: "right",
        }
      );

      if (this.$store.state.usuario.institucion.idInstitucion === 170) {
        const subtotalTransferencia = isNaN(subtotales.soloTransferencia)
          ? 0
          : subtotales.soloTransferencia;
        const totalFinalLiquidacion = item.totalPagado - subtotalTransferencia;
        doc.text(
          "Total Cobrado Transf.: $" +
            parseFloat(subtotalTransferencia)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          280,
          finalY + 32,
          { align: "right" }
        );
        doc.text(
          "Total Final Liquidacion.: $" +
            parseFloat(totalFinalLiquidacion)
              .toFixed(2)
              .toString()
              .replace(".", ","),
          280,
          finalY + 40,
          { align: "right" }
        );
      }

      window.open(doc.output("bloburl"), "_blank");
    },
    imprimirTodas() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = [
        "Fecha",
        "Profesional",
        "Importe Cobrado",
        "Importe Pagado",
      ];
      let importeTotalCobrado = 0;
      let importeTotalRetenido = 0;
      let importeTotalPagado = 0;
      source.items.forEach((element) => {
        var temp = [
          element.fechaString,
          element.nomProfesional,
          element.totalCobradoString,
          element.totalPagadoString,
        ];
        rows.push(temp);
        importeTotalCobrado += element.totalCobrado;
        importeTotalPagado += element.totalPagado;
      });
      importeTotalRetenido = importeTotalCobrado - importeTotalPagado;

      var doc = new jsPDF();
      doc.setProperties({
        title: "Listado Resúmen Liquidaciones",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion =
        this.$store.state.usuario.institucion.nombreInstitucion
          .toString()
          .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Fecha Desde: " +
          this.formatDate(this.fechaDesde) +
          "  -  " +
          "Fecha Hasta: " +
          this.formatDate(this.fechaHasta),
        13,
        16
      );

      doc.setFontSize(16);
      doc.text(
        "Listado de Liquidaciones",
        doc.internal.pageSize.getWidth() / 2,
        33,
        { align: "center" }
      );
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text("Totales:", 135, finalY + 13);

      doc.text(
        "Cobrado: $" +
          parseFloat(importeTotalCobrado)
            .toFixed(2)
            .toString()
            .replace(".", ","),
        195,
        finalY + 13,
        { align: "right" }
      );
      doc.text(
        "Retenido: $" +
          parseFloat(importeTotalRetenido)
            .toFixed(2)
            .toString()
            .replace(".", ","),
        195,
        finalY + 21,
        { align: "right" }
      );
      doc.text(
        "A Pagar: $" +
          parseFloat(importeTotalPagado)
            .toFixed(2)
            .toString()
            .replace(".", ","),
        195,
        finalY + 29,
        { align: "right" }
      );

      //doc.text("Total: $"+ parseFloat(importeTotal).toFixed(2).toString().replace('.',','), 165,finalY + 8)
      window.open(doc.output("bloburl"), "_blank");
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
        if (self.profsBuscar.length > 1)
          self.profsBuscar.unshift({
            idProfesional: 0,
            nombreProfesional: "TODOS",
          });

        self.prof = self.profsBuscar[0].idProfesional;
      });

    this.fechaDesde = this.parseDate(
      this.formatDate(this.toISOLocal(new Date()))
    );
    this.fechaHasta = this.fechaDesde;
  },

  components: {
    NuevaLiquidacion,
  },
};
</script>
