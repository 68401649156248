<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Editar Cronograma</v-toolbar-title>
        </v-toolbar>
        <v-alert color="purple" dark dismissible outlined
          >Si va a modificar el horario de inicio, podrá cambiarlo únicamente en
          múltiplos del tiempo de turno establecido para el cronograma, ya que
          sino provocaría que los turnos ya dados con el horario de inicio
          actual, no se visualicen. Ej: Si el cronograma actual comienza a las
          9:00 hs. y el tiempo de turno establecido es de 20 minutos, podrá
          poner como nuevo horario de inicio, 8:40 hs. / 8:20 hs. / 8:00 hs. o
          bien 9:20 hs. / 9:40 hs. / 10:00 hs. etc.</v-alert
        >
        <v-card-text>
          <v-row dense class="mt-0 pt-0 mb-4">
            <v-col cols="12">
              <h3>Tiempo de Turno: {{ tiempoTurno }} minutos</h3>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-menu
                ref="menu"
                v-model="menu2"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaDesde"
                    label="Hora Desde"
                    prepend-icon="mdi-clock"
                    readonly
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu2"
                  v-model="horaDesde"
                  format="24hs"
                  full-width
                  scrollable
                  @click:minute="$refs.menu.save(horaDesde)"
                ></v-time-picker>
              </v-menu>
            </v-col>

            <v-col cols="12" md="4" sm="4">
              <v-menu
                ref="menu2"
                v-model="menu3"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="horaHasta"
                    label="Hora Hasta"
                    prepend-icon="mdi-clock"
                    readonly
                    :rules="requiredRules"
                    v-bind="attrs"
                    v-on="on"
                    dense
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-if="menu3"
                  v-model="horaHasta"
                  full-width
                  scrollable
                  format="24hs"
                  @click:minute="$refs.menu2.save(horaHasta)"
                ></v-time-picker>
              </v-menu>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Vigencia Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Vigencia Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="4" sm="4">
              <v-switch
                dense
                v-model="disponibleWeb"
                label="Disponible en Web"
              ></v-switch>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <v-select
                :items="tipoTurnos"
                item-text="text"
                item-value="value"
                menu-props="auto"
                :label="tipoTurnoLabel"
                hide-details
                prepend-icon="mdi-account"
                v-model="tipoTurno"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid"
            @click.stop="modificarCronograma"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import axios from "../../../axios-auth.js";
import router from "../../../router/index.js";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    menu2: false,
    menu3: false,
    modal2: false,
    modal3: false,
    horaDesde: "",
    horaHasta: "",
    fechaDesde: "",
    fechaHasta: "",
    horaDesdeOriginal: "",
    horaHastaOriginal: "",
    fechaDesdeOriginal: "",
    fechaHastaOriginal: "",
    menu: false,
    disponibleWeb: false,
    time: null,
    idDia: null,
    idTiempoTurno: null,
    tiempoTurno: null,
    tipoTurnos: [
      { value: false, text: "Presencial" },
      { value: true, text: "Videoconsulta" },
    ],
    tipoTurno: false,
  }),
  props: ["visible", "idCronograma", "idProfesional"],
  computed: {
    show: {
      get() {
        if (this.visible) this.buscarDatosCronograma();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
    tipoTurnoLabel() {
      return this.disponibleWeb
        ? "Tipo Turno Web"
        : "Tipo Turno Predeterminado";
    },
  },
  methods: {
    buscarDatosCronograma() {
      const idCronograma = this.idCronograma;
      let self = this;

      axios
        .get("/Turnos/BuscarDatosCronograma?", {
          params: {
            idCronograma: this.idCronograma,
          },
        })
        .then((response) => {
          self.horaDesde = response.data.horaInicioCompleta;
          self.horaHasta = response.data.horaFinCompleta;
          self.disponibleWeb = response.data.disponibleWeb;
          self.fechaDesde = response.data.fechaInicio.substring(0, 10);
          self.fechaHasta = response.data.fechaFin.substring(0, 10);
          self.idDia = response.data.idDia;
          self.idTiempoTurno = response.data.idTiempoTurno;
          self.fechaDesdeOriginal = response.data.fechaInicioString;
          self.fechaHastaOriginal = response.data.fechaFinString;
          self.horaDesdeOriginal = response.data.horaInicioCompleta
            .replace(":", "")
            .trim()
            .replace(" ", "");
          self.horaHastaOriginal = response.data.horaFinCompleta
            .replace(":", "")
            .trim()
            .replace(" ", "");
          self.tiempoTurno = response.data.tiempoTurnoMinutos;
          self.tipoTurno = response.data.tipoTurno;
        })
        .catch((error) => {});
    },
    validarFecha(fecha) {
      if (fecha.toString().length > 10) return false;
      else return true;
    },
    modificarCronograma() {
      this.$swal({
        title: "Guardar Cronograma",
        text: "¿Desea registrar el cronograma?",
        icon: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          let isValidDate = this.validarFecha(this.fechaDesde);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha desde no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          isValidDate = this.validarFecha(this.fechaHasta);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha de hasta no tiene un formato válido.",
              vm: this,
            });
            return;
          }

          let self = this;
          const datosCronograma = {
            idCronograma: this.idCronograma,
            idProfesional: this.idProfesional,
            idDia: this.idDia,
            idTiempoTurno: this.idTiempoTurno,
            idConsultorio: 0,
            disponibleWeb: this.disponibleWeb,
            fechaInicio: this.fechaDesde,
            fechaFin: this.fechaHasta,
            horaInicioCompleta: this.horaDesde
              .replace(":", "")
              .trim()
              .replace(" ", ""),
            horaFinCompleta: this.horaHasta
              .replace(":", "")
              .trim()
              .replace(" ", ""),
            horaInicio: this.horaDesde
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(0, 2)
              .replace(" ", ""),
            horaFin: this.horaHasta
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(0, 2)
              .replace(" ", ""),
            minutoInicio: this.horaDesde
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(2, 4)
              .replace(" ", ""),
            minutoFin: this.horaHasta
              .replace(":", "")
              .trim()
              .replace(" ", "")
              .substring(2, 4)
              .replace(" ", ""),
            horaDesdeOriginal: this.horaDesdeOriginal,
            horaHastaOriginal: this.horaHastaOriginal,
            fechaDesdeOriginal: this.fechaDesdeOriginal,
            fechaHastaOriginal: this.fechaHastaOriginal,
          };
          if (
            datosCronograma.horaInicioCompleta > datosCronograma.horaFinCompleta
          ) {
            self.showAlert({
              icon: "error",
              title: "La hora desde no puede ser mayor que la hora hasta",
              vm: this,
            });
            return;
          }
          if (datosCronograma.fechaInicio > datosCronograma.fechaFin) {
            self.showAlert({
              icon: "error",
              title: "La fecha desde no puede ser mayor que la fecha hasta",
              vm: this,
            });
            return;
          }
          axios
            .get("/Turnos/ChequearExistenciaTurnosCronogramaModificar?", {
              params: {
                idProfesional: datosCronograma.idProfesional,
                idDia: datosCronograma.idDia,
                fechaInicio: datosCronograma.fechaInicio,
                fechaFin: datosCronograma.fechaFin,
                horaInicioCompleta: datosCronograma.horaInicioCompleta,
                horaFinCompleta: datosCronograma.horaFinCompleta,
                fechaDesdeOriginal: datosCronograma.fechaDesdeOriginal,
                fechaHastaOriginal: datosCronograma.fechaHastaOriginal,
                horaDesdeOriginal: datosCronograma.horaDesdeOriginal,
                horaHastaOriginal: datosCronograma.horaHastaOriginal,
              },
            })
            .then((response) => {
              if (response.data != "") {
                self.showAlert({
                  icon: "error",
                  title:
                    "Hay turnos reservados que quedarían fuera del cronograma",
                  vm: this,
                });
              } else {
                axios
                  .get("/Turnos/ChequearUnicidadCronogramaModificar?", {
                    params: {
                      idProfesional: datosCronograma.idProfesional,
                      idDia: datosCronograma.idDia,
                      fechaInicio: datosCronograma.fechaInicio,
                      fechaFin: datosCronograma.fechaFin,
                      horaInicioCompleta: datosCronograma.horaInicioCompleta,
                      horaFinCompleta: datosCronograma.horaFinCompleta,
                      idCronograma: datosCronograma.idCronograma,
                    },
                  })
                  .then((response) => {
                    if (response.data != "") {
                      self.showAlert({
                        icon: "error",
                        title: "Hay un cronograma solapado para el profesional",
                        vm: this,
                      });
                    } else {
                      self.grabarModificacion();
                    }
                  });
              }
            });
        }
      });
    },
    grabarModificacion() {
      let self = this;
      const tipoTurno = this.tipoTurno;
      const datosCronograma = {
        idProfesional: this.idProfesional,
        idDia: this.idDia,
        idTiempoTurno: this.idTiempoTurno,
        idConsultorio: 0,
        idCronograma: this.idCronograma,
        disponibleWeb: this.disponibleWeb,
        fechaInicioString: this.fechaDesde,
        fechaFinString: this.fechaHasta,
        horaInicioCompleta: this.horaDesde
          .replace(":", "")
          .trim()
          .replace(" ", ""),
        horaFinCompleta: this.horaHasta
          .replace(":", "")
          .trim()
          .replace(" ", ""),
        horaInicio: this.horaDesde
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(0, 2)
          .replace(" ", ""),
        horaFin: this.horaHasta
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(0, 2)
          .replace(" ", ""),
        minutoInicio: this.horaDesde
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(2, 4)
          .replace(" ", ""),
        minutoFin: this.horaHasta
          .replace(":", "")
          .trim()
          .replace(" ", "")
          .substring(2, 4)
          .replace(" ", ""),
        horaDesde: this.horaDesde,
        tipoTurno: tipoTurno,
        usuarioModi: this.$store.state.usuario.usuario,
      };
      axios
        .post("/Turnos/ModificarCronograma", datosCronograma)
        .then((response) => {
          if (response.data == "Nuevo Inicio Incorrecto") {
            self.showAlert({
              icon: "error",
              title:
                "La nueva hora desde elegida es incorrecta de acuerdo al tiempo de turno establecido para el cronograma.",
              vm: self,
            });
            return;
          }
          self.$refs.form.resetValidation();

          self.showAlert({
            icon: "success",
            title: "Cronograma Modificado",
            vm: self,
          });
          this.$emit("cronogramaModificado");
        })
        .catch((error) => {
          self.showAlert({
            icon: "error",
            title: "El cronograma no pudo ser modificado",
            vm: this,
          });
        });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
  },
};
</script>
