<template>
  <div>
    <v-card class="mb-2" tile>
      <v-card-title>
        <span class="title font-weight-light">Signos Vitales</span>
      </v-card-title>
      <v-data-table
        :headers="headers"
        :items="this.listaSignosVitales"
        class="elevation-1 row-pointer"
        locale="es-ar"
        @click:row="verSignoVital"
        item-key="idHC"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        no-data-text="Paciente sin signos vitales cargados"
        no-results-text="Paciente sin signos vitales cargados"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="verSignoVital(item)"
                >mdi-magnify</v-icon
              >
            </template>
            <span>Ver Signo Vital</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarSignoVital(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Signo Vital</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="red"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="borrarSignoVital(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Signo Vital</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoSignoVital"
          >Nuevo Registro</v-btn
        >
        <v-btn color="warning" @click.stop="showVerTodosSignosVitales = true"
          >Ver Todos</v-btn
        >
      </v-card-actions>
      <NuevoSignoVital
        :visible="showNuevoSignoVital"
        :idPaciente="idPaciente"
        :enfermerosBuscar="enfermeros"
        :origen="origen"
        @close="showNuevoSignoVital = false"
        @signoVitalRegistrado="signoVitalRegistrado"
      ></NuevoSignoVital>
      <EditarSignoVital
        :visible="showEditarSignoVital"
        :idPaciente="idPaciente"
        :idSignoVital="editedItem.idSignoVital"
        :origen="origen"
        :enfermerosBuscar="enfermeros"
        @close="showEditarSignoVital = false"
        @signoVitalModificado="signoVitalModificado"
      ></EditarSignoVital>
      <VerSignoVital
        :visible="showVerSignoVital"
        :idSignoVital="editedItem.idSignoVital"
        :paciente="paciente"
        :signoVital="editedItem"
        :origen="origen"
        :enfermerosBuscar="enfermeros"
        @close="showVerSignoVital = false"
      ></VerSignoVital>
      <VerTodosSignosVitales
        :visible="showVerTodosSignosVitales"
        :signosVitales="listaSignosVitales"
        :paciente="paciente"
        @close="showVerTodosSignosVitales = false"
      ></VerTodosSignosVitales>
    </v-card>
  </div>
</template>
<style scoped>
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import NuevoSignoVital from "../HistClin/NuevoSignoVital.vue";
import VerSignoVital from "../HistClin/VerSignoVital.vue";
import VerTodosSignosVitales from "../HistClin/VerTodosSignosVitales.vue";
import EditarSignoVital from "../HistClin/EditarSignoVital.vue";

export default {
  data: (vm) => ({
    headers: [
      { text: "Fecha", value: "fechaHoraString" },
      { text: "Profesional / Enfermero", value: "nomProfesional" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoSignoVital: false,
    showVerSignoVital: false,
    showVerTodosSignosVitales: false,
    showEditarSignoVital: false,
    editedItem: {},
    editedIndex: -1,
  }),
  props: {
    idPaciente: {
      type: Number,
    },
    lista: {
      type: Array,
    },
    paciente: {
      type: Object,
    },
    profsBuscar: {
      type: Array,
    },
    enfermeros: {
      type: Array,
    },
    institucion: {
      type: Object,
    },
    origen: {
      type: String,
      default: "HC",
    },
  },
  computed: {
    listaSignosVitales: {
      set(value) {
        if (value != []) this.$emit("actualizarSignosVitales");
      },
      get() {
        return this.lista;
      },
    },
  },
  methods: {
    borrarSignoVital(item) {
      if (
        this.$store.state.usuario.institucion.bloqueaHC &&
        this.outOfLimitDate(item.fechaHoraAlta)
      ) {
        this.showAlert({
          icon: "error",
          title:
            "El signo vital supera el tiempo disponible para edición y elminación",
          vm: this,
          timer: 3500,
        });
      } else {
        if (this.origen === "HC" && !item.idProfesional) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un enfermero. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else if (
          this.origen === "HC" &&
          !this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)
        ) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un profesional que usted no tiene asociado. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else if (!this.origen === "HC" && !item.idEnfermero) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un profesional. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else if (
          !this.origen === "HC" &&
          !this.enfermeros.some((enf) => enf.idEnfermero === item.idEnfermero)
        ) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un enfermero que usted no tiene asociado. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else {
          this.$swal({
            title: "Borrar Signo Vital",
            text: "¿Desea borrar el registro del signo vital?",
            icon: "success",
            background: "#ececec",
            showClass: {
              popup: "animate__animated animate__fadeInDown",
            },
            hideClass: {
              popup: "animate__animated animate__fadeOutUp",
            },
            showCancelButton: true,
            confirmButtonText: "Si",
            cancelButtonText: "No",
            showCloseButton: true,
            showLoaderOnConfirm: true,
          }).then((result) => {
            if (result.value) {
              //this.$swal('Turno g', 'You successfully deleted this file', 'success')

              const idSignoVital = item.idSignoVital;
              let self = this;
              axios
                .get("/HistoriaClinica/BorrarSignoVital?", {
                  params: {
                    idSignoVital: idSignoVital,
                  },
                })
                .then((response) => {
                  self.showAlert({
                    icon: "success",
                    title: "Signo Vital borrado exitosamente",
                    vm: self,
                    timer: 1700,
                  });
                  setTimeout(self.buscarSignosVitales(), 1700);
                })
                .catch((error) => {
                  self.showAlert({
                    icon: "error",
                    title: "El registro de signo vital no pudo ser borrado",
                    vm: this,
                    timer: 1700,
                  });
                });
            } else {
              //this.$swal('Cancelled', 'Your file is still intact', 'info')
            }
          });
        }
      }
    },
    editarSignoVital(item) {
      if (
        this.$store.state.usuario.institucion.bloqueaHC &&
        this.outOfLimitDate(item.fechaHoraAlta)
      ) {
        this.showAlert({
          icon: "error",
          title:
            "El registro del signo vital supera el tiempo disponible para edición y elminación",
          vm: this,
          timer: 3500,
        });
      } else {
        if (this.origen === "HC" && !item.idProfesional) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un enfermero. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else if (
          this.origen === "HC" &&
          !this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)
        ) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un profesional que usted no tiene asociado. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else if (!this.origen === "HC" && !item.idEnfermero) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un profesional. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else if (
          !this.origen === "HC" &&
          !this.enfermeros.some((enf) => enf.idEnfermero === item.idEnfermero)
        ) {
          this.showAlert({
            icon: "error",
            title:
              "El signo vital fue registrado por un enfermero que usted no tiene asociado. No puede editar el registro.",
            vm: this,
            timer: 3000,
          });
        } else {
          this.editedIndex = this.listaSignosVitales.indexOf(item);
          this.editedItem = Object.assign({}, item);
          this.showEditarSignoVital = true;
        }
      }
    },
    outOfLimitDate(hcCreationDate) {
      var horaHC = new Date(hcCreationDate);
      var horaActual = new Date();
      var timeDiff = parseFloat(
        (horaActual.getTime() - horaHC.getTime()) / 3600000
      ).toFixed(0);
      var timeLimit = this.$store.state.horasBloqueoHC;
      if (timeDiff >= timeLimit) return true;
      else return false;
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
    nuevoSignoVital() {
      this.showNuevoSignoVital = true;
    },
    verSignoVital(item) {
      this.editedIndex = this.listaSignosVitales.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerSignoVital = true;
    },
    signoVitalRegistrado() {
      this.buscarSignosVitales();
      this.showNuevoSignoVital = false;
    },
    signoVitalModificado() {
      this.buscarSignosVitales();
      this.showEditarSignoVital = false;
    },
    buscarSignosVitales() {
      this.listaSignosVitales = 1;
    },
  },
  components: {
    NuevoSignoVital,
    VerSignoVital,
    VerTodosSignosVitales,
    EditarSignoVital,
  },
};
</script>
