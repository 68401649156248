<template>
<div class="my-0 py-0">
  <h5 class="ml-2 mb-2">Paciente: {{this.$store.state.nombrePaciente}}</h5>
  <div id="meet"></div>
     <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="error" @click.stop="salir">Salir</v-btn>
    </v-card-actions>
</div>
</template>

<script>
import router from "../../router/index.js";
export default {
  mounted() {
      var domain = "meet.jit.si";
      var options = {
      roomName: this.$store.state.linkVideoLlamada,
      width: '98%',
      height: '450px',
     
       userInfo: {
        displayName: this.$store.state.nombreProfesional.toString(),
     
    },
      parentNode: document.querySelector("#meet"),
    };
    var api = new JitsiMeetExternalAPI(domain, options);
  },
  methods: {
      salir() {

      if(this.$store.state.rutaSalidaHC == 0)
      router.push({ path: "/Home" });
      if(this.$store.state.rutaSalidaHC == 1)
      router.push({ path: "/GestionTurnos" });
      if(this.$store.state.rutaSalidaHC == 2)
      router.push({ path: "/GestionTurnosSemana" });
      if(this.$store.state.rutaSalidaHC == 3)
      router.push({ path: "/GestionTurnosSemanaWide" });
    },

  },
  destroyed(){
    
    this.$store.dispatch('setRutaSalidaHC', 0)
    this.$store.dispatch('setVideollamada',false)
    this.$store.dispatch('setLinkVideollamada','')
    this.$store.dispatch('setNombreProfesional','')
    this.$store.dispatch('setNombrePaciente','')
  }
};
</script>