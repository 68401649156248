<template>
  <div class="ma-0 pa-0">
    <div style="height: 675px" v-if="this.$store.state.videollamada == true">
      <div class="left">
        <v-card color="blue" dark tile>
          <v-toolbar dark color="primary">
            <v-toolbar-title>Historias Clínicas</v-toolbar-title>
          </v-toolbar>

          <v-card-title class="mx-0 px-0">
            <v-icon large left>mdi-account</v-icon>
            <span class="title font-weight-light">Datos Paciente</span>
          </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="9" sm="4" md="2">
                <v-text-field
                  v-model="paciente.dni"
                  label="D.N.I"
                  dense
                  @keypress="onlyNumber"
                  @keypress.enter="$event.target.blur()"
                  @blur="buscarPacienteDni(1)"
                  maxlength="9"
                  :disabled="this.$store.state.dniPaciente != 0"
                ></v-text-field>
              </v-col>
              <v-col cols="2" sm="2" md="2">
                <v-btn
                  color="purple"
                  outlined
                  @click="buscarPaciente"
                  :disabled="this.$store.state.dniPaciente != 0"
                >
                  <v-icon>mdi-magnify</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="4" sm="4">
                <h4>
                  Paciente: {{ paciente.apellidos }} {{ paciente.nombres }}
                </h4>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <h4>Sexo: {{ paciente.sexo }}</h4>
              </v-col>
            </v-row>

            <v-row dense>
              <v-col cols="12" md="4" sm="4">
                <h4>Fecha Nac.: {{ paciente.fechaNac }}</h4>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <h4>Edad: {{ paciente.edad }}</h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col cols="12" md="4" sm="4">
                <h4>Teléfono: {{ paciente.celular || paciente.telefono }}</h4>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <h4>
                  Dirección: {{ paciente.direccion + " " + paciente.localidad }}
                </h4>
              </v-col>
            </v-row>
            <v-row dense>
              <v-col
                cols="12"
                md="4"
                sm="4"
                v-if="!$store.state.usuario.institucion.esEmpresa"
              >
                <h4>
                  Obra Social: {{ datoAfiliatorio.obraSocial }} -
                  {{ datoAfiliatorio.nroAfiliado }}
                </h4>
              </v-col>
              <v-col cols="12" md="4" sm="4">
                <h4>Observaciones: {{ paciente.observaciones }}</h4>
              </v-col>
            </v-row>
          </v-card-text>
          <BuscarPacienteTurno
            :visible="showBuscarPaciente"
            @close="showBuscarPaciente = false"
            @seleccionarPaciente="seleccionarPaciente($event)"
          ></BuscarPacienteTurno>
        </v-card>
        <v-alert
          color="orange"
          dark
          v-if="
            paciente.idPaciente == 0 ||
            paciente.idPaciente == '' ||
            paciente.idPaciente == null
          "
          >Seleccione un paciente ingresando su dni y apretando enter (desde el
          celular toque fuera del cuadro de texto) o buscándolo por apellido y
          nombre haciendo click en la lupa</v-alert
        >
        <v-tabs
          background-color="indigo"
          dark
          v-if="
            paciente.idPaciente != 0 &&
            paciente.idPaciente != '' &&
            paciente.idPaciente != null
          "
        >
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 1)">Antecedentes</v-tab>
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 2)">Evoluciones</v-tab>
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 3)">Diagnósticos</v-tab>
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 4)"
            >Signos Vitales</v-tab
          >
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 5)">Recetas</v-tab>
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 6)">Indicaciones</v-tab>
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 7)"
            >Pedidos de Estudios</v-tab
          >
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 8)">Archivos</v-tab>
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 9)">Odontograma</v-tab>
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 10)"
            >Vacunaciones</v-tab
          >
          <v-tab v-if="!tabs.some((o) => o.idHcTabs === 11)"
            >Notas Enfermería</v-tab
          >
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 1)">
            <Antecedentes
              :ant="paciente.antecedentes"
              :antFamiliares="paciente.antecedentesFamiliares"
              :idPaciente="paciente.idPaciente"
              :paciente="this.paciente"
              @actualizarAntecedentes="buscarAntecedentes()"
            ></Antecedentes>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 2)">
            <Evoluciones
              :idPaciente="paciente.idPaciente"
              :lista="listaEvoluciones"
              :paciente="this.paciente"
              :profsBuscar="profsBuscar"
              :institucion="institucion"
              @actualizarEvoluciones="buscarEvoluciones()"
            ></Evoluciones>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 3)">
            <Diagnosticos
              :idPaciente="paciente.idPaciente"
              :lista="listaDiagnosticos"
              :paciente="this.paciente"
              :profsBuscar="profsBuscar"
              :institucion="institucion"
              @actualizarDiagnosticos="buscarDiagnosticos()"
            ></Diagnosticos>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 4)">
            <SignosVitales
              :idPaciente="paciente.idPaciente"
              :lista="listaSignosVitales"
              :paciente="this.paciente"
              :profsBuscar="profsBuscar"
              :institucion="institucion"
              @actualizarSignosVitales="buscarSignosVitales()"
            ></SignosVitales>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 5)">
            <Recetas
              :idPaciente="paciente.idPaciente"
              :lista="listaRecetas"
              :paciente="this.paciente"
              :profsBuscar="profsBuscar"
              :institucion="institucion"
              @actualizarRecetas="buscarRecetas()"
            ></Recetas>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 6)">
            <Indicaciones
              :idPaciente="paciente.idPaciente"
              :lista="listaIndicaciones"
              :paciente="this.paciente"
              :profsBuscar="profsBuscar"
              :institucion="institucion"
              @actualizarIndicaciones="buscarIndicaciones()"
            ></Indicaciones>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 7)">
            <Pedidos
              :idPaciente="paciente.idPaciente"
              :lista="listaPedidos"
              :paciente="this.paciente"
              :profsBuscar="profsBuscar"
              :institucion="institucion"
              @actualizarPedidos="buscarPedidos()"
            ></Pedidos>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 8)">
            <Informes
              :idPaciente="paciente.idPaciente"
              :lista="listaInformes"
              :paciente="this.paciente"
              :profsBuscar="profsBuscar"
              @actualizarInformes="buscarInformes()"
            ></Informes>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 9)">
            <Odontograma
              :idPaciente="paciente.idPaciente"
              :lista="listaOdontograma"
              :paciente="this.paciente"
              :restauracion="this.restauracion"
              :corona="this.corona"
              :ausencia="this.ausencia"
              :extraccion="this.extraccion"
              :ortodoncia="this.ortodoncia"
              :fija="this.fija"
              :movil="this.movil"
              :implante="this.implante"
              :tratamiento="this.tratamiento"
              @actualizarOdontograma="buscarOdontograma()"
            ></Odontograma>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 10)">
            <GestionVacunaciones
              :idPaciente="paciente.idPaciente"
              :lista="listaVacunaciones"
              :paciente="this.paciente"
              :origen="'hc'"
              @actualizarVacunaciones="buscarVacunaciones()"
            >
            </GestionVacunaciones>
          </v-tab-item>
          <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 11)">
            <NotasEnfermeria
              :idPaciente="paciente.idPaciente"
              :lista="listaNotasEnfermeria"
              :paciente="this.paciente"
              :origen="'hc'"
              @actualizarNotasEnfermeria="buscarNotasEnfermeria()"
            >
            </NotasEnfermeria>
          </v-tab-item>
        </v-tabs>
        <v-card-actions>
          <v-btn
            v-if="
              paciente.idPaciente != 0 &&
              paciente.idPaciente != '' &&
              paciente.idPaciente != null
            "
            color="primary"
            @click.stop="showEditarPaciente = true"
            >Datos Paciente</v-btn
          >
          <v-btn
            v-if="
              paciente.idPaciente != 0 &&
              paciente.idPaciente != '' &&
              paciente.idPaciente != null
            "
            color="success"
            @click.stop="showCaracteristicas = true"
            >Características Paciente</v-btn
          >
          <v-btn
            v-if="
              paciente.idPaciente != 0 &&
              paciente.idPaciente != '' &&
              paciente.idPaciente != null
            "
            color="warning"
            @click.stop="showAlergias = true"
            >Alergias</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn color="error" @click.stop="salir">Salir</v-btn>
        </v-card-actions>
      </div>
      <div class="right" id="meet"></div>
    </div>

    <div v-if="this.$store.state.videollamada == false">
      <v-card color="blue" dark tile>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Historias Clínicas</v-toolbar-title>
        </v-toolbar>

        <v-card-title class="mx-0 px-0">
          <v-icon large left>mdi-account</v-icon>
          <span class="title font-weight-light">Datos Paciente</span>
          <v-spacer></v-spacer>
          <v-btn
            small
            class="mr-2"
            color="success"
            @click.stop="nuevoPaciente"
            v-if="this.$store.state.dniPaciente == 0"
            >Nuevo Paciente
          </v-btn>
          <v-btn
            v-if="paciente.foto && checkInnerWidht"
            small
            class="mr-2"
            color="warning"
            @click.stop="verFoto()"
            >Foto
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="4" md="2">
              <v-text-field
                v-model="paciente.dni"
                label="D.N.I"
                dense
                @keypress="onlyNumber"
                @keypress.enter="$event.target.blur()"
                @blur="buscarPacienteDni(1)"
                maxlength="9"
                :disabled="this.$store.state.dniPaciente != 0"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn
                color="purple"
                outlined
                @click="buscarPaciente"
                :disabled="this.$store.state.dniPaciente != 0"
              >
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <h4>Paciente: {{ paciente.apellidos }} {{ paciente.nombres }}</h4>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <h4>Sexo: {{ paciente.sexo }}</h4>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <h4>Fecha Nac.: {{ paciente.fechaNac }}</h4>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <h4>Edad: {{ paciente.edad }}</h4>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <h4>Teléfono: {{ paciente.celular || paciente.telefono }}</h4>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <h4>
                Dirección: {{ paciente.direccion + " " + paciente.localidad }}
              </h4>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col
              cols="12"
              md="4"
              sm="4"
              v-if="!$store.state.usuario.institucion.esEmpresa"
            >
              <h4>
                Obra Social: {{ datoAfiliatorio.obraSocial }} -
                {{ datoAfiliatorio.nroAfiliado }}
              </h4>
            </v-col>
            <v-col cols="12" md="12" sm="12">
              <h4>Observaciones: {{ paciente.observaciones }}</h4>
            </v-col>
          </v-row>
        </v-card-text>
        <BuscarPacienteTurno
          :visible="showBuscarPaciente"
          @close="showBuscarPaciente = false"
          @seleccionarPaciente="seleccionarPaciente($event)"
        ></BuscarPacienteTurno>
        <NuevoPaciente
          :visible="showNuevoPaciente"
          @cierraNuevoPacienteSinGuardar="cierraNuevoPacienteSinGuardar"
          @close="cierraNuevoPaciente($event)"
        ></NuevoPaciente>
        <VerFoto
          :visible="showVerFoto"
          :foto="paciente.foto"
          @close="showVerFoto = false"
        >
        </VerFoto>
      </v-card>
      <v-alert
        color="orange"
        dark
        v-if="
          paciente.idPaciente == 0 ||
          paciente.idPaciente == '' ||
          paciente.idPaciente == null
        "
        >Seleccione un paciente ingresando su dni y apretando enter (desde el
        celular toque fuera del cuadro de texto) o buscándolo por apellido y
        nombre haciendo click en la lupa</v-alert
      >
      <v-tabs
        background-color="indigo"
        dark
        v-if="
          paciente.idPaciente != 0 &&
          paciente.idPaciente != '' &&
          paciente.idPaciente != null
        "
      >
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 1)">Antecedentes</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 2)">Evoluciones</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 3)">Diagnósticos</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 4)">Signos Vitales</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 5)">Recetas</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 6)">Indicaciones</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 7)"
          >Pedidos de Estudios</v-tab
        >
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 8)">Archivos</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 9)">Odontograma</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 10)">Vacunaciones</v-tab>
        <v-tab v-if="!tabs.some((o) => o.idHcTabs === 11)"
          >Notas Enfermería</v-tab
        >
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 1)">
          <Antecedentes
            :ant="paciente.antecedentes"
            :antFamiliares="paciente.antecedentesFamiliares"
            :idPaciente="paciente.idPaciente"
            :paciente="this.paciente"
            @actualizarAntecedentes="buscarAntecedentes()"
          ></Antecedentes>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 2)">
          <Evoluciones
            :idPaciente="paciente.idPaciente"
            :lista="listaEvoluciones"
            :paciente="this.paciente"
            :profsBuscar="profsBuscar"
            :institucion="institucion"
            @actualizarEvoluciones="buscarEvoluciones()"
          ></Evoluciones>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 3)">
          <Diagnosticos
            :idPaciente="paciente.idPaciente"
            :lista="listaDiagnosticos"
            :paciente="this.paciente"
            :profsBuscar="profsBuscar"
            :institucion="institucion"
            @actualizarDiagnosticos="buscarDiagnosticos()"
          ></Diagnosticos>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 4)">
          <SignosVitales
            :idPaciente="paciente.idPaciente"
            :lista="listaSignosVitales"
            :paciente="this.paciente"
            :profsBuscar="profsBuscar"
            :institucion="institucion"
            @actualizarSignosVitales="buscarSignosVitales()"
          ></SignosVitales>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 5)">
          <Recetas
            :idPaciente="paciente.idPaciente"
            :lista="listaRecetas"
            :paciente="this.paciente"
            :profsBuscar="profsBuscar"
            :institucion="institucion"
            @actualizarRecetas="buscarRecetas()"
          ></Recetas>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 6)">
          <Indicaciones
            :idPaciente="paciente.idPaciente"
            :lista="listaIndicaciones"
            :paciente="this.paciente"
            :profsBuscar="profsBuscar"
            :institucion="institucion"
            @actualizarIndicaciones="buscarIndicaciones()"
          ></Indicaciones>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 7)">
          <Pedidos
            :idPaciente="paciente.idPaciente"
            :lista="listaPedidos"
            :paciente="this.paciente"
            :profsBuscar="profsBuscar"
            :institucion="institucion"
            @actualizarPedidos="buscarPedidos()"
          ></Pedidos>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 8)">
          <Informes
            :idPaciente="paciente.idPaciente"
            :lista="listaInformes"
            :paciente="this.paciente"
            :profsBuscar="profsBuscar"
            @actualizarInformes="buscarInformes()"
          ></Informes>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 9)">
          <Odontograma
            :idPaciente="paciente.idPaciente"
            :lista="listaOdontograma"
            :paciente="this.paciente"
            :restauracion="this.restauracion"
            :corona="this.corona"
            :ausencia="this.ausencia"
            :extraccion="this.extraccion"
            :ortodoncia="this.ortodoncia"
            :fija="this.fija"
            :movil="this.movil"
            :implante="this.implante"
            :tratamiento="this.tratamiento"
            @actualizarOdontograma="buscarOdontograma()"
          ></Odontograma>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 10)">
          <GestionVacunaciones
            :idPaciente="paciente.idPaciente"
            :lista="listaVacunaciones"
            :paciente="this.paciente"
            :origen="'hc'"
            @actualizarVacunaciones="buscarVacunaciones()"
          >
          </GestionVacunaciones>
        </v-tab-item>
        <v-tab-item v-if="!tabs.some((o) => o.idHcTabs === 11)">
          <NotasEnfermeria
            :idPaciente="paciente.idPaciente"
            :lista="listaNotasEnfermeria"
            :paciente="this.paciente"
            :origen="'hc'"
            @actualizarNotasEnfermeria="buscarNotasEnfermeria()"
          >
          </NotasEnfermeria>
        </v-tab-item>
      </v-tabs>
      <v-card-actions class="ml-2 actions">
        <v-btn
          v-if="
            paciente.idPaciente != 0 &&
            paciente.idPaciente != '' &&
            paciente.idPaciente != null
          "
          color="primary"
          @click.stop="showEditarPaciente = true"
          >Datos Paciente</v-btn
        >
        <v-btn
          v-if="
            paciente.idPaciente != 0 &&
            paciente.idPaciente != '' &&
            paciente.idPaciente != null
          "
          color="success"
          @click.stop="showCaracteristicas = true"
          >Características Paciente</v-btn
        >
        <v-btn
          v-if="
            paciente.idPaciente != 0 &&
            paciente.idPaciente != '' &&
            paciente.idPaciente != null
          "
          color="warning"
          @click.stop="showAlergias = true"
          >Alergias</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
    </div>
    <CaracteristicasPaciente
      v-if="showCaracteristicas"
      :visible="showCaracteristicas"
      :idPaciente="paciente.idPaciente"
      @close="showCaracteristicas = false"
    />
    <EditarPaciente
      v-if="showEditarPaciente"
      :visible="showEditarPaciente"
      :idPaciente="paciente.idPaciente"
      :dniActual="paciente.dni"
      @close="showEditarPaciente = false"
      @pacienteModificado="showEditarPaciente = false"
    ></EditarPaciente>
    <Alergias
      v-if="showAlergias"
      :visible="showAlergias"
      :nombrePaciente="paciente.apellidos + ', ' + paciente.nombres"
      :idPaciente="paciente.idPaciente"
      @close="showAlergias = false"
    />
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.left {
  float: left;
  width: 70%;
  position: absolute;
  height: 100%;
  overflow: auto;
}
.right {
  float: right;
  width: 30%;
  height: 100%;
  position: relative;
  overflow: auto;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import BuscarPacienteTurno from "../Turnos/BuscarPacienteTurno.vue";
import Antecedentes from "../HistClin/Antecedentes.vue";
import Evoluciones from "../HistClin/Evoluciones.vue";
import Recetas from "../HistClin/Recetas.vue";
import Indicaciones from "../HistClin/Indicaciones.vue";
import Pedidos from "../HistClin/Pedidos.vue";
import Informes from "../HistClin/Informes.vue";
import Odontograma from "../HistClin/Odontograma.vue";
import NuevoPaciente from "../HistClin/NuevoPaciente.vue";
import SignosVitales from "../HistClin/SignosVitales.vue";
import Diagnosticos from "../HistClin/Diagnosticos.vue";
import VerFoto from "../HistClin/VerFoto.vue";
import GestionVacunaciones from "../Enfermeria/GestionVacunaciones.vue";
import NotasEnfermeria from "../Enfermeria/NotasEnfermeria.vue";
import CaracteristicasPaciente from "./CaracteristicasPaciente.vue";
import EditarPaciente from "./EditarPaciente.vue";
import Alergias from "./Alergias.vue";

export default {
  data: (vm) => ({
    tabs: [],
    paciente: {
      dni: null,
      apellidos: "",
      nombres: "",
      fechaNac: "",
      sexo: "",
      edad: "",
      edadAux: "",
      idPaciente: null,
      antecedentes: "",
      antecedentesFamiliares: "",
      mail: "",
      observaciones: "",
      celular: "",
      telefono: "",
      direccion: "",
      localidad: "",
      foto: null,
    },
    datoAfiliatorio: {
      obraSocial: "",
      nroAfiliado: "",
    },
    showCaracteristicas: false,
    showEditarPaciente: false,
    showAlergias: false,
    showNuevoPaciente: false,
    showVerFoto: false,
    listaEvoluciones: [],
    listaSignosVitales: [],
    listaRecetas: [],
    listaIndicaciones: [],
    listaPedidos: [],
    listaInformes: [],
    listaOdontograma: [],
    listaDiagnosticos: [],
    listaVacunaciones: [],
    listaNotasEnfermeria: [],
    profsBuscar: [],
    showBuscarPaciente: false,
    institucion: {},
    restauracion: {
      r11: "white",
      r12: "white",
      r13: "white",
      r14: "white",
      r15: "white",
      r21: "white",
      r22: "white",
      r23: "white",
      r24: "white",
      r25: "white",
      r31: "white",
      r32: "white",
      r33: "white",
      r34: "white",
      r35: "white",
      r41: "white",
      r42: "white",
      r43: "white",
      r44: "white",
      r45: "white",
      r51: "white",
      r52: "white",
      r53: "white",
      r54: "white",
      r55: "white",
      r61: "white",
      r62: "white",
      r63: "white",
      r64: "white",
      r65: "white",
      r71: "white",
      r72: "white",
      r73: "white",
      r74: "white",
      r75: "white",
      r81: "white",
      r82: "white",
      r83: "white",
      r84: "white",
      r85: "white",
      r91: "white",
      r92: "white",
      r93: "white",
      r94: "white",
      r95: "white",
      r101: "white",
      r102: "white",
      r103: "white",
      r104: "white",
      r105: "white",
      r111: "white",
      r112: "white",
      r113: "white",
      r114: "white",
      r115: "white",
      r121: "white",
      r122: "white",
      r123: "white",
      r124: "white",
      r125: "white",
      r131: "white",
      r132: "white",
      r133: "white",
      r134: "white",
      r135: "white",
      r141: "white",
      r142: "white",
      r143: "white",
      r144: "white",
      r145: "white",
      r151: "white",
      r152: "white",
      r153: "white",
      r154: "white",
      r155: "white",
      r161: "white",
      r162: "white",
      r163: "white",
      r164: "white",
      r165: "white",
      r171: "white",
      r172: "white",
      r173: "white",
      r174: "white",
      r175: "white",
      r181: "white",
      r182: "white",
      r183: "white",
      r184: "white",
      r185: "white",
      r191: "white",
      r192: "white",
      r193: "white",
      r194: "white",
      r195: "white",
      r201: "white",
      r202: "white",
      r203: "white",
      r204: "white",
      r205: "white",
      r211: "white",
      r212: "white",
      r213: "white",
      r214: "white",
      r215: "white",
      r221: "white",
      r222: "white",
      r223: "white",
      r224: "white",
      r225: "white",
      r231: "white",
      r232: "white",
      r233: "white",
      r234: "white",
      r235: "white",
      r241: "white",
      r242: "white",
      r243: "white",
      r244: "white",
      r245: "white",
      r251: "white",
      r252: "white",
      r253: "white",
      r254: "white",
      r255: "white",
      r261: "white",
      r262: "white",
      r263: "white",
      r264: "white",
      r265: "white",
      r271: "white",
      r272: "white",
      r273: "white",
      r274: "white",
      r275: "white",
      r281: "white",
      r282: "white",
      r283: "white",
      r284: "white",
      r285: "white",
      r291: "white",
      r292: "white",
      r293: "white",
      r294: "white",
      r295: "white",
      r301: "white",
      r302: "white",
      r303: "white",
      r304: "white",
      r305: "white",
      r311: "white",
      r312: "white",
      r313: "white",
      r314: "white",
      r315: "white",
      r321: "white",
      r322: "white",
      r323: "white",
      r324: "white",
      r325: "white",
      r331: "white",
      r332: "white",
      r333: "white",
      r334: "white",
      r335: "white",
      r341: "white",
      r342: "white",
      r343: "white",
      r344: "white",
      r345: "white",
      r351: "white",
      r352: "white",
      r353: "white",
      r354: "white",
      r355: "white",
      r361: "white",
      r362: "white",
      r363: "white",
      r364: "white",
      r365: "white",
      r371: "white",
      r372: "white",
      r373: "white",
      r374: "white",
      r375: "white",
      r381: "white",
      r382: "white",
      r383: "white",
      r384: "white",
      r385: "white",
      r391: "white",
      r392: "white",
      r393: "white",
      r394: "white",
      r395: "white",
      r401: "white",
      r402: "white",
      r403: "white",
      r404: "white",
      r405: "white",
      r411: "white",
      r412: "white",
      r413: "white",
      r414: "white",
      r415: "white",
      r421: "white",
      r422: "white",
      r423: "white",
      r424: "white",
      r425: "white",
      r431: "white",
      r432: "white",
      r433: "white",
      r434: "white",
      r435: "white",
      r441: "white",
      r442: "white",
      r443: "white",
      r444: "white",
      r445: "white",
      r451: "white",
      r452: "white",
      r453: "white",
      r454: "white",
      r455: "white",
      r461: "white",
      r462: "white",
      r463: "white",
      r464: "white",
      r465: "white",
      r471: "white",
      r472: "white",
      r473: "white",
      r474: "white",
      r475: "white",
      r481: "white",
      r482: "white",
      r483: "white",
      r484: "white",
      r485: "white",
      r491: "white",
      r492: "white",
      r493: "white",
      r494: "white",
      r495: "white",
      r501: "white",
      r502: "white",
      r503: "white",
      r504: "white",
      r505: "white",
      r511: "white",
      r512: "white",
      r513: "white",
      r514: "white",
      r515: "white",
      r521: "white",
      r522: "white",
      r523: "white",
      r524: "white",
      r525: "white",
    },
    corona: {
      c1: "none",
      c2: "none",
      c3: "none",
      c4: "none",
      c5: "none",
      c6: "none",
      c7: "none",
      c8: "none",
      c9: "none",
      c10: "none",
      c11: "none",
      c12: "none",
      c13: "none",
      c14: "none",
      c15: "none",
      c16: "none",
      c17: "none",
      c18: "none",
      c19: "none",
      c20: "none",
      c21: "none",
      c22: "none",
      c23: "none",
      c24: "none",
      c25: "none",
      c26: "none",
      c27: "none",
      c28: "none",
      c29: "none",
      c30: "none",
      c31: "none",
      c32: "none",
      c33: "none",
      c34: "none",
      c35: "none",
      c36: "none",
      c37: "none",
      c38: "none",
      c39: "none",
      c40: "none",
      c41: "none",
      c42: "none",
      c43: "none",
      c44: "none",
      c45: "none",
      c46: "none",
      c47: "none",
      c48: "none",
      c49: "none",
      c50: "none",
      c51: "none",
      c52: "none",
    },
    implante: {
      i1: "none",
      i2: "none",
      i3: "none",
      i4: "none",
      i5: "none",
      i6: "none",
      i7: "none",
      i8: "none",
      i9: "none",
      i10: "none",
      i11: "none",
      i12: "none",
      i13: "none",
      i14: "none",
      i15: "none",
      i16: "none",
      i17: "none",
      i18: "none",
      i19: "none",
      i20: "none",
      i21: "none",
      i22: "none",
      i23: "none",
      i24: "none",
      i25: "none",
      i26: "none",
      i27: "none",
      i28: "none",
      i29: "none",
      i30: "none",
      i31: "none",
      i32: "none",
      i33: "none",
      i34: "none",
      i35: "none",
      i36: "none",
      i37: "none",
      i38: "none",
      i39: "none",
      i40: "none",
      i41: "none",
      i42: "none",
      i43: "none",
      i44: "none",
      i45: "none",
      i46: "none",
      i47: "none",
      i48: "none",
      i49: "none",
      i50: "none",
      i51: "none",
      i52: "none",
    },
    tratamiento: {
      t1: "none",
      t2: "none",
      t3: "none",
      t4: "none",
      t5: "none",
      t6: "none",
      t7: "none",
      t8: "none",
      t9: "none",
      t10: "none",
      t11: "none",
      t12: "none",
      t13: "none",
      t14: "none",
      t15: "none",
      t16: "none",
      t17: "none",
      t18: "none",
      t19: "none",
      t20: "none",
      t21: "none",
      t22: "none",
      t23: "none",
      t24: "none",
      t25: "none",
      t26: "none",
      t27: "none",
      t28: "none",
      t29: "none",
      t30: "none",
      t31: "none",
      t32: "none",
      t33: "none",
      t34: "none",
      t35: "none",
      t36: "none",
      t37: "none",
      t38: "none",
      t39: "none",
      t40: "none",
      t41: "none",
      t42: "none",
      t43: "none",
      t44: "none",
      t45: "none",
      t46: "none",
      t47: "none",
      t48: "none",
      t49: "none",
      t50: "none",
      t51: "none",
      t52: "none",
    },
    movil: {
      m1: "none",
      m2: "none",
      m3: "none",
      m4: "none",
      m5: "none",
      m6: "none",
      m7: "none",
      m8: "none",
      m9: "none",
      m10: "none",
      m11: "none",
      m12: "none",
      m13: "none",
      m14: "none",
      m15: "none",
      m16: "none",
      m17: "none",
      m18: "none",
      m19: "none",
      m20: "none",
      m21: "none",
      m22: "none",
      m23: "none",
      m24: "none",
      m25: "none",
      m26: "none",
      m27: "none",
      m28: "none",
      m29: "none",
      m30: "none",
      m31: "none",
      m32: "none",
      m33: "none",
      m34: "none",
      m35: "none",
      m36: "none",
      m37: "none",
      m38: "none",
      m39: "none",
      m40: "none",
      m41: "none",
      m42: "none",
      m43: "none",
      m44: "none",
      m45: "none",
      m46: "none",
      m47: "none",
      m48: "none",
      m49: "none",
      m50: "none",
      m51: "none",
      m52: "none",
    },
    ausencia: {
      a1: "none",
      a2: "none",
      a3: "none",
      a4: "none",
      a5: "none",
      a6: "none",
      a7: "none",
      a8: "none",
      a9: "none",
      a10: "none",
      a11: "none",
      a12: "none",
      a13: "none",
      a14: "none",
      a15: "none",
      a16: "none",
      a17: "none",
      a18: "none",
      a19: "none",
      a20: "none",
      a21: "none",
      a22: "none",
      a23: "none",
      a24: "none",
      a25: "none",
      a26: "none",
      a27: "none",
      a28: "none",
      a29: "none",
      a30: "none",
      a31: "none",
      a32: "none",
      a33: "none",
      a34: "none",
      a35: "none",
      a36: "none",
      a37: "none",
      a38: "none",
      a39: "none",
      a40: "none",
      a41: "none",
      a42: "none",
      a43: "none",
      a44: "none",
      a45: "none",
      a46: "none",
      a47: "none",
      a48: "none",
      a49: "none",
      a50: "none",
      a51: "none",
      a52: "none",
    },
    extraccion: {
      e1: "none",
      e2: "none",
      e3: "none",
      e4: "none",
      e5: "none",
      e6: "none",
      e7: "none",
      e8: "none",
      e9: "none",
      e10: "none",
      e11: "none",
      e12: "none",
      e13: "none",
      e14: "none",
      e15: "none",
      e16: "none",
      e17: "none",
      e18: "none",
      e19: "none",
      e20: "none",
      e21: "none",
      e22: "none",
      e23: "none",
      e24: "none",
      e25: "none",
      e26: "none",
      e27: "none",
      e28: "none",
      e29: "none",
      e30: "none",
      e31: "none",
      e32: "none",
      e33: "none",
      e34: "none",
      e35: "none",
      e36: "none",
      e37: "none",
      e38: "none",
      e39: "none",
      e40: "none",
      e41: "none",
      e42: "none",
      e43: "none",
      e44: "none",
      e45: "none",
      e46: "none",
      e47: "none",
      e48: "none",
      e49: "none",
      e50: "none",
      e51: "none",
      e52: "none",
    },
    fija: {
      f1: "none",
      f2: "none",
      f3: "none",
      f4: "none",
      f5: "none",
      f6: "none",
      f7: "none",
      f8: "none",
      f9: "none",
      f10: "none",
      f11: "none",
      f12: "none",
      f13: "none",
      f14: "none",
      f15: "none",
      f16: "none",
      f17: "none",
      f18: "none",
      f19: "none",
      f20: "none",
      f21: "none",
      f22: "none",
      f23: "none",
      f24: "none",
      f25: "none",
      f26: "none",
      f27: "none",
      f28: "none",
      f29: "none",
      f30: "none",
      f31: "none",
      f32: "none",
      f33: "none",
      f34: "none",
      f35: "none",
      f36: "none",
      f37: "none",
      f38: "none",
      f39: "none",
      f40: "none",
      f41: "none",
      f42: "none",
      f43: "none",
      f44: "none",
      f45: "none",
      f46: "none",
      f47: "none",
      f48: "none",
      f49: "none",
      f50: "none",
      f51: "none",
      f52: "none",
    },
    ortodoncia: {
      o1: "none",
      o2: "none",
      o3: "none",
      o4: "none",
      o5: "none",
      o6: "none",
      o7: "none",
      o8: "none",
      o9: "none",
      o10: "none",
      o11: "none",
      o12: "none",
      o13: "none",
      o14: "none",
      o15: "none",
      o16: "none",
      o17: "none",
      o18: "none",
      o19: "none",
      o20: "none",
      o21: "none",
      o22: "none",
      o23: "none",
      o24: "none",
      o25: "none",
      o26: "none",
      o27: "none",
      o28: "none",
      o29: "none",
      o30: "none",
      o31: "none",
      o32: "none",
      o33: "none",
      o34: "none",
      o35: "none",
      o36: "none",
      o37: "none",
      o38: "none",
      o39: "none",
      o40: "none",
      o41: "none",
      o42: "none",
      o43: "none",
      o44: "none",
      o45: "none",
      o46: "none",
      o47: "none",
      o48: "none",
      o49: "none",
      o50: "none",
      o51: "none",
      o52: "none",
    },
  }),
  mounted() {
    this.tabs = this.$store.state.usuario.tabsHc;
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
      });

    axios
      .get("/Usuario/BuscarDatosInstitucion", {
        params: {
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.institucion = response.data;
      });

    if (this.$store.state.rutaSalidaHC != 0) this.buscarPacienteDni(0);

    if (this.$store.state.videollamada == true) {
      var domain = "8x8.vc";
      var options = {
        roomName: this.$store.state.linkVideoLlamada,
        width: window.innerWidth * 0.29,
        height: window.innerWidth * 0.29 * 1.2,

        userInfo: {
          displayName: this.$store.state.nombreProfesional.toString(),
        },
        parentNode: document.querySelector("#meet"),
      };
      var api = new JitsiMeetExternalAPI(domain, options);
    }
  },
  methods: {
    checkInnerWidht() {
      if (window.innerWidth > 455) {
        return true;
      } else {
        return false;
      }
    },
    cierraNuevoPacienteSinGuardar() {
      this.showNuevoPaciente = false;
    },
    verFoto() {
      this.showVerFoto = true;
    },
    cierraNuevoPaciente(item) {
      this.seleccionarPaciente(item);
      this.showNuevoPaciente = false;
    },
    nuevoPaciente() {
      this.$store.dispatch("setRutaSalidaNuevoPaciente", 1);
      this.showNuevoPaciente = true;
    },
    salir() {
      if (this.$store.state.rutaSalidaHC == 0) router.push({ path: "/Home" });
      if (this.$store.state.rutaSalidaHC == 1)
        router.push({ path: "/GestionTurnos" });
      if (this.$store.state.rutaSalidaHC == 2)
        router.push({ path: "/GestionTurnosSemana" });
      if (this.$store.state.rutaSalidaHC == 3)
        router.push({ path: "/GestionTurnosSemanaWide" });
    },
    async seleccionarPaciente(item) {
      this.paciente.dni = item.nrodocumento;
      this.paciente.mail = item.email;
      this.paciente.celular = item.celular;
      this.paciente.apellidos = item.apellidos;
      this.paciente.idPaciente = item.idpaciente;
      this.paciente.nombres = item.nombres;
      this.paciente.edadAux = new Date(item.fechanacimiento);
      this.paciente.observaciones = item.observaciones;
      this.paciente.celular = item.celular;
      this.paciente.telefono = item.telefono;
      this.paciente.direccion = item.direccion;
      this.paciente.foto = item.foto;
      if (
        item.fechanacimiento == "0001-01-01T00:00:00" ||
        item.fechanacimiento == null
      )
        this.paciente.fechaNac = "";
      else
        this.paciente.fechaNac =
          item.fechanacimiento.substring(8, 10) +
          "/" +
          item.fechanacimiento.substring(5, 7) +
          "/" +
          item.fechanacimiento.substring(0, 4);
      this.paciente.sexo = item.sexo;
      // this.paciente.antecedentes = item.antecedentes;

      if (this.paciente.fechaNac != "") {
        let hoy = new Date();
        let age = hoy.getFullYear() - this.paciente.edadAux.getFullYear();
        let diferenciaMeses = hoy.getMonth() - this.paciente.edadAux.getMonth();
        if (
          diferenciaMeses < 0 ||
          (diferenciaMeses === 0 &&
            hoy.getDate() < this.paciente.edadAux.getDate())
        ) {
          age--;
        }
        if (age > 0) this.paciente.edad = age + " años";
        else {
          if (diferenciaMeses > 0)
            this.paciente.edad = diferenciaMeses + " meses";
          else {
            let diferenciaDias =
              hoy.getTime() - this.paciente.edadAux.getTime();
            this.paciente.edad =
              Math.trunc(diferenciaDias / (1000 * 60 * 60 * 24)) + " días";
          }
        }
      } else this.paciente.edad = "";

      if (!this.$store.state.usuario.institucion.esEmpresa) {
        this.buscarDatosAfiliatorios(item.idpaciente);
      }
      this.buscarAntecedentes();
      this.buscarEvoluciones();
      this.buscarSignosVitales();
      this.buscarRecetas();
      this.buscarIndicaciones();
      this.buscarPedidos();
      this.buscarInformes();
      this.buscarOdontograma();
      this.buscarDiagnosticos();
      this.buscarVacunaciones();
      if (!this.tabs.some((o) => o.idHcTabs === 11)) {
        this.buscarNotasEnfermeria();
      }
      this.showBuscarPaciente = false;
    },
    buscarPaciente() {
      this.showBuscarPaciente = true;
    },
    buscarHC(value) {
      if (value == 0) {
        this.buscarAntecedentes();
      }
      if (value == 1) {
        this.buscarEvoluciones();
      }
      if (value == 2) {
        this.buscarDiagnosticos();
      }
      if (value == 3) {
        this.buscarSignosVitales();
      }
      if (value == 4) {
        this.buscarRecetas();
      }
      if (value == 5) {
        this.buscarIndicaciones();
      }
      if (value == 6) {
        this.buscarPedidos();
      }
      if (value == 7) {
        this.buscarInformes();
      }
      if (value == 8) {
        this.buscarOdontograma();
      }
      if (value == 9) {
        this.buscarVacunaciones();
      }
      if (value == 10) {
        this.buscarNotasEnfermeria();
      }
    },

    buscarAntecedentes() {
      const idpaciente = this.idPaciente;
      let self = this;

      axios
        .get("/Paciente/BuscarDatosPaciente?", {
          params: {
            idpaciente: this.paciente.idPaciente,
          },
        })
        .then((response) => {
          this.paciente.antecedentes = response.data.antecedentes;
          this.paciente.antecedentesFamiliares =
            response.data.antecedentesFamiliares;
        })
        .catch((error) => {});
    },
    buscarPacienteDni(state) {
      var pacientesData = {};

      if (this.$store.state.dniPaciente == 0) {
        pacientesData = {
          dni: this.paciente.dni,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
      } else {
        pacientesData = {
          dni: this.$store.state.dniPaciente,
          idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
        };
      }
      let self = this;
      axios
        .get("/Paciente/GetPacienteDni?", {
          params: {
            dni: pacientesData.dni,
            idInstitucion: pacientesData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data != null && response.data != "") {
            self.paciente.dni = response.data.nrodocumento;
            self.paciente.apellidos = response.data.apellidos;
            self.paciente.nombres = response.data.nombres;
            self.paciente.idPaciente = response.data.idpaciente;
            if (response.data.fechanacimiento == "0001-01-01T00:00:00")
              self.paciente.fechaNac = "";
            else
              self.paciente.fechaNac =
                response.data.fechanacimiento.substring(8, 10) +
                "/" +
                response.data.fechanacimiento.substring(5, 7) +
                "/" +
                response.data.fechanacimiento.substring(0, 4);
            self.paciente.sexo = response.data.sexo;
            self.paciente.mail = response.data.email;
            self.paciente.celular = response.data.celular;
            self.paciente.edadAux = new Date(response.data.fechanacimiento);
            // self.paciente.antecedentes = response.data.antecedentes;
            if (self.paciente.fechaNac != "") {
              let hoy = new Date();
              let age = hoy.getFullYear() - self.paciente.edadAux.getFullYear();
              let diferenciaMeses =
                hoy.getMonth() - self.paciente.edadAux.getMonth();
              if (
                diferenciaMeses < 0 ||
                (diferenciaMeses === 0 &&
                  hoy.getDate() < self.paciente.edadAux.getDate())
              ) {
                age--;
              }
              if (age > 0) self.paciente.edad = age + " años";
              else {
                if (diferenciaMeses > 0)
                  self.paciente.edad = diferenciaMeses + " meses";
                else {
                  let diferenciaDias =
                    hoy.getTime() - self.paciente.edadAux.getTime();
                  self.paciente.edad =
                    Math.trunc(diferenciaDias / (1000 * 60 * 60 * 24)) +
                    " días";
                }
              }
            } else self.paciente.edad = "";
            self.paciente.observaciones = response.data.observaciones;
            self.paciente.celular = response.data.celular;
            self.paciente.telefono = response.data.telefono;
            self.paciente.direccion = response.data.direccion;
            self.paciente.localidad = response.data.localidad;
            self.paciente.foto = response.data.foto;
            //self.buscarHC(5);
            if (!self.$store.state.usuario.institucion.esEmpresa) {
              self.buscarDatosAfiliatorios(response.data.idpaciente);
            }
            self.buscarAntecedentes();
            self.buscarEvoluciones();
            self.buscarDiagnosticos();
            self.buscarSignosVitales();
            self.buscarRecetas();
            self.buscarIndicaciones();
            self.buscarPedidos();
            self.buscarInformes();
            self.buscarOdontograma();
            self.buscarVacunaciones();
            if (!self.tabs.some((o) => o.idHcTabs === 11)) {
              self.buscarNotasEnfermeria();
            }
          } else {
            self.vaciarModels();
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No existe ningún paciente con el número de documento ingresado.",
                vm: self,
              });
            }
          }
        })
        .catch(function (error) {});
    },
    async buscarDatosAfiliatorios(idPaciente) {
      try {
        const response = await axios.get(
          "/HistoriaClinica/BuscarUltimaReceta?",
          {
            params: {
              idPaciente,
            },
          }
        );
        this.datoAfiliatorio.nroAfiliado = response.data.nroAfiliado;
        this.datoAfiliatorio.obraSocial = response.data.obraSocial;
      } catch (error) {
        console.log(error);
      }
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if (keyCode < 48 || keyCode > 57) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    buscarEvoluciones() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetEvoluciones?", {
          params: {
            idPaciente: filtrosData.idPaciente,
            usuario: this.$store.state.usuario.usuario,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            idProfesional:
              this.$store.state.usuario.profesionales.length > 1
                ? 0
                : this.$store.state.usuario.profesionales[0].idProfesional,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaEvoluciones = response.data;
          else self.listaEvoluciones = [];
        })
        .catch(function (error) {});
    },
    buscarDiagnosticos() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetDiagnosticos?", {
          params: {
            idPaciente: filtrosData.idPaciente,
            esEmpresa: self.$store.state.usuario.institucion.esEmpresa,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaDiagnosticos = response.data;
          else self.listaDiagnosticos = [];
        })
        .catch(function (error) {});
    },
    buscarSignosVitales() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetSignosVitales?", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaSignosVitales = response.data;
          else self.listaSignosVitales = [];
        })
        .catch(function (error) {});
    },
    buscarOdontograma() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetOdontograma?", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaOdontograma = response.data;
          else self.listaOdontograma = [];
        })
        .catch(function (error) {});
      this.dibujarOdontograma();
    },
    buscarVacunaciones() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/Enfermeria/GetVacunaciones??", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaVacunaciones = response.data;
          else self.listaVacunaciones = [];
        })
        .catch(function (error) {});
    },
    buscarNotasEnfermeria() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/Enfermeria/GetNotasEnfermeria?", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaNotasEnfermeria = response.data;
          else self.listaNotasEnfermeria = [];
        })
        .catch(function (error) {});
    },
    dibujarOdontograma() {
      this.listaOdontograma.forEach((practica) => {
        if (practica.idPracticaOdontologica == 8) {
          if (practica.idDiente == 1) this.ausencia.a1 = "red";
          if (practica.idDiente == 2) this.ausencia.a2 = "red";
          if (practica.idDiente == 3) this.ausencia.a3 = "red";
          if (practica.idDiente == 4) this.ausencia.a4 = "red";
          if (practica.idDiente == 5) this.ausencia.a5 = "red";
          if (practica.idDiente == 6) this.ausencia.a6 = "red";
          if (practica.idDiente == 7) this.ausencia.a7 = "red";
          if (practica.idDiente == 8) this.ausencia.a8 = "red";
          if (practica.idDiente == 9) this.ausencia.a9 = "red";
          if (practica.idDiente == 10) this.ausencia.a10 = "red";
          if (practica.idDiente == 11) this.ausencia.a11 = "red";
          if (practica.idDiente == 12) this.ausencia.a12 = "red";
          if (practica.idDiente == 13) this.ausencia.a13 = "red";
          if (practica.idDiente == 14) this.ausencia.a14 = "red";
          if (practica.idDiente == 15) this.ausencia.a15 = "red";
          if (practica.idDiente == 16) this.ausencia.a16 = "red";
          if (practica.idDiente == 17) this.ausencia.a17 = "red";
          if (practica.idDiente == 18) this.ausencia.a18 = "red";
          if (practica.idDiente == 19) this.ausencia.a19 = "red";
          if (practica.idDiente == 20) this.ausencia.a20 = "red";
          if (practica.idDiente == 21) this.ausencia.a21 = "red";
          if (practica.idDiente == 22) this.ausencia.a22 = "red";
          if (practica.idDiente == 23) this.ausencia.a23 = "red";
          if (practica.idDiente == 24) this.ausencia.a24 = "red";
          if (practica.idDiente == 25) this.ausencia.a25 = "red";
          if (practica.idDiente == 26) this.ausencia.a26 = "red";
          if (practica.idDiente == 27) this.ausencia.a27 = "red";
          if (practica.idDiente == 28) this.ausencia.a28 = "red";
          if (practica.idDiente == 29) this.ausencia.a29 = "red";
          if (practica.idDiente == 30) this.ausencia.a30 = "red";
          if (practica.idDiente == 31) this.ausencia.a31 = "red";
          if (practica.idDiente == 32) this.ausencia.a32 = "red";
          if (practica.idDiente == 33) this.ausencia.a33 = "red";
          if (practica.idDiente == 34) this.ausencia.a34 = "red";
          if (practica.idDiente == 35) this.ausencia.a35 = "red";
          if (practica.idDiente == 36) this.ausencia.a36 = "red";
          if (practica.idDiente == 37) this.ausencia.a37 = "red";
          if (practica.idDiente == 38) this.ausencia.a38 = "red";
          if (practica.idDiente == 39) this.ausencia.a39 = "red";
          if (practica.idDiente == 40) this.ausencia.a40 = "red";
          if (practica.idDiente == 41) this.ausencia.a41 = "red";
          if (practica.idDiente == 42) this.ausencia.a42 = "red";
          if (practica.idDiente == 43) this.ausencia.a43 = "red";
          if (practica.idDiente == 44) this.ausencia.a44 = "red";
          if (practica.idDiente == 45) this.ausencia.a45 = "red";
          if (practica.idDiente == 46) this.ausencia.a46 = "red";
          if (practica.idDiente == 47) this.ausencia.a47 = "red";
          if (practica.idDiente == 48) this.ausencia.a48 = "red";
          if (practica.idDiente == 49) this.ausencia.a49 = "red";
          if (practica.idDiente == 50) this.ausencia.a50 = "red";
          if (practica.idDiente == 51) this.ausencia.a51 = "red";
          if (practica.idDiente == 52) this.ausencia.a52 = "red";
        }

        if (practica.idPracticaOdontologica == 1) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.extraccion.e1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.extraccion.e2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.extraccion.e3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.extraccion.e4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.extraccion.e5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.extraccion.e6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.extraccion.e7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.extraccion.e8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.extraccion.e9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.extraccion.e10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.extraccion.e11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.extraccion.e12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.extraccion.e13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.extraccion.e14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.extraccion.e15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.extraccion.e16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.extraccion.e17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.extraccion.e18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.extraccion.e19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.extraccion.e20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.extraccion.e21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.extraccion.e22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.extraccion.e23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.extraccion.e24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.extraccion.e25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.extraccion.e26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.extraccion.e27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.extraccion.e28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.extraccion.e29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.extraccion.e30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.extraccion.e31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.extraccion.e32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.extraccion.e33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.extraccion.e34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.extraccion.e35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.extraccion.e36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.extraccion.e37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.extraccion.e38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.extraccion.e39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.extraccion.e40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.extraccion.e41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.extraccion.e42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.extraccion.e43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.extraccion.e44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.extraccion.e45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.extraccion.e46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.extraccion.e47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.extraccion.e48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.extraccion.e49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.extraccion.e50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.extraccion.e51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.extraccion.e52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.extraccion.e1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.extraccion.e2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.extraccion.e3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.extraccion.e4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.extraccion.e5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.extraccion.e6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.extraccion.e7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.extraccion.e8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.extraccion.e9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.extraccion.e10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.extraccion.e11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.extraccion.e12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.extraccion.e13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.extraccion.e14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.extraccion.e15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.extraccion.e16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.extraccion.e17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.extraccion.e18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.extraccion.e19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.extraccion.e20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.extraccion.e21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.extraccion.e22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.extraccion.e23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.extraccion.e24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.extraccion.e25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.extraccion.e26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.extraccion.e27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.extraccion.e28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.extraccion.e29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.extraccion.e30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.extraccion.e31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.extraccion.e32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.extraccion.e33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.extraccion.e34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.extraccion.e35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.extraccion.e36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.extraccion.e37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.extraccion.e38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.extraccion.e39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.extraccion.e40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.extraccion.e41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.extraccion.e42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.extraccion.e43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.extraccion.e44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.extraccion.e45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.extraccion.e46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.extraccion.e47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.extraccion.e48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.extraccion.e49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.extraccion.e50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.extraccion.e51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.extraccion.e52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.extraccion.e1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.extraccion.e2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.extraccion.e3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.extraccion.e4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.extraccion.e5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.extraccion.e6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.extraccion.e7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.extraccion.e8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.extraccion.e9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.extraccion.e10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.extraccion.e11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.extraccion.e12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.extraccion.e13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.extraccion.e14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.extraccion.e15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.extraccion.e16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.extraccion.e17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.extraccion.e18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.extraccion.e19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.extraccion.e20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.extraccion.e21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.extraccion.e22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.extraccion.e23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.extraccion.e24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.extraccion.e25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.extraccion.e26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.extraccion.e27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.extraccion.e28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.extraccion.e29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.extraccion.e30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.extraccion.e31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.extraccion.e32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.extraccion.e33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.extraccion.e34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.extraccion.e35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.extraccion.e36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.extraccion.e37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.extraccion.e38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.extraccion.e39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.extraccion.e40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.extraccion.e41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.extraccion.e42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.extraccion.e43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.extraccion.e44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.extraccion.e45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.extraccion.e46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.extraccion.e47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.extraccion.e48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.extraccion.e49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.extraccion.e50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.extraccion.e51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.extraccion.e52 = "green";
        }
        if (practica.idPracticaOdontologica == 5) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.fija.f1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.fija.f2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.fija.f3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.fija.f4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.fija.f5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.fija.f6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.fija.f7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.fija.f8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.fija.f9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.fija.f10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.fija.f11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.fija.f12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.fija.f13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.fija.f14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.fija.f15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.fija.f16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.fija.f17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.fija.f18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.fija.f19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.fija.f20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.fija.f21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.fija.f22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.fija.f23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.fija.f24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.fija.f25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.fija.f26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.fija.f27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.fija.f28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.fija.f29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.fija.f30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.fija.f31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.fija.f32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.fija.f33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.fija.f34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.fija.f35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.fija.f36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.fija.f37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.fija.f38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.fija.f39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.fija.f40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.fija.f41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.fija.f42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.fija.f43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.fija.f44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.fija.f45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.fija.f46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.fija.f47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.fija.f48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.fija.f49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.fija.f50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.fija.f51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.fija.f52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.fija.f1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.fija.f2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.fija.f3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.fija.f4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.fija.f5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.fija.f6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.fija.f7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.fija.f8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.fija.f9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.fija.f10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.fija.f11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.fija.f12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.fija.f13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.fija.f14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.fija.f15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.fija.f16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.fija.f17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.fija.f18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.fija.f19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.fija.f20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.fija.f21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.fija.f22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.fija.f23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.fija.f24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.fija.f25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.fija.f26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.fija.f27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.fija.f28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.fija.f29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.fija.f30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.fija.f31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.fija.f32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.fija.f33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.fija.f34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.fija.f35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.fija.f36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.fija.f37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.fija.f38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.fija.f39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.fija.f40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.fija.f41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.fija.f42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.fija.f43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.fija.f44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.fija.f45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.fija.f46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.fija.f47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.fija.f48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.fija.f49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.fija.f50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.fija.f51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.fija.f52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.fija.f1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.fija.f2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.fija.f3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.fija.f4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.fija.f5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.fija.f6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.fija.f7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.fija.f8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.fija.f9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.fija.f10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.fija.f11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.fija.f12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.fija.f13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.fija.f14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.fija.f15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.fija.f16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.fija.f17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.fija.f18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.fija.f19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.fija.f20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.fija.f21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.fija.f22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.fija.f23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.fija.f24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.fija.f25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.fija.f26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.fija.f27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.fija.f28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.fija.f29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.fija.f30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.fija.f31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.fija.f32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.fija.f33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.fija.f34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.fija.f35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.fija.f36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.fija.f37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.fija.f38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.fija.f39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.fija.f40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.fija.f41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.fija.f42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.fija.f43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.fija.f44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.fija.f45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.fija.f46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.fija.f47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.fija.f48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.fija.f49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.fija.f50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.fija.f51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.fija.f52 = "green";
        }
        if (practica.idPracticaOdontologica == 6) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.movil.m1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.movil.m2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.movil.m3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.movil.m4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.movil.m5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.movil.m6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.movil.m7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.movil.m8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.movil.m9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.movil.m10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.movil.m11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.movil.m12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.movil.m13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.movil.m14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.movil.m15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.movil.m16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.movil.m17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.movil.m18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.movil.m19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.movil.m20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.movil.m21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.movil.m22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.movil.m23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.movil.m24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.movil.m25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.movil.m26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.movil.m27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.movil.m28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.movil.m29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.movil.m30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.movil.m31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.movil.m32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.movil.m33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.movil.m34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.movil.m35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.movil.m36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.movil.m37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.movil.m38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.movil.m39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.movil.m40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.movil.m41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.movil.m42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.movil.m43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.movil.m44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.movil.m45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.movil.m46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.movil.m47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.movil.m48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.movil.m49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.movil.m50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.movil.m51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.movil.m52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.movil.m1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.movil.m2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.movil.m3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.movil.m4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.movil.m5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.movil.m6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.movil.m7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.movil.m8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.movil.m9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.movil.m10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.movil.m11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.movil.m12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.movil.m13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.movil.m14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.movil.m15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.movil.m16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.movil.m17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.movil.m18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.movil.m19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.movil.m20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.movil.m21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.movil.m22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.movil.m23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.movil.m24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.movil.m25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.movil.m26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.movil.m27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.movil.m28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.movil.m29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.movil.m30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.movil.m31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.movil.m32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.movil.m33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.movil.m34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.movil.m35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.movil.m36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.movil.m37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.movil.m38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.movil.m39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.movil.m40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.movil.m41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.movil.m42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.movil.m43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.movil.m44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.movil.m45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.movil.m46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.movil.m47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.movil.m48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.movil.m49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.movil.m50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.movil.m51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.movil.m52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.movil.m1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.movil.m2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.movil.m3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.movil.m4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.movil.m5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.movil.m6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.movil.m7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.movil.m8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.movil.m9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.movil.m10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.movil.m11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.movil.m12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.movil.m13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.movil.m14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.movil.m15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.movil.m16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.movil.m17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.movil.m18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.movil.m19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.movil.m20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.movil.m21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.movil.m22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.movil.m23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.movil.m24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.movil.m25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.movil.m26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.movil.m27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.movil.m28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.movil.m29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.movil.m30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.movil.m31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.movil.m32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.movil.m33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.movil.m34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.movil.m35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.movil.m36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.movil.m37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.movil.m38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.movil.m39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.movil.m40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.movil.m41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.movil.m42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.movil.m43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.movil.m44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.movil.m45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.movil.m46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.movil.m47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.movil.m48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.movil.m49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.movil.m50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.movil.m51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.movil.m52 = "green";
        }
        if (practica.idPracticaOdontologica == 9) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.ortodoncia.o1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.ortodoncia.o2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.ortodoncia.o3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.ortodoncia.o4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.ortodoncia.o5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.ortodoncia.o6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.ortodoncia.o7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.ortodoncia.o8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.ortodoncia.o9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.ortodoncia.o10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.ortodoncia.o11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.ortodoncia.o12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.ortodoncia.o13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.ortodoncia.o14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.ortodoncia.o15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.ortodoncia.o16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.ortodoncia.o17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.ortodoncia.o18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.ortodoncia.o19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.ortodoncia.o20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.ortodoncia.o21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.ortodoncia.o22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.ortodoncia.o23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.ortodoncia.o24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.ortodoncia.o25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.ortodoncia.o26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.ortodoncia.o27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.ortodoncia.o28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.ortodoncia.o29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.ortodoncia.o30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.ortodoncia.o31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.ortodoncia.o32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.ortodoncia.o33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.ortodoncia.o34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.ortodoncia.o35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.ortodoncia.o36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.ortodoncia.o37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.ortodoncia.o38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.ortodoncia.o39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.ortodoncia.o40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.ortodoncia.o41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.ortodoncia.o42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.ortodoncia.o43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.ortodoncia.o44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.ortodoncia.o45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.ortodoncia.o46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.ortodoncia.o47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.ortodoncia.o48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.ortodoncia.o49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.ortodoncia.o50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.ortodoncia.o51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.ortodoncia.o52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.ortodoncia.o1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.ortodoncia.o2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.ortodoncia.o3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.ortodoncia.o4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.ortodoncia.o5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.ortodoncia.o6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.ortodoncia.o7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.ortodoncia.o8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.ortodoncia.o9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.ortodoncia.o10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.ortodoncia.o11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.ortodoncia.o12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.ortodoncia.o13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.ortodoncia.o14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.ortodoncia.o15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.ortodoncia.o16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.ortodoncia.o17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.ortodoncia.o18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.ortodoncia.o19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.ortodoncia.o20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.ortodoncia.o21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.ortodoncia.o22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.ortodoncia.o23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.ortodoncia.o24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.ortodoncia.o25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.ortodoncia.o26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.ortodoncia.o27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.ortodoncia.o28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.ortodoncia.o29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.ortodoncia.o30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.ortodoncia.o31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.ortodoncia.o32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.ortodoncia.o33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.ortodoncia.o34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.ortodoncia.o35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.ortodoncia.o36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.ortodoncia.o37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.ortodoncia.o38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.ortodoncia.o39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.ortodoncia.o40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.ortodoncia.o41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.ortodoncia.o42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.ortodoncia.o43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.ortodoncia.o44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.ortodoncia.o45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.ortodoncia.o46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.ortodoncia.o47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.ortodoncia.o48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.ortodoncia.o49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.ortodoncia.o50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.ortodoncia.o51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.ortodoncia.o52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.ortodoncia.o1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.ortodoncia.o2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.ortodoncia.o3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.ortodoncia.o4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.ortodoncia.o5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.ortodoncia.o6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.ortodoncia.o7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.ortodoncia.o8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.ortodoncia.o9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.ortodoncia.o10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.ortodoncia.o11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.ortodoncia.o12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.ortodoncia.o13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.ortodoncia.o14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.ortodoncia.o15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.ortodoncia.o16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.ortodoncia.o17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.ortodoncia.o18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.ortodoncia.o19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.ortodoncia.o20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.ortodoncia.o21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.ortodoncia.o22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.ortodoncia.o23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.ortodoncia.o24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.ortodoncia.o25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.ortodoncia.o26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.ortodoncia.o27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.ortodoncia.o28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.ortodoncia.o29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.ortodoncia.o30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.ortodoncia.o31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.ortodoncia.o32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.ortodoncia.o33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.ortodoncia.o34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.ortodoncia.o35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.ortodoncia.o36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.ortodoncia.o37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.ortodoncia.o38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.ortodoncia.o39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.ortodoncia.o40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.ortodoncia.o41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.ortodoncia.o42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.ortodoncia.o43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.ortodoncia.o44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.ortodoncia.o45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.ortodoncia.o46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.ortodoncia.o47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.ortodoncia.o48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.ortodoncia.o49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.ortodoncia.o50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.ortodoncia.o51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.ortodoncia.o52 = "green";
        }

        if (practica.idPracticaOdontologica == 7) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.implante.i1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.implante.i2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.implante.i3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.implante.i4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.implante.i5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.implante.i6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.implante.i7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.implante.i8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.implante.i9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.implante.i10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.implante.i11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.implante.i12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.implante.i13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.implante.i14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.implante.i15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.implante.i16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.implante.i17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.implante.i18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.implante.i19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.implante.i20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.implante.i21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.implante.i22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.implante.i23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.implante.i24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.implante.i25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.implante.i26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.implante.i27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.implante.i28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.implante.i29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.implante.i30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.implante.i31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.implante.i32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.implante.i33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.implante.i34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.implante.i35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.implante.i36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.implante.i37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.implante.i38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.implante.i39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.implante.i40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.implante.i41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.implante.i42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.implante.i43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.implante.i44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.implante.i45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.implante.i46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.implante.i47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.implante.i48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.implante.i49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.implante.i50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.implante.i51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.implante.i52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.implante.i1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.implante.i2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.implante.i3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.implante.i4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.implante.i5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.implante.i6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.implante.i7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.implante.i8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.implante.i9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.implante.i10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.implante.i11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.implante.i12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.implante.i13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.implante.i14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.implante.i15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.implante.i16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.implante.i17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.implante.i18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.implante.i19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.implante.i20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.implante.i21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.implante.i22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.implante.i23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.implante.i24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.implante.i25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.implante.i26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.implante.i27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.implante.i28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.implante.i29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.implante.i30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.implante.i31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.implante.i32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.implante.i33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.implante.i34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.implante.i35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.implante.i36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.implante.i37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.implante.i38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.implante.i39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.implante.i40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.implante.i41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.implante.i42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.implante.i43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.implante.i44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.implante.i45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.implante.i46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.implante.i47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.implante.i48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.implante.i49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.implante.i50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.implante.i51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.implante.i52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.implante.i1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.implante.i2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.implante.i3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.implante.i4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.implante.i5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.implante.i6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.implante.i7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.implante.i8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.implante.i9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.implante.i10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.implante.i11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.implante.i12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.implante.i13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.implante.i14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.implante.i15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.implante.i16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.implante.i17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.implante.i18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.implante.i19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.implante.i20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.implante.i21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.implante.i22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.implante.i23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.implante.i24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.implante.i25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.implante.i26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.implante.i27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.implante.i28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.implante.i29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.implante.i30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.implante.i31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.implante.i32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.implante.i33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.implante.i34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.implante.i35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.implante.i36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.implante.i37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.implante.i38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.implante.i39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.implante.i40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.implante.i41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.implante.i42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.implante.i43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.implante.i44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.implante.i45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.implante.i46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.implante.i47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.implante.i48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.implante.i49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.implante.i50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.implante.i51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.implante.i52 = "green";
        }

        if (practica.idPracticaOdontologica == 2) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.tratamiento.t1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.tratamiento.t2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.tratamiento.t3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.tratamiento.t4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.tratamiento.t5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.tratamiento.t6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.tratamiento.t7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.tratamiento.t8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.tratamiento.t9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.tratamiento.t10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.tratamiento.t11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.tratamiento.t12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.tratamiento.t13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.tratamiento.t14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.tratamiento.t15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.tratamiento.t16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.tratamiento.t17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.tratamiento.t18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.tratamiento.t19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.tratamiento.t20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.tratamiento.t21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.tratamiento.t22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.tratamiento.t23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.tratamiento.t24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.tratamiento.t25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.tratamiento.t26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.tratamiento.t27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.tratamiento.t28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.tratamiento.t29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.tratamiento.t30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.tratamiento.t31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.tratamiento.t32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.tratamiento.t33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.tratamiento.t34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.tratamiento.t35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.tratamiento.t36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.tratamiento.t37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.tratamiento.t38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.tratamiento.t39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.tratamiento.t40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.tratamiento.t41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.tratamiento.t42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.tratamiento.t43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.tratamiento.t44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.tratamiento.t45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.tratamiento.t46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.tratamiento.t47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.tratamiento.t48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.tratamiento.t49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.tratamiento.t50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.tratamiento.t51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.tratamiento.t52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.tratamiento.t1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.tratamiento.t2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.tratamiento.t3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.tratamiento.t4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.tratamiento.t5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.tratamiento.t6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.tratamiento.t7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.tratamiento.t8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.tratamiento.t9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.tratamiento.t10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.tratamiento.t11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.tratamiento.t12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.tratamiento.t13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.tratamiento.t14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.tratamiento.t15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.tratamiento.t16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.tratamiento.t17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.tratamiento.t18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.tratamiento.t19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.tratamiento.t20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.tratamiento.t21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.tratamiento.t22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.tratamiento.t23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.tratamiento.t24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.tratamiento.t25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.tratamiento.t26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.tratamiento.t27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.tratamiento.t28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.tratamiento.t29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.tratamiento.t30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.tratamiento.t31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.tratamiento.t32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.tratamiento.t33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.tratamiento.t34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.tratamiento.t35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.tratamiento.t36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.tratamiento.t37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.tratamiento.t38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.tratamiento.t39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.tratamiento.t40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.tratamiento.t41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.tratamiento.t42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.tratamiento.t43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.tratamiento.t44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.tratamiento.t45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.tratamiento.t46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.tratamiento.t47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.tratamiento.t48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.tratamiento.t49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.tratamiento.t50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.tratamiento.t51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.tratamiento.t52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.tratamiento.t1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.tratamiento.t2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.tratamiento.t3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.tratamiento.t4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.tratamiento.t5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.tratamiento.t6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.tratamiento.t7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.tratamiento.t8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.tratamiento.t9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.tratamiento.t10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.tratamiento.t11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.tratamiento.t12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.tratamiento.t13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.tratamiento.t14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.tratamiento.t15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.tratamiento.t16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.tratamiento.t17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.tratamiento.t18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.tratamiento.t19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.tratamiento.t20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.tratamiento.t21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.tratamiento.t22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.tratamiento.t23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.tratamiento.t24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.tratamiento.t25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.tratamiento.t26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.tratamiento.t27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.tratamiento.t28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.tratamiento.t29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.tratamiento.t30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.tratamiento.t31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.tratamiento.t32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.tratamiento.t33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.tratamiento.t34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.tratamiento.t35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.tratamiento.t36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.tratamiento.t37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.tratamiento.t38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.tratamiento.t39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.tratamiento.t40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.tratamiento.t41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.tratamiento.t42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.tratamiento.t43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.tratamiento.t44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.tratamiento.t45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.tratamiento.t46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.tratamiento.t47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.tratamiento.t48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.tratamiento.t49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.tratamiento.t50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.tratamiento.t51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.tratamiento.t52 = "green";
        }

        if (practica.idPracticaOdontologica == 3) {
          if (practica.idDiente == 1 && practica.estado == 1)
            this.corona.c1 = "red";
          if (practica.idDiente == 2 && practica.estado == 1)
            this.corona.c2 = "red";
          if (practica.idDiente == 3 && practica.estado == 1)
            this.corona.c3 = "red";
          if (practica.idDiente == 4 && practica.estado == 1)
            this.corona.c4 = "red";
          if (practica.idDiente == 5 && practica.estado == 1)
            this.corona.c5 = "red";
          if (practica.idDiente == 6 && practica.estado == 1)
            this.corona.c6 = "red";
          if (practica.idDiente == 7 && practica.estado == 1)
            this.corona.c7 = "red";
          if (practica.idDiente == 8 && practica.estado == 1)
            this.corona.c8 = "red";
          if (practica.idDiente == 9 && practica.estado == 1)
            this.corona.c9 = "red";
          if (practica.idDiente == 10 && practica.estado == 1)
            this.corona.c10 = "red";
          if (practica.idDiente == 11 && practica.estado == 1)
            this.corona.c11 = "red";
          if (practica.idDiente == 12 && practica.estado == 1)
            this.corona.c12 = "red";
          if (practica.idDiente == 13 && practica.estado == 1)
            this.corona.c13 = "red";
          if (practica.idDiente == 14 && practica.estado == 1)
            this.corona.c14 = "red";
          if (practica.idDiente == 15 && practica.estado == 1)
            this.corona.c15 = "red";
          if (practica.idDiente == 16 && practica.estado == 1)
            this.corona.c16 = "red";
          if (practica.idDiente == 17 && practica.estado == 1)
            this.corona.c17 = "red";
          if (practica.idDiente == 18 && practica.estado == 1)
            this.corona.c18 = "red";
          if (practica.idDiente == 19 && practica.estado == 1)
            this.corona.c19 = "red";
          if (practica.idDiente == 20 && practica.estado == 1)
            this.corona.c20 = "red";
          if (practica.idDiente == 21 && practica.estado == 1)
            this.corona.c21 = "red";
          if (practica.idDiente == 22 && practica.estado == 1)
            this.corona.c22 = "red";
          if (practica.idDiente == 23 && practica.estado == 1)
            this.corona.c23 = "red";
          if (practica.idDiente == 24 && practica.estado == 1)
            this.corona.c24 = "red";
          if (practica.idDiente == 25 && practica.estado == 1)
            this.corona.c25 = "red";
          if (practica.idDiente == 26 && practica.estado == 1)
            this.corona.c26 = "red";
          if (practica.idDiente == 27 && practica.estado == 1)
            this.corona.c27 = "red";
          if (practica.idDiente == 28 && practica.estado == 1)
            this.corona.c28 = "red";
          if (practica.idDiente == 29 && practica.estado == 1)
            this.corona.c29 = "red";
          if (practica.idDiente == 30 && practica.estado == 1)
            this.corona.c30 = "red";
          if (practica.idDiente == 31 && practica.estado == 1)
            this.corona.c31 = "red";
          if (practica.idDiente == 32 && practica.estado == 1)
            this.corona.c32 = "red";
          if (practica.idDiente == 33 && practica.estado == 1)
            this.corona.c33 = "red";
          if (practica.idDiente == 34 && practica.estado == 1)
            this.corona.c34 = "red";
          if (practica.idDiente == 35 && practica.estado == 1)
            this.corona.c35 = "red";
          if (practica.idDiente == 36 && practica.estado == 1)
            this.corona.c36 = "red";
          if (practica.idDiente == 37 && practica.estado == 1)
            this.corona.c37 = "red";
          if (practica.idDiente == 38 && practica.estado == 1)
            this.corona.c38 = "red";
          if (practica.idDiente == 39 && practica.estado == 1)
            this.corona.c39 = "red";
          if (practica.idDiente == 40 && practica.estado == 1)
            this.corona.c40 = "red";
          if (practica.idDiente == 41 && practica.estado == 1)
            this.corona.c41 = "red";
          if (practica.idDiente == 42 && practica.estado == 1)
            this.corona.c42 = "red";
          if (practica.idDiente == 43 && practica.estado == 1)
            this.corona.c43 = "red";
          if (practica.idDiente == 44 && practica.estado == 1)
            this.corona.c44 = "red";
          if (practica.idDiente == 45 && practica.estado == 1)
            this.corona.c45 = "red";
          if (practica.idDiente == 46 && practica.estado == 1)
            this.corona.c46 = "red";
          if (practica.idDiente == 47 && practica.estado == 1)
            this.corona.c47 = "red";
          if (practica.idDiente == 48 && practica.estado == 1)
            this.corona.c48 = "red";
          if (practica.idDiente == 49 && practica.estado == 1)
            this.corona.c49 = "red";
          if (practica.idDiente == 50 && practica.estado == 1)
            this.corona.c50 = "red";
          if (practica.idDiente == 51 && practica.estado == 1)
            this.corona.c51 = "red";
          if (practica.idDiente == 52 && practica.estado == 1)
            this.corona.c52 = "red";

          if (practica.idDiente == 1 && practica.estado == 2)
            this.corona.c1 = "blue";
          if (practica.idDiente == 2 && practica.estado == 2)
            this.corona.c2 = "blue";
          if (practica.idDiente == 3 && practica.estado == 2)
            this.corona.c3 = "blue";
          if (practica.idDiente == 4 && practica.estado == 2)
            this.corona.c4 = "blue";
          if (practica.idDiente == 5 && practica.estado == 2)
            this.corona.c5 = "blue";
          if (practica.idDiente == 6 && practica.estado == 2)
            this.corona.c6 = "blue";
          if (practica.idDiente == 7 && practica.estado == 2)
            this.corona.c7 = "blue";
          if (practica.idDiente == 8 && practica.estado == 2)
            this.corona.c8 = "blue";
          if (practica.idDiente == 9 && practica.estado == 2)
            this.corona.c9 = "blue";
          if (practica.idDiente == 10 && practica.estado == 2)
            this.corona.c10 = "blue";
          if (practica.idDiente == 11 && practica.estado == 2)
            this.corona.c11 = "blue";
          if (practica.idDiente == 12 && practica.estado == 2)
            this.corona.c12 = "blue";
          if (practica.idDiente == 13 && practica.estado == 2)
            this.corona.c13 = "blue";
          if (practica.idDiente == 14 && practica.estado == 2)
            this.corona.c14 = "blue";
          if (practica.idDiente == 15 && practica.estado == 2)
            this.corona.c15 = "blue";
          if (practica.idDiente == 16 && practica.estado == 2)
            this.corona.c16 = "blue";
          if (practica.idDiente == 17 && practica.estado == 2)
            this.corona.c17 = "blue";
          if (practica.idDiente == 18 && practica.estado == 2)
            this.corona.c18 = "blue";
          if (practica.idDiente == 19 && practica.estado == 2)
            this.corona.c19 = "blue";
          if (practica.idDiente == 20 && practica.estado == 2)
            this.corona.c20 = "blue";
          if (practica.idDiente == 21 && practica.estado == 2)
            this.corona.c21 = "blue";
          if (practica.idDiente == 22 && practica.estado == 2)
            this.corona.c22 = "blue";
          if (practica.idDiente == 23 && practica.estado == 2)
            this.corona.c23 = "blue";
          if (practica.idDiente == 24 && practica.estado == 2)
            this.corona.c24 = "blue";
          if (practica.idDiente == 25 && practica.estado == 2)
            this.corona.c25 = "blue";
          if (practica.idDiente == 26 && practica.estado == 2)
            this.corona.c26 = "blue";
          if (practica.idDiente == 27 && practica.estado == 2)
            this.corona.c27 = "blue";
          if (practica.idDiente == 28 && practica.estado == 2)
            this.corona.c28 = "blue";
          if (practica.idDiente == 29 && practica.estado == 2)
            this.corona.c29 = "blue";
          if (practica.idDiente == 30 && practica.estado == 2)
            this.corona.c30 = "blue";
          if (practica.idDiente == 31 && practica.estado == 2)
            this.corona.c31 = "blue";
          if (practica.idDiente == 32 && practica.estado == 2)
            this.corona.c32 = "blue";
          if (practica.idDiente == 33 && practica.estado == 2)
            this.corona.c33 = "blue";
          if (practica.idDiente == 34 && practica.estado == 2)
            this.corona.c34 = "blue";
          if (practica.idDiente == 35 && practica.estado == 2)
            this.corona.c35 = "blue";
          if (practica.idDiente == 36 && practica.estado == 2)
            this.corona.c36 = "blue";
          if (practica.idDiente == 37 && practica.estado == 2)
            this.corona.c37 = "blue";
          if (practica.idDiente == 38 && practica.estado == 2)
            this.corona.c38 = "blue";
          if (practica.idDiente == 39 && practica.estado == 2)
            this.corona.c39 = "blue";
          if (practica.idDiente == 40 && practica.estado == 2)
            this.corona.c40 = "blue";
          if (practica.idDiente == 41 && practica.estado == 2)
            this.corona.c41 = "blue";
          if (practica.idDiente == 42 && practica.estado == 2)
            this.corona.c42 = "blue";
          if (practica.idDiente == 43 && practica.estado == 2)
            this.corona.c43 = "blue";
          if (practica.idDiente == 44 && practica.estado == 2)
            this.corona.c44 = "blue";
          if (practica.idDiente == 45 && practica.estado == 2)
            this.corona.c45 = "blue";
          if (practica.idDiente == 46 && practica.estado == 2)
            this.corona.c46 = "blue";
          if (practica.idDiente == 47 && practica.estado == 2)
            this.corona.c47 = "blue";
          if (practica.idDiente == 48 && practica.estado == 2)
            this.corona.c48 = "blue";
          if (practica.idDiente == 49 && practica.estado == 2)
            this.corona.c49 = "blue";
          if (practica.idDiente == 50 && practica.estado == 2)
            this.corona.c50 = "blue";
          if (practica.idDiente == 51 && practica.estado == 2)
            this.corona.c51 = "blue";
          if (practica.idDiente == 52 && practica.estado == 2)
            this.corona.c52 = "blue";

          if (practica.idDiente == 1 && practica.estado == 3)
            this.corona.c1 = "green";
          if (practica.idDiente == 2 && practica.estado == 3)
            this.corona.c2 = "green";
          if (practica.idDiente == 3 && practica.estado == 3)
            this.corona.c3 = "green";
          if (practica.idDiente == 4 && practica.estado == 3)
            this.corona.c4 = "green";
          if (practica.idDiente == 5 && practica.estado == 3)
            this.corona.c5 = "green";
          if (practica.idDiente == 6 && practica.estado == 3)
            this.corona.c6 = "green";
          if (practica.idDiente == 7 && practica.estado == 3)
            this.corona.c7 = "green";
          if (practica.idDiente == 8 && practica.estado == 3)
            this.corona.c8 = "green";
          if (practica.idDiente == 9 && practica.estado == 3)
            this.corona.c9 = "green";
          if (practica.idDiente == 10 && practica.estado == 3)
            this.corona.c10 = "green";
          if (practica.idDiente == 11 && practica.estado == 3)
            this.corona.c11 = "green";
          if (practica.idDiente == 12 && practica.estado == 3)
            this.corona.c12 = "green";
          if (practica.idDiente == 13 && practica.estado == 3)
            this.corona.c13 = "green";
          if (practica.idDiente == 14 && practica.estado == 3)
            this.corona.c14 = "green";
          if (practica.idDiente == 15 && practica.estado == 3)
            this.corona.c15 = "green";
          if (practica.idDiente == 16 && practica.estado == 3)
            this.corona.c16 = "green";
          if (practica.idDiente == 17 && practica.estado == 3)
            this.corona.c17 = "green";
          if (practica.idDiente == 18 && practica.estado == 3)
            this.corona.c18 = "green";
          if (practica.idDiente == 19 && practica.estado == 3)
            this.corona.c19 = "green";
          if (practica.idDiente == 20 && practica.estado == 3)
            this.corona.c20 = "green";
          if (practica.idDiente == 21 && practica.estado == 3)
            this.corona.c21 = "green";
          if (practica.idDiente == 22 && practica.estado == 3)
            this.corona.c22 = "green";
          if (practica.idDiente == 23 && practica.estado == 3)
            this.corona.c23 = "green";
          if (practica.idDiente == 24 && practica.estado == 3)
            this.corona.c24 = "green";
          if (practica.idDiente == 25 && practica.estado == 3)
            this.corona.c25 = "green";
          if (practica.idDiente == 26 && practica.estado == 3)
            this.corona.c26 = "green";
          if (practica.idDiente == 27 && practica.estado == 3)
            this.corona.c27 = "green";
          if (practica.idDiente == 28 && practica.estado == 3)
            this.corona.c28 = "green";
          if (practica.idDiente == 29 && practica.estado == 3)
            this.corona.c29 = "green";
          if (practica.idDiente == 30 && practica.estado == 3)
            this.corona.c30 = "green";
          if (practica.idDiente == 31 && practica.estado == 3)
            this.corona.c31 = "green";
          if (practica.idDiente == 32 && practica.estado == 3)
            this.corona.c32 = "green";
          if (practica.idDiente == 33 && practica.estado == 3)
            this.corona.c33 = "green";
          if (practica.idDiente == 34 && practica.estado == 3)
            this.corona.c34 = "green";
          if (practica.idDiente == 35 && practica.estado == 3)
            this.corona.c35 = "green";
          if (practica.idDiente == 36 && practica.estado == 3)
            this.corona.c36 = "green";
          if (practica.idDiente == 37 && practica.estado == 3)
            this.corona.c37 = "green";
          if (practica.idDiente == 38 && practica.estado == 3)
            this.corona.c38 = "green";
          if (practica.idDiente == 39 && practica.estado == 3)
            this.corona.c39 = "green";
          if (practica.idDiente == 40 && practica.estado == 3)
            this.corona.c40 = "green";
          if (practica.idDiente == 41 && practica.estado == 3)
            this.corona.c41 = "green";
          if (practica.idDiente == 42 && practica.estado == 3)
            this.corona.c42 = "green";
          if (practica.idDiente == 43 && practica.estado == 3)
            this.corona.c43 = "green";
          if (practica.idDiente == 44 && practica.estado == 3)
            this.corona.c44 = "green";
          if (practica.idDiente == 45 && practica.estado == 3)
            this.corona.c45 = "green";
          if (practica.idDiente == 46 && practica.estado == 3)
            this.corona.c46 = "green";
          if (practica.idDiente == 47 && practica.estado == 3)
            this.corona.c47 = "green";
          if (practica.idDiente == 48 && practica.estado == 3)
            this.corona.c48 = "green";
          if (practica.idDiente == 49 && practica.estado == 3)
            this.corona.c49 = "green";
          if (practica.idDiente == 50 && practica.estado == 3)
            this.corona.c50 = "green";
          if (practica.idDiente == 51 && practica.estado == 3)
            this.corona.c51 = "green";
          if (practica.idDiente == 52 && practica.estado == 3)
            this.corona.c52 = "green";
        }

        if (practica.idPracticaOdontologica == 4) {
          if (
            practica.idDiente == 1 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r11 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r21 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r31 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r41 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r51 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r61 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r71 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r81 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r91 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r101 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r111 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r121 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r131 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r141 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r151 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r161 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r171 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r181 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r191 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r201 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r211 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r221 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r231 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r241 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r251 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r261 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r271 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r281 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r291 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r301 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r311 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r321 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r331 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r341 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r351 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r361 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r371 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r381 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r391 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r401 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r411 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r421 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r431 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r441 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r451 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r461 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r471 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r481 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r491 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r501 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r511 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 1 &&
            practica.estado == 1
          )
            this.restauracion.r521 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r12 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r22 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r32 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r42 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r52 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r62 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r72 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r82 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r92 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r102 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r112 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r122 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r132 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r142 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r152 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r162 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r172 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r182 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r192 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r202 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r212 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r222 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r232 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r242 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r252 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r262 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r272 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r282 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r292 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r302 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r312 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r322 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r332 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r342 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r352 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r362 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r372 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r382 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r392 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r402 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r412 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r422 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r432 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r442 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r452 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r462 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r472 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r482 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r492 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r502 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r512 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 2 &&
            practica.estado == 1
          )
            this.restauracion.r522 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r13 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r23 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r33 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r43 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r53 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r63 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r73 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r83 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r93 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r103 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r113 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r123 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r133 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r143 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r153 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r163 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r173 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r183 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r193 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r203 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r213 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r223 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r233 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r243 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r253 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r263 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r273 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r283 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r293 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r303 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r313 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r323 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r333 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r343 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r353 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r363 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r373 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r383 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r393 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r403 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r413 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r423 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r433 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r443 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r453 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r463 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r473 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r483 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r493 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r503 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r513 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 3 &&
            practica.estado == 1
          )
            this.restauracion.r523 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r14 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r24 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r34 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r44 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r54 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r64 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r74 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r84 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r94 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r104 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r114 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r124 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r134 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r144 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r154 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r164 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r174 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r184 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r194 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r204 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r214 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r224 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r234 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r244 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r254 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r264 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r274 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r284 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r294 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r304 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r314 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r324 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r334 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r344 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r354 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r364 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r374 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r384 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r394 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r404 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r414 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r424 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r434 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r444 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r454 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r464 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r474 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r484 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r494 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r504 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r514 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 4 &&
            practica.estado == 1
          )
            this.restauracion.r524 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r15 = "red";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r25 = "red";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r35 = "red";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r45 = "red";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r55 = "red";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r65 = "red";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r75 = "red";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r85 = "red";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r95 = "red";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r105 = "red";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r115 = "red";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r125 = "red";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r135 = "red";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r145 = "red";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r155 = "red";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r165 = "red";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r175 = "red";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r185 = "red";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r195 = "red";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r205 = "red";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r215 = "red";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r225 = "red";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r235 = "red";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r245 = "red";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r255 = "red";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r265 = "red";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r275 = "red";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r285 = "red";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r295 = "red";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r305 = "red";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r315 = "red";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r325 = "red";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r335 = "red";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r345 = "red";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r355 = "red";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r365 = "red";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r375 = "red";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r385 = "red";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r395 = "red";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r405 = "red";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r415 = "red";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r425 = "red";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r435 = "red";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r445 = "red";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r455 = "red";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r465 = "red";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r475 = "red";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r485 = "red";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r495 = "red";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r505 = "red";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r515 = "red";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 5 &&
            practica.estado == 1
          )
            this.restauracion.r525 = "red";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r11 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r21 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r31 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r41 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r51 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r61 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r71 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r81 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r91 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r101 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r111 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r121 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r131 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r141 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r151 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r161 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r171 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r181 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r191 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r201 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r211 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r221 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r231 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r241 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r251 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r261 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r271 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r281 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r291 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r301 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r311 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r321 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r331 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r341 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r351 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r361 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r371 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r381 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r391 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r401 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r411 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r421 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r431 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r441 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r451 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r461 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r471 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r481 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r491 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r501 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r511 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 1 &&
            practica.estado == 2
          )
            this.restauracion.r521 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r12 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r22 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r32 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r42 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r52 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r62 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r72 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r82 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r92 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r102 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r112 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r122 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r132 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r142 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r152 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r162 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r172 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r182 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r192 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r202 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r212 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r222 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r232 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r242 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r252 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r262 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r272 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r282 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r292 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r302 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r312 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r322 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r332 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r342 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r352 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r362 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r372 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r382 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r392 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r402 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r412 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r422 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r432 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r442 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r452 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r462 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r472 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r482 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r492 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r502 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r512 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 2 &&
            practica.estado == 2
          )
            this.restauracion.r522 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r13 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r23 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r33 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r43 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r53 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r63 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r73 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r83 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r93 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r103 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r113 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r123 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r133 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r143 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r153 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r163 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r173 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r183 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r193 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r203 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r213 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r223 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r233 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r243 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r253 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r263 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r273 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r283 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r293 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r303 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r313 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r323 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r333 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r343 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r353 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r363 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r373 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r383 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r393 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r403 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r413 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r423 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r433 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r443 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r453 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r463 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r473 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r483 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r493 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r503 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r513 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 3 &&
            practica.estado == 2
          )
            this.restauracion.r523 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r14 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r24 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r34 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r44 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r54 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r64 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r74 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r84 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r94 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r104 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r114 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r124 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r134 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r144 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r154 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r164 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r174 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r184 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r194 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r204 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r214 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r224 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r234 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r244 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r254 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r264 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r274 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r284 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r294 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r304 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r314 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r324 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r334 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r344 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r354 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r364 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r374 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r384 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r394 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r404 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r414 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r424 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r434 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r444 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r454 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r464 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r474 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r484 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r494 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r504 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r514 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 4 &&
            practica.estado == 2
          )
            this.restauracion.r524 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r15 = "blue";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r25 = "blue";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r35 = "blue";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r45 = "blue";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r55 = "blue";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r65 = "blue";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r75 = "blue";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r85 = "blue";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r95 = "blue";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r105 = "blue";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r115 = "blue";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r125 = "blue";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r135 = "blue";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r145 = "blue";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r155 = "blue";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r165 = "blue";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r175 = "blue";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r185 = "blue";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r195 = "blue";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r205 = "blue";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r215 = "blue";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r225 = "blue";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r235 = "blue";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r245 = "blue";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r255 = "blue";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r265 = "blue";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r275 = "blue";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r285 = "blue";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r295 = "blue";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r305 = "blue";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r315 = "blue";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r325 = "blue";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r335 = "blue";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r345 = "blue";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r355 = "blue";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r365 = "blue";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r375 = "blue";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r385 = "blue";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r395 = "blue";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r405 = "blue";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r415 = "blue";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r425 = "blue";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r435 = "blue";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r445 = "blue";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r455 = "blue";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r465 = "blue";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r475 = "blue";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r485 = "blue";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r495 = "blue";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r505 = "blue";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r515 = "blue";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 5 &&
            practica.estado == 2
          )
            this.restauracion.r525 = "blue";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r11 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r21 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r31 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r41 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r51 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r61 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r71 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r81 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r91 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r101 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r111 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r121 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r131 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r141 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r151 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r161 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r171 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r181 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r191 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r201 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r211 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r221 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r231 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r241 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r251 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r261 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r271 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r281 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r291 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r301 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r311 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r321 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r331 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r341 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r351 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r361 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r371 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r381 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r391 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r401 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r411 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r421 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r431 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r441 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r451 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r461 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r471 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r481 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r491 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r501 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r511 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 1 &&
            practica.estado == 3
          )
            this.restauracion.r521 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r12 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r22 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r32 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r42 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r52 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r62 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r72 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r82 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r92 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r102 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r112 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r122 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r132 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r142 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r152 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r162 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r172 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r182 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r192 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r202 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r212 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r222 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r232 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r242 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r252 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r262 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r272 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r282 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r292 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r302 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r312 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r322 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r332 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r342 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r352 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r362 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r372 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r382 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r392 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r402 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r412 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r422 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r432 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r442 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r452 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r462 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r472 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r482 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r492 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r502 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r512 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 2 &&
            practica.estado == 3
          )
            this.restauracion.r522 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r13 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r23 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r33 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r43 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r53 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r63 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r73 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r83 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r93 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r103 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r113 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r123 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r133 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r143 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r153 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r163 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r173 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r183 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r193 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r203 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r213 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r223 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r233 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r243 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r253 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r263 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r273 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r283 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r293 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r303 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r313 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r323 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r333 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r343 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r353 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r363 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r373 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r383 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r393 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r403 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r413 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r423 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r433 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r443 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r453 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r463 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r473 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r483 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r493 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r503 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r513 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 3 &&
            practica.estado == 3
          )
            this.restauracion.r523 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r14 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r24 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r34 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r44 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r54 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r64 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r74 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r84 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r94 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r104 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r114 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r124 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r134 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r144 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r154 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r164 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r174 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r184 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r194 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r204 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r214 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r224 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r234 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r244 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r254 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r264 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r274 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r284 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r294 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r304 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r314 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r324 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r334 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r344 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r354 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r364 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r374 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r384 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r394 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r404 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r414 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r424 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r434 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r444 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r454 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r464 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r474 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r484 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r494 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r504 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r514 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 4 &&
            practica.estado == 3
          )
            this.restauracion.r524 = "green";

          if (
            practica.idDiente == 1 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r15 = "green";

          if (
            practica.idDiente == 2 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r25 = "green";

          if (
            practica.idDiente == 3 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r35 = "green";

          if (
            practica.idDiente == 4 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r45 = "green";

          if (
            practica.idDiente == 5 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r55 = "green";

          if (
            practica.idDiente == 6 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r65 = "green";

          if (
            practica.idDiente == 7 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r75 = "green";

          if (
            practica.idDiente == 8 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r85 = "green";

          if (
            practica.idDiente == 9 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r95 = "green";

          if (
            practica.idDiente == 10 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r105 = "green";

          if (
            practica.idDiente == 11 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r115 = "green";

          if (
            practica.idDiente == 12 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r125 = "green";

          if (
            practica.idDiente == 13 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r135 = "green";

          if (
            practica.idDiente == 14 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r145 = "green";

          if (
            practica.idDiente == 15 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r155 = "green";

          if (
            practica.idDiente == 16 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r165 = "green";

          if (
            practica.idDiente == 17 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r175 = "green";

          if (
            practica.idDiente == 18 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r185 = "green";

          if (
            practica.idDiente == 19 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r195 = "green";

          if (
            practica.idDiente == 20 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r205 = "green";

          if (
            practica.idDiente == 21 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r215 = "green";

          if (
            practica.idDiente == 22 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r225 = "green";

          if (
            practica.idDiente == 23 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r235 = "green";

          if (
            practica.idDiente == 24 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r245 = "green";

          if (
            practica.idDiente == 25 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r255 = "green";

          if (
            practica.idDiente == 26 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r265 = "green";

          if (
            practica.idDiente == 27 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r275 = "green";

          if (
            practica.idDiente == 28 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r285 = "green";

          if (
            practica.idDiente == 29 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r295 = "green";

          if (
            practica.idDiente == 30 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r305 = "green";

          if (
            practica.idDiente == 31 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r315 = "green";

          if (
            practica.idDiente == 32 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r325 = "green";

          if (
            practica.idDiente == 33 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r335 = "green";

          if (
            practica.idDiente == 34 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r345 = "green";

          if (
            practica.idDiente == 35 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r355 = "green";

          if (
            practica.idDiente == 36 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r365 = "green";

          if (
            practica.idDiente == 37 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r375 = "green";

          if (
            practica.idDiente == 38 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r385 = "green";
          if (
            practica.idDiente == 39 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r395 = "green";

          if (
            practica.idDiente == 40 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r405 = "green";

          if (
            practica.idDiente == 41 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r415 = "green";
          if (
            practica.idDiente == 42 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r425 = "green";

          if (
            practica.idDiente == 43 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r435 = "green";

          if (
            practica.idDiente == 44 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r445 = "green";

          if (
            practica.idDiente == 45 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r455 = "green";

          if (
            practica.idDiente == 46 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r465 = "green";

          if (
            practica.idDiente == 47 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r475 = "green";

          if (
            practica.idDiente == 48 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r485 = "green";

          if (
            practica.idDiente == 49 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r495 = "green";

          if (
            practica.idDiente == 50 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r505 = "green";

          if (
            practica.idDiente == 51 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r515 = "green";

          if (
            practica.idDiente == 52 &&
            practica.idCara == 5 &&
            practica.estado == 3
          )
            this.restauracion.r525 = "green";
        }
      });
    },
    buscarIndicaciones() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetIndicaciones?", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaIndicaciones = response.data;
          else self.listaIndicaciones = [];
        })
        .catch(function (error) {});
    },
    buscarRecetas() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetRecetas?", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaRecetas = response.data;
          else self.listaRecetas = [];
        })
        .catch(function (error) {});
    },
    buscarPedidos() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetPedidos?", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaPedidos = response.data;
          else self.listaPedidos = [];
        })
        .catch(function (error) {});
    },
    buscarInformes() {
      const filtrosData = {
        idPaciente: this.paciente.idPaciente,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetInformes?", {
          params: {
            idPaciente: filtrosData.idPaciente,
          },
        })
        .then((response) => {
          if (response.data != null && response.data != "")
            self.listaInformes = response.data;
          else self.listaInformes = [];
        })
        .catch(function (error) {});
    },
    vaciarModels() {
      this.paciente.apellidos = "";
      this.paciente.nombres = "";
      this.paciente.edad = "";
      this.paciente.edadAux = "";
      this.paciente.fechaNac = "";
      this.paciente.sexo = "";
      this.paciente.idPaciente = null;
      this.paciente.antecedentes = "";
      this.paciente.mail = "";
      this.paciente.foto = "";
      this.datoAfiliatorio.obraSocial = "";
      this.datoAfiliatorio.nroAfiliado = "";
      //this.obraSocial = this.obrasSociales[0].idObraSocial;
    },
    actualizarAntecedentes(item) {
      this.paciente.antecedentes = item.antecedentes;
      this.paciente.antecedentesFamiliares = item.antecedentesFamiliares;
    },
    actualizarOdontograma(
      item,
      restauracion,
      corona,
      ausencia,
      extraccion,
      ortodoncia,
      fija,
      movil,
      implante,
      tratamiento
    ) {
      this.listaOdontograma = item;
      this.restauracion = restauracion;
      this.corona = corona;
      this.ausencia = ausencia;
      this.extraccion = extraccion;
      this.ortodoncia = ortodoncia;
      this.fija = fija;
      this.movil = movil;
      this.implante = implante;
      this.tratamiento = tratamiento;
    },
  },
  components: {
    BuscarPacienteTurno,
    Antecedentes,
    Evoluciones,
    SignosVitales,
    Recetas,
    Indicaciones,
    Pedidos,
    Informes,
    Odontograma,
    NuevoPaciente,
    Diagnosticos,
    VerFoto,
    GestionVacunaciones,
    NotasEnfermeria,
    CaracteristicasPaciente,
    EditarPaciente,
    Alergias,
  },
  destroyed() {
    this.$store.dispatch("setDni", 0);
    this.$store.dispatch("setRutaSalidaHC", 0);
    this.$store.dispatch("setVideollamada", false);
    this.$store.dispatch("setLinkVideollamada", "");
  },
};
</script>
<style lang="scss">
.v-card__text {
  h4 {
    color: #fff;
    font-size: 1rem;
    line-height: 1.5;
  }
}
</style>
