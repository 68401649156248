<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Prespuesto</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Editar Presupuesto</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaPresupuesto"
                :value="fechaPresupuesto"
                label="Fecha Presupuesto"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-text-field
                v-model="fechaVigencia"
                :value="fechaVigencia"
                label="Fecha Vigencia Hasta"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="4" md="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
                ref="profsBuscar"
              ></v-select>
            </v-col>
            <v-col cols="9" sm="4" md="4">
              <v-text-field
                v-model="paciente"
                prepend-icon="mdi-account"
                :rules="requiredRules"
                label="Paciente"
                :disabled="true"
                dense
                maxlength="150"
                hint="Puede buscar un paciente existente apretando la lupa"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn color="primary" @click="buscarPaciente">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="4" md="6">
            <v-chip class="ma-0" label color="transparent" text-color="black">
              <h3>Detalle del presupuesto</h3>
            </v-chip>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="9" sm="4" md="8">
            <v-text-field
              v-model="texto"
              prepend-icon="mdi-account"
              label="Descripción"
              dense
              maxlength="150"
            ></v-text-field>
          </v-col>
          <v-col cols="9" sm="4" md="2">
            <v-text-field
              v-model="valor"
              prepend-icon="mdi-currency-usd"
              label="Importe"
              dense
              @keypress="onlyNumber"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="4" md="2" class="add-detail-column">
            <v-btn color="warning" :disabled="isDetailDisabled" @click.stop="agregarDetalle">Agregar detalle</v-btn>
          </v-col>
        </v-row>

        <v-data-table
          :headers="headers"
          :items="this.listaDetalle"
          class="elevation-1"
          :hide-default-footer="true"
          locale="es-ar"
          item-key="idCrobro"
          :items-per-page="10000"
          ref="myTable"
          :disable-sort="true"
        >
          <template v-slot:item.actions="{ item }">
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium color="purple" class="mr-3" v-on="on" v-bind="attrs" @click.stop="editarDetalle(item)"
                  >mdi-pencil</v-icon
                >
              </template>
              <span>Editar Detalle</span>
            </v-tooltip>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-icon medium v-on="on" class="mr-3" color="red" v-bind="attrs" @click="borrarDetalle(item)"
                  >mdi-delete</v-icon
                >
              </template>
              <span>Eliminar Detalle</span>
            </v-tooltip>
          </template>
        </v-data-table>
        <v-row>
          <v-col cols="12" class="add-detail-column">
            <h2>Total: {{ total }}</h2></v-col
          >
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid || listaDetalle.length < 1" @click.stop="guardarPresupuesto"
            >Guardar</v-btn
          >
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card-text>
      <BuscarPacienteTurno
        :visible="showBuscarPaciente"
        @close="showBuscarPaciente = false"
        @seleccionarPaciente="seleccionarPaciente($event)"
      ></BuscarPacienteTurno>
      <ModificarDetalle
        :visible="showModificarDetalle"
        :detalle="editedItem"
        @confirmaModificacionDetalle="confirmaModificacionDetalle($event)"
        @close="showModificarDetalle = false"
      />
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.add-detail-column {
  display: flex;
  justify-content: end;
}
</style>
<script>
import axios from "../../axios-auth.js";
import BuscarPacienteTurno from "../Turnos/BuscarPacienteTurno.vue";
import CobrosMixin from "../Caja/CobrosMixin.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import ModificarDetalle from "./EditarDetallePresupuesto.vue";
import Vue from "vue";

export default {
  props: {
    visible: {
      required: false,
    },
    idPresupuesto: {
      required: true,
    },
  },
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    totalRulesCobros: [(v) => !!v || "Dato obligatorio", (v) => v >= 0 || "El total debe ser mayor o igual a 0"],
    totalRulesPagos: [(v) => !!v || "Dato obligatorio", (v) => v > 0 || "El total debe ser mayor a 0"],
    prof: "",
    profsBuscar: [],
    fechaPresupuesto: null,
    fechaVigencia: null,
    paciente: null,
    idPaciente: null,
    texto: null,
    valor: null,
    total: parseFloat(0).toFixed(2),
    listaDetalle: [],
    headers: [
      { text: "Descripcion", value: "texto" },
      { text: "Valor", value: "valor" },
      { text: "Acciones", value: "actions" },
    ],
    editedItem: {},
    editedIndex: -1,
    showBuscarPaciente: false,
    institucion: null,
    showModificarDetalle: false,
  }),
  mixins: [CobrosMixin],
  watch: {
    listaDetalle: {
      deep: true,
      handler(val) {
        this.total = parseFloat(val.reduce((acc, detalle) => acc + +detalle.valor, 0)).toFixed(2);
      },
    },
  },
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
    isDetailDisabled() {
      return !this.texto || !this.valor;
    },
  },
  methods: {
    async confirmaModificacionDetalle(item) {
      const itemModifica = this.listaDetalle.find(
        (detalle) => detalle.idPresupuestoDetalle === item.idPresupuestoDetalle
      );
      itemModifica.texto = item.texto;
      itemModifica.valor = item.valor;
      this.showModificarDetalle = false;
    },
    editarDetalle(item) {
      this.editedIndex = this.listaDetalle.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showModificarDetalle = true;
    },
    agregarDetalle() {
      if (isNaN(this.valor)) {
        this.showAlert({
          icon: "error",
          title: "El importe no tiene un formato válido.",
          vm: this,
        });
        return;
      }
      this.listaDetalle.push({
        idPresupuestoDetalle: +Math.random().toString().substring(2, 6),
        texto: this.texto,
        valor: parseFloat(this.valor).toFixed(2),
      });
      this.texto = "";
      this.valor = "";
    },
    seleccionarPaciente(item) {
      (this.idPaciente = item.idpaciente), (this.paciente = item.apellidos + ", " + item.nombres);
      this.showBuscarPaciente = false;
    },
    buscarPaciente() {
      this.showBuscarPaciente = true;
    },
    guardarPresupuesto() {
      const title = "Modificar Presupuesto";
      const text = "¿Desea modificar el presupuesto?";
      this.$swal({
        title: title,
        text: text,
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          let isValidDate = this.validarFecha(this.fechaPresupuesto);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha del presupuesto no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          isValidDate = this.validarFecha(this.fechaVigencia);
          if (!isValidDate) {
            this.showAlert({
              icon: "error",
              title: "La fecha de vigencia no tiene un formato válido.",
              vm: this,
            });
            return;
          }
          if (this.fechaPresupuesto > this.fechaVigencia) {
            this.showAlert({
              icon: "error",
              title: "La fecha de vigencia no puede ser menor que la fecha del presupuesto",
              vm: this,
            });
            return;
          }
          const datosPresupuesto = {
            fechaPresupuesto: this.fechaPresupuesto,
            fechaVigencia: this.fechaVigencia,
            idPaciente: this.idPaciente,
            total: parseFloat(this.total).toFixed(2),
            idProfesional: this.prof,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            detalle: this.listaDetalle,
            idPresupuesto: this.idPresupuesto,
          };
          let self = this;
          axios
            .post("/Caja/ModificarPresupuesto", datosPresupuesto)
            .then((response) => {
              self.$refs.form.resetValidation();
              document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
              const alertTitle = "Presupuesto modificado";
              self.showAlert({
                icon: "success",
                title: alertTitle,
                vm: self,
              });
              self
                .$swal({
                  title: "Imprimir Prespuesto",
                  text: "¿Desea imprimir el presupuesto?",
                  icon: "success",
                  type: "success",
                  showCancelButton: true,
                  background: "#ececec",
                  confirmButtonText: "Si",
                  cancelButtonText: "No",
                  showCloseButton: true,
                  showLoaderOnConfirm: true,
                })
                .then((result) => {
                  if (result.value) {
                    self.imprimirPresupuesto();
                    self.vaciarModels();
                    self.$emit("presupuestoModificado");
                  } else {
                    self.vaciarModels();
                    self.$emit("presupuestoModificado");
                  }
                });
            })
            .catch((error) => {
              const alertTitleError = self.tipoMovimiento === "El presupuesto no pudo ser modificado";
              self.showAlert({
                icon: "error",
                title: alertTitleError,
                vm: this,
              });
            });
        }
      });
    },
    validarFecha(fecha) {
      if (fecha.toString().length > 10) return false;
      else return true;
    },
    borrarDetalle(item) {
      this.listaDetalle = this.listaDetalle.filter(
        (detalle) => detalle.idPresupuestoDetalle !== item.idPresupuestoDetalle
      );
    },
    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Turnos/BuscarProfesionalesAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.profsBuscar = response.data.profesionales;
        });
      axios
        .get("/Usuario/BuscarDatosInstitucion", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.institucion = response.data;
        });
      axios
        .get("/Caja/BuscarPresupuestoPorId", {
          params: {
            idPresupuesto: this.idPresupuesto,
          },
        })
        .then((response) => {
          self.fechaPresupuesto = response.data.fechaPresupuesto.substring(0, 10);
          self.fechaVigencia = response.data.fechaVigencia.substring(0, 10);
          self.prof = response.data.idProfesional;
          self.idPaciente = response.data.idPaciente;
          self.listaDetalle = response.data.detalle;
          self.paciente = response.data.nombrePaciente;
        });
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    onlyNumber($event) {
      let keyCode = $event.keyCode ? $event.keyCode : $event.which;
      if ((keyCode < 48 || keyCode > 57) && keyCode != 46 && keyCode != 45) {
        // 46 is dot
        $event.preventDefault();
      }
    },
    vaciarModels() {
      this.paciente = "";
      this.idPaciente = null;
      this.fechaVigencia = null;
      this.listaDetalle = [];
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    imprimirPresupuesto() {
      var doc = new jsPDF();
      doc.setProperties({
        title: "Presupuesto",
      });

      let rows = [];
      let columnHeader = ["Descripción", "Importe"];
      let importeTotal = 0;
      this.listaDetalle.forEach((element) => {
        const valor =
          element.valor >= 0
            ? `$${parseFloat(element.valor).toFixed(2)}`
            : `-$${parseFloat(Math.abs(element.valor)).toFixed(2)}`;
        var temp = [element.texto, valor];
        rows.push(temp);
        importeTotal += +element.valor;
      });

      let nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion.toString().trim();

      var nombreProf = this.$refs.profsBuscar.selectedItems[0].nombreProfesional;
      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      if (this.prof === 920) {
        doc.text("Dirección: Catamarca 2076 4 F  ", 13, 15);
        doc.text("Tel: 236881598", 13, 20);
        doc.text("Mail: maguilo2076@hotmail.com", 13, 25);
      } else {
        doc.text("Dirección: " + this.institucion.direccion.toString().replace(", Argentina", ""), 13, 15);

        if (
          this.institucion.telefono != null &&
          this.institucion.telefono != "" &&
          this.institucion.celular != null &&
          this.institucion.celular != ""
        )
          doc.text("Tel / Cel: " + this.institucion.telefono + " - " + this.institucion.celular, 13, 20);
        if (
          this.institucion.telefono != null &&
          this.institucion.telefono != "" &&
          (this.institucion.celular == null || this.institucion.celular == "")
        )
          doc.text("Tel: " + this.institucion.telefono, 13, 20);
        if (
          (this.institucion.telefono == null || this.institucion.telefono == "") &&
          this.institucion.celular != null &&
          this.institucion.celular != ""
        )
          doc.text("Cel: " + this.institucion.celular, 13, 20);
        if (this.institucion.mail != "" && this.institucion.mail != null)
          doc.text("Mail: " + this.institucion.mail, 13, 25);
      }
      doc.setFontSize(16);
      doc.text("Presupuesto", doc.internal.pageSize.getWidth() / 2, 45, {
        align: "center",
      });
      doc.line(88, 46, 122, 46);
      doc.setFontSize(12);
      doc.text("Profesional: " + nombreProf, 13, 55);
      doc.text("Paciente: " + this.paciente, 13, 62);
      doc.text("Fecha Presupuesto: " + this.formatDate(this.fechaPresupuesto), 13, 69);
      doc.text("Fecha Vigencia Hasta: " + this.formatDate(this.fechaVigencia), 13, 76);

      doc.setFontSize(14);
      doc.text("Detalle del Presupuesto", doc.internal.pageSize.getWidth() / 2, 93, {
        align: "center",
      });
      doc.line(78, 94, 132, 94);

      doc.autoTable(columnHeader, rows, {
        columnStyles: { 1: { halign: "right" } },
        headStyles: { 1: { halign: "right" } },
        startY: 102,
        didParseCell: function (table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
            if (table.column.raw === "Importe") table.cell.styles.halign = "right";
          }
        },
      });

      let finalY = doc.lastAutoTable.finalY;

      if (finalY > 250) {
        doc.addPage();
        finalY = 20;
      }
      const total =
        importeTotal >= 0
          ? "Importe Total: $" + parseFloat(importeTotal).toFixed(2).toString().replace(".", ",")
          : "Importe Total: -$" + parseFloat(Math.abs(importeTotal)).toFixed(2).toString().replace(".", ",");
      doc.text(total, 195, finalY + 16, {
        align: "right",
      });

      window.open(doc.output("bloburl"), "_blank");
    },
  },
  components: {
    BuscarPacienteTurno,
    ModificarDetalle,
  },
};
</script>
