<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Listado de Evoluciones</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Evoluciones</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="2" sm="4">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaDesde"
                :value="fechaDesde"
                label="Fecha Desde"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
            <v-col cols="12" sm="6" md="6">
              <v-text-field
                v-model="fechaHasta"
                :value="fechaHasta"
                :rules="requiredRules"
                label="Fecha Hasta"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarEvoluciones(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaEvoluciones"
        class="elevation-1 row-pointer"
        locale="es-ar"
        item-key="idEvolucion"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
      </v-data-table>
      <v-row class="mt-4">
        <v-spacer></v-spacer>
        <v-text-field
          v-model="cantEvoluciones"
          label="Cant. Evoluciones"
          readonly
          class="shrink mr-6"
          style="width: 100px"
        >
        </v-text-field>
      </v-row>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          color="warning"
          :disabled="listaEvoluciones.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    paciente: null,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    prof: "",
    profsBuscar: [],
    listaEvoluciones: [],
    headers: [
      { text: "Dia", value: "diaSemana" },
      { text: "Fecha", value: "fechaString" },
      { text: "Dni", value: "dniPaciente" },
      { text: "Paciente", value: "nombrePaciente" },
      { text: "Profesional", value: "nombreProfesional" },
    ],
    fechaDesde: null,
    fechaHasta: null,
    cantEvoluciones: 0,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },
    buscarEvoluciones(state) {
      const filtrosData = {
        fechaDesde: this.fechaDesde,
        fechaHasta: this.fechaHasta,
        idProfesional: this.prof,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/BuscarEvoluciones?", {
          params: {
            fechaDesde: filtrosData.fechaDesde,
            fechaHasta: filtrosData.fechaHasta,
            idProfesional: filtrosData.idProfesional,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "") {
            self.listaEvoluciones = response.data;
            self.cantEvoluciones = self.listaEvoluciones.length;
          } else {
            self.listaEvoluciones = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay evoluciones que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function(error) {});
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
    imprimir() {
      let source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = ["Dia", "Fecha", "Dni", "Paciente", "Profesional"];
      let cantEvoluciones = this.listaEvoluciones.length;
      source.items.forEach((element) => {
        var temp = [
          element.diaSemana,
          element.fechaString,
          element.dniPaciente,
          element.nombrePaciente,
          element.nombreProfesional,
        ];
        rows.push(temp);
      });

      var doc = new jsPDF();
      doc.setProperties({
        title: "Listado de Evoluciones",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Fecha Desde: " +
          this.formatDate(this.fechaDesde) +
          "  -  " +
          "Fecha Hasta: " +
          this.formatDate(this.fechaHasta),
        13,
        16
      );

      doc.setFontSize(16);
      doc.text(
        "Listado de Evoluciones",
        doc.internal.pageSize.getWidth() / 2,
        33,
        {
          align: "center",
        }
      );
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function(table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });
      let finalY = doc.lastAutoTable.finalY;
      doc.setFontSize(12);
      doc.text("Cant. Evoluciones: " + cantEvoluciones, 245, finalY + 8);
      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
        if (self.profsBuscar.length > 1)
          self.profsBuscar.unshift({
            idProfesional: 0,
            nombreProfesional: "TODOS",
          });

        self.prof = self.profsBuscar[0].idProfesional;
      });

    this.fechaDesde = this.parseDate(
      this.formatDate(this.toISOLocal(new Date()))
    );
    this.fechaHasta = this.fechaDesde;
  },
};
</script>
