<template>
  <div class="ma-0 pa-0">
    <div class="left">
      <v-card color="blue" dark tile>
        <v-toolbar dark color="primary">
          <v-toolbar-title>Panel de Enfermería</v-toolbar-title>
        </v-toolbar>
        <v-card-title class="mx-0 px-0">
          <v-icon large left>mdi-account</v-icon>
          <span class="title font-weight-light">Datos Paciente</span>
        </v-card-title>
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="4" md="2">
              <v-text-field
                v-model="paciente.dni"
                label="D.N.I"
                dense
                @keypress="onlyNumber"
                @keypress.enter="$event.target.blur()"
                @blur="buscarPacienteDni(1)"
                maxlength="9"
              ></v-text-field>
            </v-col>
            <v-col cols="2" sm="2" md="2">
              <v-btn color="purple" outlined @click="buscarPaciente">
                <v-icon>mdi-magnify</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <h4>Paciente: {{ paciente.apellidos }} {{ paciente.nombres }}</h4>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <h4>Sexo: {{ paciente.sexo }}</h4>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <h4>Fecha Nac.: {{ paciente.fechaNac }}</h4>
            </v-col>
            <v-col cols="12" md="4" sm="4">
              <h4>Edad: {{ paciente.edad }}</h4>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="4" sm="4">
              <h4>Observaciones: {{ paciente.observaciones }}</h4>
            </v-col>
          </v-row>
        </v-card-text>
        <BuscarPacienteTurno
          :visible="showBuscarPaciente"
          @close="showBuscarPaciente = false"
          @seleccionarPaciente="seleccionarPaciente($event)"
        ></BuscarPacienteTurno>
      </v-card>
      <v-alert
        color="orange"
        dark
        v-if="
          paciente.idPaciente == 0 ||
            paciente.idPaciente == '' ||
            paciente.idPaciente == null
        "
        >Seleccione un paciente ingresando su dni y apretando enter (desde el
        celular toque fuera del cuadro de texto) o buscándolo por apellido y
        nombre haciendo click en la lupa</v-alert
      >
      <v-tabs
        background-color="indigo"
        dark
        v-if="
          paciente.idPaciente != 0 &&
            paciente.idPaciente != '' &&
            paciente.idPaciente != null
        "
      >
        <v-tab>Vacunaciones</v-tab>
        <v-tab>Signos Vitales</v-tab>
        <v-tab>Notas Enfermería</v-tab>
        <v-tab-item>
          <GestionVacunaciones
            v-if="
              paciente.idPaciente != 0 &&
                paciente.idPaciente != '' &&
                paciente.idPaciente != null
            "
            :idPaciente="paciente.idPaciente"
            :lista="listaVacunaciones"
            :paciente="this.paciente"
            :enfermerosHabilitados="enfermerosHabilitados"
            :institucion="institucion"
            @actualizarVacunaciones="buscarVacunaciones()"
          />
        </v-tab-item>
        <v-tab-item>
          <SignosVitales
            :idPaciente="paciente.idPaciente"
            :lista="listaSignosVitales"
            :paciente="this.paciente"
            :enfermeros="enfermerosHabilitados"
            :institucion="institucion"
            origen="enfermeria"
            @actualizarSignosVitales="buscarSignosVitales()"
          />
        </v-tab-item>
        <v-tab-item>
          <NotasEnfermeria
            :idPaciente="paciente.idPaciente"
            :lista="listaNotasEnfermeria"
            :paciente="this.paciente"
            :enfermerosHabilitados="enfermerosHabilitados"
            :institucion="institucion"
            origen="enfermeria"
            @actualizarNotasEnfermeria="buscarNotasEnfermeria()"
          />
        </v-tab-item>
      </v-tabs>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
    </div>
  </div>
</template>
<style scoped>
  ::v-deep .v-data-table__empty-wrapper {
    display: none;
  }
</style>
<script>
  import axios from "../../axios-auth.js";
  import router from "../../router/index.js";
  import BuscarPacienteTurno from "../Turnos/BuscarPacienteTurno.vue";
  import GestionVacunaciones from "./GestionVacunaciones.vue";
  import SignosVitales from "../HistClin/SignosVitales.vue";
  import NotasEnfermeria from "./NotasEnfermeria.vue";

  export default {
    data: (vm) => ({
      paciente: {
        dni: null,
        apellidos: "",
        nombres: "",
        fechaNac: "",
        sexo: "",
        edad: "",
        edadAux: "",
        idPaciente: null,
        antecedentes: "",
        antecedentesFamiliares: "",
        mail: "",
        observaciones: "",
      },
      showNuevoPaciente: false,
      enfermerosHabilitados: [],
      showBuscarPaciente: false,
      institucion: {},
      listaVacunaciones: [],
      listaSignosVitales: [],
      listaNotasEnfermeria: [],
    }),
    mounted() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      axios
        .get("/Enfermeria/BuscarEnfermerosAsociados?", {
          params: {
            usuario: profData.usuario,
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.enfermerosHabilitados = response.data;
        });

      axios
        .get("/Usuario/BuscarDatosInstitucion", {
          params: {
            idInstitucion: profData.idInstitucion,
          },
        })
        .then((response) => {
          self.institucion = response.data;
        });
    },
    methods: {
      cierraNuevoPacienteSinGuardar() {
        this.showNuevoPaciente = false;
      },
      cierraNuevoPaciente(item) {
        this.seleccionarPaciente(item);
        this.showNuevoPaciente = false;
      },
      nuevoPaciente() {
        this.$store.dispatch("setRutaSalidaNuevoPaciente", 1);
        this.showNuevoPaciente = true;
      },
      salir() {
        router.push({ path: "/Home" });
      },
      seleccionarPaciente(item) {
        this.paciente.dni = item.nrodocumento;
        this.paciente.mail = item.email;
        this.paciente.celular = item.celular;
        this.paciente.apellidos = item.apellidos;
        this.paciente.idPaciente = item.idpaciente;
        this.paciente.nombres = item.nombres;
        this.paciente.edadAux = new Date(item.fechanacimiento);
        this.paciente.observaciones = item.observaciones;
        if (
          item.fechanacimiento == "0001-01-01T00:00:00" ||
          item.fechanacimiento == null
        )
          this.paciente.fechaNac = "";
        else
          this.paciente.fechaNac =
            item.fechanacimiento.substring(8, 10) +
            "/" +
            item.fechanacimiento.substring(5, 7) +
            "/" +
            item.fechanacimiento.substring(0, 4);
        this.paciente.sexo = item.sexo;
        // this.paciente.antecedentes = item.antecedentes;

        if (this.paciente.fechaNac != "") {
          let hoy = new Date();
          let age = hoy.getFullYear() - this.paciente.edadAux.getFullYear();
          let diferenciaMeses =
            hoy.getMonth() - this.paciente.edadAux.getMonth();
          if (
            diferenciaMeses < 0 ||
            (diferenciaMeses === 0 &&
              hoy.getDate() < this.paciente.edadAux.getDate())
          ) {
            age--;
          }
          if (age > 0) this.paciente.edad = age + " años";
          else {
            if (diferenciaMeses > 0)
              this.paciente.edad = diferenciaMeses + " meses";
            else {
              let diferenciaDias =
                hoy.getTime() - this.paciente.edadAux.getTime();
              this.paciente.edad =
                Math.trunc(diferenciaDias / (1000 * 60 * 60 * 24)) + " días";
            }
          }
        } else this.paciente.edad = "";
        this.buscarVacunaciones();
        this.buscarSignosVitales();
        this.buscarNotasEnfermeria();
        this.showBuscarPaciente = false;
      },
      buscarPaciente() {
        this.showBuscarPaciente = true;
      },
      buscarVacunaciones() {
        const filtrosData = {
          idPaciente: this.paciente.idPaciente,
        };

        let self = this;
        axios
          .get("/Enfermeria/GetVacunaciones?", {
            params: {
              idPaciente: filtrosData.idPaciente,
            },
          })
          .then((response) => {
            if (response.data != null && response.data != "")
              self.listaVacunaciones = response.data;
            else self.listaVacunaciones = [];
          })
          .catch(function(error) {});
      },
      buscarSignosVitales() {
        const filtrosData = {
          idPaciente: this.paciente.idPaciente,
        };

        let self = this;
        axios
          .get("/HistoriaClinica/GetSignosVitales?", {
            params: {
              idPaciente: filtrosData.idPaciente,
            },
          })
          .then((response) => {
            if (response.data != null && response.data != "")
              self.listaSignosVitales = response.data;
            else self.listaSignosVitales = [];
          })
          .catch(function(error) {});
      },
      buscarNotasEnfermeria() {
        const filtrosData = {
          idPaciente: this.paciente.idPaciente,
        };

        let self = this;
        axios
          .get("/Enfermeria/GetNotasEnfermeria?", {
            params: {
              idPaciente: filtrosData.idPaciente,
            },
          })
          .then((response) => {
            if (response.data != null && response.data != "")
              self.listaNotasEnfermeria = response.data;
            else self.listaNotasEnfermeria = [];
          })
          .catch(function(error) {});
      },
      buscarPacienteDni(state) {
        var pacientesData = {};

        if (this.$store.state.dniPaciente == 0) {
          pacientesData = {
            dni: this.paciente.dni,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          };
        } else {
          pacientesData = {
            dni: this.$store.state.dniPaciente,
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          };
        }
        let self = this;
        axios
          .get("/Paciente/GetPacienteDni?", {
            params: {
              dni: pacientesData.dni,
              idInstitucion: pacientesData.idInstitucion,
            },
          })
          .then(function(response) {
            if (response.data != null && response.data != "") {
              self.paciente.dni = response.data.nrodocumento;
              self.paciente.apellidos = response.data.apellidos;
              self.paciente.nombres = response.data.nombres;
              self.paciente.idPaciente = response.data.idpaciente;
              if (response.data.fechanacimiento == "0001-01-01T00:00:00")
                self.paciente.fechaNac = "";
              else
                self.paciente.fechaNac =
                  response.data.fechanacimiento.substring(8, 10) +
                  "/" +
                  response.data.fechanacimiento.substring(5, 7) +
                  "/" +
                  response.data.fechanacimiento.substring(0, 4);
              self.paciente.sexo = response.data.sexo;
              self.paciente.mail = response.data.email;
              self.paciente.celular = response.data.celular;
              self.paciente.edadAux = new Date(response.data.fechanacimiento);
              // self.paciente.antecedentes = response.data.antecedentes;
              if (self.paciente.fechaNac != "") {
                let hoy = new Date();
                let age =
                  hoy.getFullYear() - self.paciente.edadAux.getFullYear();
                let diferenciaMeses =
                  hoy.getMonth() - self.paciente.edadAux.getMonth();
                if (
                  diferenciaMeses < 0 ||
                  (diferenciaMeses === 0 &&
                    hoy.getDate() < self.paciente.edadAux.getDate())
                ) {
                  age--;
                }
                if (age > 0) self.paciente.edad = age + " años";
                else {
                  if (diferenciaMeses > 0)
                    self.paciente.edad = diferenciaMeses + " meses";
                  else {
                    let diferenciaDias =
                      hoy.getTime() - self.paciente.edadAux.getTime();
                    self.paciente.edad =
                      Math.trunc(diferenciaDias / (1000 * 60 * 60 * 24)) +
                      " días";
                  }
                }
              } else self.paciente.edad = "";
              self.paciente.observaciones = response.data.observaciones;
              self.buscarVacunaciones();
              self.buscarSignosVitales();
              self.buscarNotasEnfermeria();
            } else {
              self.vaciarModels();
              if (state == 1) {
                self.showAlert({
                  icon: "error",
                  title:
                    "No existe ningún paciente con el número de documento ingresado.",
                  vm: self,
                });
              }
            }
          })
          .catch(function(error) {});
      },
      showAlert(alertInfo) {
        alertInfo.vm.$swal.fire({
          icon: alertInfo.icon,
          title: alertInfo.title,
          showConfirmButton: false,
          background: "#ececec",
          timer: 2000,
        });
      },
      onlyNumber($event) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        if (keyCode < 48 || keyCode > 57) {
          $event.preventDefault();
        }
      },
      vaciarModels() {
        this.paciente.apellidos = "";
        this.paciente.nombres = "";
        this.paciente.edad = "";
        this.paciente.edadAux = "";
        this.paciente.fechaNac = "";
        this.paciente.sexo = "";
        this.paciente.idPaciente = null;
        this.paciente.antecedentes = "";
        this.paciente.mail = "";
      },
      actualizarVacunaciones(item) {
        this.paciente.antecedentes = item.antecedentes;
        this.paciente.antecedentesFamiliares = item.antecedentesFamiliares;
      },
    },
    components: {
      BuscarPacienteTurno,
      GestionVacunaciones,
      SignosVitales,
      NotasEnfermeria,
    },
  };
</script>
