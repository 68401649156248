<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Vacunas</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Vacunas</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12" sm="12">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-account"
                label="Nombres"
                maxlength="80"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid"
          color="primary"
          @click.stop="buscarVacunas(1)"
        >
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaVacunas"
        class="elevation-1"
        locale="es-ar"
        item-key="idVacuna"
        :items-per-page="15"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaVacuna">Nueva Vacuna</v-btn>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevaVacuna
        :visible="showNuevaVacuna"
        @close="cierraNuevaVacuna()"
        @vacunaRegistrada="vacunaRegistrada()"
      ></NuevaVacuna>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import NuevaVacuna from "./NuevaVacuna.vue";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    nombre: null,
    listaVacunas: [],
    headers: [{ text: "Nombre", value: "nombre" }],
    showNuevaVacuna: false,
  }),
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    cierraNuevaVacuna() {
      this.showNuevaVacuna = false;
    },
    vacunaRegistrada() {
      this.showNuevaVacuna = false;
      this.buscarVacunas(0);
    },
    nuevaVacuna() {
      this.showNuevaVacuna = true;
    },
    buscarVacunas(state) {
      const filtrosData = {
        nombre: this.nombre,
      };

      let self = this;
      axios
        .get("/Enfermeria/GetVacunas?", {
          params: {
            nombre: filtrosData.nombre,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaVacunas = response.data;
          else {
            self.listaVacunas = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay obras vacunas que coincidan con el criterio de búsqueda.",
                vm: self,
              });
            }
          }
        })
        .catch(function(error) {});
    },
  },
  created() {
    this.buscarVacunas(0);
  },
  components: {
    NuevaVacuna,
  },
};
</script>
