<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    :fullscreen="fullscreen"
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          Buscar Medicamento
        </v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-text-field
                v-model="nombre"
                prepend-icon="mdi-account"
                label="Nombre"
                hint="Ingrese al menos 3 caracteres"
                maxlength="50"
                dense
                :rules="nombreRules"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn
          :disabled="!valid || loading"
          color="primary"
          @click.stop="buscarMedicamento"
          :loading="loading"
          >Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="headers"
        :items="this.listaMedicamentos"
        class="elevation-1"
        locale="es-ar"
        item-key="nroregistro"
        :disable-sort="true"
        :items-per-page="15"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                class="mr-3"
                color="primary"
                v-on="on"
                v-bind="attrs"
                @click="seleccionarMedicamento(item)"
                >mdi-checkbox-marked-circle</v-icon
              >
            </template>
            <span>Seleccionar Medicamento</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-card>
  </v-dialog>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}

.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>

<script>
import axios from "../../axios-auth.js";
export default {
  data: (vm) => ({
    valid: false,
    fullscreen: true,
    maxWidth: "100%",
    lazy: false,
    nombreRules: [
      (v) => (v && v.length >= 3) || "Ingrese al menos 3 caracteres",
    ],
    nombre: null,
    headers: [
      {
        text: "Nombre",
        align: "start",
        filterable: false,
        value: "nombre",
      },
      { text: "Presentación", value: "presentacion" },

      { text: "Seleccionar", value: "actions", sortable: false },
    ],
    listaMedicamentos: [],
    loading: false,
  }),

  computed: {
    show: {
      get() {
        if (this.visible) {
          if (this.$store.state.videollamada == true) {
            this.fullscreen = false;
            this.maxWidth = "68%";
          } else {
            this.fullscreen = true;
            this.maxWidth = "100%";
          }
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          this.$emit("close");
        }
      },
    },
  },
  props: ["visible"],
  methods: {
    buscarMedicamento() {
      const medicamentosData = {
        nombre: this.nombre,
      };
      this.loading = true;
      let self = this;
      axios
        .get("/HistoriaClinica/GetMedicamentosNombre?", {
          params: {
            nombre: medicamentosData.nombre,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaMedicamentos = response.data;
          else self.listaMedicamentos = [];

          self.loading = false;
        })
        .catch(function(error) {
          self.loading = false;
        });
    },
    vaciarModels() {
      this.listaMedicamentos = [];
      this.nombre = "";
    },
    seleccionarMedicamento(item) {
      this.vaciarModels();
      this.$refs.form.resetValidation();
      this.$emit("seleccionarMedicamento", item);
    },
  },
};
</script>
