<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Gestión de Topes</v-toolbar-title>
      </v-toolbar>

      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <v-chip label color="transparent" text-color="black">
                <h3>Búsqueda de Topes Configurados</h3>
              </v-chip>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="3" sm="4">
              <v-checkbox
                v-model="soloVigentes"
                label="Buscar solo topes vigentes"
                color="primary"
                dense
                @change="buscarTopes(0)"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6" sm="6">
              <v-select
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                dense
              ></v-select>
            </v-col>
            <v-col cols="12" md="6" sm="6">
              <v-select
                :items="osBuscar"
                item-text="nombre"
                item-value="idObraSocial"
                menu-props="auto"
                :label="`${resolveLabel}`"
                hide-details
                prepend-icon="mdi-account"
                v-model="os"
                dense
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-form>
      <v-card-actions class="mt-0 pt-0">
        <v-spacer></v-spacer>
        <v-btn :disabled="!valid" color="primary" @click.stop="buscarTopes(1)">
          Buscar
          <v-icon dark right>mdi-magnify</v-icon>
        </v-btn>
      </v-card-actions>

      <v-data-table
        :headers="
          $store.state.usuario.institucion.esEmpresa ? headersEmpresa : headers
        "
        :items="this.listaTopes"
        class="elevation-1"
        locale="es-ar"
        item-key="idCMT"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página',
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarTope(item)"
                >mdi-pencil</v-icon
              >
            </template>
            <span>Editar Tope</span>
          </v-tooltip>
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarTope(item)"
                >mdi-delete</v-icon
              >
            </template>
            <span>Eliminar Tope</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevoTope">Nuevo Tope</v-btn>
        <v-btn
          color="warning"
          :disabled="listaTopes.length < 1"
          @click.stop="imprimir"
          >Imprimir</v-btn
        >
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevoTope
        :visible="showNuevoTope"
        @close="cierraNuevoTope()"
        @topeRegistrado="topeRegistrado()"
      ></NuevoTope>
      <EditarTope
        :visible="showEditarTope"
        :idCMT="editedItem.idCMT"
        @close="cierraEditarTope()"
        @topeModificado="topeModificado()"
      ></EditarTope>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
</style>
<script>
import axios from "../../../axios-auth.js";
import router from "../../../router/index.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
import NuevoTope from "../Configuraciones/NuevoTope.vue";
import EditarTope from "../Configuraciones/EditarTope.vue";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    soloVigentes: true,
    prof: "",
    profsBuscar: [],
    os: "",
    osBuscar: [],
    listaTopes: [],
    headers: [
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Obra Social", value: "nombre" },
      { text: "Tope Diario", value: "cantMax" },
      { text: "Vig. Desde", value: "fechaDesdeString" },
      { text: "Vig. Hasta", value: "fechaHastaString" },
      { text: "Acciones", value: "actions" },
    ],
    headersEmpresa: [
      { text: "Profesional", value: "nombreProfesional" },
      { text: "Sede", value: "nombre" },
      { text: "Tope Diario", value: "cantMax" },
      { text: "Vig. Desde", value: "fechaDesdeString" },
      { text: "Vig. Hasta", value: "fechaHastaString" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevoTope: false,
    showEditarTope: false,
    editedItem: {},
    editedIndex: -1,
  }),
  computed: {
    resolveLabel() {
      return this.$store.state.usuario.institucion.esEmpresa
        ? "Sede"
        : "Obra Social";
    },
  },
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarTope(item) {
      this.$swal({
        title: "Borrar Tope",
        text: "¿Desea borrar el tope?",
        icon: "success",
        background: "#ececec",
        showClass: {
          popup: "animate__animated animate__fadeInDown",
        },
        hideClass: {
          popup: "animate__animated animate__fadeOutUp",
        },
        showCancelButton: true,
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const idCMT = item.idCMT;
          let self = this;
          axios
            .get("/ObraSocial/BorrarTope?", {
              params: {
                idCMT: idCMT,
              },
            })
            .then((response) => {
              self.showAlert({
                icon: "success",
                title: "Tope borrado exitosamente",
                vm: self,
              });
              setTimeout(self.buscarTopes(0), 1700);
            })
            .catch((error) => {
              self.showAlert({
                icon: "error",
                title: "El tope no pudo ser borrado",
                vm: this,
              });
            });
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },
    cierraNuevoTope() {
      this.showNuevoTope = false;
      //this.buscarObrasSociales();
    },
    cierraEditarTope() {
      this.showEditarTope = false;
      //this.buscarObrasSociales();
    },
    topeRegistrado() {
      this.showNuevoTope = false;
      this.buscarTopes(0);
    },
    topeModificado() {
      this.showEditarTope = false;
      this.buscarTopes(0);
    },
    nuevoTope() {
      this.showNuevoTope = true;
    },
    editarTope(item) {
      this.editedIndex = this.listaTopes.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarTope = true;
    },
    buscarTopes(state) {
      const filtrosData = {
        idProfesional: this.prof,
        idObraSocial: this.os,
        soloVigentes: this.soloVigentes,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/ObraSocial/GetTopesFiltros?", {
          params: {
            idProfesional: filtrosData.idProfesional,
            idObraSocial: filtrosData.idObraSocial,
            soloVigentes: filtrosData.soloVigentes,
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function(response) {
          if (response.data != null && response.data != "")
            self.listaTopes = response.data;
          else {
            self.listaTopes = [];
            if (state == 1) {
              self.showAlert({
                icon: "error",
                title:
                  "No hay topes que coincidan con los filtros de búsqueda ingresados.",
                vm: self,
              });
            }
          }
        })
        .catch(function(error) {});
    },
    imprimir() {
      var source = this.$refs["myTable"];
      let rows = [];
      let columnHeader = this.$store.state.usuario.institucion.esEmpresa
        ? ["Profesional", "Sede", "Tope Diario", "Vig. Desde", "Vig. Hasta"]
        : [
            "Profesional",
            "Obra Social",
            "Tope Diario",
            "Vig. Desde",
            "Vig. Hasta",
          ];
      let importeTotal = 0;
      source.items.forEach((element) => {
        var temp = [
          element.nombreProfesional,
          element.nombre,
          element.cantMax,
          element.fechaDesdeString,
          element.fechaHastaString,
        ];
        rows.push(temp);
      });
      //let columnHeader = source.items.headers
      //let rows = source.items.rows
      var doc = new jsPDF();
      doc.setProperties({
        title: "Listado Topes",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);

      doc.setFontSize(16);
      doc.text("Listado de Topes", doc.internal.pageSize.getWidth() / 2, 33, {
        align: "center",
      });
      doc.autoTable(columnHeader, rows, {
        startY: 39,
        didParseCell: function(table) {
          if (table.section === "head") {
            table.cell.styles.fillColor = "#c4c5c5";
          }
        },
      });

      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
  },
  created() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
        if (self.profsBuscar.length > 1)
          self.profsBuscar.unshift({
            idProfesional: 0,
            nombreProfesional: "TODOS",
          });

        self.prof = self.profsBuscar[0].idProfesional;
      })
      .catch((error) => {});

    const osData = {
      nombre: null,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };

    axios
      .get("/ObraSocial/GetObraSocialNomDeInst?", {
        params: {
          nombre: osData.nombre,
          idInstitucion: osData.idInstitucion,
          estado: 1,
        },
      })
      .then((response) => {
        self.osBuscar = response.data;
        if (self.osBuscar.length > 1)
          self.osBuscar.unshift({ idObraSocial: 0, nombre: "TODAS" });

        self.os = self.osBuscar[0].idObraSocial;
      })
      .catch((error) => {});

    this.buscarTopes(0);
  },
  components: {
    NuevoTope,
    EditarTope,
  },
};
</script>
