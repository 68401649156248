<template>
  <v-dialog v-model="show" persistent transition="dialog-bottom-transition">
    <v-form ref="form" v-model="valid" :lazy-validation="lazy">
      <v-card>
        <v-toolbar dark color="primary">
          <v-btn icon dark @click="show = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>Nuevo Usuario</v-toolbar-title>
        </v-toolbar>
        <v-alert color="purple" dark dismissible outlined
          >Al nombre de usuario que elija se le adosará al final su número de
          institución:
          {{ this.$store.state.usuario.institucion.idInstitucion }}</v-alert
        >
        <v-card-text>
          <v-row dense>
            <v-col cols="9" sm="6" md="7">
              <v-text-field
                v-model="usuario"
                prepend-icon="mdi-account"
                label="Usuario"
                dense
                required
                :rules="requiredRules"
                maxlength="50"
              ></v-text-field>
            </v-col>

            <v-col cols="9" sm="6" md="7">
              <v-text-field
                v-model="passNuevo"
                prepend-icon="mdi-lock"
                @blur="$v.passNuevo.$touch()"
                :rules="requiredRules"
                label="Contraseña Nueva"
                type="Password"
                required
                maxlength="30"
              ></v-text-field>
            </v-col>
            <v-col cols="9" sm="6" md="7">
              <v-text-field
                v-model="passNuevoRepetir"
                prepend-icon="mdi-lock"
                @blur="$v.passNuevoRepetir.$touch()"
                label="Repetir Contraseña Nueva"
                :rules="requiredRules"
                type="Password"
                required
                maxlength="30"
              ></v-text-field>
            </v-col>
            <v-col cols="9" sm="6" md="7">
              <v-switch dense v-model="estadoUsuario" label="Activo"></v-switch>
            </v-col>
            <v-col cols="12" class="pa-0">
              <ul>
                <li
                  style="color:red; font-size:13px;margin-bottom:4px"
                  v-show="!$v.passNuevoRepetir.sameAs"
                >
                  Las contraseñas no coinciden
                </li>
                <li
                  style="color:red; font-size:13px"
                  v-show="!$v.passNuevo.minLen"
                >
                  La nueva contraseña debe tener al menos 6 caracteres
                </li>
              </ul>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="success"
            :disabled="!valid || $v.$invalid"
            @click.stop="guardarUsuario"
            >Guardar</v-btn
          >

          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>

<script>
import axios from "../../axios-auth.js";
import { minLength, sameAs } from "vuelidate/lib/validators";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    usuario: "",
    passNuevo: "",
    passNuevoRepetir: "",
    estadoUsuario: true,
  }),

  props: ["visible"],

  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  validations: {
    passNuevo: {
      minLen: minLength(6),
    },
    passNuevoRepetir: {
      //sameAs: sameAs('password')
      sameAs: sameAs((vm) => {
        return vm.passNuevo;
      }),
    },
  },
  methods: {
    guardarUsuario() {
      this.$swal({
        title: "Guardar Usuario",
        text: "¿Desea registrar el usuario?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          //this.$swal('Turno g', 'You successfully deleted this file', 'success')

          const datosUsuario = {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
            usuario: this.usuario,
            clave: this.passNuevo,
            estadoUsuario: this.estadoUsuario,
            idPaciente: null,
            codActivacion: null,
          };
          let self = this;

          axios
            .get("/Usuario/ChequearUnicidadUsuario?", {
              params: {
                usuario: datosUsuario.usuario,
                idInstitucion: datosUsuario.idInstitucion,
              },
            })
            .then((response) => {
              if (response.data != "") {
                self.showAlert({
                  icon: "error",
                  title:
                    "Ya existe un usuario registrado con ese nombre, por favor elija otro.",
                  vm: this,
                  timer: 3000,
                });
              } else {
                axios
                  .post("/Usuario/GuardarUsuario", datosUsuario)
                  .then((response) => {
                    self.$refs.form.resetValidation();

                    document.getElementsByClassName(
                      "v-dialog--active"
                    )[0].scrollTop = 0;
                    self.showAlert({
                      icon: "success",
                      title:
                        "El usuario " +
                        datosUsuario.usuario +
                        datosUsuario.idInstitucion.toString() +
                        " ha sido registrado correctamente",
                      vm: self,
                      timer: 3500,
                    });
                    self.vaciarModels();
                    self.$emit("usuarioRegistrado");
                  })
                  .catch((error) => {
                    self.showAlert({
                      icon: "error",
                      title: "El usuario no pudo ser registrado",
                      vm: self,
                      timer: 2000,
                    });
                  });
              }
            })
            .catch((error) => {});
        } else {
          //this.$swal('Cancelled', 'Your file is still intact', 'info')
        }
      });
    },

    vaciarModels() {
      this.usuario = "";
      this.pass = "";
      this.passNuevo = "";
      this.passNuevoRepetir = "";
      this.estadoUsuario = true;
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: alertInfo.timer,
      });
    },
  },

  created() {
    this.vaciarModels();
  },
};
</script>
