<template>
  <v-dialog
    :max-width="maxWidth"
    v-model="show"
    persistent
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Ver Signo Vital</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-select
                v-if="signoVital.idProfesional"
                :items="profsBuscar"
                item-text="nombreProfesional"
                item-value="idProfesional"
                menu-props="auto"
                label="Profesional"
                hide-details
                prepend-icon="mdi-account"
                v-model="prof"
                readonly
                dense
              ></v-select>
              <v-select
                v-else
                :items="enfermerosBuscar"
                item-text="apellidoNombre"
                item-value="idEnfermero"
                menu-props="auto"
                label="Enfermero"
                hide-details
                prepend-icon="mdi-account"
                v-model="enfermero"
                readonly
                dense
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                readonly
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-human-male-height"
                v-model="estatura"
                label="Estatura"
                suffix="m"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="estatura != null && estatura != '' && estatura > 2.6"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-scale"
                v-model="peso"
                label="Peso"
                suffix="kg"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="peso != null && peso != '' && peso > 400"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-human-handsup"
                v-model="masaCorporal"
                label="I.M.C."
                suffix="kg/m2"
                readonly
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-thermometer"
                v-model="temperatura"
                label="Temperatura"
                suffix="°C"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    temperatura != null &&
                      temperatura != '' &&
                      (temperatura > 45 || temperatura < 5)
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-heart-pulse"
                v-model="frecRespiratoria"
                label="Frec. Resp."
                readonly
                suffix="r/m"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    frecRespiratoria != null &&
                      frecRespiratoria != '' &&
                      frecRespiratoria > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-pulse"
                v-model="sistolica"
                label="Sistólica"
                readonly
                suffix="mmHg"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="sistolica != null && sistolica != '' && sistolica > 300"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-pulse"
                v-model="diastolica"
                label="Diastólica"
                readonly
                suffix="mmHg"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    diastolica != null && diastolica != '' && diastolica > 300
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-heart"
                v-model="frecCardiaca"
                label="Frec. Cardíaca"
                suffix="lpm"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    frecCardiaca != null &&
                      frecCardiaca != '' &&
                      frecCardiaca > 300
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-human"
                v-model="porcGrasaCorp"
                label="% Grasa Corp."
                suffix="%"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    porcGrasaCorp != null &&
                      porcGrasaCorp != '' &&
                      porcGrasaCorp > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-weight-lifter"
                v-model="porcMasaMusc"
                label="% Masa Musc."
                suffix="%"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    porcMasaMusc != null &&
                      porcMasaMusc != '' &&
                      porcMasaMusc > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-baby-face"
                v-model="perimCefalico"
                label="Per. Cefálico"
                suffix="cm"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    perimCefalico != null &&
                      perimCefalico != '' &&
                      perimCefalico > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-lungs"
                v-model="satOxigeno"
                label="Sat. Oxígeno"
                readonly
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="
                    satOxigeno != null && satOxigeno != '' && satOxigeno > 100
                  "
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" md="2" sm="4" class="mr-3">
              <v-text-field
                prepend-icon="mdi-needle"
                v-model="glucemia"
                label="Glucemia"
                suffix="mg/dL"
                maxlength="7"
                :rules="signosRules"
              >
                <template
                  v-slot:append-outer
                  v-if="glucemia != null && glucemia != '' && glucemia > 999"
                >
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on }">
                      <v-icon v-on="on" color="red">
                        mdi-information-outline
                      </v-icon>
                    </template>
                    Fuera de rango
                  </v-tooltip>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="warning" @click.stop="imprimir">Imprimir</v-btn>
          <v-btn color="error" @click.stop="show = false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>
<style scoped>
.v-dialog__content {
  align-items: left;
  justify-content: left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    signosRules: [(v) => !v || v > 0 || "El total debe ser mayor a 0"],
    prof: "",
    profsBuscar: [],
    enfermerosBuscar: [],
    enfermero: null,
    fecha: null,
    fechaHoraAlta: null,
    maxWidth: "100%",
    estatura: null,
    peso: null,
    masaCorporal: null,
    temperatura: null,
    frecRespiratoria: null,
    sistolica: null,
    diastolica: null,
    frecCardiaca: null,
    porcGrasaCorp: null,
    porcMasaMusc: null,
    perimCefalico: null,
    satOxigeno: null,
    glucemia: null,
  }),
  props: {
    visible: { type: Boolean },
    idSignoVital: { type: Number },
    paciente: { type: Object },
    signoVital: {
      required: false,
    },
    origen: { type: String, default: "HC" },
  },
  computed: {
    show: {
      get() {
        if (this.visible) {
          this.setearModels();
          this.getLogoUrl();
        }
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getLogoUrl() {
      let self = this;

      axios
        .get("/HistoriaClinica/GetLogo?", {
          params: {
            idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
          },
        })
        .then((response) => {
          self.base64 = response.data;
        })
        .catch((error) => {});
    },
    imprimir() {
      var doc = new jsPDF();

      doc.setProperties({
        title: "Registro de Signos Vitales",
      });
      //doc.text('Listado de Turnos',20,10);
      var nombreInstitucion = this.$store.state.usuario.institucion.nombreInstitucion
        .toString()
        .trim();

      doc.setFontSize(12);
      doc.text(nombreInstitucion, 13, 10);
      doc.setFontSize(10);
      doc.text(
        "Paciente: " + this.paciente.apellidos + " " + this.paciente.nombres,
        13,
        25
      );

      doc.text("D.N.I.: " + this.paciente.dni, 13, 30);
      doc.text("Feche de Nac.: " + this.paciente.fechaNac, 13, 35);
      doc.text("Edad: " + this.paciente.edad, 13, 40);
      doc.setFontSize(16);

      if (this.base64 != null && this.base64 != "")
        doc.addImage(this.base64, 140, 5, 60, 20);

      doc.text("Signos Vitales", doc.internal.pageSize.getWidth() / 2, 50, {
        align: "center",
      });
      doc.line(87, 51, 123, 51);
      doc.line(13, 60, 200, 60);
      doc.setFontSize(13);
      if (this.signoVital.idProfesional) {
        doc.text(
          "Profesional: " +
            this.signoVital.matricula +
            " - " +
            this.signoVital.nomProfesional,
          13,
          67
        );
      } else {
        doc.text("Enfermero: " + this.signoVital.nomProfesional, 13, 67);
      }
      doc.text("Fecha: " + this.signoVital.fechaHoraString, 13, 73);
      doc.line(13, 76, 200, 76);
      doc.setFontSize(12);

      if (this.signoVital.estatura == null || this.signoVital.estatura == "")
        doc.text("Estatura: S/D", 16, 86);
      else doc.text("Estatura: " + this.signoVital.estatura + " m", 16, 86);

      if (this.signoVital.peso == null || this.signoVital.peso == "")
        doc.text("Peso: S/D", 16, 96);
      else doc.text("Peso: " + this.signoVital.peso + " kg", 16, 96);

      if (
        this.signoVital.masaCorporal == null ||
        this.signoVital.masaCorporal == ""
      )
        doc.text("I.M.C.:: S/D", 16, 106);
      else
        doc.text("I.M.C.: " + this.signoVital.masaCorporal + " kg/m2", 16, 106);

      if (
        this.signoVital.temperatura == null ||
        this.signoVital.temperatura == ""
      )
        doc.text("Temperatura: S/D", 16, 116);
      else
        doc.text(
          "Temperatura: " + this.signoVital.temperatura + " °C",
          16,
          116
        );

      if (
        this.signoVital.porcGrasaCorporal == null ||
        this.signoVital.porcGrasaCorporal == ""
      )
        doc.text("% Grasa Corporal: S/D", 16, 126);
      else
        doc.text(
          "% Grasa Corporal: " + this.signoVital.porcGrasaCorporal + " %",
          16,
          126
        );

      if (
        this.signoVital.porcMasaMuscular == null ||
        this.signoVital.porcMasaMuscular == ""
      )
        doc.text("% Masa Muscular: S/D", 16, 136);
      else
        doc.text(
          "% Masa Muscular: " + this.signoVital.porcMasaMuscular + " %",
          16,
          136
        );

      if (
        this.signoVital.frecRespiratoria == null ||
        this.signoVital.frecRespiratoria == ""
      )
        doc.text("Frec. Respiratoria: S/D", 140, 86);
      else
        doc.text(
          "Frec. Respiratoria: " + this.signoVital.frecRespiratoria + " r/m",
          140,
          86
        );

      if (this.signoVital.sistolica == null || this.signoVital.sistolica == "")
        doc.text("Pres. Sistólica: S/D", 140, 96);
      else
        doc.text(
          "Pres. Sistólica: " + this.signoVital.sistolica + " mmHg",
          140,
          96
        );

      if (
        this.signoVital.diastolica == null ||
        this.signoVital.diastolica == ""
      )
        doc.text("Pres. Diastólica: S/D", 140, 106);
      else
        doc.text(
          "Pres. Diastólica: " + this.signoVital.diastolica + " mmHg",
          140,
          106
        );

      if (
        this.signoVital.frecCardiaca == null ||
        this.signoVital.frecCardiaca == ""
      )
        doc.text("Frec. Cardíaca: S/D", 140, 116);
      else
        doc.text(
          "Frec. Cardíaca: " + this.signoVital.frecCardiaca + " lpm",
          140,
          116
        );

      if (
        this.signoVital.perimCefalico == null ||
        this.signoVital.perimCefalico == ""
      )
        doc.text("Per. Cefálico: S/D", 140, 126);
      else
        doc.text(
          "Per. Cefálico: " + this.signoVital.perimCefalico + " cm",
          140,
          126
        );

      if (
        this.signoVital.satOxigeno == null ||
        this.signoVital.satOxigeno == ""
      )
        doc.text("Sat. Oxígeno: S/D", 140, 136);
      else doc.text("Sat. Oxígeno: " + this.signoVital.satOxigeno, 140, 136);

      if (this.signoVital.glucemia == null || this.signoVital.glucemia == "")
        doc.text("Glucemia: S/D", 16, 146);
      else
        doc.text("Glucemia: " + this.signoVital.glucemia + " mg/dL", 16, 146);

      window.open(doc.output("bloburl"), "_blank");

      //doc.save('turnos.pdf');
    },
    setearModels() {
      const profData = {
        usuario: this.$store.state.usuario.usuario,
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };
      let self = this;
      if (this.signoVital.idProfesional) {
        axios
          .get("/Turnos/BuscarProfesionalesAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.profsBuscar = response.data.profesionales;
          });
      } else {
        axios
          .get("/Enfermeria/BuscarEnfermerosAsociados?", {
            params: {
              usuario: profData.usuario,
              idInstitucion: profData.idInstitucion,
            },
          })
          .then((response) => {
            self.enfermerosBuscar = response.data;
          });
      }
      axios
        .get("/HistoriaClinica/GetSignoVital?", {
          params: {
            idSignoVital: self.idSignoVital,
          },
        })
        .then((response) => {
          self.fecha = response.data.fechaHora.substring(0, 10);
          self.fechaHoraAlta = response.data.fechaHoraAlta;
          self.prof = response.data.idProfesional;
          self.estatura = response.data.estatura;
          self.peso = response.data.peso;
          self.masaCorporal = response.data.masaCorporal;
          self.temperatura = response.data.temperatura;
          self.frecRespiratoria = response.data.frecRespiratoria;
          self.sistolica = response.data.sistolica;
          self.diastolica = response.data.diastolica;
          self.frecCardiaca = response.data.frecCardiaca;
          self.porcGrasaCorp = response.data.porcGrasaCorporal;
          self.porcMasaMusc = response.data.porcMasaMuscular;
          self.perimCefalico = response.data.perimCefalico;
          self.satOxigeno = response.data.satOxigeno;
          self.glucemia = response.data.glucemia;
          self.enfermero = response.data.idEnfermero;
        })
        .catch((error) => {});
      if (this.$store.state.videollamada == true) this.maxWidth = "68%";
      else this.maxWidth = "100%";
    },
    vaciarModels() {
      this.estatura = "";
      this.peso = "";
      this.masaCorporal = "";
      this.temperatura = "";
      this.frecRespiratoria = "";
      this.sistolica = "";
      this.diastolica = "";
      this.frecCardiaca = "";
      this.porcGrasaCorp = "";
      this.porcMasaMusc = "";
      this.perimCefalico = "";
      this.satOxigeno = "";
      this.glucemia = "";
      this.enfermero = null;
    },
  },
};
</script>
