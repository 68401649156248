<template>
  <div class="container">
    <v-card>
      <v-toolbar dark color="primary">
        <v-toolbar-title>Plantillas de Pedidos de Estudio</v-toolbar-title>
      </v-toolbar>

      <v-data-table
        :headers="headers"
        :items="this.listaPlantillas"
        class="elevation-1 row-pointer"
        locale="es-ar"
        item-key="idPlantillaPedido"
        :items-per-page="10"
        ref="myTable"
        :disable-sort="true"
        :footer-props="{
          itemsPerPageText: 'Filas por página'
        }"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="blue"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="verPlantilla(item)"
              >mdi-eye</v-icon>
            </template>
            <span>Ver Plantilla</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                color="purple"
                class="mr-3"
                v-on="on"
                v-bind="attrs"
                @click.stop="editarPlantilla(item)"
              >mdi-pencil</v-icon>
            </template>
            <span>Editar Plantilla</span>
          </v-tooltip>

          <v-tooltip top>
            <template v-slot:activator="{ on, attrs }">
              <v-icon
                medium
                v-on="on"
                color="red"
                v-bind="attrs"
                @click="borrarPlantilla(item)"
              >mdi-delete</v-icon>
            </template>
            <span>Eliminar Plantilla</span>
          </v-tooltip>
        </template>
      </v-data-table>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="success" @click.stop="nuevaPlantilla">Nueva Plantilla</v-btn>
        <v-btn color="error" @click.stop="salir">Salir</v-btn>
      </v-card-actions>
      <NuevaPlantilla
        :visible="showNuevaPlantilla"
        @close="cierraNuevaPlantilla()"
        @plantillaRegistrada="plantillaRegistrada()"
      ></NuevaPlantilla>
      <EditarPlantilla
        :visible="showEditarPlantilla"
        :idPlantillaPedido="editedItem.idPlantillaPedido"
        @close="cierraEditarPlantilla()"
        @plantillaModificada="plantillaModificada()"
      ></EditarPlantilla>
      <VerPlantilla
        :visible="showVerPlantilla"
        :plantilla="editedItem"
        @close="showVerPlantilla=false"
      ></VerPlantilla>
    </v-card>
  </div>
</template>
<style scoped>
::v-deep .v-data-table__empty-wrapper {
  display: none;
}
.row-pointer >>> tbody tr :hover {
  cursor: pointer;
}
</style>
<script>
import axios from "../../axios-auth.js";
import router from "../../router/index.js";
import NuevaPlantilla from "../HistClin/NuevaPlantilla.vue";
import EditarPlantilla from "../HistClin/EditarPlantilla.vue";
import VerPlantilla from "../HistClin/VerPlantilla.vue";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    nombre: null,
    listaPlantillas: [],
    headers: [
      { text: "Nombre Plantilla", value: "nombrePlantillaPedido" },
      { text: "Creada Por", value: "nomProfesional" },
      { text: "Acciones", value: "actions" },
    ],
    showNuevaPlantilla: false,
    showEditarPlantilla: false,
    showVerPlantilla: false,
    editedItem: {},
    editedIndex: -1,
    profsBuscar: [],
  }),
  mounted() {
    const profData = {
      usuario: this.$store.state.usuario.usuario,
      idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
    };
    let self = this;
    axios
      .get("/Turnos/BuscarProfesionalesAsociados?", {
        params: {
          usuario: profData.usuario,
          idInstitucion: profData.idInstitucion,
        },
      })
      .then((response) => {
        self.profsBuscar = response.data.profesionales;
      });
  },
  methods: {
    salir() {
      router.push({ path: "/Home" });
    },

    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 1500,
      });
    },
    borrarPlantilla(item) {
      if (this.profsBuscar.some((o) => o.idProfesional == item.idProfesional)) {
        this.$swal({
          title: "Borrar Plantilla de Pedidos de Estudio",
          text: "¿Desea borrar la plantilla?",
          icon: "success",
          background: "#ececec",
          showClass: {
            popup: "animate__animated animate__fadeInDown",
          },
          hideClass: {
            popup: "animate__animated animate__fadeOutUp",
          },
          showCancelButton: true,
          confirmButtonText: "Si",
          cancelButtonText: "No",
          showCloseButton: true,
          showLoaderOnConfirm: true,
        }).then((result) => {
          if (result.value) {
            //this.$swal('Turno g', 'You successfully deleted this file', 'success')

            const idPlantillaPedido = item.idPlantillaPedido;
            let self = this;
            axios
              .get("/HistoriaClinica/BorrarPlantilla?", {
                params: {
                  idPlantillaPedido: idPlantillaPedido,
                },
              })
              .then((response) => {
                self.showAlert({
                  icon: "success",
                  title: "Plantilla borrada exitosamente",
                  vm: self,
                });
                setTimeout(self.buscarPlantillas(), 1700);
              })
              .catch((error) => {
                self.showAlert({
                  icon: "error",
                  title: "La plantilla no pudo ser borrada",
                  vm: this,
                });
              });
          } else {
            //this.$swal('Cancelled', 'Your file is still intact', 'info')
          }
        });
      } else {
        this.showAlert({
          icon: "error",
          title:
            "La plantilla fue creada por un profesional que usted no tiene asociado. No puede eliminar la plantilla",
          vm: this,
          timer: 3000,
        });
      }
    },
    cierraNuevaPlantilla() {
      this.showNuevaPlantilla = false;
      //this.buscarObrasSociales();
    },
    cierraEditarPlantilla() {
      this.showEditarPlantilla = false;
      //this.buscarObrasSociales();
    },
    plantillaRegistrada() {
      this.showNuevaPlantilla = false;
      this.buscarPlantillas();
    },
    plantillaModificada() {
      this.showEditarPlantilla = false;
      this.buscarPlantillas();
    },
    nuevaPlantilla() {
      this.showNuevaPlantilla = true;
    },
    editarPlantilla(item) {
      this.editedIndex = this.listaPlantillas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showEditarPlantilla = true;
    },
    buscarPlantillas() {
      const filtrosData = {
        idInstitucion: this.$store.state.usuario.institucion.idInstitucion,
      };

      let self = this;
      axios
        .get("/HistoriaClinica/GetPlantillasDeInst?", {
          params: {
            idInstitucion: filtrosData.idInstitucion,
          },
        })
        .then(function (response) {
          if (response.data!=null && response.data!='')
            self.listaPlantillas = response.data;
          else
            self.listaPlantillas = [];
        })
        .catch(function (error) {
          
        });
    },
    verPlantilla(item) {
      this.editedIndex = this.listaPlantillas.indexOf(item);
      this.editedItem = Object.assign({}, item);
      this.showVerPlantilla = true;
    },
  },
  created() {
    this.buscarPlantillas();
  },
  components: {
    NuevaPlantilla,
    EditarPlantilla,
    VerPlantilla,
  },
};
</script>