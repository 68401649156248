<template>

  <v-dialog :max-width="maxWidth" v-model="show" persistent transition="dialog-bottom-transition">
  
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Editar Registro</v-toolbar-title>
      </v-toolbar>
      <v-form ref="form" v-model="valid" :lazy-validation="lazy">
        <v-card-text>
         <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-text-field
                v-model="fecha"
                :value="fecha"
                label="Fecha"
                :rules="requiredRules"
                prepend-icon="mdi-calendar"
                type="date"
                dense
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="dientes"
                item-text="nroDiente"
                item-value="idDiente"
                menu-props="auto"
                label="Diente"
                hide-details
                prepend-icon="mdi-tooth"
                v-model="diente"
                dense
              ></v-select>
            </v-col>
          </v-row>
           <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="caras"
                item-text="nombreCara"
                item-value="idCara"
                menu-props="auto"
                label="Cara"
                hide-details
                prepend-icon="mdi-tooth"
                v-model="cara"
                @change="buscarPracticas(cara)"
                dense
              ></v-select>
            </v-col>
          </v-row>
           <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="practicas"
                item-text="descripcion"
                item-value="idPracticaOdontologica"
                menu-props="auto"
                label="Práctica"
                hide-details
                prepend-icon="mdi-tooth"
                v-model="practica"
                dense
              ></v-select>
            </v-col>
          </v-row>
           <v-row dense>
            <v-col cols="12" sm="7" md="4">
              <v-select
                :items="estados"
                menu-props="auto"
                label="Estado"
                :rules="requiredRules"
                hide-details
                prepend-icon="mdi-tooth"
                :color="getColor()"
                v-model="estado"
                dense
              ></v-select>
            </v-col>
          </v-row>
           <v-row dense>
             <v-col cols="12" sm="7" md="4">
              <v-textarea
                prepend-icon="mdi-tooth"
                v-model="observaciones"
                label="Observaciones"
                clearable
                auto-grow
              ></v-textarea>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="success" :disabled="!valid" @click.stop="modificarRegistro">Guardar</v-btn>
          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
    </v-dialog>
 
</template>
<style scoped>
.v-dialog__content {
   align-items:left;
   justify-content:left;
}
</style>
<script>
import axios from "../../axios-auth.js";

export default {
  data: (vm) => ({
    valid: false,
    lazy: false,
    requiredRules: [(v) => !!v || "Dato obligatorio"],
    observaciones: '',
    cara: "",
    caras: [],
    diente: "",
    dientes: [],
    estado: "",
    estados: ['A Realizar','Realizado','Rehacer'],
    practica: "",
    practicas: [],
    fecha: null,
    
    maxWidth: '100%'
  }),
  props: ["visible", "idPaciente","idOdontograma","idCara"],
  computed: {
    show: {
      get() {
        if(this.visible)
          this.setearModels();
        return this.visible;
      },
      set(value) {
        if (!value) {
          this.$refs.form.resetValidation();
          this.vaciarModels();
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  methods: {
    getColor(){
      if(this.estado == "")
      return 'black'
      if(this.estado == "A Realizar")
      return 'blue'
      if(this.estado == 'Realizado')
      return 'red'
       if(this.estado == 'Rehacer')
      return 'green'
    },
    modificarRegistro() {
      this.$swal({
        title: "Modificar Práctica",
        text: "¿Desea modificar la práctica?",
        icon: "success",
        type: "success",
        showCancelButton: true,
        background: "#ececec",
        confirmButtonText: "Si",
        cancelButtonText: "No",
        showCloseButton: true,
        showLoaderOnConfirm: true,
      }).then((result) => {
        if (result.value) {
          
          let est = 0;
          if(this.estado == "Realizado")
            est = 1;
          else
          {
           if(this.estado == "A Realizar")
            est = 2;
            else
            est = 3;
          }
          if(this.practica == 8)
            est = 1;
          const datosOdontograma = {
            fecha: this.fecha,
            idPaciente: this.idPaciente,
            idDiente: this.diente,
            idCara: this.cara,
            idPracticaOdontologica: this.practica,
            estado: est,
            observaciones: this.observaciones,
            idOdontograma: this.idOdontograma           
          };
          let self = this;
          axios
            .post("/HistoriaClinica/ModificarOdontograma", datosOdontograma)
            .then((response) => {
              self.$refs.form.resetValidation();
              document.getElementsByClassName(
                "v-dialog--active"
              )[0].scrollTop = 0;
              self.showAlert({
                icon: "success",
                title: "Práctica modificada exitosamente",
                vm: self,
              });
              self.$emit('odontogramaModificado')
            })
            .catch((error) => {
              
              self.showAlert({
                icon: "error",
                title: "La práctica no pudo ser modificada",
                vm: this,
              });
            });
        }
      });
    },
    buscarPracticas(face){
       let self = this;
       axios
        .get("/HistoriaClinica/GetPracticasOdontologicas?",{
          params: {
            idCara: face
          }
        }
        )
        .then((response) => {
          self.practicas = response.data;
          self.practica = self.practicas[0].idPracticaOdontologica;
          
        }).catch({});
    },

    setearModels() {
      
      let self = this;
      axios
        .get("/HistoriaClinica/GetDientes?"
        )
        .then((response) => {
          self.dientes = response.data;
         
          
        }).catch({});
      axios
        .get("/HistoriaClinica/GetCaras?"
        )
        .then((response) => {
          self.caras = response.data;
         
          
        }).catch({});

        this.buscarPracticas(self.idCara);
       
       const idOdontograma = this.idOdontograma;

       axios.get("/HistoriaClinica/GetRegistroOdontograma?", {
           params: {
               idOdontograma: idOdontograma
           }
       }).then(
           response => {
               self.diente = response.data.idDiente;
               self.cara = response.data.idCara;
               self.practica = response.data.idPracticaOdontologica;
               self.observaciones = response.data.observaciones;
               self.fecha = response.data.fecha.substring(0,10);
               const est = response.data.estado
               if(est == 1)
               self.estado = 'Realizado'
               if(est == 2)
               self.estado = 'A Realizar'
               if(est == 3)
               self.estado = 'Rehacer'
           }
       )
      
     
      if(this.$store.state.videollamada == true)
        this.maxWidth = '68%'
      else
        this.maxWidth = '100%'
    },

    parseDate(date) {
      if (!date) return null;
      const [day, month, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);
      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        "Z"
      )
        .toString()
        .substr(0, 10);
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${day}/${month}/${year}`;
    },

    vaciarModels() {
      this.estado= "";
      this.observaciones= "";
    },
    showAlert(alertInfo) {
      alertInfo.vm.$swal.fire({
        icon: alertInfo.icon,
        title: alertInfo.title,
        showConfirmButton: false,
        background: "#ececec",
        timer: 2000,
      });
    },
  },
};
</script>