<template>
  <v-dialog :max-width="maxWidth" v-model="show" persistent transition="dialog-bottom-transition">
    <v-card >
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Plantilla de Pedido de Estudio</v-toolbar-title>
      </v-toolbar>
        <v-card-text>
             <v-row class="my-4 mx-1">
                <h3>Creada por: {{plantilla.nomProfesional}}</h3>
            </v-row>
            <v-row class="my-4 mx-1">
                <h3>Nombre de la Plantilla: {{plantilla.nombrePlantillaPedido}}</h3>
            </v-row>
            
            <v-row class="mt-4 mx-1">
                <h3>Texto de la Plantilla:</h3>
            </v-row>
            <v-row class="ml-1">
                <v-alert class="multi-line">{{plantilla.textoPlantillaPedido}}</v-alert>
            </v-row>
           
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" @click.stop="show=false">Salir</v-btn>
        </v-card-actions>
     
    </v-card>
  </v-dialog>
</template>
<style scoped>
.multi-line {
  white-space: pre-line;
}
.v-dialog__content {
   align-items:left;
   justify-content:left;
}
</style>
<script>
import axios from "../../axios-auth.js";
import jsPDF from "jspdf";
import autotable from "jspdf-autotable";
export default {
  data: (vm) => ({
   maxWidth: '100%'
  }),
  props: ["visible", "plantilla"],
  computed: {
    show: {
      get() {
      if(this.$store.state.videollamada == true)
        this.maxWidth = '68%'
      else
        this.maxWidth = '100%'
        return this.visible;
      },
      set(value) {
        if (!value) {
         
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      },
    },
  },
  
  
};
</script>