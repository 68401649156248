<template>
  <v-dialog v-model="show" fullscreen transition="dialog-bottom-transition">
      <v-form>
    <v-card>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="show = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Referencias Odontograma</v-toolbar-title>
      </v-toolbar>
      
      <v-card-title>Colores</v-card-title> 
      <v-card-text>        
        <v-row dense>
            <v-col cols="12" sm="4" md="4">
            <v-chip 
            text-color="white" 
            class="ma-2" 
            color="red">Realizado</v-chip>
          </v-col>          
          <v-col cols="12" sm="4" md="4">
            <v-chip 
            text-color="white" 
            class="ma-2" 
            color="blue">A Realizar</v-chip>
          </v-col>
           <v-col cols="12" sm="4" md="4">
            <v-chip
              text-color="white"
              class="ma-2"
              color="green"
            >Rehacer</v-chip>
          </v-col>
          
        </v-row>
      </v-card-text>
      <v-card-title>Dibujos</v-card-title>
      <v-card-text>
        <div style="overflow: auto">
        <svg id="svg" width="830px" height="220px">
          <g transform="scale(2), translate(5,10)">
            
            <g transform="translate(0,0)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              
              <line x1="0" y1="0" x2="20" y2="20" stroke="red" stroke-width= "1"/>
              <line x1="0" y1="20" x2="20" y2="0" stroke="red" stroke-width= "1"/>
              <text x="23" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Extracción</text>
            </g>

            <g transform="translate(0,40)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              
              <text x="0" y="28" fill="red" stroke="red" stroke-width="0.1" style="font-size: 5.5pt; font-weight: normal">TC</text>
              <text x="23" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Trat. de Conducto</text>
            </g>

            <g transform="translate(100,0)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              <circle cx="10" cy="10" r="9" stroke="red" stroke-width="1" fill="none"></circle>
              <text x="23" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Corona</text>
            </g>

            <g transform="translate(100,40)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="blue" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="green" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="red" stroke="black" stroke-width="0.5" color="red"></polygon>
              <text x="23" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Arreglos</text>
            </g>

            <g transform="translate(200,0)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              <polygon points="-2,-2 22,-2 22,22, -2,22" fill="none" stroke="red" stroke-width="0.5" color="red"></polygon>
              <text x="25" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Prótesis Fija</text>
            </g>

            <g transform="translate(200,40)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              <polygon style="cursor: pointer" points="-2,-2 22,-2 22,22, -2,22" fill="none" stroke="red" stroke-width="0.5" color="red"></polygon>
              <line x1="6" y1="-2" x2="14" y2="-2" style="stroke: rgb(255, 255, 255); stroke-width: 1"/>
              <line x1="6" y1="22" x2="14" y2="22" style="stroke: rgb(255, 255, 255); stroke-width: 1"/>
              <text x="25" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Prot. Removible</text>
            </g>

            <g transform="translate(300,0)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              <text x="16" y="28" fill="red" stroke="red" stroke-width="0.1" style="font-size: 5.5pt; font-weight: normal">I</text>
              <text x="23" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Implante</text>
            </g>
            
            <g transform="translate(300,40)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              <line x1="0" y1="20" x2="20" y2="0" stroke="red" stroke-width= "1"/>
              <text x="23" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Ausencia Diente</text>
            </g>

            <g transform="translate(0,80)">
              <polygon style="cursor: pointer" points="5,15 15,15 20,20 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 20,0 15,5 5,5" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="15,5 20,0 20,20 15,15" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="0,0 5,5 5,15 0,20" fill="white" stroke="black" stroke-width="0.5" color="black"></polygon>
              <polygon style="cursor: pointer" points="5,5 15,5 15,15, 5,15" fill="white" stroke="black" stroke-width="0.5" color="red"></polygon>
              <line x1="0" y1="7" x2="20" y2="7" stroke="red" stroke-width= "1"/>
              <line x1="0" y1="10" x2="20" y2="10" stroke="red" stroke-width= "1"/>
              <line x1="0" y1="13" x2="20" y2="13" stroke="red" stroke-width= "1"/>
              <text x="23" y="12" fill="black" stroke="black" stroke-width="0.1" style="font-size: 6pt; font-weight: normal">Ortodoncia</text>
            </g>

            
          </g>
        </svg>
        </div>
      </v-card-text>
        <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="error" @click.stop="show=false">Salir</v-btn>
      </v-card-actions>
    </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
export default {
  computed: {
    show: {
      get() {
        return this.visible;
      },
      set(value) {
        if (!value) {
          document.getElementsByClassName("v-dialog--active")[0].scrollTop = 0;
          this.$emit("close");
        }
      }
    }
  },
  props: ["visible"]
};
</script>